import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import store from 'store';
import generateTheme from 'theme';

import reportWebVitals from 'reportWebVitals';

import FireDangerViewer from 'apps/FireDangerViewer';
import FuelStateEditor from 'apps/FuelStateEditor';
import GlobalStyleSheet from 'utils/styles';
import ThunkErrorReporter from 'utils/ErrorReporting';

/*
 * React StrictMode should be added when the following issues are solved:
 * - Material UI: https://github.com/mui-org/material-ui/issues/13394
 *    Fix already implmented and will be released in the next major version planned Q3 2020
 *
 * - React Swipable Views: https://github.com/oliviertassinari/react-swipeable-views/issues/534
 *    Solved when new maintainer is on it, updates as recent as the day of writing this comment.
 *    Requested maintainence access just in case.
 *
 *
 * To add, simply wrap <React.StrictMode> ... </React.StrictMode>
 * around the JSX in the render function
 */

const BaseComponent: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <CssBaseline />
    <GlobalStyleSheet />
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={generateTheme()}>
          <ThunkErrorReporter>
            <Router>{children}</Router>
          </ThunkErrorReporter>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.REACT_APP_ID === 'FireDangerViewer') {
  root.render(
    <BaseComponent>
      <FireDangerViewer />
    </BaseComponent>,
  );
} else {
  root.render(
    <BaseComponent>
      <FuelStateEditor />
    </BaseComponent>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') reportWebVitals(console.log);
