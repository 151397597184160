import React from 'react';
import { MobileView, BrowserView } from 'react-device-detect';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, AppBar, Toolbar, Link, Typography, Menu, MenuItem } from '@material-ui/core';
import { Info, Person } from '@material-ui/icons';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks';
import { AnalyticsActions } from 'state/analytics';
import { AuthManager } from 'models';
import ToastComponent from './ToastComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      backgroundColor: theme.palette.background.nav,
    },
    right: {
      position: 'relative',
      marginLeft: 'auto',
      width: 'auto',
      marginRight: theme.spacing(2),
    },
    link: {
      color: theme.palette.text.link,
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
      fontSize: 'larger',
    },
    navBtn: {
      display: 'inline-flex',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface NavBarProps {
  menuLocation?: string;
}

const NavBar: React.FunctionComponent<NavBarProps> = ({ menuLocation }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { auth, user } = useAppSelector((state) => state);
  let userText = 'Not Logged In';
  if (user.user.status === 'loading' || auth.status === 'loading') {
    userText = 'Logging In...';
  } else if (user.user.status === 'finished' && user.user.object) {
    userText = `${user.user.object.firstName} ${user.user.object.lastName}`;
  }

  const isUserManager = auth.object?.decoded_token?.['cognito:groups']
    ? auth.object.decoded_token['cognito:groups'].indexOf('UserManager') > -1
    : false;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserManagerClick = () => {
    handleMenuClose();
    navigate('/admin/users');
  };

  const handleMyDetailsClick = () => {
    handleMenuClose();
    navigate('/self/mydetails');
  };

  const onLogoutClick = () => {
    handleMenuClose();
    dispatch(AnalyticsActions.postLogout()).finally(() => {
      AuthManager.logout();
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link component={RouterLink} to={menuLocation ?? '/'}>
            <img
              aria-label="Navigate to menu"
              src="/logo-2x/default.png"
              style={{
                width: 148,
                height: 24,
              }}
              alt=""
            />
          </Link>
          <div className={classes.right}>
            <MobileView>
              <Typography>
                <Link className={classes.link} component={RouterLink} to="/help">
                  <Info className={classes.icon} /> Help
                </Link>
              </Typography>
            </MobileView>
            <BrowserView>
              <Typography style={{ display: 'grid', placeItems: 'center', gridAutoFlow: 'column' }}>
                <Link className={classes.link} component={RouterLink} to="/help">
                  <Info className={classes.icon} /> Help
                </Link>
                <Link
                  className={classes.link}
                  title={auth.object?.decoded_token?.email}
                  component="button"
                  onClick={handleMenuClick}
                >
                  <Person className={classes.icon} /> {userText}
                </Link>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  {isUserManager && <MenuItem onClick={handleUserManagerClick}>User Manager</MenuItem>}
                  <MenuItem onClick={handleMyDetailsClick}>My Details</MenuItem>
                  <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                </Menu>
              </Typography>
            </BrowserView>
          </div>
        </Toolbar>
      </AppBar>
      <ToastComponent />
    </div>
  );
};

NavBar.displayName = 'NavBar';
export default NavBar;
