import { AuthDomains } from 'models/authdomains';
import { AuthDomainsActions } from 'state/authdomains';

/**
 * Checkes if the auth domains are available in local storage, and / or
 * are expired.
 *
 * If either are true, the method will request new data from the server, and
 * store that data in local storage in anticipation for the next time this data
 * might be needed.
 *
 * @param dispatch - A valid `ThunkDispatch` method.
 */
async function authDomainDataLoader(dispatch: any): Promise<AuthDomains[]> {
  // Check local storage for the data
  const authDomainsKey = 'authDomains';
  const authDomainsTTLKey = 'authDomainsTTL';

  const authDomainLocal = localStorage.getItem(authDomainsKey);
  const authDomainTTL = localStorage.getItem(authDomainsTTLKey);

  if (authDomainLocal == null || authDomainTTL == null || Date.now() >= parseInt(authDomainTTL)) {
    // Auth Domains data is NOT in local storage, OR the data in local
    // storage has expired.

    // Dispatch a request for the data
    const result = await dispatch(AuthDomainsActions.getAuthDomains());

    // Save this to local storage
    localStorage.setItem(authDomainsKey, JSON.stringify(result));

    // Set the TTL to 10 minutes
    const tenMinutesLater = Date.now() + 10 * 60 * 1000;
    localStorage.setItem(authDomainsTTLKey, tenMinutesLater.toString());

    return result;
  } else {
    return JSON.parse(authDomainLocal);
  }
}

export default authDomainDataLoader;
