import React from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { LocationManager, MapLayerManager } from 'models';
import { toReadableAge, getFullName } from 'utils';
import { LoadableImage, Note } from 'components';
import { fuelConditionText } from 'models/location';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
    },
    title: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    subtitle: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    personText: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    accordion: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: `1px solid ${theme.palette.common.neutralXLight}`,
      padding: theme.spacing(3),
    },
    note: {
      width: '100%',
      margin: theme.spacing(1),
    },
    noteMeta: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
  }),
);

type FireAreaLocationItemProps = {
  location: LocationManager.Location;
  layer: MapLayerManager.MapLayer;
};

const formatValue = (
  mapLayer: MapLayerManager.MapLayer,
  obs: LocationManager.Location.Observation | undefined | null,
) => {
  if (obs == null) return '';
  switch (mapLayer?.type) {
    case 'grass-curing':
      return obs.curing != null ? `${obs.curing.toFixed(0)}%` : 'null';
    case 'grass-fuel-condition':
      return obs.fuelCondition != null ? `${fuelConditionText(obs.fuelCondition)}` : 'null';
    case 'grass-fuel-load':
      return obs.fuelLoad != null ? `${obs.fuelLoad.toFixed(1)} t/ha` : 'null';
    default:
      return '';
  }
};

const FireAreaLocationItem: React.FunctionComponent<FireAreaLocationItemProps> = ({ location, layer }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { name, observations } = location;
  const latestObs = observations.find((obs) => obs.validated === true);

  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div>
          <Typography variant="h6" className={classes.title}>
            {`${name}: ${latestObs != null ? formatValue(layer, latestObs) : 'N/A'}`}
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Observed {latestObs?.time ? toReadableAge(Date.now() - latestObs.time) : 'N/A'}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordion}>
        <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
          <div>
            <div style={{ padding: theme.spacing(1) }}>
              <Typography variant="subtitle2" className={classes.personText}>
                Observer: {getFullName(latestObs?.observer)}
              </Typography>
              <Typography variant="subtitle2" className={classes.personText}>
                Validator: {getFullName(latestObs?.validator)}
              </Typography>
            </div>
            <div>
              {latestObs?.notes.map((note) => (
                <Note key={`note-${note.id}`} note={note} />
              ))}
            </div>
          </div>
          <div style={{ minHeight: 120 }}>
            <LoadableImage
              imgStyle={{ maxHeight: '-webkit-fill-available' }}
              divStyle={{ height: '100%', justifyItems: 'end', alignItems: 'start' }}
              src={latestObs?.imageUrl}
              alt="Observation Photo"
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

FireAreaLocationItem.displayName = 'FireAreaLocationItem';
export default FireAreaLocationItem;
