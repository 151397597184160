import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    checkedItem: {
      border: `2px solid ${theme.palette.common.green}`,
      display: 'flex',
      verticalAlign: 'middle',
      margin: theme.spacing(1),
      cursor: 'pointer',
    },
    checkedIcon: {
      color: theme.palette.common.green,
      margin: theme.spacing(1),
    },
    checkedText: {
      color: theme.palette.common.black,
      padding: theme.spacing(1),
    },
    uncheckedItem: {
      display: 'flex',
      verticalAlign: 'middle',
      margin: theme.spacing(1),
      cursor: 'pointer',
    },
    uncheckedIcon: {
      color: theme.palette.common.neutral,
      margin: theme.spacing(1),
    },
    uncheckedText: {
      color: 'grey',
      padding: theme.spacing(1),
    },
  }),
);

interface ButtonLikeRadioInputProps {
  options: { text: string; value: number }[];
  onChange: (value: number) => void;
  initialValue: number;
  style?: React.CSSProperties;
}

const ButtonLikeRadioInput: React.FunctionComponent<ButtonLikeRadioInputProps> = ({
  options,
  onChange,
  initialValue,
  style,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue || 0);

  useEffect(() => {
    if (onChange) onChange(initialValue ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} style={style}>
      {options.map((option) => {
        if (option.value === value)
          return (
            <Paper key={option.text} className={classes.checkedItem}>
              <RadioButtonChecked className={classes.checkedIcon} />
              <Typography variant="h6" className={classes.checkedText}>
                {option.text}
              </Typography>
            </Paper>
          );
        return (
          <Paper
            key={option.text}
            className={classes.uncheckedItem}
            onClick={() => {
              setValue(option.value);
              onChange(option.value);
            }}
          >
            <RadioButtonUnchecked className={classes.uncheckedIcon} />
            <Typography variant="h6" className={classes.uncheckedText}>
              {option.text}
            </Typography>
          </Paper>
        );
      })}
    </div>
  );
};

ButtonLikeRadioInput.displayName = 'ButtonLikeRadioInput';
export default ButtonLikeRadioInput;
