import { makeAPICall } from './api';

export declare namespace API {
  // TODO - Put stuff in here
  export interface AuthDomains {
    provider_id: number;
    provider_name: string;
    provider_type: string;
    domain_names: string[];
  }
}

export interface AuthDomains {
  provider_id: number;
  provider_name: string;
  provider_type: string;
  domain_names: string[];
}

const mapAPIAuthDomainsToAuthDomains = (authDomains: API.AuthDomains): AuthDomains => ({
  provider_id: authDomains.provider_id,
  domain_names: authDomains.domain_names,
  provider_name: authDomains.provider_name,
  provider_type: authDomains.provider_type,
});

export const getAuthDomains = makeAPICall<AuthDomains[] | null>(
  // `GET` request, therefore no payload
  () => ({
    ext: '/auth-domains',
  }),
  (root) => {
    return root.map(mapAPIAuthDomainsToAuthDomains);
  },
);
