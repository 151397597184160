import { makeAPICall } from './api';

export declare namespace API {
  export interface Item {
    help_topic_id: number;
    title: string;
    long_title: string;
    tags: string[];
    summary: string;
    topic: string;
    group_name: string;
    related_topics: number[];
    column_span: number;
    external_link?: string;
    content?: string;
    priority?: number;
    created: string;
    modified: string;
  }

  export interface ListRoot {
    count: number;
    data: Item[];
    updated: Date;
  }
}

export interface Item {
  id: number;
  title: string;
  longTitle?: string;
  tags: string[];
  summary?: string;
  topic: string;
  group: string;
  related: number[];
  columnSpan?: number;
  externalLink?: string;
  content?: string;
  priority: number;
  updated: Date;
  created: Date;
}

export type AddItemPayload = { item: Omit<Item, 'id' | 'updated' | 'created'> };

export type EditItemPayload = { item: Omit<Item, 'updated' | 'created'> };

const mapAPIItemToItem = (item: API.Item): Item => ({
  id: item.help_topic_id,
  title: item.title,
  longTitle: item.long_title,
  tags: item.tags,
  summary: item.summary,
  topic: item.topic,
  group: item.group_name,
  related: item.related_topics,
  columnSpan: item.column_span,
  externalLink: item.external_link,
  priority: item.priority ?? 0,
  content: item.content,
  updated: new Date(item.modified),
  created: new Date(item.created),
});

export const getList = makeAPICall<Item[], void, API.ListRoot>(
  () => ({
    ext: '/help/',
  }),
  (root) => root.data.map(mapAPIItemToItem).sort((a, b) => b.priority - a.priority),
);

export const getItem = makeAPICall<Item | null, { id: number }, API.ListRoot>(
  (payload) => ({
    ext: `/help/${payload.id}`,
  }),
  (root) => {
    if (root.data.length === 0) return null;

    return mapAPIItemToItem(root.data[0]);
  },
);

export const addItem = makeAPICall<null, AddItemPayload>(
  (payload) => {
    const { item } = payload;

    const data: any = {};

    data.title = item.title;
    if (item.longTitle) data.long_title = item.longTitle;
    data.tags = item.tags;
    data.summary = item.summary ?? '';
    data.topic = item.topic;
    data.group_name = item.group;
    data.related_topics = item.related;
    data.column_span = item.columnSpan ?? 1;
    data.priority = item.priority ?? 0;
    if (item.externalLink) data.external_link = item.externalLink;
    data.content = item.content ?? '';

    return {
      ext: `/help`,
      contentType: 'application/json',
      requestData: {
        method: 'POST',
        body: JSON.stringify(data),
      },
    };
  },
  () => null,
);

export const editItem = makeAPICall<null, EditItemPayload>(
  (payload) => {
    const { item } = payload;

    const data: any = {};

    data.title = item.title;
    if (item.longTitle) data.long_title = item.longTitle;
    data.tags = item.tags;
    data.summary = item.summary ?? '';
    data.topic = item.topic;
    data.group_name = item.group;
    data.related_topics = item.related;
    data.column_span = item.columnSpan ?? 1;
    data.priority = item.priority ?? 0;
    if (item.externalLink) data.external_link = item.externalLink;
    data.content = item.content ?? '';

    return {
      ext: `/help/${item.id}`,
      contentType: 'application/json',
      requestData: {
        method: 'PUT',
        body: JSON.stringify(data),
      },
    };
  },
  () => null,
);

export const deleteItem = makeAPICall<null, { id: number }>(
  (payload) => {
    const { id } = payload;

    return {
      ext: `/help/${id}`,
      contentType: null,
      requestData: {
        method: 'DELETE',
      },
    };
  },
  () => null,
);
