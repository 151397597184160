import { makeAPICall } from './api';

export declare namespace LocationAssignment {
  export interface User {
    user_id: number;
    user_name: string;
    user_email: string;
  }

  export interface Geometry {
    type: string;
  }

  export interface Location {
    location_id: number;
    location_name: string;
    geometry: Geometry;
  }

  export interface Role {
    role_id: number;
    role_name: string;
  }

  export interface LocationAssignment {
    user: User;
    location: Location;
    role: Role;
  }

  export interface RootObject {
    data: LocationAssignment[];
    count: number;
  }
}

export const getAssignmentsForLocation = makeAPICall<LocationAssignment.LocationAssignment[], { locationId: number }>(
  (payload) => ({
    ext: `/assigned-locations/${payload.locationId}`,
  }),
  (x: LocationAssignment.RootObject) => x.data,
);

export enum UserLocationActions {
  add,
  remove,
}

export const updateUserRoleOnLocation = makeAPICall<
  null,
  { role: string; userId: number; locationId: number; action: UserLocationActions }
>(
  (payload) => {
    const data = new FormData();

    data.append('user_id', `${payload.userId}`);
    data.append('location_id', `${payload.locationId}`);
    data.append('role', payload.role);

    return {
      ext: '/assigned-locations',
      contentType: null,
      requestData: {
        method: payload.action === UserLocationActions.add ? 'POST' : 'DELETE',
        body: data,
      },
    };
  },
  () => null,
);
