/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Grid } from '@material-ui/core';

import { MapLayerManager } from 'models';
import { EditorMenuButton } from 'components';
import { toTimeDDMonYearFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    map: {
      width: '100%',
      height: '100%',
    },
    drawer: {
      width: '50%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '50%',
    },
    drawerContent: {
      padding: theme.spacing(1),
    },
    error: {
      color: 'red',
      fontWeight: 'bold',
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    subtitle: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.neutralDark, // neutralDark
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      left: '0%',
    },
    contentShift: {
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: '50%',
    },
  }),
);

interface WorkflowInitComponentProps {
  layer?: MapLayerManager.MapLayer | null;
  onRegenerateClick?: () => void;
}

const EditorWorkflowInitComponent: React.FunctionComponent<WorkflowInitComponentProps> = ({
  layer,
  onRegenerateClick,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        {layer ? (
          <>
            <Typography variant="h5" className={classes.title}>
              Process {layer.name} map
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Created: {toTimeDDMonYearFormat(new Date(layer.created))}
            </Typography>
            {layer.status === 'rejected' && (
              <Typography variant="subtitle1" className={classes.error}>
                Rejected
              </Typography>
            )}
            {layer.status === 'error' && (
              <Typography variant="subtitle1" className={classes.error}>
                Error: {layer.error || 'Unkown Error: Check Notes'}
              </Typography>
            )}
            <Typography variant="subtitle1" className={classes.subtitle}>
              Start your review by selecting one of the following:
            </Typography>
            {!(layer.type === 'time-since-fire' || layer.type === 'fuel-type' || layer.status === 'authorised') && (
              <EditorMenuButton
                title={`${layer.name} map`}
                summary="Explore the map layers data and make edits"
                to={`/editor/layer/${layer.id}/arealist`}
              />
            )}
            {layer.status !== 'authorised' && (
              <EditorMenuButton
                title="Upload / Download"
                summary="Download the .tif file to make edits and upload"
                to={`/editor/layer/${layer.id}/download`}
              />
            )}

            {!(layer.status === 'error' || layer.status === 'authorised') && (
              <EditorMenuButton
                title="Submit"
                summary="Add notes and submit for authorisation"
                to={`/editor/layer/${layer.id}/submit`}
              />
            )}

            {!(layer.type === 'time-since-fire' || layer.type === 'fuel-type') && (
              <EditorMenuButton
                title="Regenerate"
                summary="Regenerate the layer with the latest data"
                onClick={onRegenerateClick}
              />
            )}
            <EditorMenuButton title="Notes" summary="View all notes" to={`/editor/layer/${layer.id}/notes`} />
          </>
        ) : (
          <Typography variant="h5" className={classes.title}>
            Layer Not Found
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default EditorWorkflowInitComponent;
