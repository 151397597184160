import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

interface LoadableImageProps {
  src?: string;
  aspectRatio?: number;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  divStyle?: React.CSSProperties;
  alt?: string;
  className?: string;
  divClassName?: string;
  progressSize?: number;
  width?: number;
  height?: number;
}

const LoadableImage: React.FunctionComponent<LoadableImageProps> = ({
  src,
  style,
  imgStyle,
  divStyle,
  alt,
  aspectRatio,
  className,
  divClassName,
  progressSize,
  width,
  height,
}) => {
  const [isLoaded, setIsLoaded] = useState(src == null);

  const loadingStyle: React.CSSProperties = {
    display: 'grid',
    placeItems: 'center',
  };
  if (width != null) loadingStyle.width = width;
  if (height != null) loadingStyle.height = height;

  if (aspectRatio)
    return (
      <div
        style={{
          position: 'relative',
          height: 0,
          overflow: 'hidden',
          paddingTop: `${aspectRatio * 100}%`,
          ...style,
          ...divStyle,
        }}
        className={divClassName}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {src && (
              <img
                src={src}
                alt={alt}
                className={className}
                onLoad={() => setIsLoaded(true)}
                width={width}
                height={height}
                style={{
                  display: isLoaded ? 'block' : 'none',
                  ...style,
                  ...imgStyle,
                }}
              />
            )}
            {!isLoaded && (
              <div style={loadingStyle}>
                <CircularProgress size={progressSize ?? 20} aria-valuetext="loading" />
              </div>
            )}
          </div>
        </div>
      </div>
    );

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        ...style,
        ...divStyle,
      }}
    >
      {src && (
        <img
          src={src}
          alt={alt}
          className={className}
          onLoad={() => setIsLoaded(true)}
          width={width}
          height={height}
          style={{
            display: isLoaded ? 'block' : 'none',
            maxWidth: '100%',
            maxHeight: '100%',
            ...imgStyle,
          }}
        />
      )}
      {!isLoaded && (
        <div style={loadingStyle}>
          <CircularProgress size={progressSize ?? 20} aria-valuetext="loading" />
        </div>
      )}
    </div>
  );
};

LoadableImage.displayName = 'LoadableImage';
export default LoadableImage;
