/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { UserManager } from 'models';
import { getFullName } from 'utils';
import PrimaryButton from 'components/PrimaryButton';

const UserSelector: FunctionComponent<{
  onSelect: (user: UserManager.User.User) => void;
  users: UserManager.User.User[];
  id: string;
}> = ({ onSelect, users, id }) => {
  const [value, setValue] = useState<UserManager.User.User | null>(null);

  return (
    <>
      <div
        id={id}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          marginBottom: 24,
        }}
      >
        <Autocomplete
          options={users}
          value={value}
          onChange={(_, val) => val && setValue(val)}
          getOptionLabel={(option) => `${getFullName(option)} ${option.user_email}`}
          style={{ flex: 1, marginRight: 8, height: 40 }}
          renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
        />
        <PrimaryButton id={`${id}Add`} onClick={() => value && onSelect(value)} disabled={!value}>
          Add
        </PrimaryButton>
      </div>
    </>
  );
};

export default UserSelector;
