import React, { PropsWithChildren } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { MapOverlay } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.white,
      border: '0px',
      '&:focus': {
        outline: '0px',
      },
      cursor: 'pointer',
    },
  }),
);

interface FloatingMapButtonProps extends PropsWithChildren {
  ariaLabel?: string | undefined;
  width?: string | number;
  height?: string | number;
  top?: string | number;
  left?: string | number;
  opacity?: number;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  className?: string;
  muiButton?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  flex?: boolean;
}

const FloatingMapButton: React.FunctionComponent<FloatingMapButtonProps> = ({
  ariaLabel = undefined,
  width = '45px',
  height = '45px',
  top = '0%',
  left = '0%',
  opacity = 1,
  style = {},
  buttonStyle,
  className,
  onClick,
  children,
  muiButton,
  flex = false,
}) => {
  const classes = useStyles();
  return (
    <MapOverlay
      top={top}
      left={left}
      width={width}
      height={height}
      opacity={opacity}
      flex={flex}
      style={{
        borderRadius: width,
        boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
        ...style,
      }}
      className={className || ''}
    >
      {muiButton ? (
        <Button aria-label={ariaLabel} className={classes.root} style={buttonStyle} type="button" onClick={onClick}>
          {children}
        </Button>
      ) : (
        <button aria-label={ariaLabel} className={classes.root} style={buttonStyle} type="button" onClick={onClick}>
          {children}
        </button>
      )}
    </MapOverlay>
  );
};

FloatingMapButton.displayName = 'FloatingMapButton';
export default FloatingMapButton;
