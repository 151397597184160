import { createReducer } from '@reduxjs/toolkit';

import { IncidentManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type IncidentState = {
  all: Loadable<IncidentManager.Incident[]>;
  identify: Loadable<IncidentManager.Incident[]>;
};

const initialState: IncidentState = {
  all: {
    status: 'idle',
  },
  identify: {
    status: 'idle',
  },
};

export const [getIncidentsByBBox, setIdentify] = makeThunkFromAPICall(
  IncidentManager.getIncidentsByBBox,
  'incidents/identify',
);

export const [getIncidents, setIncidents] = makeThunkFromAPICall(IncidentManager.getIncidents, 'incidents/set');

export const incidentReducer = createReducer(initialState, (builder) => {
  builder.addCase(setIncidents, (state, action) => {
    return { ...state, all: { ...state.all, ...action.payload.loadable } };
  });

  builder.addCase(setIdentify, (state, action) => {
    return { ...state, identify: { ...state.identify, ...action.payload.loadable } };
  });
});

export const IncidentActions = {
  getIncidentsByBBox,
  getIncidents,
};

export default incidentReducer;
