import { Common } from 'models';
import { makeAPICall, makeMockAPICall } from './api';

import * as MapLayerManager from 'models/maplayer';
import * as LocationManager from 'models/location';

export declare namespace API {
  export interface FuelTypeRecord {
    jurisdiction: Common.Jurisdictions;
    date: string;
    filename: string;
  }

  export interface ArchiveMapRecord {
    type: MapLayerManager.MapLayer.Type;
    jurisdiction: Common.Jurisdictions;
    date: string;
    filename: string;
  }

  export interface FuelTypeRoot {
    data: FuelTypeRecord[];
    count: number;
  }
}

/**
 * Intended to be CSV ready
 */
export interface FlatObservation {
  id: number;
  time: Date;
  curing: number | null;
  curingPrevious: number | null;
  fuelCondition: number | null;
  fuelConditionPrevious: number | null;
  fuelLoad: number | null;
  fuelLoadPrevious: number | null;
  confident: boolean;
  continuous: boolean;
  status: string;
  observerId: number | null;
  observerName: string | null;
  observerEmail: string | null;
  validatorId: number | null;
  validatorName: string | null;
  validatorEmail: string | null;
  imageUrl: string | null;
  locationId: number;
  locationName: string;
  locationStatus: string;
  locationGeometryType: string;
  locationCoordinates: string;
}

export interface ArchiveMapRecord {
  type: MapLayerManager.MapLayer.Type;
  jurisdiction: Common.Jurisdictions;
  date: Date;
  id: string;
}

export interface FuelTypeRecord {
  jurisdiction: Common.Jurisdictions;
  date: Date;
  id: string;
}

export interface Filter {
  start?: Date;
  end?: Date;
  jurisdiction?: Common.Jurisdictions | 'all';
}

export interface MapFilter extends Filter {
  type?: MapLayerManager.MapLayer.Type;
}

export interface ObsFilter extends Filter {
  observer?: number;
  validator?: number;
}

export const getObservationArchive = makeAPICall<
  FlatObservation[],
  { filter: Filter },
  LocationManager.Location.ObservationAPI.Root
>(
  (payload) => {
    const { start, end, jurisdiction } = payload.filter;

    let qparams = '?';
    if (jurisdiction) qparams += `jurisdiction=${jurisdiction}&`;
    if (start) qparams += `start_date=${start.toISOString()}&`;
    if (end) qparams += `end_date=${end.toISOString()}&`;

    return {
      ext: `/locations/observations/field${qparams}`,
    };
  },
  (root) => {
    const results: FlatObservation[] = [];

    root.data.forEach((location) => {
      const locDetails = {
        locationId: location.location_id,
        locationName: location.location_name,
        locationStatus: location.status,
        locationGeometryType: location.geometry.type,
        locationCoordinates: JSON.stringify(location.geometry.coordinates),
      };

      location.observations?.forEach((obs) => {
        const time = new Date(obs.observation_time);

        results.push({
          ...locDetails,
          time,
          id: obs.observation_id,
          curing: obs.curing,
          curingPrevious: obs.curing_prev,
          fuelCondition: obs.fuel_condition,
          fuelConditionPrevious: obs.fuel_condition_prev,
          fuelLoad: obs.fuel_load,
          fuelLoadPrevious: obs.fuel_load_prev,
          confident: obs.is_confident,
          continuous: obs.is_continuous,
          imageUrl: obs.photo_url || null,
          status: obs.status,
          observerId: obs.observer?.user_id ?? null,
          observerName:
            obs.observer?.family_name && obs.observer?.given_name
              ? `${obs.observer.family_name} ${obs.observer.given_name}`
              : null,
          observerEmail: obs.observer?.user_email ?? null,
          validatorId: obs.validator?.user_id ?? null,
          validatorName:
            obs.validator?.family_name && obs.validator?.given_name
              ? `${obs.validator.family_name} ${obs.validator.given_name}`
              : null,
          validatorEmail: obs.validator?.user_email ?? null,
        });
      });
    });

    return results;
  },
);

const australianStates = ['nsw', 'vic', 'qld', 'tas', 'sa', 'nt', 'wa', 'act'];

export const getArchiveLayers = makeAPICall<ArchiveMapRecord[], { filter: MapFilter }, API.ArchiveMapRecord[]>(
  (payload) => {
    const { start, end, jurisdiction, type } = payload.filter;

    let qparams = '?';
    if (type) qparams += `map_types=${type}&`;
    if (jurisdiction) {
      if (jurisdiction === 'all') {
        for (let i = 0; i < australianStates.length; i += 1) {
          qparams += `jurisdictions=${australianStates[i]}&`;
        }
      } else {
        qparams += `jurisdictions=${jurisdiction}&`;
      }
    }

    if (start) qparams += `start_date=${start.toISOString()}&`;
    if (end) qparams += `end_date=${end.toISOString()}&`;

    return {
      ext: `/maplayers/archive-layers${qparams}`,
      requestData: {
        method: 'GET',
      },
    };
  },
  (root) =>
    root.map((record) => ({
      id: record.filename,
      jurisdiction: record.jurisdiction,
      type: record.type,
      date: new Date(record.date),
    })),
);

export const getFuelTypeRecords = makeAPICall<FuelTypeRecord[], { filter: Filter }, API.FuelTypeRecord[]>(
  (payload) => {
    const { start, end, jurisdiction } = payload.filter;

    let qparams = '?';
    if (jurisdiction) {
      if (jurisdiction === 'all') {
        for (let i = 0; i < australianStates.length; i += 1) {
          qparams += `jurisdictions=${australianStates[i]}&`;
        }
      } else {
        qparams += `jurisdictions=${jurisdiction}&`;
      }
    }
    if (start) qparams += `start_date=${start.toISOString()}&`;
    if (end) qparams += `end_date=${end.toISOString()}&`;

    return {
      ext: `/fuel-type/archive-files${qparams}`,
      requestData: {
        method: 'GET',
      },
    };
  },
  (root) =>
    root.map((record) => ({
      id: record.filename,
      jurisdiction: record.jurisdiction,
      date: new Date(record.date),
    })),
);

export const downloadMapTif = makeAPICall<Blob, { file: string }, { link: string }>(
  (payload) => {
    const { file } = payload;

    return {
      ext: `/maplayers/archive-layer?file_name=${file}`,
      requestData: {
        method: 'GET',
      },
    };
  },
  async (root) => {
    const blob = await fetch(root.link).then((res) => res.blob());
    return blob;
  },
);

export const downloadMapTifMock = makeMockAPICall<Blob, { file: string }, { link: string }>(
  (payload) => {
    const { file } = payload;

    return {
      ext: `/archive/${file}`,
      requestData: {
        method: 'GET',
      },
    };
  },
  async () => {
    return new Blob();
  },
  { link: '' },
  2500,
  0.2,
);

/**
 * Export to CSV function for observations
 */
export const toCSV = (data: FlatObservation[]): string => {
  const header = [
    'id',
    'time',
    'curing',
    'curingPrevious',
    'fuelCondition',
    'fuelConditionPrevious',
    'fuelLoad',
    'fuelLoadPrevious',
    'confident',
    'continuous',
    'status',
    'observerId',
    'observerName',
    'observerEmail',
    'validatorId',
    'validatorName',
    'validatorEmail',
    'imageUrl',
    'locationId',
    'locationName',
    'locationStatus',
  ];

  let parameterValueRow = [];
  const allStringRows = [];
  allStringRows.push(header.join(','));
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      const obsRow = data[i];
      for (let k = 0; k < header.length; k += 1) {
        // get each value and exclude values with a comma
        const paramMap = new Map(Object.entries(obsRow));
        let paramVal = String(paramMap.get(header[k]) ?? '');
        // If the parameter includes a comma, the csv standard is
        // to encase it in quotations
        if (paramVal.includes(',')) {
          // paramVal = '';
          paramVal = `"${paramVal}"`;
        }
        parameterValueRow.push(paramVal);
      }
      allStringRows.push(parameterValueRow.join(','));
      parameterValueRow = [];
    }
  }

  return allStringRows.join('\r\n');
};
