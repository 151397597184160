import React from 'react';
import { FuelTypeModelsManager } from 'models';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    th: {
      fontWeight: 'bold',
      fontSize: 10,
      textAlign: 'left',
    },
    tc: {
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      textAlign: 'center',
      fontSize: '14px',
    },
    smPad: {
      padding: 12,
    },
    square: {
      borderRadius: 4,
      width: 48,
      height: 48,
      padding: 1,
      fontSize: '14px',
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: theme.spacing(0.75),
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
  }),
);

type ValidationResultsTableProps = {
  validationResults: FuelTypeModelsManager.FuelTypeValidationResult[];
};

const ValidationResultsTable: React.FunctionComponent<ValidationResultsTableProps> = (tableProps) => {
  const classes = useStyles();
  const { validationResults } = tableProps;

  const generateKey = (pre: string) => {
    return `${pre}_${new Date().getTime()}`;
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.th} ${classes.smPad}`} style={{ textAlign: 'left' }}>
              Field Name
            </TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Error Type</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FTno_State</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Error Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {validationResults.map((row, index) => (
            <TableRow key={generateKey(index.toString())}>
              <TableCell className={`${classes.th} ${classes.smPad}`} component="th" scope="row">
                {row.field}
              </TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`} component="th" scope="row">
                {row.error_type}
              </TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                {row.ft_no_state}
              </TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                {row.error_detail}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ValidationResultsTable;
