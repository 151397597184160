import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Paper, Typography, Button, CircularProgress } from '@material-ui/core';

import { LocationManager } from 'models';
import { CuringStatus, FuelConditionStatus, FuelLoadStatus, ConfidenceStatus, AreYouSureDialog } from 'components';
import { toReadableAge } from 'utils';
import PrimaryButton from 'components/PrimaryButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    segmentContainer: {
      display: 'grid',
      padding: `0px ${theme.spacing(1)}px`,
      gridAutoFlow: 'column',
      gridTemplateColumns: '3fr repeat(4, 2fr) 3fr',
      alignItems: 'start',
      justifyItems: 'start',
    },
    segmentContainerMobile: {
      display: 'grid',
      padding: `0px ${theme.spacing(1)}px`,
      alignItems: 'start',
      justifyItems: 'start',
    },
    segment: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(isMobile ? 1 : 2)}px ${theme.spacing(1)}px`,
    },
    viewDetailsButton: {
      color: theme.palette.common.grey,
      textDecoration: 'underline',
      marginRight: theme.spacing(1),
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      width: 'fit-content',
    },
  }),
);

type LocationListItemProps = {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onAccept?: (event: any) => void;
  onCuringClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onFuelConditionClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onFuelLoadClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  display?: 'block' | 'inline-block';
  style?: React.CSSProperties;
  location: LocationManager.Location;
};

const LocationListItem: React.FunctionComponent<LocationListItemProps> = ({
  location,
  onClick,
  style,
  display,
  onAccept,
  onCuringClick,
  onFuelConditionClick,
  onFuelLoadClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { name, age, id, observations, newObservation } = location;

  const [hover, setHover] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const submittedObs = observations[0] as (typeof observations)[0] | undefined;
  const lastValidatedObs = observations.find((obs) => obs.validated === true);

  const disabled = submittedObs ? submittedObs.validated && !submittedObs.validatedToday : true;

  let bgColor = theme.palette.common.white;
  if (hover) bgColor = theme.palette.common.neutralXXLight;
  if (submittedObs?.validated ?? true) bgColor = theme.palette.common.neutralLight;

  const buttonText = 'Accept';

  return (
    <Paper
      className={classes.root}
      variant="outlined"
      style={{
        backgroundColor: bgColor,
        minWidth: isMobile ? '100%' : 1200,
        display: display || 'flex',
        ...style,
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
    >
      <div className={isMobile ? classes.segmentContainerMobile : classes.segmentContainer}>
        <div
          className={classes.segment}
          style={{
            borderRight: isMobile ? '0px' : `1px solid ${theme.palette.common.neutralXLight}`,
            placeContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Typography
            component="span"
            variant="h6"
            color="textPrimary"
            className={classes.text}
            style={{ fontWeight: 'bold' }}
          >
            {name}
          </Typography>
          <Typography component="span" variant="subtitle1" color="textSecondary" className={classes.text}>
            {toReadableAge(age == null ? null : age)}
          </Typography>
        </div>

        <CuringStatus
          className={classes.segment}
          style={{ marginLeft: isMobile ? 0 : theme.spacing(2) }}
          observation={submittedObs}
          prevObservation={lastValidatedObs}
          onValueClick={(!disabled && onCuringClick) || undefined}
        />

        <FuelConditionStatus
          className={classes.segment}
          observation={submittedObs}
          prevObservation={lastValidatedObs}
          onValueClick={(!disabled && onFuelConditionClick) || undefined}
        />

        <FuelLoadStatus
          className={classes.segment}
          observation={submittedObs}
          prevObservation={lastValidatedObs}
          onValueClick={(!disabled && onFuelLoadClick) || undefined}
        />

        <ConfidenceStatus className={classes.segment} observation={submittedObs} prevObservation={lastValidatedObs} />

        <div className={classes.segment} style={{ alignSelf: 'center', justifySelf: 'end', flexDirection: 'row' }}>
          <Button
            className={classes.viewDetailsButton}
            variant="text"
            disabled={disabled}
            component={RouterLink}
            to={`/validator/location/${id}`}
          >
            View Details
          </Button>
          <PrimaryButton
            variant="contained"
            disabled={disabled || newObservation.status === 'loading'}
            onClick={() => setConfirmOpen(true)}
          >
            {buttonText}
            {newObservation.status === 'loading' && (
              <CircularProgress size={16} style={{ paddingLeft: '4px' }} aria-valuetext="loading" />
            )}
          </PrimaryButton>
        </div>
      </div>
      <AreYouSureDialog
        open={confirmOpen}
        onConfirm={() => {
          setConfirmOpen(false);
          if (onAccept) onAccept(null);
        }}
        onClose={() => setConfirmOpen(false)}
      />
    </Paper>
  );
};

LocationListItem.displayName = 'LocationListItem';
export default LocationListItem;
