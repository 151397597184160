import { AssignedLocationManager } from 'models';
import { makeThunkFromAPICall } from 'state';

export const [getAssignmentsForLocation, setGetAssignmentStatus] = makeThunkFromAPICall(
  AssignedLocationManager.getAssignmentsForLocation,
  'assigned-location/get-assignments',
);

export const [updateUserRoleOnLocation, setUpdateAssignmentStatus] = makeThunkFromAPICall(
  AssignedLocationManager.updateUserRoleOnLocation,
  'assigned-location/update-assignments',
);

export const AssignedLocationActions = {
  getAssignmentsForLocation,
  updateUserRoleOnLocation,
};
