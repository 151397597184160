import { makeAPICall } from './api';

export interface Payload {
  message: string;
  params?: Record<string, string | number | undefined>;
  level?: 'info' | 'debug' | 'warning' | 'error';
}

export const postEvent = makeAPICall<null, Payload>(
  (payload) => {
    const { message, params, level } = payload;

    return {
      ext: `/log/event?message=${message}${level ? `&level=${level}` : ''}`,
      contentType: 'application/json',
      requestData: {
        method: 'POST',
        body: JSON.stringify(params ?? {}),
      },
    };
  },
  () => null,
);
