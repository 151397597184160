import { Common } from 'models';
import { makeAPICall } from './api';
import { dataURLtoBlob } from 'utils';

export declare namespace FuelTypeModels {
  export namespace API {
    export interface ParameterValues {
      FBM: string;
      H_o: number;
      FI_b: number;
      FI_o: number;
      FI_s: number;
      FL_b: number;
      FL_o: number;
      FL_s: number;
      Fk_b: number;
      Fk_o: number;
      Fk_s: number;
      H_el: number;
      H_ns: number;
      Cov_o: number;
      FHS_b: number;
      FHS_s: number;
      FI_el: number;
      FI_ns: number;
      FL_el: number;
      FL_ns: number;
      Fk_el: number;
      Fk_ns: number;
      Hk_ns: number;
      FHS_el: number;
      FHS_ns: number;
      WF_Sav?: number;
      Prod_BG: number;
      WRF_For: number;
      FI_total: number;
      FL_total: number;
      FTno_FDR: number;
      Fk_total: number;
      Fuel_FDR: string;
      Spotting: number;
      WF_Heath?: number;
      Fuel_Name: string;
      FTno_State: number;
      // Unnamed: 37?: any; // no clue what "Unamed 37" is, but the api returns it
    }

    export interface User {
      user_id: number;
      receive_sys_emails: boolean;
      user_name: string;
      user_email: string;
      given_name?: any;
      family_name?: any;
    }

    export interface Data {
      // fuel_type_model_parameter_set_id: number;
      model_id: number;
      jurisdiction_id: number;
      status?: any;
      // parameter_list: ParameterValues;
      // jurisdiction_fuel_type_no: number;
      submitter: User | null;
      parameter_list: Param[];
      notes?: Note[];
      approver: User | null;
      submission_time?: Date;
      approval_time?: Date;
    }

    export interface BomData {
      model_id: number;
      status?: any;
      parameter_list: Param[];
    }

    export interface Param {
      fuel_type_model_id: number;
      fuel_type_model_parameter_list_id: number;
      parameter_values: FuelTypeModels.API.ParameterValues;
    }

    export interface Note {
      note_id: number;
      note: string;
      modified: string;
      user: User | null;
    }

    export interface Root {
      data: Data[];
      count: number;
    }

    export interface BomRoot {
      data: BomData[];
      count: number;
    }
  }

  export interface FuelTypeModel {
    id: number;
    submitter: FuelTypeModels.API.User | null;
    approver: FuelTypeModels.API.User | null;
    jurisdiction_id: number;
    status: string;
    submission_date?: Date;
    authorise_date?: Date;
    param_list: FuelTypeModels.API.ParameterValues[];
    notes?: Common.Note[];
  }

  export interface BomFuelTypeModel {
    id: number;
    status: string;
    param_list: FuelTypeModels.API.ParameterValues[];
  }
}

export interface FuelTypeModels {
  data: FuelTypeModels.FuelTypeModel[];
  count: number;
}

export interface BomFuelTypeModels {
  data: FuelTypeModels.BomFuelTypeModel[];
  count: number;
}

export interface FuelTypeValidationResult {
  field: string;
  error_detail: string;
  error_type: string;
  ft_no_state: string;
}

export interface FuelTypeDifference {
  operation: 'Add' | 'Modify' | 'Delete';
  field: string;
  difference: string;
  ft_no_state: string;
}

export interface FuelTypeValidationResults {
  data: FuelTypeValidationResult[];
  count: number;
}

export interface FuelTypeModelSummary {
  submission_date?: Date;
  authorise_date?: Date;
  submitter: FuelTypeModels.API.User | null;
  approver: FuelTypeModels.API.User | null;
  status: string | null;
}

export interface FuelTypeModelUploadFields {
  blob: string | undefined;
  filename: string | undefined;
  note: string | undefined;
}

export interface FuelTypeModelNoteFields {
  modelId: number;
  noteText: string;
}

export const mapAPIToFuelTypeModels = (root: FuelTypeModels.API.Root): FuelTypeModels => ({
  count: root.count,
  data: root.data.map((d) => ({
    id: d.model_id,
    param_list: d.parameter_list.map((pl) => {
      return pl.parameter_values;
    }),
    notes: d.notes?.map((note) => ({
      id: note.note_id,
      note: note.note,
      modified: note.modified,
      user: note.user
        ? {
            id: note.user.user_id,
            userName: note.user.user_name,
            email: note.user.user_email,
            firstName: note.user.given_name,
            familyName: note.user.family_name,
          }
        : undefined,
    })),
    submitter: d.submitter,
    approver: d.approver,
    jurisdiction_id: d.jurisdiction_id,
    status: d.status,
    submission_date: d.submission_time ? new Date(d.submission_time) : undefined,
    authorise_date: d.approval_time ? new Date(d.approval_time) : undefined,
  })),
});

export const mapAPIToBomFuelTypeModels = (root: FuelTypeModels.API.BomRoot): BomFuelTypeModels => ({
  count: root.count,
  data: root.data.map((d) => ({
    id: d.model_id,
    param_list: d.parameter_list.map((pl) => {
      return pl.parameter_values;
    }),
    status: d.status,
  })),
});

export const getBomFuelTypes = makeAPICall<BomFuelTypeModels, void, FuelTypeModels.API.BomRoot>(
  () => ({
    ext: `/fuel-type/bom/all`,
  }),
  mapAPIToBomFuelTypeModels,
);

// TODO: Maintainence
// Jurisdiction input added to remove an error but notice it's not being used
// but as it isn't used, it's possible all fuel types are coming back
// Used in AddNote, after calling submitFuelTypeModelsNote
// Need to confirm it's safe to remove superflous jurisdiction input then remove it.
export const getFuelTypeModelsList = makeAPICall<
  FuelTypeModels,
  { jurisdiction?: string | null },
  FuelTypeModels.API.Root
>(
  () => ({
    ext: `/fuel-type`,
  }),
  mapAPIToFuelTypeModels,
);

export const getFuelTypeModelsForDownload = makeAPICall<
  FuelTypeModels,
  { jurisdiction: string },
  FuelTypeModels.API.Root
>(
  (pl) => ({
    ext: `/fuel-type/${pl.jurisdiction}`,
  }),
  mapAPIToFuelTypeModels,
);

export const getFuelTypeModelsAdhoc = makeAPICall<FuelTypeModels, FuelTypeModelUploadFields, FuelTypeModels.API.Root>(
  (payload) => {
    const data = new FormData();
    const { blob, filename } = payload;

    if (blob && filename) {
      data.append('fuel_type_csv', dataURLtoBlob(blob), filename);
    }

    return {
      ext: '/fuel-type/adhoc',
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  mapAPIToFuelTypeModels,
);

export const getValidationResultsAdhoc = makeAPICall<
  FuelTypeValidationResults,
  FuelTypeModelUploadFields,
  FuelTypeValidationResults
>(
  (payload) => {
    const data = new FormData();
    const { blob, filename } = payload;

    if (blob && filename) {
      data.append('fuel_type_csv', dataURLtoBlob(blob), filename);
    }

    return {
      ext: '/fuel-type/validate/adhoc',
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  (x) => x,
);

export const submitFuelTypeModel = makeAPICall<null, FuelTypeModelUploadFields>(
  (payload) => {
    const data = new FormData();
    const { note, blob, filename } = payload;

    if (note) data.append('note', note);
    if (blob && filename) {
      data.append('fuel_type_csv', dataURLtoBlob(blob), filename);
    }

    return {
      ext: '/fuel-type/submit',
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const approveFuelTypeModel = makeAPICall<null, { note: string }>(
  () => {
    return {
      ext: '/fuel-type/approve',
      contentType: null,
      requestData: {
        method: 'POST',
        body: null,
      },
    };
  },
  () => null,
);

export const rejectFuelTypeModel = makeAPICall<null, { note: string }>(
  (payload) => {
    const { note } = payload;
    const data = new FormData();
    data.append('note', `${note}`);

    return {
      ext: '/fuel-type/reject',
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const submitFuelTypeModelsNote = makeAPICall<null, FuelTypeModelNoteFields>(
  (payload) => {
    // Append the form parameters
    const data = new FormData();
    data.append('note', `${payload.noteText}`);

    return {
      ext: `/fuel-type/${payload.modelId}`,
      contentType: null,
      requestData: {
        method: 'PUT',
        body: data,
      },
    };
  },
  () => null,
);

export const getFuelTypeById = (
  id: number,
  fuelTypeModels?: FuelTypeModels | BomFuelTypeModels | null,
): FuelTypeModels.API.ParameterValues | null => {
  if (fuelTypeModels == null) return null;
  for (let i = 0; i < fuelTypeModels.data.length; i += 1) {
    for (let j = 0; j < fuelTypeModels.data[i].param_list.length; j += 1) {
      if (fuelTypeModels.data[i].param_list[j].FTno_State === id) {
        return fuelTypeModels.data[i].param_list[j];
      }
    }
  }
  return null;
};

export const toCSV = (data: FuelTypeModels.FuelTypeModel[] | FuelTypeModels.BomFuelTypeModel[]): string => {
  const header = [
    'Fuel_Name',
    'FBM',
    'Fuel_FDR',
    'FTno_State',
    'H_o',
    'FI_b',
    'FI_o',
    'FI_s',
    'FL_b',
    'FL_o',
    'FL_s',
    'Fk_b',
    'Fk_o',
    'Fk_s',
    'H_el',
    'H_ns',
    'Cov_o',
    'FHS_b',
    'FHS_s',
    'FI_el',
    'FI_ns',
    'FL_el',
    'FL_ns',
    'Fk_el',
    'Fk_ns',
    'Hk_ns',
    'FHS_el',
    'FHS_ns',
    'WF_Sav',
    'Prod_BG',
    'WRF_For',
    'FI_total',
    'FL_total',
    'FTno_FDR',
    'Fk_total',
    'Spotting',
    'WF_Heath',
  ];

  let parameterValueRow = [];
  const allStringRows = [];
  allStringRows.push(header.join(','));
  for (let i = 0; i < data.length; i += 1) {
    const modelRow = data[i];
    for (let j = 0; j < modelRow.param_list.length; j += 1) {
      const paramListRow = modelRow.param_list[j];
      for (let k = 0; k < header.length; k += 1) {
        const paramMap = new Map(Object.entries(paramListRow));
        let value = paramMap.get(header[k]) ?? '';
        if (typeof value == 'string') {
          if (value.includes(',')) {
            value = `\"${value}\"`;
          }
        }
        parameterValueRow.push(value);
      }
      allStringRows.push(parameterValueRow.join(','));
      parameterValueRow = [];
    }
  }

  return allStringRows.join('\r\n');
};

export const calculateDifferences = (
  fuelTypeModelsComp: FuelTypeModels.FuelTypeModel[],
  fuelTypeModelsAuth: FuelTypeModels.FuelTypeModel[],
) => {
  const fieldsToCheck = [
    'Fuel_Name',
    'FBM',
    'Fuel_FDR',
    'FTno_State',
    'H_o',
    'FI_b',
    'FI_o',
    'FI_s',
    'FL_b',
    'FL_o',
    'FL_s',
    'Fk_b',
    'Fk_o',
    'Fk_s',
    'H_el',
    'H_ns',
    'Cov_o',
    'FHS_b',
    'FHS_s',
    'FI_el',
    'FI_ns',
    'FL_el',
    'FL_ns',
    'Fk_el',
    'Fk_ns',
    'Hk_ns',
    'FHS_el',
    'FHS_ns',
    'WF_Sav',
    'Prod_BG',
    'WRF_For',
    'FI_total',
    'FL_total',
    'FTno_FDR',
    'Fk_total',
    'Spotting',
    'WF_Heath',
  ];

  const newDifferences = [];
  if (fuelTypeModelsComp?.length === 1 && fuelTypeModelsAuth?.length === 1) {
    for (let i = 0; i < fuelTypeModelsComp[0].param_list.length; i += 1) {
      const ftno = fuelTypeModelsComp[0].param_list[i].FTno_State;
      const authFTM = fuelTypeModelsAuth[0].param_list.filter((x) => x.FTno_State.toString() === ftno.toString());
      const compMap = new Map(Object.entries(fuelTypeModelsComp[0].param_list[i]));
      if (authFTM?.length === 1) {
        const authMap = new Map(Object.entries(authFTM[0]));
        for (let j = 0; j < fieldsToCheck.length; j += 1) {
          const compVal = compMap.get(fieldsToCheck[j]) ? compMap.get(fieldsToCheck[j]).toString() : '';
          const authVal = authMap.get(fieldsToCheck[j]) ? authMap.get(fieldsToCheck[j]).toString() : '';
          if (compVal !== authVal) {
            // console.log(`comp ${compVal} auth ${authVal}`);
            const newDiff = {} as FuelTypeDifference;
            newDiff.operation = 'Modify';
            newDiff.field = fieldsToCheck[j];
            newDiff.difference = `${compVal} : ${authVal}`;
            newDiff.ft_no_state = fuelTypeModelsComp[0].param_list[i].FTno_State.toString();
            newDifferences.push(newDiff);
          }
        }
      } else if (authFTM?.length === 0) {
        for (let j = 0; j < fieldsToCheck.length; j += 1) {
          const compVal = compMap.get(fieldsToCheck[j]) ? compMap.get(fieldsToCheck[j]).toString() : '';
          const newDiff = {} as FuelTypeDifference;
          newDiff.operation = 'Add';
          newDiff.field = fieldsToCheck[j];
          newDiff.difference = `${compVal} : `;
          newDiff.ft_no_state = fuelTypeModelsComp[0].param_list[i].FTno_State.toString();
          newDifferences.push(newDiff);
        }
      }
    }
  }

  // Check for deletions
  if (fuelTypeModelsComp?.length === 1 && fuelTypeModelsAuth?.length === 1) {
    for (let i = 0; i < fuelTypeModelsAuth[0].param_list.length; i += 1) {
      const ftnoAuth = fuelTypeModelsAuth[0].param_list[i].FTno_State
        ? fuelTypeModelsAuth[0].param_list[i].FTno_State.toString()
        : '';
      const ftnoComps = fuelTypeModelsComp[0].param_list.filter((x) => x.FTno_State.toString() === ftnoAuth);
      if (ftnoComps.length !== 1) {
        // must have been deleted
        const newDiff = {} as FuelTypeDifference;
        newDiff.operation = 'Delete';
        newDiff.field = 'FTno_State';
        newDiff.difference = ` : ${ftnoAuth}`;
        newDiff.ft_no_state = ftnoAuth;
        newDifferences.push(newDiff);
      }
    }
  }
  return newDifferences;
};
