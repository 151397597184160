import React, { useEffect, useState } from 'react';

import { useParams, Link, Routes, Route, useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Button, LinearProgress, Typography, Container, CircularProgress, Paper } from '@material-ui/core';

import {
  ExpandableProgressNav,
  ButtonLikeRadioInput,
  ProgressNavList,
  ErrorSegment,
  CustomDateTimePicker,
  HelpDrawer,
  HelpTypography,
  PrettierTextArea,
  LoadableImage,
  AreYouSureDialog,
  Note,
} from 'components';
import { toTimeDDMonYearFormat } from 'utils';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ObserverActions } from 'state/observer';
import { ToastActions } from 'state/toast';
import { ToastType } from 'models/toast';
import createDataEntrySteps from './createDataEntrySteps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
      width: 'fit-content',
    },
    subtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      color: theme.palette.text.secondary,
    },
    reviewSectionText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    errorText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      width: '100%',
    },
    buttonLight: {
      height: '50px',
      width: '90%',
      marginLeft: '5%',
      backgroundColor: theme.palette.common.neutralLight,
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(1),
      marginTop: '5px',
      '&:hover': {
        backgroundColor: theme.palette.common.neutralLight,
      },
      fontWeight: 'bold',
    },
    button: {
      height: '50px',
      width: '90%',
      marginLeft: '5%',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginTop: '5px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
    },
    lowerSegment: {
      width: '100%',
      height: '75px',
    },
    stepsContainer: {
      display: 'grid',
      overflow: 'auto',
      gridTemplateRows: 'auto 1fr auto 1fr',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    container: {
      overflow: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    progress: {
      backgroundColor: theme.palette.common.neutralLight,
    },
    progressBar: {
      backgroundColor: theme.palette.common.neutralDark,
    },
    noteSubtitle: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    note: {
      width: '100%',
      margin: theme.spacing(1),
    },
  }),
);

const FSEDataEntryMobile: React.FunctionComponent<Record<string, unknown>> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { locationId: locationIdRaw } = useParams<{ locationId: string }>();
  const locationId = locationIdRaw ?? '';
  const [confirmOpen, setConfirmOpen] = useState(false);
  const fuelLoadState = useState(false);
  const [helpDrawerOpen, setHelpDrawerOpen] = useState(false);
  const [helpId, setHelpId] = useState('');
  const [submitting, setSubmitting] = useState('');
  const [hasSetTime, setHasSetTime] = useState(false);

  const openHelp = (id: string) => {
    setHelpId(id);
    setHelpDrawerOpen(true);
  };

  const closeHelp = (event: any) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setHelpDrawerOpen(false);
  };

  // handle getting the stepId if it exists and setting it to 0 if it doesn't
  // useParams won't recognise it as it's not explicitly set, but doing so gets
  // rid of the review page.
  const stepIdMatch = window.location.pathname.match(/step\/([0-9]*)[/]?/);
  const stepIdStr = stepIdMatch == null ? null : stepIdMatch[1];
  const stepId = stepIdStr == null || Number.isNaN(parseInt(stepIdStr, 10)) ? 0 : parseInt(stepIdStr, 10);

  const { observer, auth } = useAppSelector((state) => state);
  const { locations, upload } = observer;
  const location = locations.object && locations.object.find((loc) => loc.id === parseInt(locationId, 10));
  const newObservation = location?.newObservation.object != null ? location.newObservation.object : {};

  // Fix for bug AF-948
  // A minimum date (inclusive) in order to prevent the user from backdating an
  // observation before the previous observation which will cause an observation
  // to be stuck in the submitted state and never be visible for validation
  const prevValidatedObs = location?.observations.find((obs) => obs.validated);
  const minObsBackdate = prevValidatedObs ? new Date(prevValidatedObs.time) : undefined;
  // The timedate picker doesn't seem to enforce minimum time, so just forcing the
  // new observation to be the next day.
  minObsBackdate?.setUTCHours(0, 0, 0, 0);
  minObsBackdate?.setUTCDate(minObsBackdate.getUTCDate() + 1);

  const dispatch = useAppDispatch();

  const steps = createDataEntrySteps(newObservation, location, dispatch, openHelp, fuelLoadState);

  const isReview = window.location.pathname.indexOf('review') > -1;
  useEffect(() => {
    if ((stepIdStr == null && !isReview) || stepId >= steps.length) {
      navigate(`/observer/location/${locationId}/step/0`, {
        replace: true,
      });
    }
    // If it's the review page check to make sure required options are completed
    // if not, then redirect to that step
    // find Index that is required and not complete
    const incompleteStep = steps.findIndex((step) => !step.optional && !step.completed);
    if (isReview && incompleteStep > -1) {
      navigate(`/observer/location/${locationId}/step/${incompleteStep}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locationId, stepId, stepIdStr]);

  useEffect(() => {
    if (locations.status === 'idle' && auth.status === 'finished') dispatch(ObserverActions.getLocations());
  }, [dispatch, locations.status, auth.status]);

  useEffect(() => {
    if (
      (submitting === 'add' && upload.status === 'finished') ||
      (submitting === 'edit' && location?.newObservation.status === 'finished')
    ) {
      ToastActions.showToast({
        type: ToastType.success,
        message: `You have successfully submitted the observation for ${location?.name}`,
      });
      setSubmitting('');
      navigate(`/observer/locations/list`);
    }
    if (upload.status === 'error') {
      ToastActions.showToast({
        type: ToastType.error,
        message: 'Error uploading observation',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, upload.status, location?.newObservation.status]);

  const submitObservation = async () => {
    if (location) {
      if (location.observations[0]?.submitted) {
        dispatch(
          ObserverActions.editObservation({ location, id: location.observations[0].id, update_observer: true }),
        ).then(() => dispatch(ObserverActions.getLocations()));
        setSubmitting('edit');
      } else {
        dispatch(ObserverActions.addObservation({ location })).then(() => dispatch(ObserverActions.getLocations()));
        setSubmitting('add');
      }
    }
  };

  if (locations.status === 'idle' || locations.status === 'loading')
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
        <CircularProgress style={{ margin: theme.spacing(2) }} aria-valuetext="loading" />
      </div>
    );

  if (locations.status === 'error' && locations.error)
    return (
      <ErrorSegment
        style={{ marginTop: theme.spacing(4) }}
        error={locations.error}
        text="Failed To load Locations"
        onRetry={() => dispatch(ObserverActions.getLocations())}
      />
    );

  if (location == null && locations.status === 'finished')
    return (
      <Typography variant="h4" align="center" className={classes.errorText}>
        Error 404: Page Not Found
      </Typography>
    );

  if (location == null) return <></>;

  const step = steps[stepId];

  return (
    <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
      <LinearProgress
        classes={{ colorPrimary: classes.progress, barColorPrimary: classes.progressBar }}
        variant="determinate"
        value={isReview ? 100 : ((stepId + 1) / steps.length) * 100}
      />
      <Routes>
        <Route
          path="step/*"
          element={
            <>
              <Container className={classes.stepsContainer}>
                <ExpandableProgressNav
                  steps={steps}
                  currentStepId={stepId}
                  onChange={(value) => navigate(`/observer/location/${locationId}/step/${value}`)}
                />

                {step != null ? (
                  <>
                    <div>
                      <HelpTypography
                        variant="h4"
                        helpId={step.helpId}
                        onTriggerHelp={openHelp}
                        className={classes.title}
                      >
                        {step.heading}
                      </HelpTypography>
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        {step.label}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: 'grid',
                        alignItems: 'start',
                        justifyItems: 'center',
                        gridTemplateRows: 'auto 1fr',
                      }}
                    >
                      {step.content}
                    </div>
                  </>
                ) : (
                  <Typography variant="h4" className={classes.title}>
                    Invalid Step
                  </Typography>
                )}
              </Container>

              <Paper elevation={0} className={classes.lowerSegment}>
                {stepId < steps.length - 1 ? (
                  <Button
                    className={classes.button}
                    variant="contained"
                    component={Link}
                    to={`/observer/location/${locationId}/step/${stepId + 1}`}
                  >
                    {`Next : ${steps[stepId + 1].heading}`}
                  </Button>
                ) : (
                  <Button
                    className={step?.optional && !step?.completed ? classes.buttonLight : classes.button}
                    variant="contained"
                    component={Link}
                    onClick={() => {
                      if (!hasSetTime) {
                        dispatch(
                          ObserverActions.setNewObservation({
                            id: location.id,
                            current: {
                              time: Date.now(),
                            },
                          }),
                        );
                        setHasSetTime(true);
                      }
                    }}
                    to={`/observer/location/${locationId}/review`}
                  >
                    Next : Observation Summary
                  </Button>
                )}
              </Paper>
            </>
          }
        />

        <Route
          path="review"
          element={
            <Container className={classes.container}>
              <ExpandableProgressNav
                steps={steps}
                currentStepId={steps.length - 1}
                onChange={(value) => navigate(`/observer/location/${locationId}/step/${value}`)}
              />

              <Typography variant="h4" className={classes.title}>
                {location.name}
              </Typography>

              <div style={{ display: 'flex' }}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  {newObservation.time && toTimeDDMonYearFormat(new Date(newObservation.time))}
                </Typography>

                <CustomDateTimePicker
                  className={classes.subtitle}
                  style={{ textDecoration: 'underline', marginLeft: theme.spacing(2) }}
                  onChange={(date) =>
                    dispatch(
                      ObserverActions.setNewObservation({
                        id: location.id,
                        current: {
                          time: date.getTime(),
                        },
                      }),
                    )
                  }
                  text="Change date and time"
                  minDate={minObsBackdate}
                  minDateMessage="You cannot backdate an observation before the last validated observation"
                />
              </div>

              <ProgressNavList
                steps={steps}
                onChange={(value) => navigate(`/observer/location/${locationId}/step/${value}`)}
              />

              <Typography variant="subtitle1" className={classes.reviewSectionText}>
                Photo
              </Typography>

              {newObservation.image ||
              (location.observations[0] &&
                location.observations[0]?.submitted &&
                location.observations[0]?.imageUrl) ? (
                <div style={{ margin: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}>
                  <LoadableImage
                    src={
                      newObservation.image ??
                      (location.observations[0] && location.observations[0].submitted
                        ? location.observations[0].imageUrl
                        : undefined)
                    }
                  />
                </div>
              ) : (
                <Typography variant="subtitle2" className={classes.note}>
                  No photo available
                </Typography>
              )}

              <Typography variant="subtitle1" className={classes.reviewSectionText}>
                Confidence level
              </Typography>

              <ButtonLikeRadioInput
                options={[
                  { text: 'Confident', value: 1 },
                  { text: 'Not confident', value: 0 },
                ]}
                initialValue={newObservation.confident ?? location.observations[0]?.confident ? 1 : 0}
                onChange={(value) =>
                  dispatch(
                    ObserverActions.setNewObservation({
                      id: location.id,
                      current: {
                        confident: value === 1,
                      },
                    }),
                  )
                }
                style={{ width: '100%' }}
              />

              <Typography variant="subtitle1" className={classes.reviewSectionText}>
                Notes
              </Typography>

              {(!location?.observations[0]?.validated &&
                location?.observations[0]?.notes.map((note, idx) => (
                  <Note key={note.id || `idx-${idx}`} note={note} />
                ))) || (
                <Typography variant="subtitle2" className={classes.note}>
                  No notes available
                </Typography>
              )}

              <Typography variant="subtitle1" className={classes.reviewSectionText}>
                Additional Note
              </Typography>

              <PrettierTextArea
                paperStyle={{
                  marginBottom: theme.spacing(2),
                }}
                minRows={5}
                value={newObservation.newNote?.note || ''}
                onChange={(event) =>
                  dispatch(
                    ObserverActions.setNewObservation({
                      id: location.id,
                      current: {
                        newNote: { note: event.target.value, id: 0, modified: new Date().toISOString() },
                      },
                    }),
                  )
                }
              />

              {upload.status === 'error' && upload.error && <ErrorSegment error={upload.error} hideRetry />}

              <Button
                className={classes.button}
                disabled={upload.status === 'loading'}
                fullWidth
                style={{ width: '100%', marginLeft: 0 }}
                onClick={() => setConfirmOpen(true)}
              >
                Submit Observation
                {upload.status === 'loading' && (
                  <CircularProgress size={16} style={{ marginLeft: theme.spacing(1) }} aria-valuetext="loading" />
                )}
              </Button>
            </Container>
          }
        />
      </Routes>

      <HelpDrawer helpId={helpId} open={helpDrawerOpen} onClick={closeHelp} onKeyDown={closeHelp} />
      <AreYouSureDialog
        open={confirmOpen}
        onConfirm={() => {
          setConfirmOpen(false);
          submitObservation();
        }}
        onClose={() => setConfirmOpen(false)}
      />
    </div>
  );
};

export default FSEDataEntryMobile;
