import { Common, JurisdictionsEnum } from 'models';
import { makeAPICall } from './api';

export declare namespace AuthUser {
  export namespace API {
    export interface Role {
      role_id: number;
      role_name: string;
    }

    export interface IdentityProvider {
      providerId: number;
      providerName: string;
      providerType: string;
      domainNames: string[];
    }

    export interface Root {
      user_id: number;
      receive_sys_emails: boolean;
      receive_observer_emails: boolean;
      receive_validator_emails: boolean;
      receive_editor_emails: boolean;
      receive_authoriser_emails: boolean;
      user_name: string;
      user_email: string;
      given_name: string;
      family_name: string;
      roles: Role[];
      identity_provider_id: number;
      identity_provider: IdentityProvider;
    }
  }

  export interface IdentityProvider {
    providerId: number;
    providerName: string;
    providerType: string;
    domainNames: string[];
  }

  export interface AuthUserGetRequest {
    id: number;
    receiveEmails: boolean;
    receiveObserverEmails: boolean;
    receiveValidatorEmails: boolean;
    receiveEditorEmails: boolean;
    receiveAuthoriserEmails: boolean;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: AuthUser.Role[];
    state?: Common.Jurisdictions;
    identityProviderId: number;
    identityProvider: IdentityProvider;
  }

  export interface AuthUserUpdateRequest {
    receive_observer_emails: boolean;
    receive_validator_emails: boolean;
    receive_editor_emails: boolean;
    receive_authoriser_emails: boolean;
    given_name: string;
    family_name: string;
    userIsOkta: boolean;
  }

  export interface Role {
    id: number;
    name: string;
  }
}

/**
 * Get current users details based on the sent token
 */
export const getSelf = makeAPICall<AuthUser.AuthUserGetRequest, void, AuthUser.API.Root>(
  () => ({
    ext: '/users/self',
  }),
  (root) => ({
    id: root.user_id,
    receiveEmails: root.receive_sys_emails,
    receiveObserverEmails: root.receive_observer_emails,
    receiveValidatorEmails: root.receive_validator_emails,
    receiveEditorEmails: root.receive_editor_emails,
    receiveAuthoriserEmails: root.receive_authoriser_emails,
    userName: root.user_name,
    email: root.user_email,
    firstName: root.given_name,
    lastName: root.family_name,
    identityProviderId: root.identity_provider_id,
    identityProvider: root.identity_provider,
    roles: root.roles.map((apiRole) => ({
      id: apiRole.role_id,
      name: apiRole.role_name,
    })),
    state: root.roles
      .map((role) => role.role_name)
      .find((name) => Object.keys(JurisdictionsEnum).indexOf(name) > -1) as Common.Jurisdictions | undefined,
  }),
);

export const updatePreferences = makeAPICall<null, { receiveEmails: boolean }, null>(
  (payload) => {
    return {
      ext: '/users/self',
      contentType: 'application/x-www-form-urlencoded',
      requestData: {
        method: 'PUT',
        body: `receive_sys_emails=${payload.receiveEmails}`,
      },
    };
  },
  () => null,
);

export const updateSelfDetails = makeAPICall<null, AuthUser.AuthUserUpdateRequest, null>(
  (payload) => {
    const data = new FormData();
    // Exclude given_name and family_name if the user's auth provider is oktas
    if (!payload.userIsOkta) {
      data.append('given_name', payload.given_name);
      data.append('family_name', payload.family_name);
    }

    data.append('receive_observer_emails', String(payload.receive_observer_emails));
    data.append('receive_validator_emails', String(payload.receive_validator_emails));
    data.append('receive_editor_emails', String(payload.receive_editor_emails));
    data.append('receive_authoriser_emails', String(payload.receive_authoriser_emails));

    return {
      ext: '/users/self',
      contentType: null,
      requestData: {
        method: 'PUT',
        body: data,
      },
    };
  },
  () => null,
);
