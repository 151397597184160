import { ActionCreatorWithPayload, ThunkDispatch, createAction } from '@reduxjs/toolkit';

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { NavigateFunction } from 'react-router';

import config from 'config';
import { Loadable, ErrorTypes, AuthManager } from 'models';
import { RootState } from 'store';

import { AuthActions, AuthState } from './auth';
import { AuthUserState, AuthUserActions } from './authuser';

import { logout } from '../models/auth';

export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export interface LoadableAction<T, U = void> {
  loadable: Loadable<T>;
  input: U;
}

export function handleLogin(
  dispatch: ThunkDispatch<RootState, void, Action<any>>,
  auth: AuthState,
  user: AuthUserState,
  navigate: NavigateFunction,
) {
  const urlParams = new URLSearchParams(window.location.search);
  const storedAuthStr = localStorage.getItem(config.localstorage_auth);
  let usingStoredToken = false;

  if (storedAuthStr && auth.status === 'idle') {
    const storedAuth = JSON.parse(storedAuthStr) as AuthManager.Auth;

    // check if we have an access token and it still appears to be valid
    if (storedAuth?.creds?.access_token && storedAuth.refresh_time && new Date() < new Date(storedAuth.refresh_time)) {
      dispatch(AuthActions.setStatus({ status: 'finished' }));
      dispatch(
        AuthActions.setAuth({
          creds: storedAuth.creds,
          refresh_time: new Date(storedAuth.refresh_time),
        }),
      );

      usingStoredToken = true;

      // now that we have auth, we can test this by getting user details
      if (user.user.status === 'idle') {
        dispatch(AuthUserActions.getSelf())
          .then(() => {
            localStorage.setItem(config.loginRetryKey, '0');
            dispatch(AuthActions.refreshToken({ auth: storedAuth }));
          })
          .catch(() => {
            // This code retries the login logic 5 times before redirecting the
            // user to the logout UI
            let counter;
            try {
              const temp = localStorage.getItem(config.loginRetryKey);
              counter = temp === null ? 0 : +temp;
            } catch (error) {
              // Stop the retry loop, something is wrong
              console.error(error);
              counter = 5;
            }
            localStorage.removeItem(config.localstorage_auth);
            if (counter < 5) {
              // Increment the counter to prevent the retry loop from going on forever
              localStorage.setItem(config.loginRetryKey, (counter + 1).toString());
              AuthManager.gotoAuth();
            } else {
              // Too many retries, logout the user.
              logout();
            }
          });
      }
    }
  }

  if (auth.status === 'idle' && auth.object == null && !usingStoredToken) {
    // Check if code has been sent
    const code = urlParams.get('code');
    const redirectPath = urlParams.get('state');
    if (code) {
      if (window.history.pushState) {
        if (redirectPath) {
          const path = window.location.origin + redirectPath ? redirectPath : '';
          navigate(path);
        } else {
          window.history.pushState({ path: window.location.origin }, '', window.location.origin);
        }
      }

      // use code to get Token, then get  User
      dispatch(AuthActions.getToken({ code })).then(() => {
        // now that we have auth, we can test this by getting user details

        if (user.user.status === 'idle') {
          dispatch(AuthUserActions.getSelf())
            .then(() => {})
            .catch(() => {
              // Here be a successful login but unable to get user info
            });
        }
      });
    } else {
      console.log('Redirecting to login page');
      // Redirect to AWS Cognito login
      AuthManager.gotoAuth();
    }
  }
}

export function makeThunkFromAPICall<Obj, Payload>(
  apiCall: (
    payload: Payload,
    state?: RootState,
    dispatch?: ThunkDispatch<RootState, unknown, Action<string>>,
  ) => Promise<Obj>,
  type: string,
  requireAuth = true,
): [
  (payload: Payload) => AppThunkAction<Promise<Obj>>,
  ActionCreatorWithPayload<LoadableAction<Obj, Payload>, string>,
] {
  const setterAction = <ActionCreatorWithPayload<LoadableAction<Obj, Payload>, string>>createAction(type);
  return [
    (payload) => async (dispatch, getState) => {
      const dispatchSetter = (loadable: Loadable<Obj>) =>
        setterAction && dispatch(setterAction({ loadable, input: payload }));
      const state = getState();
      dispatchSetter({ status: 'loading' });
      try {
        const auth = state.auth.object;
        if (!requireAuth || (requireAuth && auth)) {
          const obj = await apiCall(payload, state, dispatch);
          dispatchSetter({
            object: obj,
            status: 'finished',
          });
          return obj;
        }
        const err = {
          error: 'No Credentials available',
          code: ErrorTypes.INVALID_CREDENTIALS,
        };
        throw err;
      } catch (err: any) {
        dispatchSetter({
          error: err,
          status: 'error',
        });
        throw err;
      }
    },
    setterAction,
  ];
}
