import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Drawer, DrawerProps, Typography, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { helpLookup } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.common.black,
    },
    titleArea: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderBottom: '1px solid white',
    },
    titleText: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      padding: theme.spacing(2),
    },
    closeText: {
      color: theme.palette.common.neutralXLight,
      fontWeight: 'bold',
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
      textDecoration: 'underline',
    },
    closeIcon: {
      color: theme.palette.common.neutralXLight,
    },
    helpText: {
      color: theme.palette.common.white,
      padding: theme.spacing(2),
    },
  }),
);

interface HelpDrawerProps extends DrawerProps {
  helpId: keyof typeof helpLookup;
  onDrawerClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDrawerKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const HelpDrawer: React.FunctionComponent<HelpDrawerProps> = (props) => {
  const classes = useStyles();
  const { helpId, onDrawerClick, onDrawerKeyDown, ...restProps } = props;

  const title = helpLookup[helpId] ? helpLookup[helpId].title : 'Missing Help';
  const helpText = helpLookup[helpId] ? helpLookup[helpId].text : [`The help text for "${helpId}" is missing.`];

  // To render JSX inside the title of the material ui a jsx fragment must be used
  const multilineText = (
    <>
      {helpText.map((txt) => (
        <span style={{ display: 'block' }}>{txt}</span>
      ))}
    </>
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Drawer {...restProps} anchor="bottom">
      <Paper className={classes.root} role="presentation" onClick={onDrawerClick} onKeyDown={onDrawerKeyDown}>
        <div className={classes.titleArea}>
          <Typography className={classes.titleText}>{title}</Typography>
          <div style={{ display: 'grid', placeItems: 'center', gridAutoFlow: 'column' }}>
            <Close className={classes.closeIcon} />
            <Typography className={classes.closeText}>Close</Typography>
          </div>
        </div>
        <Typography className={classes.helpText}>{multilineText}</Typography>
      </Paper>
    </Drawer>
  );
};

HelpDrawer.displayName = 'HelpDrawer';
export default HelpDrawer;
