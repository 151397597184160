import React, { useState, FunctionComponent } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Typography,
  TextField,
  Card,
  CardActionArea,
  CardContent,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';

import { getFullName } from 'utils';
import { LocationManager, UserManager, AssignedLocationManager } from 'models';
import { useAppDispatch } from 'hooks';
import * as AssignedLocationActions from 'state/assignedlocation';

import UserSelector from './UserSelector';

const panelWidth = 450;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      background: theme.palette.common.white,
    },
    leftPanel: {
      width: panelWidth,
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
    },
    leftContent: {
      height: '100%',
      overflow: 'auto',
      padding: 25,
    },
    rightPanel: {
      position: 'absolute',
      left: panelWidth,
      right: 0,
      bottom: 0,
      top: 0,
      height: '100%',
    },
    backLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.common.black,
    },
    title: {
      marginTop: 25,
      fontSize: 40,
      paddingBottom: 8,
      borderBottom: '2px solid black',
      marginBottom: 25,
      position: 'relative',
    },
    subTitle: {
      marginTop: 25,
      fontSize: 20,
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: '1px solid black',
      color: theme.palette.grey[700],
      position: 'relative',
    },
    addNewLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      bottom: 16,
      fontSize: 14,
    },
    editLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 14,
      marginLeft: 12,
    },
    input: {
      marginBottom: 12,
    },
    spaced: {
      margin: theme.spacing(1),
    },
    spacedCard: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    cardContent: {
      display: 'grid',
      gridTemplateColumns: '90% auto',
      placeItems: 'center',
      maxWidth: '100%',
    },
    rightIcon: {
      marginRight: 0,
    },
    fixedHeight: {
      paddingTop: 8,
      minHeight: 110,
      maxHeight: 400,
      overflow: 'auto',
    },
    coords: {
      color: '#4D4C51',
    },
    inactive: {
      color: 'silver',
    },
  }),
);

const CardListComponent: FunctionComponent<{
  selectedUsers: AssignedLocationManager.LocationAssignment.User[];
  availableUsers: UserManager.User.User[];
  loading: boolean;
  location: LocationManager.Location.LocationAdminAPI.Location;
  roleName: string;
  onUpdate: () => void;
}> = ({ selectedUsers, availableUsers, loading, location, roleName, onUpdate }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch();

  const dispatchUpdate = (
    user: AssignedLocationManager.LocationAssignment.User,
    action: AssignedLocationManager.UserLocationActions,
  ) => {
    dispatch(
      AssignedLocationActions.updateUserRoleOnLocation({
        action,
        userId: user.user_id,
        locationId: location.location_id,
        role: roleName,
      }),
    ).then(onUpdate);
  };

  const handleAddUser = (user: AssignedLocationManager.LocationAssignment.User) => {
    dispatchUpdate(user, AssignedLocationManager.UserLocationActions.add);
  };

  const handleRemoveUser = (user: AssignedLocationManager.LocationAssignment.User) => {
    dispatchUpdate(user, AssignedLocationManager.UserLocationActions.remove);
  };

  const renderItem = (item: AssignedLocationManager.LocationAssignment.User) => {
    return (
      <Card elevation={0} className={classes.spacedCard} key={item.user_id}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <div>
              <Typography gutterBottom variant="h6">
                {getFullName(item)}
              </Typography>
              <Typography gutterBottom variant="h6">
                {item.user_email}
              </Typography>
            </div>
            <Close
              className={classes.rightIcon}
              onClick={() => handleRemoveUser(item)}
              role="button"
              tabIndex={0}
              onKeyPress={() => handleRemoveUser(item)}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const searchTerm = value.toLowerCase().trim();

  return (
    <div className={classes.fixedHeight}>
      {availableUsers.length > 0 && (
        <UserSelector id={`locationEdit${roleName}s`} users={availableUsers} onSelect={handleAddUser} />
      )}
      <TextField
        className={classes.input}
        id={`locationEdit${roleName}Search`}
        variant="outlined"
        fullWidth
        size="small"
        label="Search"
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setValue(e.target.value)}
      />
      {loading && <CircularProgress size={16} aria-valuetext="loading" />}
      <div id={`${roleName}sAssignedToLocation`}>
        {selectedUsers.filter((x) => x.user_name.toLowerCase().startsWith(searchTerm)).map(renderItem)}
      </div>
    </div>
  );
};

export default CardListComponent;
