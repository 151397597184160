import { createReducer } from '@reduxjs/toolkit';

import { HelpManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type HelpState = {
  list: Loadable<HelpManager.Item[]>;
  post: Loadable<null>;
  put: Loadable<null>;
  delete: Loadable<null>;
  get: Record<number, Loadable<HelpManager.Item | null>>;
};

const initialState: HelpState = {
  list: {
    status: 'idle',
  },
  put: {
    status: 'idle',
  },
  post: {
    status: 'idle',
  },
  delete: {
    status: 'idle',
  },
  get: {},
};

export const [getList, setList] = makeThunkFromAPICall(HelpManager.getList, 'help/list');

export const [getItem, setItem] = makeThunkFromAPICall(HelpManager.getItem, 'help/set');

export const [addItem, setAdd] = makeThunkFromAPICall(HelpManager.addItem, 'help/add');

export const [editItem, setEdit] = makeThunkFromAPICall(HelpManager.editItem, 'help/edit');

export const [deleteItem, setDelete] = makeThunkFromAPICall(HelpManager.deleteItem, 'help/delete');

export const helpReducer = createReducer(initialState, (builder) => {
  builder.addCase(setList, (state, action) => {
    return { ...state, list: { ...state.list, ...action.payload.loadable } };
  });
  builder.addCase(setItem, (state, action) => {
    return {
      ...state,
      get: {
        ...state.get,
        [action.payload.input.id]: {
          ...(state.get[action.payload.input.id]
            ? state.get[action.payload.input.id]
            : {
                status: 'idle',
              }),
          ...action.payload.loadable,
        },
      },
    };
  });
  builder.addCase(setAdd, (state, action) => {
    return { ...state, post: { ...state.post, ...action.payload.loadable } };
  });
  builder.addCase(setEdit, (state, action) => {
    return { ...state, put: { ...state.put, ...action.payload.loadable } };
  });
  builder.addCase(setDelete, (state, action) => {
    return { ...state, delete: { ...state.delete, ...action.payload.loadable } };
  });
});

export const HelpActions = {
  getList,
  getItem,
  addItem,
  editItem,
  deleteItem,
};

export default helpReducer;
