import { createReducer } from '@reduxjs/toolkit';

import { FuelTypeModelsManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type FuelTypeModelsState = {
  bom: Loadable<FuelTypeModelsManager.BomFuelTypeModels>;
  local: Loadable<FuelTypeModelsManager.FuelTypeModels>;
  adhoc: Loadable<FuelTypeModelsManager.FuelTypeModels>;
  download: Loadable<FuelTypeModelsManager.FuelTypeModels>;
  valResultsAdHoc: Loadable<FuelTypeModelsManager.FuelTypeValidationResults>;
};

const initialState: FuelTypeModelsState = {
  bom: {
    status: 'idle',
  },
  local: {
    status: 'idle',
  },
  adhoc: {
    status: 'idle',
  },
  download: {
    status: 'idle',
  },
  valResultsAdHoc: {
    status: 'idle',
  },
};

export const [getBomFuelTypeModelsAll, setBomFuelTypeModelsAll] = makeThunkFromAPICall(
  FuelTypeModelsManager.getBomFuelTypes,
  'fueltypemodels/set-bom-all',
);

export const [getFuelTypeModelsForJurisdiction, setFuelTypeModelsLocal] = makeThunkFromAPICall(
  FuelTypeModelsManager.getFuelTypeModelsList,
  'fueltypemodels/set-local',
);

export const [getFuelTypeModelsForDownload, setFuelTypeModelsForDownload] = makeThunkFromAPICall(
  FuelTypeModelsManager.getFuelTypeModelsForDownload,
  'fueltypemodels/set-download',
);

export const [getFuelTypeModelsAdhoc, setFuelTypeModelsAdhoc] = makeThunkFromAPICall(
  FuelTypeModelsManager.getFuelTypeModelsAdhoc,
  'fueltypemodels/set-adhoc',
);

export const [getValidationResultsAdhoc, setValidationResultsAdhoc] = makeThunkFromAPICall(
  FuelTypeModelsManager.getValidationResultsAdhoc,
  'fueltypemodels/val-results-adhoc',
);

export const [uploadFuelTypeModel, submitFuelTypeModelsLocal] = makeThunkFromAPICall(
  FuelTypeModelsManager.submitFuelTypeModel,
  'fueltypemodels/submit-local',
);

export const [approveFuelTypeModel, setFuelTypeModelApproveStatus] = makeThunkFromAPICall(
  FuelTypeModelsManager.approveFuelTypeModel,
  'fueltypemodel/set-approve-status',
);

export const [rejectFuelTypeModel, setFuelTypeModelRejectStatus] = makeThunkFromAPICall(
  FuelTypeModelsManager.rejectFuelTypeModel,
  'fueltypemodel/set-reject-status',
);

export const [submitFuelTypeModelsNote, setFuelTypeModelNote] = makeThunkFromAPICall(
  FuelTypeModelsManager.submitFuelTypeModelsNote,
  'fueltypemodels/submit-note',
);

export const fuelTypeModelReducer = createReducer(initialState, (builder) => {
  builder.addCase(setBomFuelTypeModelsAll, (state, action) => {
    return { ...state, bom: { ...state.bom, ...action.payload.loadable } };
  });
  builder.addCase(setFuelTypeModelsLocal, (state, action) => {
    return { ...state, local: { ...state.local, ...action.payload.loadable } };
  });
  builder.addCase(setFuelTypeModelsAdhoc, (state, action) => {
    return { ...state, adhoc: { ...state.adhoc, ...action.payload.loadable } };
  });
  builder.addCase(setFuelTypeModelsForDownload, (state, action) => {
    return { ...state, download: { ...state.download, ...action.payload.loadable } };
  });
  builder.addCase(setValidationResultsAdhoc, (state, action) => {
    return { ...state, valResultsAdHoc: { ...state.valResultsAdHoc, ...action.payload.loadable } };
  });
});

export const FuelTypeModelActions = {
  getBomFuelTypeModelsAll,
  getFuelTypeModelsForJurisdiction,
  getFuelTypeModelsForDownload,
  getFuelTypeModelsAdhoc,
  getValidationResultsAdhoc,
  uploadFuelTypeModel,
  approveFuelTypeModel,
  rejectFuelTypeModel,
  submitFuelTypeModelsNote,
};

export default fuelTypeModelReducer;
