/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ValidatorActions } from 'state/validator';
import {
  ErrorSegment,
  LoadableImage,
  CuringStatus,
  FuelConditionStatus,
  FuelLoadStatus,
  ConfidenceStatus,
  EditFieldDialog,
  ViewUtils,
  AreYouSureDialog,
  PrimaryButton,
  Note,
} from 'components';
import { toReadableAge, getFullName } from 'utils';
import { LocationManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr auto',
      gridTemplateColumns: isMobile ? '100%' : '50% 50%',
      overflow: isMobile ? 'auto' : 'hidden',
    },
    gridLeft: {},
    leftSection: {
      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: '1fr auto',
    },
    rightSection: {
      display: 'block',
      backgroundColor: theme.palette.common.neutralLight,
    },
    container: {
      width: '100%',
      display: 'grid',
    },
    sectionBack: {
      margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(5),
    },
    section: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginBottom: theme.spacing(3),
    },
    sectionGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridAutoFlow: 'column',
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginBottom: theme.spacing(3),
    },
    sectionNotes: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginBottom: theme.spacing(3),
      display: 'grid',
    },
    bottomNav: {
      width: '100%',
      display: 'grid',
      placeItems: 'center',
      position: 'relative',
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      borderTop: '1px solid lightgrey',
    },
    button: {
      width: '60%',
      margin: `${theme.spacing(5)}px ${theme.spacing(1)}px`,
    },
    backButton: {
      display: 'flex',
      justifyContent: 'center',
      textDecoration: 'underline',
      fontWeight: 'bold',
      width: 'fit-content',
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    subtitle: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark
      fontWeight: 'bold',
    },
    progress: {
      width: '100%',
      display: 'grid',
      placeContent: 'center',
    },
  }),
);

function FSEValidatorDashboard() {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { validator, auth } = useAppSelector((state) => state);
  const { locations } = validator;
  const { locationId } = useParams<{ locationId: string }>();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSettings, setModalSettings] = useState<ViewUtils.EditFieldDialogParams | null>(null);

  const openModal = (option: ViewUtils.ClickableFields, location: LocationManager.Location) => {
    ViewUtils.openEditFieldDialog({ option, location, setModalSettings, setModalOpen });
  };

  const location = locations.object?.find((loc) => loc.id === parseInt(locationId ?? '', 10));

  useEffect(() => {
    if (locations.status === 'idle' && auth.status === 'finished') dispatch(ValidatorActions.getLocations());
  }, [dispatch, locations.status, auth.status]);

  if (locations.status === 'loading' || locations.status === 'idle')
    return (
      <>
        <div className={classes.root}>
          <div className={classes.progress}>
            <CircularProgress aria-valuetext="loading" />
          </div>
        </div>
      </>
    );

  if (!location)
    return (
      <div className={classes.root}>
        <ErrorSegment
          error={{ code: 404, error: 'Location not Found' }}
          onRetry={() => dispatch(ValidatorActions.getLocations())}
        />
      </div>
    );

  const submittedObs = location.observations[0] as (typeof location.observations)[0] | undefined;
  const lastValidatedObs = location.observations.find((obs) => obs.validated === true);

  const disabled = submittedObs ? submittedObs.validated && !submittedObs.validatedToday : true;

  if (!submittedObs)
    return (
      <div className={classes.root}>
        <ErrorSegment
          error={{ code: 404, error: 'No observations for Location found' }}
          onRetry={() => dispatch(ValidatorActions.getLocations())}
        />
      </div>
    );

  const handleAccept = () =>
    dispatch(ValidatorActions.validateObservation({ location, id: submittedObs.id })).then(() => {
      dispatch(ValidatorActions.getLocations());
      navigate('/validator/dashboard');
    });

  const submittedAgeText = toReadableAge(Date.now() - submittedObs.time);

  return (
    <>
      <BrowserView viewClassName={classes.root}>
        <div className={classes.leftSection}>
          <Grid container style={{ overflow: 'auto' }}>
            <Grid item sm={1} md={2} />
            <Grid item xs={8}>
              {locations.status === 'error' && locations.error && (
                <ErrorSegment
                  error={locations.error}
                  text="Failed To load Locations"
                  onRetry={() => dispatch(ValidatorActions.getLocations())}
                />
              )}
              {location && submittedObs != null && (
                <div className={classes.container}>
                  <div className={classes.sectionBack}>
                    <Button
                      variant="text"
                      className={classes.backButton}
                      component={RouterLink}
                      to="/validator/dashboard"
                    >
                      <ChevronLeft /> back to list view
                    </Button>
                  </div>
                  <div className={classes.section}>
                    <Typography variant="h4">{location.name}</Typography>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Submitted by {getFullName(submittedObs.observer)} {submittedAgeText}
                    </Typography>
                  </div>

                  <div className={classes.sectionGrid}>
                    <CuringStatus
                      observation={submittedObs}
                      prevObservation={lastValidatedObs}
                      onValueClick={() => !disabled && openModal('curing', { ...location })}
                    />
                    <FuelConditionStatus
                      observation={submittedObs}
                      prevObservation={lastValidatedObs}
                      onValueClick={() => !disabled && openModal('fuelCondition', { ...location })}
                    />
                  </div>
                  <div className={classes.sectionGrid}>
                    <FuelLoadStatus
                      observation={submittedObs}
                      prevObservation={lastValidatedObs}
                      onValueClick={() => !disabled && openModal('fuelLoad', { ...location })}
                    />
                    <ConfidenceStatus observation={submittedObs} />
                  </div>
                  <div className={classes.sectionNotes}>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Notes
                    </Typography>
                    {submittedObs.notes.map((note) => (
                      <Note key={note.id} note={note} />
                    ))}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <div className={classes.bottomNav}>
            <PrimaryButton variant="contained" className={classes.button} onClick={() => setConfirmOpen(true)}>
              Accept
              {location.newObservation.status === 'loading' && <CircularProgress size={20} aria-valuetext="loading" />}
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.rightSection} style={{ overflow: 'hidden' }}>
          {submittedObs.imageUrl && (
            <LoadableImage
              imgStyle={{ maxHeight: '-webkit-fill-available' }}
              divStyle={{ height: '100%' }}
              src={submittedObs.imageUrl}
              alt="Photo of the Observation"
            />
          )}
        </div>
      </BrowserView>
      <MobileView viewClassName={classes.root}>
        <div style={{ padding: theme.spacing(2) }}>
          {locations.status === 'error' && locations.error && (
            <ErrorSegment
              error={locations.error}
              text="Failed To load Locations"
              onRetry={() => dispatch(ValidatorActions.getLocations())}
            />
          )}
          {location && submittedObs != null && (
            <div className={classes.container}>
              <div className={classes.sectionBack}>
                <Button variant="text" className={classes.backButton} component={RouterLink} to="/validator/dashboard">
                  <ChevronLeft /> back to list view
                </Button>
              </div>
              <div className={classes.section}>
                <Typography variant="h4">{location.name}</Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Submitted by {getFullName(submittedObs.observer)} {submittedAgeText}
                </Typography>
              </div>

              <div className={classes.sectionGrid}>
                <CuringStatus
                  observation={submittedObs}
                  prevObservation={lastValidatedObs}
                  onValueClick={() => !disabled && openModal('curing', { ...location })}
                />
                <FuelConditionStatus
                  observation={submittedObs}
                  prevObservation={lastValidatedObs}
                  onValueClick={() => !disabled && openModal('fuelCondition', { ...location })}
                />
              </div>
              <div className={classes.sectionGrid}>
                <FuelLoadStatus
                  observation={submittedObs}
                  prevObservation={lastValidatedObs}
                  onValueClick={() => !disabled && openModal('fuelLoad', { ...location })}
                />
                <ConfidenceStatus observation={submittedObs} />
              </div>
              <div className={classes.sectionNotes}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Notes
                </Typography>
                {submittedObs.notes.map((note) => (
                  <Note key={note.id} note={note} />
                ))}
              </div>
            </div>
          )}
          {submittedObs.imageUrl && (
            <LoadableImage
              imgStyle={{ maxHeight: '-webkit-fill-available' }}
              divStyle={{ height: 'auto' }}
              src={submittedObs.imageUrl}
              alt="Photo of the Observation"
            />
          )}
          {location && submittedObs != null && (
            <div className={classes.bottomNav}>
              <PrimaryButton variant="contained" className={classes.button} onClick={() => setConfirmOpen(true)}>
                Accept
                {location.newObservation.status === 'loading' && (
                  <CircularProgress size={20} aria-valuetext="loading" />
                )}
              </PrimaryButton>
            </div>
          )}
        </div>
      </MobileView>
      {/* EditFieldDialog has to unmount between operations to avoid input persistence errors */}
      {modalSettings && modalOpen && (
        <EditFieldDialog open={modalOpen} onClose={() => setModalOpen(false)} {...modalSettings} />
      )}

      <AreYouSureDialog
        open={confirmOpen}
        onConfirm={() => {
          setConfirmOpen(false);
          if (handleAccept) handleAccept();
        }}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  );
}

export default FSEValidatorDashboard;
