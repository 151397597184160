import { makeAPICall } from './api';

export declare namespace API {
  export interface JurisdictionSummaryRecord {
    user_data: UserRecord[];
    location_data: LocationRecord[];
  }

  export interface UserRecord {
    user_name: string;
    is_observer: string;
    observer_locations: string;
    is_validator: string;
    validator_locations: string;
    is_editor: string;
    is_user_manager: string;
    is_authoriser: string;
    is_fdv_standard: string;
    is_fdv_elevated: string;
    last_active: string;
  }

  export interface LocationRecord {
    location: string;
    lat: string;
    lon: string;
    FMD: string;
    FWD: string;
    sub_area: string;
    number_of_observers: string;
    number_of_validators: string;
    observer_user_names: string;
    validator_user_names: string;
    last_observation: string;
    location_status: string;
  }
}
export interface JurisdictionSummaryRecord {
  userRecords: UserRecord[];
  locationRecords: LocationRecord[];
  jurisdiction: string;
}

export interface UserRecord {
  userName: string;
  isObserver: string;
  observerLocations: string;
  isValidator: string;
  validatorLocations: string;
  isEditor: string;
  isAuthoriser: string;
  isUserManager: string;
  isFdvStandard: string;
  isFdvElevated: string;
  lastActive: string;
}

interface LocationRecord {
  location: string;
  lat: string;
  lon: string;
  FMD: string;
  FWD: string;
  SubArea: string;
  numberOfObservers: string;
  numberOfValidators: string;
  observerUserNames: string;
  validatorUserNames: string;
  lastObservation: string;
  locationStatus: string;
}

/* eslint-disable */
const userHeaderToApiFieldMap: { [key: string]: string } = {
  'User': 'userName',
  'Is observer': 'isObserver',
  'Locations assigned to as observer': 'observerLocations',
  'Is validator': 'isValidator',
  'Locations assigned to as validator': 'validatorLocations',
  'Is editor': 'isEditor',
  'Is authoriser': 'isAuthoriser',
  'Is user manager': 'isUserManager',
  'Is FDV standard': 'isFdvStandard',
  'Is FDV elevated': 'isFdvElevated',
  'Last active': 'lastActive',
};

const locationHeaderToApiFieldMap: { [key: string]: string } = {
  'Location': 'location',
  'Lat': 'lat',
  'Lon': 'lon',
  'FMD': 'FMD',
  'FWD': 'FWD',
  'Sub-Area': 'SubArea',
  'Number of observers': 'numberOfObservers',
  'Number of validators': 'numberOfValidators',
  'Observer user names': 'observerUserNames',
  'Validator user names': 'validatorUserNames',
  'Last observation': 'lastObservation',
  'Location status': 'locationStatus'
};

const mapAPIDataToJurisdictionSummary = (
  root: API.JurisdictionSummaryRecord,
  jurisdiction: string | null,
): JurisdictionSummaryRecord => {
  if (!jurisdiction) {
    throw new Error('Jurisdiction is null or undefined');
  }
  return {
    jurisdiction,
    userRecords: root.user_data.map((user) => ({
      userName: user.user_name,
      isObserver: user.is_observer,
      observerLocations: user.observer_locations,
      isValidator: user.is_validator,
      validatorLocations: user.validator_locations,
      isAuthoriser: user.is_authoriser,
      isEditor: user.is_editor,
      isUserManager: user.is_user_manager,
      isFdvStandard: user.is_fdv_standard,
      isFdvElevated: user.is_fdv_elevated,
      lastActive: user.last_active,
    })),
    locationRecords: root.location_data.map((location) => ({
      location: location.location,
      lat: location.lat,
      lon: location.lon,
      FMD: location.FMD,
      FWD: location.FWD,
      SubArea: location.sub_area,
      numberOfObservers: location.number_of_observers,
      numberOfValidators: location.number_of_validators,
      observerUserNames: location.observer_user_names,
      validatorUserNames: location.validator_user_names,
      lastObservation: location.last_observation,
      locationStatus: location.location_status
    })),
  };
};

export const getJurisdictionSummary = makeAPICall<
  JurisdictionSummaryRecord,
  { jurisdiction: string | null },
  API.JurisdictionSummaryRecord
>(
  (jurisdiction) => {
    if (!jurisdiction) {
      throw new Error('Jurisdiction is null or undefined');
    }
    return {
      ext: `/jurisdiction-summary/${jurisdiction.jurisdiction}`,
      requestData: {
        method: 'GET',
      },
    };
  },
  (data, { jurisdiction }) => {
    return mapAPIDataToJurisdictionSummary(data, jurisdiction);
  },
);

export const toCSV = (jurisdictionSummary: JurisdictionSummaryRecord, isUser = false): string => {
  const userHeaders: string[] = [
    'User',
    'Is observer',
    'Locations assigned to as observer',
    'Is validator',
    'Locations assigned to as validator',
    'Is editor',
    'Is authoriser',
    'Is user manager',
    'Is FDV standard',
    'Is FDV elevated',
    'Last active',
  ];

  const locationHeaders: string[] = [
    'Location',
    'Lat',
    'Lon',
    'FMD',
    'FWD',
    'Sub-Area',
    'Number of observers',
    'Number of validators',
    'Observer user names',
    'Validator user names',
    'Last observation',
    'Location status'
  ];

  const data = isUser ? jurisdictionSummary.userRecords : jurisdictionSummary.locationRecords;
  const headers = isUser ? userHeaders : locationHeaders;
  const headerMapper = isUser ? userHeaderToApiFieldMap : locationHeaderToApiFieldMap;

  let parameterValueRow = [];
  const allStringRows = [];
  allStringRows.push(headers.join(','));
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      const obsRow = data[i];
      for (let k = 0; k < headers.length; k += 1) {
        const paramMap = new Map(Object.entries(obsRow));
        let paramVal = String(paramMap.get(headerMapper[headers[k]]));
        if (paramVal === undefined || paramVal === null) {
          paramVal = '';
        }
        if (paramVal.includes(',')) {
          paramVal = `"${paramVal}"`;
        }
        parameterValueRow.push(paramVal);
      }
      allStringRows.push(parameterValueRow.join(','));
      parameterValueRow = [];
    }
  }
  return allStringRows.join('\r\n');
};
