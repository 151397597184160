import React, { PropsWithChildren } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Link, Card, CardActions, CardContent, Divider, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      background: theme.palette.common.white,
    },
    link: {
      color: theme.palette.text.link,
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
      fontSize: 'larger',
      fontWeight: 'bold',
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.link,
    },
    headerContainer: {
      display: 'flex',
      padding: theme.spacing(2),
      alignItems: 'center',
    },
    headerContent: {
      flex: '1 1 auto',
    },
    headerText: {
      fontWeight: 'bold',
    },
    disabledLink: {
      pointerEvents: 'none',
    },
  }),
);

interface MenuCardProps extends PropsWithChildren {
  title: string;
  actionText: string;
  to: string;
  alertText?: string;
  alertColor?: string;
  disabled?: boolean;
}

const MenuCard: React.FunctionComponent<MenuCardProps> = ({
  title,
  actionText,
  to,
  alertText,
  alertColor,
  children,
  disabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const color = alertColor || theme.palette.success.main;

  return (
    <Card
      className={classes.root}
      style={{ backgroundColor: disabled ? theme.palette.common.neutralLight : theme.palette.common.white }}
      variant="outlined"
      data-testid={`menucard-${title}-card`}
    >
      <div className={classes.headerContainer}>
        <div className={classes.headerContent}>
          <Typography component="span" variant="subtitle2" color="textSecondary" className={classes.headerText}>
            {title}
          </Typography>
          {alertText && (
            <>
              <Typography
                component="span"
                variant="subtitle2"
                style={{
                  marginLeft: theme.spacing(0.5),
                  marginRight: theme.spacing(0.5),
                }}
                color="textSecondary"
                className={classes.headerText}
              >
                ·
              </Typography>
              <Typography component="span" variant="subtitle2" style={{ color }} className={classes.headerText}>
                {alertText}
              </Typography>
            </>
          )}
        </div>
      </div>
      <CardContent style={{ paddingTop: '0px' }}>{children}</CardContent>
      <Divider />
      <Link
        data-testid={`menucard-${title}-link`}
        className={disabled ? classes.disabledLink : ''}
        component={RouterLink}
        to={to}
      >
        <CardActions>
          <Typography className={classes.link}>{actionText}</Typography>
          <ArrowForward className={classes.icon} style={{ marginLeft: 'auto' }} />
        </CardActions>
      </Link>
    </Card>
  );
};

MenuCard.displayName = 'MenuCard';
export default MenuCard;
