import React, { useState } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Paper, Typography } from '@material-ui/core';

import { FireAreaManager, MapLayerManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    segmentContainer: {
      display: 'grid',
      padding: `0px ${theme.spacing(2)}px`,
      gridAutoFlow: 'column',
      gridTemplateColumns: '200px 200px auto',
      alignItems: 'start',
      justifyItems: 'start',
      width: '100%',
      cursor: 'pointer',
    },
    segment: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
    segmentEnd: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
      justifySelf: 'end',
      alignItems: 'flex-end',
      minWidth: 175,
    },
    segmentTitle: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark;
    },
    segmentAge: {
      color: theme.palette.common.grey, // theme.palette.common.grey;
      fontWeight: 'bold',
    },
    segmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
    segmentMiddleInner: {
      paddingRight: theme.spacing(3),
    },
    segmentValue: {
      minWidth: '32px',
    },
    img: {
      display: 'block',
      overflow: 'hidden',
      width: '100%',
    },
    imgPaper: {
      padding: 0,
      overflow: 'hidden',
    },
    button: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    viewDetailsButton: {
      color: theme.palette.common.grey,
      textDecoration: 'underline',
      marginRight: theme.spacing(1),
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      width: 'fit-content',
    },
  }),
);

type FireAreaListItemProps = {
  display?: 'block' | 'inline-block';
  style?: React.CSSProperties;
  firearea: FireAreaManager.FireArea;
  layer?: MapLayerManager.MapLayer;
  onFocus?: (firearea: FireAreaManager.FireArea) => void;
  onClick?: (firearea: FireAreaManager.FireArea) => void;
};

const FireAreaListItem: React.FunctionComponent<FireAreaListItemProps> = ({
  firearea,
  layer,
  style,
  display,
  onFocus,
  onClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { name, statistics } = firearea;

  const stat = statistics[0] ?? null;

  const [hover, setHover] = useState(false);

  const updateHover = (value: boolean) => {
    setHover(value);
  };

  let bgColor = theme.palette.common.white;
  if (hover) bgColor = theme.palette.common.neutralXXLight;

  const getUnits = () => {
    switch (layer?.name) {
      case 'Grass Fuel Condition':
        return '';
        break;
      case 'Grass Fuel Load':
        return ' t/ha';
        break;
      default:
        return '%';
    }
  };

  return (
    <Paper
      className={classes.root}
      variant="outlined"
      style={{
        backgroundColor: bgColor,
        display: display || 'flex',
        ...style,
      }}
      onMouseEnter={() => updateHover(true)}
      onMouseLeave={() => updateHover(false)}
      onTouchStart={() => updateHover(true)}
      onTouchEnd={() => updateHover(false)}
    >
      <div
        className={classes.segmentContainer}
        onClick={() => onClick && onClick(firearea)}
        onKeyPress={() => onFocus && onFocus(firearea)}
        role="button"
        tabIndex={0}
      >
        <div className={classes.segment} style={{ borderRight: `1px solid ${theme.palette.common.neutralXLight}` }}>
          <Typography variant="subtitle2" className={classes.segmentTitle}>
            &nbsp;
          </Typography>
          <div className={classes.segmentMiddle}>
            <Typography variant="h6" className={classes.segmentValue} style={{ fontWeight: 'bold' }}>
              {name}
            </Typography>
          </div>
          <Typography variant="subtitle2" className={classes.segmentAge}>
            {firearea.locations.length} observation points
          </Typography>
        </div>

        <div className={classes.segment}>
          <Typography variant="subtitle2" className={classes.segmentTitle}>
            {layer?.type === 'grass-curing' ? 'Grass Curing' : layer?.name}
          </Typography>
          <div style={{ display: 'flex' }}>
            <div className={classes.segmentMiddleInner}>
              <div className={classes.segmentMiddle}>
                <Typography variant="h5" className={classes.segmentValue}>
                  {stat?.percentile10 ? `${stat.percentile10}${getUnits()}` : 'N/A'}
                </Typography>
              </div>
              <Typography variant="subtitle2" className={classes.segmentAge}>
                10th
              </Typography>
            </div>
            <div className={classes.segmentMiddleInner}>
              <div className={classes.segmentMiddle}>
                <Typography variant="h5" className={classes.segmentValue}>
                  {stat?.percentile50 ? `${stat.percentile50}${getUnits()}` : 'N/A'}
                </Typography>
              </div>
              <Typography variant="subtitle2" className={classes.segmentAge}>
                50th
              </Typography>
            </div>
            <div className={classes.segmentMiddleInner}>
              <div className={classes.segmentMiddle}>
                <Typography variant="h5" className={classes.segmentValue}>
                  {stat?.percentile90 ? `${stat.percentile90}${getUnits()}` : 'N/A'}
                </Typography>
              </div>
              <Typography variant="subtitle2" className={classes.segmentAge}>
                90th
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.segmentEnd}>
          {layer?.name && layer?.name !== 'Grass Fuel Condition' && layer?.name !== 'Grass Fuel Load' && (
            <div style={{ padding: `0px ${theme.spacing(2)}px` }}>
              <Typography variant="subtitle2" className={classes.segmentTitle}>
                Satellite vs. field
              </Typography>
              <div className={classes.segmentMiddle}>
                <Typography variant="h6" className={classes.segmentValue}>
                  {stat?.satelliteFieldDiff ? `${stat.satelliteFieldDiff}%` : 'N/A'}
                </Typography>
              </div>
              <Typography variant="subtitle2" className={classes.segmentAge}>
                Max difference
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
};

FireAreaListItem.displayName = 'FireAreaListItem';
export default FireAreaListItem;
