/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Button, CircularProgress } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

import { MapLayerManager, FireAreaManager, Loadable } from 'models';
import { FireAreaListItem } from 'components';
import { getProperty } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    section: {
      padding: theme.spacing(1),
    },
  }),
);

interface WorkflowAreaListProps {
  layer?: MapLayerManager.MapLayer | null;
  fireAreas?: Loadable<FireAreaManager.FireArea[]> | null;
  onSelectFireArea?: (area: FireAreaManager.FireArea) => void;
  operations?: MapLayerManager.MapLayer.StagedOperation[];
}

const sortValues: {
  field: (string | number)[];
  desc: boolean;
  label?: string;
}[] = [
  {
    field: ['statistics', '0', 'satelliteFieldDiff'],
    desc: true,
    label: 'Max difference',
  },
  {
    field: ['name'],
    desc: false,
    label: 'Name',
  },
  {
    field: ['statistics', '0', 'percentile10'],
    desc: true,
    label: '10th percentile',
  },
  {
    field: ['statistics', '0', 'percentile50'],
    desc: true,
    label: '50th percentile',
  },
  {
    field: ['statistics', '0', 'percentile90'],
    desc: true,
    label: '90th percentile',
  },
];

const rotateSort = (value: number) => (value + 1) % sortValues.length;

const WorkflowAreaList: React.FunctionComponent<WorkflowAreaListProps> = ({
  fireAreas,
  layer,
  onSelectFireArea,
  operations,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [sortByIdx, setSortByIdx] = React.useState(0);
  const sortBy = sortValues[rotateSort(sortByIdx)];

  const sortFn = (a: FireAreaManager.FireArea, b: FireAreaManager.FireArea) => {
    // Typescript is complaining that a[sortBy.field] may be null and this can
    // be the case however it won't cause an issue
    const fieldA = getProperty(sortBy.field, a);
    const fieldB = getProperty(sortBy.field, b);
    if (fieldA == null && fieldB == null) return 0;
    if (sortBy.desc) {
      if (fieldA == null) return -1;
      if (fieldB == null) return 1;
      return fieldA < fieldB ? 1 : -1;
    }
    if (fieldA == null) return 1;
    if (fieldB == null) return -1;
    return fieldA > fieldB ? 1 : -1;
  };

  // TODO: This can take a second to render/sort, so perhaps it should
  // be virtualised? See https://github.com/bvaughn/react-virtualized
  const fireAreaListItems = fireAreas?.object
    ?.slice()
    .sort(sortFn)
    .map((area) => (
      <FireAreaListItem
        firearea={area}
        layer={layer ?? undefined}
        key={area.id}
        onClick={() => onSelectFireArea && onSelectFireArea(area)}
      />
    ));

  return (
    <div className={classes.root}>
      <div className={classes.section} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">{layer?.name ? `${layer.name} overview` : 'Invalid Layer'}</Typography>
        <Button variant="outlined" style={{ width: 200 }} onClick={() => setSortByIdx(rotateSort(sortByIdx))}>
          <FilterList />
          {sortBy.label || sortBy.field}
        </Button>
      </div>
      {operations && operations.length > 0 && (
        <div className={classes.section}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.common.neutralDark }}>
            {operations.length} edits made to this layer. Click submit to apply them and regenerate the layer
          </Typography>
        </div>
      )}

      <div className={classes.section}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.common.neutralDark }}>
          Showing {fireAreas?.object?.length ?? 0} areas
        </Typography>
      </div>
      {fireAreas?.status === 'idle' || fireAreas?.status === 'loading' ? (
        <div className={classes.section} style={{ display: 'grid', placeItems: 'center' }}>
          <CircularProgress size={20} aria-valuetext="loading" />
        </div>
      ) : undefined}
      <div className={classes.section}>{fireAreaListItems}</div>
    </div>
  );
};

export default WorkflowAreaList;
