import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card, CardContent, Link } from '@material-ui/core';
import { CheckCircle, AccessTime, RadioButtonUnchecked } from '@material-ui/icons';

import { ProgressSteps } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    card: {
      marginTop: theme.spacing(1),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    cardContent: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    cardTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      fontSize: '1.5rem',
    },
    cardSubtitle: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      display: 'flex',
      verticalAlign: 'middle',
    },
    editLink: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    check: {
      color: theme.palette.common.green,
      width: '40px',
      height: '40px',
      marginRight: theme.spacing(1),
      marginTop: 4,
    },
    uncheck: {
      color: theme.palette.common.neutral,
      width: '40px',
      height: '40px',
      marginRight: theme.spacing(1),
      marginTop: 4,
    },
  }),
);

interface ProgressNavListProps {
  onChange?: (value: number) => void;
  steps: ProgressSteps[];
}

const ProgressNavList: React.FunctionComponent<ProgressNavListProps> = ({ steps, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {steps.map((step, idx) => (
        <Card key={step.heading} className={classes.card}>
          <CardContent className={classes.cardContent}>
            {step.completed ? (
              <CheckCircle className={classes.check} />
            ) : (
              <RadioButtonUnchecked className={classes.uncheck} />
            )}
            <div>
              <Typography variant="h4" className={classes.cardTitle}>
                {step.title}
              </Typography>
              <Typography variant="subtitle1" className={classes.cardSubtitle}>
                <AccessTime style={{ marginRight: '2px' }} />
                {step.subtitle}
              </Typography>
            </div>
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              <Link
                href="#"
                variant="subtitle1"
                color="textSecondary"
                className={classes.editLink}
                onClick={() => {
                  if (onChange) onChange(idx);
                }}
              >
                Edit
              </Link>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

ProgressNavList.displayName = 'ProgressNavList';
export default ProgressNavList;
