import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, Switch, TextField, Theme, Typography } from '@material-ui/core';

import { ToastActions } from 'state/toast';
import { PrimaryButton } from 'components';
import { ToastManager, UserManager } from 'models';
import * as UserActions from 'state/user';
import { getUserState } from 'utils';
import { useAppSelector, useAppDispatch } from 'hooks';

import { useNavigate } from 'react-router';
import authDomainDataLoader from 'utils/authDomainHelper';
import { AuthDomains } from 'models/authdomains';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    errorText: {
      fontWeight: 'bold',
      color: 'red',
      fontSize: 14,
      marginBottom: 15,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(7),
    },
    paddedField: {
      marginBottom: theme.spacing(2),
    },
  }),
);

type GroupsType = {
  [key: string]: string[];
};
const groups: GroupsType = {
  Authoriser: ['Editor', 'Validator', 'Observer'],
  Editor: ['Validator', 'Observer'],
  Validator: ['Observer'],
  Observer: [],
  UserManager: [],
  HelpPublisher: [],
  'fdv-elevated': ['fdv-standard'],
  'fdv-standard': [],
};

let oktaArrData: AuthDomains[];

const AdminNewUser = () => {
  const classes = useStyles();
  const [firstnameValue, setFirstname] = useState('');
  const [lastnameValue, setLastname] = useState('');
  const [emailValue, setEmail] = useState('');
  const [groupsValue, setGroup] = useState<string[]>([]);
  const [loadingValue, setLoading] = useState(false);
  const [emailIsAllowed, setEmailIsAllowed] = useState(true);
  const [badDomain, setBadDomain] = useState('');

  const { auth } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSysAdmin = auth.object?.decoded_token?.['cognito:groups']
    ? auth.object.decoded_token['cognito:groups'].indexOf('SystemAdmin') > -1
    : false;

  const isValid = [firstnameValue, lastnameValue, emailValue, groupsValue.length].filter((x) => !x).length === 0;

  useEffect(() => {
    // Load the valid email domains okta
    authDomainDataLoader(dispatch).then((result: AuthDomains[]) => {
      oktaArrData = result;
    });
  });

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const group = event.target.name;
    const changeEl = document.getElementsByName(group)[0] as HTMLInputElement;
    console.log(`trigger group is: ${group} value is ${changeEl.checked}`);
    let groupsValueNew = [...groupsValue];

    // Do all the checked ones
    const groupNames = Object.keys(groups);
    for (let i = 0; i < groupNames.length; i += 1) {
      const inputEl = document.getElementsByName(groupNames[i])[0] as HTMLInputElement;
      if (inputEl.checked) {
        if (!groupsValueNew.includes(groupNames[i])) {
          groupsValueNew.push(groupNames[i]);
          console.log(`added group: ${groupNames[i]}`);
        }

        const subGrps = groups[groupNames[i]];
        for (let j = 0; j < subGrps.length; j += 1) {
          const subinputEl = document.getElementsByName(subGrps[j])[0] as HTMLInputElement;
          subinputEl.checked = true;
          if (!groupsValueNew.includes(subGrps[j])) {
            groupsValueNew.push(subGrps[j]);
            console.log(`added sub group: ${subGrps[j]}`);
          }
        }
      }
    }

    // Remove any unchecked ones
    for (let i = 0; i < groupNames.length; i += 1) {
      const inputEl = document.getElementsByName(groupNames[i])[0] as HTMLInputElement;
      if (!inputEl.checked) {
        if (groupsValueNew.includes(groupNames[i])) {
          groupsValueNew = groupsValueNew.filter((s) => s !== groupNames[i]);
          console.log(`removed group: ${groupNames[i]}`);
        }
      }
    }

    // Set the state
    setGroup(groupsValueNew);
  };

  const renderGroup = (group: string) => {
    const checked = groupsValue.includes(group);
    return (
      <div className={classes.paddedField}>
        <Switch
          key={group}
          checked={checked}
          disabled={group === 'SystemAdmin' && !isSysAdmin}
          onChange={handleCheckChange}
          color="primary"
          name={group}
        />
        {group}
      </div>
    );
  };

  const oktaEmailCheck = (value: string) => {
    let foundMatch = false;
    oktaArrData.forEach((oktaData) => {
      oktaData.domain_names.forEach((domain) => {
        if (value.includes('@' + domain)) {
          // Error, bad stuff
          setBadDomain(domain);
          setEmailIsAllowed(false);
          foundMatch = true;
          return;
        }
      });
    });

    // Email is fine, set the email as per normal
    if (!foundMatch) {
      setBadDomain('');
      setEmailIsAllowed(true);
    }

    setEmail(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const user: UserManager.User.UserNewRequest = {
      user_name: emailValue,
      user_email: emailValue,
      family_name: lastnameValue,
      given_name: firstnameValue,
      groups: groupsValue,
    };
    try {
      await dispatch(UserActions.addNewUser({ user }));
    } catch (e: any) {
      await dispatch(
        ToastActions.showToast({
          message: e?.data?.detail || 'Error when attempting to invite user',
          type: ToastManager.ToastType.error,
        }),
      );
      setLoading(false);
      return;
    }
    const userState = getUserState(auth?.object);
    await dispatch(UserActions.getUsersForJurisdiction({ jurisdiction: userState || '' }));
    await dispatch(
      ToastActions.showToast({
        message: `${firstnameValue} ${lastnameValue} invited`,
        type: ToastManager.ToastType.success,
      }),
    );
    setLoading(false);
    navigate('/admin/users');
  };

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.title}>
            Invite a new user
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={4}>
              <h4>Details </h4>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <h4>Groups</h4>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h4" className={classes.errorText}>
                {emailIsAllowed ? '' : `@${badDomain} is not allowed`}
              </Typography>
              <TextField
                className={classes.paddedField}
                label="Email"
                variant="outlined"
                value={emailValue}
                fullWidth
                placeholder="john.smith@email.com"
                onChange={(e) => oktaEmailCheck(e.target.value)}
                error={!emailIsAllowed}
              />
              <TextField
                className={classes.paddedField}
                label="Firstname"
                variant="outlined"
                value={firstnameValue}
                fullWidth
                placeholder="John"
                onChange={(e) => setFirstname(e.target.value)}
              />
              <TextField
                className={classes.paddedField}
                label="Lastname"
                variant="outlined"
                value={lastnameValue}
                fullWidth
                placeholder="Smith"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>

            <Grid item xs={1} />
            <Grid item xs={6}>
              {Object.keys(groups).map(renderGroup)}
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton
                disabled={!isValid || !emailIsAllowed}
                size="large"
                onClick={handleSubmit}
                loading={loadingValue}
                style={{ width: 150 }}
              >
                Invite
              </PrimaryButton>{' '}
              <Button size="large" onClick={() => navigate('/admin/users')} style={{ width: 150 }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminNewUser;
