/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
// This is disabled so we can use the recommended approach to using react-dropzone
import React, { useCallback, useEffect, useState } from 'react';

import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Card, Grid, Theme, Typography, Button, Link, RootRef, CircularProgress } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';

import config from 'config';
import { useAppSelector, useAppDispatch } from 'hooks';
import { LoadableImage, AreYouSureDialog, ErrorSegment } from 'components';
import { EditorActions } from 'state/editor';
import { LayerActions } from 'state/layers';
import { getUserState } from 'utils';

import { messageWhenBadRequest } from 'utils/error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    h4: {
      color: theme.palette.common.neutralDark,
      fontWeight: 600,
      fontSize: '14px',
    },
    primaryButton: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: 200,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    downloadCard: {
      height: 500,
      textAlign: 'center',
      verticalAlign: 'middle',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundColor: theme.palette.common.neutralXXLight,
    },
    uploadCard: {
      height: 500,
      background: theme.palette.common.neutralXXLight,
      textAlign: 'center',
      display: 'grid',
      placeItems: 'center',
    },
  }),
);

const UploadDownload = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { auth, editor } = useAppSelector((state) => state);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const [downloadImgSrc, setDownloadImgSrc] = useState<string>('');
  const { maplayers, tiffDownload, tiffUpload, modisDownload } = editor;
  const { layerId } = useParams<{ layerId: string }>();
  const layer = maplayers.object && maplayers.object.find((l) => l.id === +(layerId ?? ''));
  const userJurisdiction = auth.object ? getUserState(auth.object) : null;
  const geoserverLayerId = layer ? `fse-${userJurisdiction}:${layer.type}-candidate-${userJurisdiction}` : null;

  const geoserverTimeParam = layer ? new Date(layer.created).toISOString() : '';

  // Error is used incase the layer is in an error state, in which the download will be the last authorised layer
  // Using an empty time value will mean the WMS will default to the latest in this layer
  const geoserverLayerIdLastAuthorised = layer
    ? `fse-${userJurisdiction}:${layer.type}-authorised-${userJurisdiction}`
    : null;

  useEffect(() => {
    if (!downloadImgSrc && auth.status === 'finished' && geoserverLayerId && geoserverLayerIdLastAuthorised) {
      const jdBounds = config.jurisdictionBounds[userJurisdiction ?? 'aus'];
      const height = 400;
      const width = Math.floor(height * ((jdBounds.maxLong - jdBounds.minLong) / (jdBounds.maxLat - jdBounds.minLat)));

      dispatch(
        LayerActions.queryWMSImage({
          options: {
            layers: layer?.status === 'error' ? geoserverLayerIdLastAuthorised : geoserverLayerId,
            time: layer?.status === 'error' ? '' : geoserverTimeParam,
            bbox: `${jdBounds.minLong},${jdBounds.minLat},${jdBounds.maxLong},${jdBounds.maxLat}`,
            height,
            width,
            // @ts-ignore
            rnd: `${Math.floor(Math.random() * 1000)}`,
          },
        }),
      )
        .then((dataURL) => setDownloadImgSrc(dataURL))
        .catch(() => setDownloadImgSrc('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='));
    }
  }, [auth.status, maplayers.status]);

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: File) => {
      setUploadFile(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: 'image/tiff' });
  const { ref, ...rootProps } = getRootProps({ className: 'dropzone' });

  const handleDownload = async () => {
    if (layer) {
      const blob = await dispatch(EditorActions.downloadTiff({ layer }));
      const url = window.URL.createObjectURL(blob);
      const created = new Date(layer.created);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${layer.type}-${created.getFullYear()}${`${created.getMonth() + 1}`.padStart(
        2,
        '0',
      )}${`${created.getDate()}`.padStart(2, '0')}.tif`;

      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const handleModisDownload = async () => {
    const blob = await dispatch(EditorActions.downloadModisTiff());
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `bom-modis-curing-latest.tif`;

    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleUpload = async () => {
    if (layer && uploadFile) {
      await dispatch(EditorActions.uploadTiff({ layer, file: uploadFile ?? undefined }));
      navigate('/editor/dashboard');
    }
  };

  useEffect(() => {
    if (maplayers.status === 'idle' && auth.status === 'finished') dispatch(EditorActions.getLayers());
  }, [dispatch, maplayers.status, auth.status]);

  return (
    <div style={{ margin: `0px ${theme.spacing(3)}px` }}>
      <div style={{ marginTop: theme.spacing(3) }}>
        <Link
          component={RouterLink}
          to={`/editor/layer/${layerId}`}
          style={{
            display: window.location.pathname === `/editor/layer/${layerId}` ? 'none' : 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ display: 'flex', alignItems: 'center', color: theme.palette.common.neutralDark }}
          >
            <ChevronLeft /> Back
          </Typography>
        </Link>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {maplayers.status === 'finished' ? (
            <Typography variant="h4">{layer ? `Upload / Download ${layer.name} map` : 'Invalid map'}</Typography>
          ) : (
            <Typography variant="h4" />
          )}

          {layer?.type === 'grass-curing' && (
            <Button
              className={classes.primaryButton}
              disabled={modisDownload.status === 'loading'}
              onClick={handleModisDownload}
              style={{ color: 'white' }}
            >
              Download latest BOM Satellite layer
              {modisDownload.status === 'loading' && (
                <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
              )}
            </Button>
          )}
        </div>
      </div>

      <Grid container>
        <Grid item xs={6} style={{ padding: 25 }}>
          <h4 className={classes.h4}>Download</h4>
          <Card elevation={3} className={classes.downloadCard}>
            <LoadableImage style={{ height: 500 }} src={downloadImgSrc} />
          </Card>
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <Button
              className={classes.primaryButton}
              disabled={tiffDownload.status === 'loading'}
              onClick={handleDownload}
              style={{ margin: theme.spacing(2), width: '80%', color: 'white' }}
            >
              Download .tif file
              {tiffDownload.status === 'loading' && (
                <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
              )}
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} style={{ padding: 25 }}>
          <h4 className={classes.h4}>Upload</h4>
          {/* @ts-ignore */}
          <RootRef rootRef={ref}>
            <div {...rootProps}>
              <Card elevation={3} className={classes.uploadCard}>
                <input {...getInputProps()} />
                {tiffUpload.status === 'loading' && (
                  <Typography
                    style={{
                      color: theme.palette.common.grey,
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                  >
                    Image Uploading... Please wait...
                  </Typography>
                )}
                {tiffUpload.status !== 'loading' && uploadFile != null && (
                  <Typography
                    style={{
                      color: theme.palette.common.grey,
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                  >
                    Image Added, Click button below to upload or click/drag here to pick another
                  </Typography>
                )}
                {tiffUpload.status !== 'loading' && uploadFile == null && (
                  <Typography
                    style={{
                      color: theme.palette.common.grey,
                      fontSize: '24px',
                      fontWeight: 600,
                    }}
                  >
                    Click or Drag your .tif file here
                  </Typography>
                )}
              </Card>
            </div>
          </RootRef>
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <Button
              className={classes.primaryButton}
              disabled={tiffUpload.status === 'loading' || uploadFile == null}
              onClick={() => setConfirmOpen(true)}
              style={{ margin: theme.spacing(2), width: '80%', color: 'white' }}
            >
              Upload file
              {tiffUpload.status === 'loading' && (
                <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
              )}
            </Button>
            {tiffUpload.status === 'error' && tiffUpload?.error && (
              <ErrorSegment text={messageWhenBadRequest(tiffUpload.error)} error={tiffUpload.error} hideRetry />
            )}
          </div>
        </Grid>
      </Grid>
      <AreYouSureDialog
        open={confirmOpen}
        onConfirm={() => {
          setConfirmOpen(false);
          handleUpload();
        }}
        onClose={() => setConfirmOpen(false)}
        message="Are you sure? This will replace the existing candidate layer"
      />
    </div>
  );
};

export default UploadDownload;
