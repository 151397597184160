import React, { useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { MenuCard } from 'components';
import { ObserverActions } from 'state/observer';
import { ValidatorActions } from 'state/validator';

import { AuthManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
  }),
);

function FSEMenuMobile() {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { auth, observer, validator } = useAppSelector((state) => state);

  const hasGroup = (group: AuthManager.UserGroups): boolean => {
    if (auth.object?.decoded_token?.['cognito:groups'])
      return auth.object?.decoded_token?.['cognito:groups']?.indexOf(group) > -1;
    return false;
  };

  useEffect(() => {
    if (auth.status === 'finished' && observer.locations.status === 'idle') dispatch(ObserverActions.getLocations());
  }, [dispatch, auth.status, observer.locations.status]);

  useEffect(() => {
    if (auth.status === 'finished' && validator.locations.status === 'idle') dispatch(ValidatorActions.getLocations());
  }, [dispatch, auth.status, validator.locations.status]);

  // Counts up locations that have no validated observation today where most recent obersvation is validated and locations without any observations.
  const observerCount = observer.locations.object?.reduce<number>(
    (acc, loc) =>
      acc + (loc.observations[0]?.validated && !loc.observations[0]?.validatedToday ? 1 : 0) + +!loc.observations[0],
    0,
  );

  const validatorCount = validator.locations.object?.reduce<number>(
    (acc, loc) => acc + (!loc.observations[0]?.validated && loc.observations[0]?.submitted ? 1 : 0),
    0,
  );

  return (
    <>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h4" className={classes.title}>
            Fuel State Editor
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MenuCard
                title="Observer"
                actionText="New Observation"
                to="/observer/locations/list"
                disabled={!hasGroup('Observer')}
                alertText={observerCount != null ? `${observerCount} to observe` : undefined}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Add fuel state observations
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={12}>
              <MenuCard
                title="Validator"
                actionText="View here"
                to="/validator/dashboard"
                disabled={!hasGroup('Validator')}
                alertText={validatorCount ? `${validatorCount} to validate` : undefined}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Validate observations
                </Typography>
              </MenuCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default FSEMenuMobile;
