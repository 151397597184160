import { Common, jurisdictions } from 'models';
import { makeAPICall } from './api';
import { toDDMonFormatAEST } from 'utils';

export declare namespace FDR {
  export namespace APIDetailed {
    export interface Record {
      aac: string;
      forecast_start: string;
      forecast_end: string;
      issue_time: string;
      fuel_type: string;
      fbi: number;
      coverage: string;
      coverage_fbi_2: string;
      coverage_fbi_3: string;
      coverage_fbi_4: string;
      start_time_fbi_2: string;
      start_time_fbi_3: string;
      start_time_fbi_4: string;
      period_fbi_2: string;
      period_fbi_3: string;
      period_fbi_4: string;
      fire_area_name: string;
    }

    export interface Day {
      data: Record[];
      forecast_start: string;
      forecast_end: string;
    }

    export interface Root {
      data: Day[];
      count: number;
      updated: string;
    }
  }

  export namespace APIWeather {
    export interface Record {
      aac: string;
      forecast_start: string;
      forecast_end: string;
      issue_time: string;
      air_temperature_maximum_50_percentile: string;
      air_temperature_maximum_90_percentile: string;
      relative_humidity_minimum_10_percentile: string;
      relative_humidity_minimum_50_percentile: string;
      wind_gust_at_elevation_1_50_percentile: string;
      wind_gust_at_elevation_1_90_percentile: string;
      wind_direction_at_elevation_1: string;
      wind_speed_at_elevation_1_50_percentile: string;
      wind_speed_at_elevation_1_90_percentile: string;
      wind_direction_at_elevation_2: string | null;
      wind_speed_at_elevation_2: string;
      mix_hgt: string;
      lal: number | null;
      precipitation_25_percentile: string;
      precipitation_75_percentile: string;
      fire_area_name: string;
    }

    export interface Day {
      data: Record[];
      forecast_start: string;
      forecast_end: string;
    }

    export interface Root {
      data: Day[];
      count: number;
      updated: string;
    }
  }

  export namespace APISummary {
    export interface LocalArea {
      aac: string;
      forecast_start: string;
      forecast_end: string;
      issue_time: string;
      description: string;
      parent_aac: string;
      fdr: string | null;
      haines_index: number | null;
      haines_flag: string | null;
      lightning_activity_level?: number | null;
      wcdi: number | null;
      fbi: number | null;
      minor_fdr_extreme_flag: string | null;
      fire_danger_rating_max_in_subareas: string | null;
      fire_area_name: string | null;
      areas: null;
    }

    export interface Record {
      aac: string;
      forecast_start: string;
      forecast_end: string;
      issue_time: string;
      description: string;
      parent_aac?: null;
      fdr: string | null;
      haines_index: number | null;
      haines_flag: string | null;
      lightning_activity_level?: number | null;
      wcdi: number | null;
      fbi: number | null;
      minor_fdr_extreme_flag: string | null;
      fire_danger_rating_max_in_subareas: string | null;
      fire_area_name: string;
      areas: LocalArea[];
    }

    export interface Day {
      data: Record[];
      forecast_start: string;
      forecast_end: string;
    }

    export interface Root {
      data: Day[];
      count: number;
      updated: string;
    }
  }

  export interface Day<T> {
    data: T;
    forecastStart: Date;
    forecastEnd: Date;
    issueTime: Date;
  }

  export interface Area {
    id: string;
    name: string;
    jurisdiction: Common.Jurisdictions;
    forecastStart: Date;
    forecastEnd: Date;
    issueTime: Date;
  }

  export interface DetailedForecastData {
    fbi: number;
    coverage: string;
    coverageFbi2: string;
    coverageFbi3: string;
    coverageFbi4: string;
    startTimeFbi2: string;
    startTimeFbi3: string;
    startTimeFbi4: string;
    periodFbi2: string;
    periodFbi3: string;
    periodFbi4: string;
  }

  export interface DetailedForecast extends Area {
    data: Record<string, DetailedForecastData>;
  }

  export interface DetailedForecastList {
    forecasts: Record<Common.Jurisdictions, Day<DetailedForecast[]>[]>;
    updated: Date;
  }

  export interface WeatherForecastData {
    airTemperatureMaximum50Percentile: string;
    airTemperatureMaximum90Percentile: string;
    relativeHumidityMinimum10Percentile: string;
    relativeHumidityMinimum50Percentile: string;
    windGustAtElevation150Percentile: string;
    windGustAtElevation190Percentile: string;
    windDirectionAtElevation1: string;
    windSpeedAtElevation150Percentile: string;
    windSpeedAtElevation190Percentile: string;
    windDirectionAtElevation2: string | null;
    windSpeedAtElevation2: string;
    mixHgt: string;
    lal: number | null;
    precipitation25Percentile: string;
    precipitation75Percentile: string;
  }

  export interface WeatherForecast extends Area {
    weather: WeatherForecastData;
  }

  export interface WeatherForecastList {
    forecasts: Record<Common.Jurisdictions, Day<WeatherForecast[]>[]>;
    updated: Date;
  }

  export interface SummaryForecastDataArea {
    id: string;
    name: string | null;
    parent: string;
    fdr: string | null;
    hainesIndex: number | null;
    hainesFlag: string | null;
    lightningActivityLevel?: number | null;
    wcdi: number | null;
    fbi: number | null;
    minorFdrExtremeFlag: string | null;
  }

  export interface SummaryForecastData {
    fdr: string | null;
    fdrMaxInSubAreas: string | null;
    hainesIndex: number | null;
    hainesFlag: string | null;
    lightningActivityLevel?: number | null;
    wcdi: number | null;
    fbi: number | null;
    minorFdrExtremeFlag: string | null;
    areas: SummaryForecastDataArea[];
  }

  export interface SummaryForecast extends Area {
    summary: SummaryForecastData;
  }

  export interface SummaryForecastList {
    forecasts: Record<Common.Jurisdictions, Day<SummaryForecast[]>[]>;
    updated: Date;
  }
}

const initJurisdictions = () =>
  jurisdictions.reduce((acc, obj) => ({ ...acc, [obj]: [] }), {}) as Record<Common.Jurisdictions, any[]>;

const getJurisdictionDayIndex = (
  jurisdictionDays: FDR.Day<any>[],
  currentDay: FDR.APISummary.Day | FDR.APIDetailed.Day | FDR.APIWeather.Day,
  currentRecord: FDR.APISummary.Record | FDR.APIDetailed.Record | FDR.APIWeather.Record,
) => {
  let dayIdx = jurisdictionDays.findIndex(
    (day) => day.forecastStart.getTime() === new Date(currentRecord.forecast_start).getTime(),
  );
  if (dayIdx === -1) {
    jurisdictionDays.push({
      data: [],
      forecastStart: new Date(currentDay.forecast_start),
      forecastEnd: new Date(currentDay.forecast_end),
      issueTime: new Date(currentRecord.issue_time),
    });
    dayIdx = jurisdictionDays.length - 1;
  }
  return dayIdx;
};

const filterDuplicateDays = (allDays: FDR.Day<any>[]) => {
  return allDays.filter((day) => {
    const matches = allDays.filter((d) => toDDMonFormatAEST(d.forecastStart) === toDDMonFormatAEST(day.forecastStart));
    if (matches.length > 1) {
      matches.sort((a, b) => +a.issueTime - +b.issueTime);
      if (matches[matches.length - 1].forecastStart !== day.forecastStart) {
        return false;
      }
    }
    return true;
  });
};

const ACT_FIRE_AREA_NAME = 'AUSTRALIAN CAPITAL TERRITORY';

export const getDetailedForecast = makeAPICall<FDR.DetailedForecastList, void, FDR.APIDetailed.Root>(
  () => ({
    ext: '/fire-weather-areas/forecast/detailed',
  }),
  (root) => {
    const results: FDR.DetailedForecastList = {
      forecasts: initJurisdictions(),
      updated: root.updated ? new Date(root.updated) : new Date(0),
    };
    root.data.forEach((day) => {
      const lookup: Record<string, number> = {};

      day.data.forEach((record) => {
        let jurisdiction: Common.Jurisdictions;

        // ACT records are grouped under NSW jurisdiction so we remap them here
        if (record.fire_area_name?.toUpperCase().includes(ACT_FIRE_AREA_NAME) && results.forecasts.act) {
          jurisdiction = 'act';
        } else {
          jurisdiction = record.aac.split('_')[0].toLowerCase() as Common.Jurisdictions;
        }

        const dayIdx = getJurisdictionDayIndex(results.forecasts[jurisdiction], day, record);

        if (lookup[record.aac] == null) {
          const initRow = {
            id: record.aac,
            name: record.fire_area_name,
            jurisdiction,
            forecastStart: new Date(record.forecast_start),
            forecastEnd: new Date(record.forecast_end),
            issueTime: new Date(record.issue_time),
            data: {},
          };

          results.forecasts[jurisdiction]?.[dayIdx].data.push(initRow);
          lookup[record.aac] = results.forecasts[jurisdiction][dayIdx].data.length - 1;
        }

        const row = {
          fbi: record.fbi,
          coverage: record.coverage || '',
          coverageFbi2: record.coverage_fbi_2 || '',
          coverageFbi3: record.coverage_fbi_3 || '',
          coverageFbi4: record.coverage_fbi_4 || '',
          startTimeFbi2: record.start_time_fbi_2 || '',
          startTimeFbi3: record.start_time_fbi_3 || '',
          startTimeFbi4: record.start_time_fbi_4 || '',
          periodFbi2: record.period_fbi_2 || '',
          periodFbi3: record.period_fbi_3 || '',
          periodFbi4: record.period_fbi_4 || '',
        };

        results.forecasts[jurisdiction][dayIdx].data[lookup[record.aac]].data[record.fuel_type] = row;
      });
    });

    jurisdictions.forEach((j) => {
      results.forecasts[j].sort((a, b) => +a.forecastStart - +b.forecastStart);

      results.forecasts[j] = filterDuplicateDays(results.forecasts[j]);
    });

    return results;
  },
);

export const getWeatherForecast = makeAPICall<FDR.WeatherForecastList, void, FDR.APIWeather.Root>(
  () => ({
    ext: '/fire-weather-areas/forecast/weather',
  }),
  (root) => {
    const results: FDR.WeatherForecastList = {
      forecasts: initJurisdictions(),
      updated: root.updated ? new Date(root.updated) : new Date(0),
    };

    root.data.forEach((day) => {
      day.data.forEach((record) => {
        let jurisdiction: Common.Jurisdictions;

        // ACT records are grouped under NSW jurisdiction so we remap them here
        if (record.fire_area_name?.toUpperCase().includes(ACT_FIRE_AREA_NAME) && results.forecasts.act) {
          jurisdiction = 'act';
        } else {
          jurisdiction = record.aac.split('_')[0].toLowerCase() as Common.Jurisdictions;
        }

        const dayIdx = getJurisdictionDayIndex(results.forecasts[jurisdiction], day, record);

        const row = {
          id: record.aac,
          name: record.fire_area_name,
          jurisdiction,
          forecastStart: new Date(record.forecast_start),
          forecastEnd: new Date(record.forecast_end),
          issueTime: new Date(record.issue_time),
          weather: {
            airTemperatureMaximum50Percentile: record.air_temperature_maximum_50_percentile,
            airTemperatureMaximum90Percentile: record.air_temperature_maximum_90_percentile,
            relativeHumidityMinimum10Percentile: record.relative_humidity_minimum_10_percentile,
            relativeHumidityMinimum50Percentile: record.relative_humidity_minimum_50_percentile,
            windGustAtElevation150Percentile: record.wind_gust_at_elevation_1_50_percentile,
            windGustAtElevation190Percentile: record.wind_gust_at_elevation_1_90_percentile,
            windDirectionAtElevation1: record.wind_direction_at_elevation_1,
            windSpeedAtElevation150Percentile: record.wind_speed_at_elevation_1_50_percentile,
            windSpeedAtElevation190Percentile: record.wind_speed_at_elevation_1_90_percentile,
            windDirectionAtElevation2: record.wind_direction_at_elevation_2,
            windSpeedAtElevation2: record.wind_speed_at_elevation_2,
            mixHgt: record.mix_hgt,
            lal: record.lal,
            precipitation25Percentile: record.precipitation_25_percentile,
            precipitation75Percentile: record.precipitation_75_percentile,
          },
        };

        results.forecasts[jurisdiction][dayIdx].data.push(row);
      });
    });

    jurisdictions.forEach((j) => {
      results.forecasts[j].sort((a, b) => +a.forecastStart - +b.forecastStart);

      results.forecasts[j] = filterDuplicateDays(results.forecasts[j]);
    });

    return results;
  },
);

export const getSummaryForecast = makeAPICall<FDR.SummaryForecastList, void, FDR.APISummary.Root>(
  () => ({
    ext: '/fire-weather-areas/forecast/summary',
  }),
  (root) => {
    const results: FDR.SummaryForecastList = {
      forecasts: initJurisdictions(),
      updated: root.updated ? new Date(root.updated) : new Date(0),
    };

    root.data.forEach((day) => {
      day.data.forEach((record) => {
        let jurisdiction: Common.Jurisdictions;

        // ACT records are grouped under NSW jurisdiction so we remap them here
        if (record.fire_area_name?.toUpperCase().includes(ACT_FIRE_AREA_NAME) && results.forecasts.act) {
          jurisdiction = 'act';
        } else {
          jurisdiction = record.aac.split('_')[0].toLowerCase() as Common.Jurisdictions;
        }

        const dayIdx = getJurisdictionDayIndex(results.forecasts[jurisdiction], day, record);

        const row = {
          id: record.aac,
          name: record.fire_area_name,
          jurisdiction,
          forecastStart: new Date(record.forecast_start),
          forecastEnd: new Date(record.forecast_end),
          issueTime: new Date(record.issue_time),
          summary: {
            fdr: record.fdr,
            fdrMaxInSubAreas: record.fire_danger_rating_max_in_subareas,
            hainesIndex: record.haines_index,
            hainesFlag: record.haines_flag,
            lightningActivityLevel: record.lightning_activity_level,
            wcdi: record.wcdi,
            fbi: record.fbi,
            minorFdrExtremeFlag: record.minor_fdr_extreme_flag,
            areas: record.areas.map((area) => ({
              id: area.aac,
              name: area.description,
              parent: area.parent_aac,
              fdr: area.fdr,
              hainesIndex: area.haines_index,
              hainesFlag: area.haines_flag,
              lightningActivityLevel: area.lightning_activity_level,
              wcdi: area.wcdi,
              fbi: area.fbi,
              minorFdrExtremeFlag: record.minor_fdr_extreme_flag,
            })),
          },
        };

        results.forecasts[jurisdiction][dayIdx].data.push(row);
      });
    });

    jurisdictions.forEach((j) => {
      results.forecasts[j].sort((a, b) => +a.forecastStart - +b.forecastStart);

      results.forecasts[j] = filterDuplicateDays(results.forecasts[j]);
    });

    return results;
  },
);
