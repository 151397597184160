import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

import { LocationManager } from 'models';
import { toReadableAge } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
    segmentTitle: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark;
    },
    segmentAge: {
      color: theme.palette.common.grey, // theme.palette.common.grey;
      fontWeight: 'bold',
    },
    segmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
    segmentValue: {
      cursor: 'pointer',
      textDecoration: 'underline',
      minWidth: '32px',
    },
    segmentDiff: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface CuringStatusProps {
  style?: React.CSSProperties;
  className?: string;
  observation?: LocationManager.Location.Observation;
  prevObservation?: LocationManager.Location.Observation;
  onValueClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const CuringStatus: React.FunctionComponent<CuringStatusProps> = ({
  style,
  className,
  observation,
  prevObservation,
  onValueClick,
}) => {
  const classes = useStyles();

  const diff = prevObservation && observation && (observation.curing ?? 0) - (prevObservation.curing ?? 0);
  const diffTime =
    observation != null && prevObservation != null
      ? new Date(observation.time).getTime() - new Date(prevObservation.time).getTime()
      : null;

  return (
    <div className={className} style={style}>
      <Typography variant="subtitle2" className={classes.segmentTitle}>
        Curing
      </Typography>
      {observation != null ? (
        <div className={classes.segmentMiddle}>
          <Typography variant="h5" className={classes.segmentValue} onClick={onValueClick}>
            {observation.curing != null ? `${observation.curing}%` : 'null'}
          </Typography>
          {observation.curing != null && diff ? (
            <Typography
              variant="subtitle1"
              component="span"
              className={classes.segmentDiff}
              style={{ color: diff > 0 ? 'red' : 'green' }}
            >
              {diff > 0 ? <ArrowUpward /> : <ArrowDownward />} {Math.abs(diff)}%
            </Typography>
          ) : undefined}
        </div>
      ) : (
        <Typography variant="h5" className={classes.segmentValue} onClick={onValueClick}>
          null
        </Typography>
      )}
      {diffTime ? (
        <Typography variant="subtitle2" className={classes.segmentAge}>
          Since {toReadableAge(diffTime)}
        </Typography>
      ) : undefined}
    </div>
  );
};

CuringStatus.displayName = 'CuringStatus';
export default CuringStatus;
