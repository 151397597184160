import { createReducer } from '@reduxjs/toolkit';

import { Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';
import { getCoordinates } from 'utils';

export type UserLocationState = Loadable<GeolocationPosition>;

const initialState: UserLocationState = {
  status: 'idle',
};

export const [getPosition, setStatus] = makeThunkFromAPICall<GeolocationPosition, void>(
  getCoordinates,
  'user/location/set-status',
  false,
);

export const userLocationReducer = createReducer(initialState, (builder) => {
  builder.addCase(setStatus, (state, action) => {
    return { ...state, ...action.payload.loadable };
  });
});

export const UserLocationActions = {
  getPosition,
};

export default userLocationReducer;
