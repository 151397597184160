import React, { useState, useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { FuelTypeModelsManager } from 'models';

import { FuelTypeModelActions } from 'state/fueltypemodels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1)}px 0px`,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'pointer',
      },
    },
    disabled: {
      backgroundColor: theme.palette.common.neutral,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'default',
      },
    },
    linkTitle: {
      fontWeight: 'bold',
    },
    linkSubtitle: {
      fontWeight: 'bold',
    },
    linkArrow: {
      color: theme.palette.common.neutral,
    },
  }),
);

interface FuelTypeModelDownloadProps {
  title: string;
  summary: string;
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
  localJurisdiction: string;
}

const FuelTypeModelDownload: React.FunctionComponent<FuelTypeModelDownloadProps> = ({
  title,
  summary,
  localJurisdiction,
  disabled,
}) => {
  const classes = useStyles();
  const { download } = useAppSelector((state) => state.fuelTypeModels);
  const dispatch = useAppDispatch();
  const [jurisdiction, setJurisdiction] = useState(localJurisdiction);
  const [kickoffDownload, setKickoffDownload] = useState(false);

  useEffect(() => {
    if (download.status === 'finished') {
      if (download?.object && kickoffDownload) {
        const authorisedFTMs = download?.object.data.filter((x) => x.status === 'authorised');
        if (authorisedFTMs.length === 1) {
          const csvContent = FuelTypeModelsManager.toCSV(authorisedFTMs);
          const link = window.document.createElement('a');
          link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
          link.setAttribute('download', 'fuel-type-models.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setKickoffDownload(false);
        }
      }
    }
  }, [dispatch, download, kickoffDownload]);

  const downloadFuelTypeModels = () => {
    if (jurisdiction) {
      dispatch(FuelTypeModelActions.getFuelTypeModelsForDownload({ jurisdiction: jurisdiction || '' })).then(() => {
        setKickoffDownload(true);
      });
    }
  };

  const handleClick = () => {
    if (!disabled) {
      downloadFuelTypeModels();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKickoffDownload(false);
    setJurisdiction((event.target as HTMLInputElement).value);
  };

  return (
    <Paper className={`${classes.link} ${disabled ? classes.disabled : ''}`} elevation={0}>
      <div>
        <Typography variant="h5" className={classes.linkTitle}>
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" className={classes.linkSubtitle}>
          {summary}
        </Typography>
        <RadioGroup aria-label="Location" name="displayMode" value={jurisdiction} onChange={handleChange} row>
          <FormControlLabel value="act" control={<Radio />} label="ACT" />
          <FormControlLabel value="nsw" control={<Radio />} label="NSW" />
          <FormControlLabel value="nt" control={<Radio />} label="NT" />
          <FormControlLabel value="qld" control={<Radio />} label="QLD" />
          <FormControlLabel value="sa" control={<Radio />} label="SA" />
          <FormControlLabel value="tas" control={<Radio />} label="TAS" />
          <FormControlLabel value="vic" control={<Radio />} label="VIC" />
          <FormControlLabel value="wa" control={<Radio />} label="WA" />
        </RadioGroup>
      </div>
      <div>
        <ArrowForward className={classes.linkArrow} onClick={handleClick} />
      </div>
    </Paper>
  );
};

FuelTypeModelDownload.displayName = 'FuelTypeModelDownload';
export default FuelTypeModelDownload;
