/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, CircularProgress, Divider, Link, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { getUserState } from 'utils';
import { useAppSelector, useAppDispatch } from 'hooks';
import { FuelTypeModelActions } from 'state/fueltypemodels';
import { FuelTypeModelListItem } from 'components';
import { FuelTypeModelsManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
    filterButton: {
      width: '250px',
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
    },
    filterButtonSelected: {
      width: '250px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
    },
    button: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    topSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'stretch',
    },
  }),
);

function FSEEditorFuelTypeModelDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const { auth } = useAppSelector((state) => state);
  const { local } = useAppSelector((state) => state.fuelTypeModels);

  const dispatch = useAppDispatch();
  const fuelTypeModelSummary: Record<string, FuelTypeModelsManager.FuelTypeModelSummary[]> = {};

  const fuelTypeModels = local?.object?.data;
  let keyCounter = 0;
  const backLocation = `/editor/dashboard`;

  if (fuelTypeModels) {
    for (let i = 0; i < fuelTypeModels.length; i += 1) {
      if (fuelTypeModelSummary[fuelTypeModels[i].status] == null) {
        fuelTypeModelSummary[fuelTypeModels[i].status] = [];
        const ftmSummary: FuelTypeModelsManager.FuelTypeModelSummary = {
          submission_date: fuelTypeModels[i].submission_date,
          authorise_date: fuelTypeModels[i].authorise_date,
          approver: fuelTypeModels[i].approver,
          submitter: fuelTypeModels[i].submitter,
          status: fuelTypeModels[i].status,
        };
        fuelTypeModelSummary[fuelTypeModels[i].status].push(ftmSummary);
      }
    }

    // Always add a 'New' item so users can experiment with a new model
    fuelTypeModelSummary.new = [];
    const ftmSummaryEmpty: FuelTypeModelsManager.FuelTypeModelSummary = {
      submission_date: undefined,
      authorise_date: undefined,
      approver: null,
      submitter: null,
      status: 'new',
    };
    fuelTypeModelSummary.new.push(ftmSummaryEmpty);
  }

  const summaryItems = Object.keys(fuelTypeModelSummary).map((type) => {
    const summaryItem = fuelTypeModelSummary[type][0];
    keyCounter += 1;
    return <FuelTypeModelListItem fse_role="editor" key={keyCounter} summaryItem={summaryItem} />;
  });

  useEffect(() => {
    if (local.status === 'idle' && auth.status === 'finished') {
      if (auth?.object) {
        const userState = getUserState(auth?.object);
        dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' }));
      }
    }
  }, [auth?.object, auth.status, dispatch, local]);

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <div style={{ display: 'flex' }}>
            <Link
              component={RouterLink}
              to={backLocation}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ display: 'flex', alignItems: 'center', color: theme.palette.common.neutralDark }}
              >
                <ChevronLeft /> Back
              </Typography>
            </Link>
          </div>

          <Typography variant="h4" className={classes.title}>
            <span style={{ color: theme.palette.common.neutralDark }}>Editor /</span> Process fuel type models
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {local.status === 'loading' && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ margin: '8px' }} size={24} aria-valuetext="loading" />
            </div>
          )}
          {summaryItems}
        </Grid>
      </Grid>
    </>
  );
}

export default FSEEditorFuelTypeModelDashboard;
