export enum ToastType {
  error,
  success,
}

export interface Toast {
  type: ToastType;
  message: string;
  index?: number;
}
