import React, { useEffect, useState } from 'react';

import { Style, Icon } from 'ol/style';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Container, Theme, Button, CircularProgress } from '@material-ui/core';
import { Sort, MyLocation } from '@material-ui/icons';

import { fromLonLat } from 'ol/proj';
import { View } from 'ol';

import config from 'config';
import { LocationManager } from 'models';
import { Map, MapOverlay, LocationCard, FloatingMapButton, ErrorSegment } from 'components';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ObserverActions } from 'state/observer';
import { UserLocationActions } from 'state/userlocation';
import { ZoomToBounds, MapCommand, ZoomToCoords } from 'components/map/MapCommands';
import { getUserState } from 'utils';

const panelWidth = 400;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      background: theme.palette.common.white,
    },
    leftPanel: {
      width: panelWidth,
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
    },
    leftContent: {
      height: '100%',
      overflow: 'auto',
      padding: 25,
    },
    rightPanel: {
      position: 'absolute',
      left: panelWidth,
      right: 0,
      bottom: 0,
      top: 0,
      height: '100%',
    },
    bottomNav: {
      width: '100%',
      position: 'relative',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      borderTop: '1px solid lightgrey',
    },
    container: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh',
    },
    sortButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    sortButtonText: {
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
    },
    headerText: {
      fontWeight: 'bold',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
);

const sortValues: {
  field: keyof LocationManager.Location;
  desc: boolean;
  label?: string;
}[] = [
  {
    field: 'distance',
    desc: false,
  },
  {
    field: 'age',
    desc: true,
  },
  {
    field: 'name',
    desc: false,
  },
];

const rotateSort = (value: number) => (value + 1) % sortValues.length;

function FSELocationsDesktop() {
  const { observer, position, auth } = useAppSelector((state) => state);
  const { locations } = observer;
  const dispatch = useAppDispatch();

  const hasPosition = position.object && position.object.coords.latitude && position.object.coords.longitude;

  const [dispatchToMap, setDispatchToMap] = useState<{ dispatch?: (command: MapCommand) => void }>({});

  const handleMapRegistration = (x: (command: MapCommand) => void) => {
    setDispatchToMap({ dispatch: x });
  };
  const mapDispatch = (command: MapCommand) => dispatchToMap.dispatch && dispatchToMap.dispatch(command);

  const view = new View({ center: fromLonLat([130, -28]), zoom: 5 });

  useEffect(() => {
    if (auth.status === 'finished') {
      const jd = getUserState(auth.object);
      if (jd) mapDispatch(new ZoomToBounds(config.jurisdictionBounds[jd]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.status, dispatchToMap]);

  useEffect(() => {
    if (auth.status === 'finished') dispatch(ObserverActions.getLocations());
  }, [dispatch, auth.status]);

  useEffect(() => {
    if (position.status === 'idle') dispatch(UserLocationActions.getPosition());
  }, [dispatch, position.status]);

  useEffect(() => {
    if (position.status === 'finished') dispatch(ObserverActions.updateDistances());
  }, [position.status, dispatch]);

  useEffect(() => {
    // Update users position every 2.5 seconds
    const intervalPosition = setInterval(() => dispatch(UserLocationActions.getPosition()), 2500);
    setTimeout(() => dispatch(ObserverActions.updateDistances()), 250);

    // Check the locations list every 60 seconds
    const intervalLocation = setInterval(() => dispatch(ObserverActions.getLocations()), 60000);

    return () => {
      clearInterval(intervalPosition);
      clearInterval(intervalLocation);
    };
  }, [dispatch]);

  const classes = useStyles();
  const [sortByIdx, setSortByIdx] = React.useState(position.object?.coords.latitude != null ? 2 : 0);
  const sortBy = sortValues[rotateSort(sortByIdx)];

  const sortFn = (a: LocationManager.Location, b: LocationManager.Location) => {
    // Typescript is complaining that a[sortBy.field] may be null and this can
    // be the case however it won't cause an issue

    if (a[sortBy.field] == null && b[sortBy.field] == null) return 0;
    if (sortBy.desc) {
      if (a[sortBy.field] == null) return -1;
      if (b[sortBy.field] == null) return 1;
      // @ts-ignore
      return a[sortBy.field] < b[sortBy.field] ? 1 : -1;
    }
    if (a[sortBy.field] == null) return 1;
    if (b[sortBy.field] == null) return -1;
    // @ts-ignore
    return a[sortBy.field] > b[sortBy.field] ? 1 : -1;
  };

  // Counts up locations that have no validated observation today where most recent obersvation is validated and locations without any observations.
  const observerCount = observer.locations.object?.reduce<number>(
    (acc, loc) =>
      acc + (loc.observations[0]?.validated && !loc.observations[0]?.validatedToday ? 1 : 0) + +!loc.observations[0],
    0,
  );

  const locationsLayerSettings = {
    id: 'locations',
    dataSource: ['observer', 'locations', 'object'],
    toFeatures: LocationManager.toFeatures,
    style: new Style({
      image: new Icon({
        src: '/map/marker-light.png',
      }),
    }),
  };

  const locationCards =
    locations.object &&
    locations.object
      .slice()
      .sort(sortFn)
      .map(({ id, name, distance, age, highlight, lon, lat, observations }) => (
        <LocationCard
          id={id}
          key={`list-${id}`}
          name={name}
          distance={distance}
          age={age}
          highlight={highlight}
          observations={observations}
          onClick={() => {
            dispatch(ObserverActions.setHighlightLocation(id));
            mapDispatch(new ZoomToCoords([lon, lat], 15));
          }}
        />
      ));

  return (
    <div className={classes.root}>
      <div className={classes.leftPanel}>
        <div className={classes.leftContent}>
          <Container className={classes.container}>
            <Typography variant="h5" color="textPrimary" className={classes.headerText}>
              {observerCount ?? 0} locations awaiting observation
            </Typography>
            <Button
              variant="outlined"
              className={classes.sortButton}
              onClick={() => setSortByIdx(rotateSort(sortByIdx))}
            >
              <Sort className={classes.sortButtonText} />
              <Typography className={classes.sortButtonText}>Sort by {sortBy.label || sortBy.field}</Typography>
            </Button>
            {locations.status === 'loading' && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress aria-valuetext="loading" />
              </div>
            )}
            {locations.status === 'error' && locations.error && (
              <ErrorSegment
                error={locations.error}
                text="Failed To load Locations"
                onRetry={() => dispatch(ObserverActions.getLocations())}
              />
            )}

            {locationCards}
          </Container>
        </div>
      </div>
      <div className={classes.rightPanel}>
        <Map
          registerMapCommand={handleMapRegistration}
          featureLayers={[locationsLayerSettings]}
          view={view}
          showUser
          basemap="OSM"
          shouldDisplay
        >
          <MapOverlay
            top="calc(100% - 60px)"
            left="calc(100% - 60px)"
            width="45px"
            height="45px"
            opacity={1}
            style={{
              borderRadius: 50,
              boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            }}
          >
            <FloatingMapButton
              ariaLabel="zoom to location"
              onClick={() => {
                if (position.object && hasPosition) {
                  mapDispatch(
                    new ZoomToCoords([position.object.coords.longitude, position.object.coords.latitude], 15),
                  );
                }
              }}
            >
              <MyLocation
                style={{
                  color: hasPosition ? 'black' : 'lightgrey',
                }}
              />
            </FloatingMapButton>
          </MapOverlay>
        </Map>
      </div>
    </div>
  );
}

export default FSELocationsDesktop;
