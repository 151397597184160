import React, { useEffect, useMemo } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography } from '@material-ui/core';

import { getUserState } from 'utils';
import { useAppSelector, useAppDispatch } from 'hooks';
import { ObserverActions } from 'state/observer';
import { ValidatorActions } from 'state/validator';
import { EditorActions } from 'state/editor';
import { AuthoriserActions } from 'state/authoriser';
import { FuelTypeModelActions } from 'state/fueltypemodels';

import { MenuCard } from 'components';

import { AuthManager, MapLayerManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
  }),
);

function FSEMenuDesktop() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { auth, observer, validator, editor, authoriser, fuelTypeModels } = useAppSelector((state) => state);

  const hasGroup = (group: AuthManager.UserGroups): boolean => {
    if (auth.object?.decoded_token?.['cognito:groups'])
      return auth.object?.decoded_token?.['cognito:groups']?.indexOf(group) > -1;
    return false;
  };

  useEffect(() => {
    if (auth.status === 'finished' && observer.locations.status === 'idle') dispatch(ObserverActions.getLocations());
  }, [dispatch, auth.status, observer.locations.status]);

  useEffect(() => {
    if (auth.status === 'finished' && validator.locations.status === 'idle') dispatch(ValidatorActions.getLocations());
  }, [dispatch, auth.status, validator.locations.status]);

  useEffect(() => {
    if (auth.status === 'finished' && editor.maplayers.status === 'idle') dispatch(EditorActions.getLayers());
  }, [dispatch, auth.status, editor.maplayers.status]);

  useEffect(() => {
    if (auth.status === 'finished' && authoriser.maplayers.status === 'idle') dispatch(AuthoriserActions.getLayers());
  }, [dispatch, auth.status, authoriser.maplayers.status]);

  useEffect(() => {
    if (auth.status === 'finished' && fuelTypeModels.local.status === 'idle') {
      if (auth?.object) {
        const userState = getUserState(auth?.object);
        dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' }));
      }
    }
  }, [auth?.object, auth.status, dispatch, fuelTypeModels.local.status]);

  // Counts up locations that have no validated observation today where most recent obersvation is validated and locations without any observations.
  const observerCount = observer.locations.object?.reduce<number>(
    (acc, loc) =>
      acc + (loc.observations[0]?.validated && !loc.observations[0]?.validatedToday ? 1 : 0) + +!loc.observations[0],
    0,
  );

  const validatorCount = validator.locations.object?.reduce<number>(
    (acc, loc) => acc + (!loc.observations[0]?.validated && loc.observations[0]?.submitted ? 1 : 0),
    0,
  );

  const editorLayersByType: Record<string, MapLayerManager.MapLayer[]> = {};

  editor.maplayers?.object?.forEach((layer) => {
    if (editorLayersByType[layer.type] == null) editorLayersByType[layer.type] = [];
    editorLayersByType[layer.type].push(layer);
  });

  const editorLatestLayers = Object.keys(editorLayersByType).map(
    (type) => editorLayersByType[type].sort((a, b) => (a.created > b.created ? -1 : 1))[0],
  );

  const editorCount = editorLatestLayers.reduce<number>(
    (acc, layer) =>
      acc + (layer.status === 'auto-candidate' || layer.status === 'rejected' || layer.status === 'processing' ? 1 : 0),
    0,
  );

  const authorisorCount = useMemo(() => {
    const authorisorLayersByType: Record<string, MapLayerManager.MapLayer[]> = {};

    authoriser.maplayers?.object?.forEach((layer) => {
      if (authorisorLayersByType[layer.type] == null) authorisorLayersByType[layer.type] = [];
      authorisorLayersByType[layer.type].push(layer);
    });

    const authorisorLatestLayers = Object.keys(authorisorLayersByType).map((type) => {
      return authorisorLayersByType[type].sort((a, b) => (a.created > b.created ? -1 : 1));
    });

    const authorisedOrPendingLayers = authorisorLatestLayers.map((layerType) => {
      return layerType.filter((layer) => layer.status === 'authorised' || layer.status === 'pending-authorisation')[0];
    });

    return authorisedOrPendingLayers.reduce<number>(
      (acc, layer) => acc + (layer.status === 'pending-authorisation' ? 1 : 0),
      0,
    );
  }, [authoriser.maplayers?.object]);

  // eslint-disable-next-line
  const authoriserFuelTypeModelCount = fuelTypeModels.local.object?.data.reduce<number>(
    (acc, layer) => {
      return acc + (layer.status === 'submitted' ? 1 : 0);
    }, 0) ?? 0;

  const totalAuthoriserCount = authorisorCount + authoriserFuelTypeModelCount;

  return (
    <>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h4" className={classes.title}>
            Fuel State Editor
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.menuText} color="textSecondary">
                {hasGroup('Observer') && !hasGroup('Validator') && !hasGroup('Editor') && !hasGroup('Authoriser')
                  ? ''
                  : 'Select from the following Fuel State Editor tasks'}
              </Typography>
            </Grid>
            <Grid item xs={6} xl={4}>
              <MenuCard
                title="Observer"
                actionText="New Observation"
                to="/observer/locations/list"
                disabled={!hasGroup('Observer')}
                alertText={observerCount ? `${observerCount} to observe` : undefined}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Add fuel state observations
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={6} xl={4}>
              <MenuCard
                title="Validator"
                actionText="View here"
                to="/validator/dashboard"
                disabled={!hasGroup('Validator')}
                alertText={validatorCount ? `${validatorCount} to validate` : undefined}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Validate observations
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={6} xl={4}>
              <MenuCard
                title="Editor"
                actionText="View here"
                to="/editor/dashboard"
                disabled={!hasGroup('Editor')}
                alertText={editorCount ? `${editorCount} to review` : undefined}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Process fuel state maps
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={6} xl={4}>
              <MenuCard
                title="Authoriser"
                actionText="View here"
                to="/authoriser/dashboard"
                disabled={!hasGroup('Authoriser')}
                alertText={totalAuthoriserCount ? `${totalAuthoriserCount} to authorise` : undefined}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Authorise fuel state maps
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={6} xl={4}>
              <MenuCard
                title="Export"
                actionText="View here"
                to="/export"
                disabled={!(hasGroup('Editor') || hasGroup('Authoriser'))}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Export fuel state data
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={6} xl={4}>
              <MenuCard
                title="Locations"
                actionText="View here"
                to="/admin/locations"
                disabled={!hasGroup('Validator')}
              >
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Manage observation locations
                </Typography>
              </MenuCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default FSEMenuDesktop;
