import React, { useState, useEffect } from 'react';

// React Swipable Views is a dead package
// https://github.com/oliviertassinari/react-swipeable-views/issues/676
// The PR below will fix the issue but it's been closed and not merged
// https://github.com/oliviertassinari/react-swipeable-views/pull/668
// Package below a workaround but has no types it seems
// https://www.npmjs.com/package/react-swipeable-views-react-18-fix
// @ts-ignore
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Button } from '@material-ui/core';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { CarouselSlides } from 'models';
import { LoadableImage, HelpTypography } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 750,
      minWidth: 200,
      flexGrow: 1,
      position: 'relative',
    },
    img: {
      display: 'block',
      overflow: 'hidden',
      width: '100%',
    },
    buttonNext: {
      position: 'absolute',
      top: '50%',
      left: 'calc(98% - 32px)',
      minWidth: 24,
      borderRadius: 50,
      padding: 4,
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    buttonBack: {
      position: 'absolute',
      top: '50%',
      left: '2%',
      minWidth: 24,
      borderRadius: 50,
      padding: 4,
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    overlay: {
      position: 'absolute',
      display: 'contents',
      width: '100%',
      height: '100%',
      top: '0%',
      left: '0%',
    },
    slide: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      border: '2px solid',
      borderRadius: 5,
    },
    swipe: {
      '@media screen and (max-width:600px)': {
        paddingLeft: '10%',
        paddingRight: '10%',
      },
      '@media screen and (max-width:400px)': {
        paddingLeft: '5%',
        paddingRight: '5%',
      },

      paddingLeft: '20%',
      paddingRight: '20%',
    },
  }),
);

interface CarouselProps {
  style?: React.CSSProperties;
  onChange?: (value: number) => void;
  slides: CarouselSlides[];
  initialStep?: number;
  initialValue?: number;
  openHelp?: (id: string) => void;
}

const CarouselValueSelector: React.FunctionComponent<CarouselProps> = ({
  style,
  onChange,
  slides,
  initialStep,
  initialValue,
  openHelp,
}) => {
  let initStep = initialStep;
  if (initialStep == null && initialValue != null) {
    initStep = slides.findIndex((slide) => slide.value === initialValue);
    if (initStep === -1) initStep = undefined;
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(initStep ?? 0);
  const maxSteps = slides.length;
  const theme = useTheme();

  const handleStepChange = (step: number) => {
    setActiveStep(step);
    if (onChange) onChange(slides[step].value);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      handleStepChange(prevActiveStep + 1);
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      handleStepChange(prevActiveStep - 1);
      return prevActiveStep - 1;
    });
  };

  useEffect(() => {
    if (onChange) onChange(slides[initStep ?? 0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        ...style,
      }}
    >
      <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents className={classes.swipe}>
        {slides.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div
                className={classes.slide}
                style={{
                  paddingBottom: 4,
                  ...step.style,
                  borderColor: activeStep === index && step.style ? step.style.borderColor : theme.palette.common.white,
                }}
              >
                <div>
                  <HelpTypography
                    variant="h5"
                    style={{
                      paddingTop: 4,
                      color: 'black',
                      width: 'fit-content',
                    }}
                    helpId={step.helpId || ''}
                    onTriggerHelp={openHelp}
                  >
                    {step.title}

                    {step.subtitle && (
                      <Typography component="span" variant="subtitle2">
                        {step.subtitle}
                      </Typography>
                    )}
                  </HelpTypography>

                  <Typography
                    variant="subtitle2"
                    style={{
                      height: 42,
                    }}
                  >
                    {step.label}
                  </Typography>
                </div>
                <LoadableImage className={classes.img} src={step.imgPath} alt={step.label} aspectRatio={0.6171} />
              </div>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <div className={classes.overlay}>
        <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.buttonBack}>
          <KeyboardArrowLeft />
        </Button>
        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.buttonNext}>
          <KeyboardArrowRight />
        </Button>
      </div>
    </div>
  );
};

CarouselValueSelector.displayName = 'CarouselValueSelector';
export default CarouselValueSelector;
