import React from 'react';

const LinkButton: React.FunctionComponent<{ onClick: () => void; text: string; className: string }> = ({
  onClick,
  text,
  className,
}) => {
  return (
    <span className={className} onClick={onClick} role="button" onKeyDown={onClick} tabIndex={0}>
      {text}
    </span>
  );
};

export default LinkButton;
