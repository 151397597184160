/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Link, TextField, Button, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ChevronLeft } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { HelpActions } from 'state/help';
import { HelpManager } from 'models';
import { AreYouSureDialog, ReactSummernote } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    back: {
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
      textDecoration: 'underline',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      display: 'flex',
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      color: theme.palette.common.black,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 250,
      width: 350,
    },
    inputArea: {
      margin: theme.spacing(1),
      marginBottom: 0,
      marginTop: theme.spacing(2),
      minWidth: 250,
      width: 350,
    },
    submitBtn: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralLight}`,
      marginBottom: 2,
      borderRadius: 8,
      color: theme.palette.common.black70,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
      },
    },
  }),
);

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  ignoreCase: true,
  ignoreAccents: true,
  stringify: (option: HelpManager.Item) => option.title,
});

function HelpAddEdit() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth, help } = useAppSelector((state) => state);

  const [deleteAreYouSureOpen, setDeleteAreYouSureOpen] = useState(false);

  const { helpItem } = useParams<{ helpItem: string }>();

  const itemId = parseInt(helpItem ?? '', 10);
  const item = help.get[itemId]?.object != null ? help.get[itemId].object : null;

  const [title, setTitle] = useState(item?.title ?? '');
  const [longTitle, setLongTitle] = useState(item?.longTitle ?? '');
  const [tags, setTags] = useState<string[]>(item?.tags ?? []);
  const [summary, setSummary] = useState(item?.summary ?? '');
  const [topic, setTopic] = useState(item?.topic ?? '');
  const [group, setGroup] = useState(item?.group ?? '');
  const [priority, setPriority] = useState(item?.priority ?? 0);
  const [columnSpan, setColumnSpan] = useState(item?.columnSpan ?? 1);
  const [externalLink, setExternalLink] = useState(item?.externalLink ?? '');
  const [related, setRelated] = useState<number[]>(item?.related ?? []);
  const [content, setContent] = useState(item?.content ?? '');

  const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

  const isAdding = item == null;

  const isValid = (): boolean =>
    (title &&
      topic &&
      group &&
      columnSpan > 0 &&
      columnSpan <= 4 &&
      content != null &&
      !(externalLink !== '' && !externalLink.match(urlRegex)) &&
      !Number.isNaN(priority)) ||
    false;

  const onChange = (e: any) => {
    setContent(e as string);
  };

  const onAddEdit = () => {
    if (isAdding) {
      dispatch(
        HelpActions.addItem({
          item: {
            title,
            longTitle: longTitle || undefined,
            group,
            topic,
            priority,
            summary: summary || '',
            columnSpan,
            externalLink: externalLink || undefined,
            tags,
            related,
            content,
          },
        }),
      ).then(() => {
        dispatch(HelpActions.getList());
        navigate(`/help`);
      });
    } else {
      dispatch(
        HelpActions.editItem({
          item: {
            id: itemId,
            title,
            longTitle: longTitle || undefined,
            group,
            topic,
            priority,
            summary: summary || '',
            columnSpan,
            externalLink: externalLink || undefined,
            tags,
            related,
            content,
          },
        }),
      ).then(() => {
        dispatch(HelpActions.getList());
        dispatch(HelpActions.getItem({ id: itemId }));
        navigate(`/help/${itemId}`);
      });
    }
  };

  const onDelete = () => {
    dispatch(HelpActions.deleteItem({ id: itemId })).then(() => {
      dispatch(HelpActions.getList());
      navigate('/help');
    });
  };

  const reload = (itemToEdit: HelpManager.Item) => {
    if (itemToEdit) {
      setTitle(itemToEdit.title);
      if (itemToEdit.longTitle) setLongTitle(itemToEdit.longTitle);
      setTags(itemToEdit.tags);
      if (itemToEdit.summary) setSummary(itemToEdit.summary);
      setTopic(itemToEdit.topic);
      setGroup(itemToEdit.group);
      setColumnSpan(itemToEdit.columnSpan ?? 1);
      if (itemToEdit.externalLink) setExternalLink(itemToEdit.externalLink);
      setRelated(itemToEdit.related);
      setContent(itemToEdit.content ?? '');
    }
  };

  useEffect(() => {
    if (
      !Number.isNaN(itemId) &&
      auth.status === 'finished' &&
      (help.get[itemId] == null || help.get[itemId]?.status === 'idle')
    ) {
      dispatch(HelpActions.getItem({ id: itemId })).then((i) => i && reload(i));
    }
  }, [auth.status, help.get[itemId]?.status]);

  useEffect(() => {
    if (!Number.isNaN(itemId) && auth.status === 'finished') {
      dispatch(HelpActions.getItem({ id: itemId })).then((i) => i && reload(i));
    }
  }, [auth.status]);

  useEffect(() => {
    if (auth.status === 'finished' && help.list.status === 'idle') {
      dispatch(HelpActions.getList());
    }
  }, [auth.status, help.list.status]);

  if (!Number.isNaN(itemId) && (help.get[itemId] == null || help.get[itemId].status === 'loading')) {
    return (
      <div style={{ margin: theme.spacing(2) }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={30} aria-valuetext="loading" />
          <Typography align="center" variant="h6">
            Loading help article
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Link component={RouterLink} to="/help">
            <Typography align="left" variant="subtitle1" className={classes.back}>
              <ChevronLeft />
              Help centre
            </Typography>
          </Link>
          <Typography variant="h4" className={classes.title}>
            {isAdding ? 'Add new article' : 'Edit article'}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginBottom: theme.spacing(2) }}>
            <div className={classes.inputArea}>
              <TextField
                className={classes.textField}
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!title}
              />
            </div>
            <div className={classes.inputArea}>
              <TextField
                className={classes.textField}
                label="Long Title"
                value={longTitle}
                onChange={(e) => setLongTitle(e.target.value)}
              />
            </div>
            <div className={classes.inputArea}>
              <TextField
                className={classes.textField}
                label="Group"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                error={!group}
              />
            </div>
            <div className={classes.inputArea}>
              <TextField
                className={classes.textField}
                label="Topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                error={!topic}
              />
            </div>
            <div className={classes.inputArea}>
              <Typography variant="body2">
                A short summary only visible for multicolumn articles on the search page.
              </Typography>
              <TextField
                className={classes.textField}
                label="Summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                multiline
                minRows={1}
                maxRows={3}
              />
            </div>
            <div className={classes.inputArea}>
              <Typography variant="body2">
                How many columns (out of 4) should this article take up on the search page.
              </Typography>
              <TextField
                className={classes.textField}
                label="Columns"
                value={columnSpan}
                onChange={(e) => setColumnSpan(+e.target.value)}
                type="number"
                inputProps={{ max: 4, min: 1 }}
                error={!(columnSpan > 0 && columnSpan <= 4)}
              />
            </div>
            <div className={classes.inputArea}>
              <Typography variant="body2">
                If the external link is set, then this article will be in a &apos;link&apos; mode, where the link in the
                article will send the user directly to the url entered here.
              </Typography>
              <TextField
                className={classes.textField}
                label="External Link (optional)"
                value={externalLink}
                onChange={(e) => setExternalLink(e.target.value)}
                error={externalLink !== '' && !externalLink.match(urlRegex)}
              />
            </div>
            <div className={classes.inputArea}>
              <Typography variant="body2">
                A priority level for this topic being displayed higher in the search pages list. The higher the number
                the higher the priority. The highest priority for each group will determine the order of the groups and
                within the groups the order of the tags.
              </Typography>
              <TextField
                className={classes.textField}
                type="number"
                label="Priority"
                value={priority}
                onChange={(e) => setPriority(+e.target.value)}
                error={Number.isNaN(priority)}
              />
            </div>
            <div className={classes.inputArea}>
              <Typography variant="body2">
                A set of space separated keywords used as keywords to help users search for this article
              </Typography>
              <TextField
                className={classes.textField}
                label="Tags"
                value={tags.join(' ')}
                onChange={(e) => setTags(e.target.value.split(' '))}
              />
            </div>
            <div className={classes.inputArea}>
              <Typography variant="body2">
                The related articles that are displayed at the bottom of this article
              </Typography>
              <Autocomplete
                multiple
                className={classes.textField}
                filterOptions={filterOptions}
                options={help.list.object ?? []}
                value={help.list.object?.filter((i) => related.indexOf(i.id) > -1) ?? []}
                getOptionLabel={(option: HelpManager.Item) => option.title}
                renderInput={(params) => <TextField {...params} variant="standard" label="Related Articles" />}
                onChange={(_, val) => setRelated(val.map((i) => i.id))}
              />
            </div>
          </div>
          <ReactSummernote value={content} onChange={onChange} />
          <div
            style={{
              display: 'flex',
              marginBottom: theme.spacing(6),
              marginTop: theme.spacing(3),
              justifyContent: 'space-between',
            }}
          >
            {!isAdding ? (
              <Button
                className={classes.submitBtn}
                onClick={() => setDeleteAreYouSureOpen(true)}
                disabled={help.delete.status === 'loading'}
              >
                {help.delete.status === 'loading' && (
                  <CircularProgress style={{ width: 16, height: 16, margin: '0px 8px' }} aria-valuetext="loading" />
                )}
                Delete article
              </Button>
            ) : (
              <div />
            )}
            <Button
              className={classes.submitBtn}
              onClick={onAddEdit}
              disabled={help.post.status === 'loading' || help.put.status === 'loading' || !isValid()}
            >
              {(help.post.status === 'loading' || help.put.status === 'loading') && (
                <CircularProgress style={{ width: 16, height: 16, margin: '0px 8px' }} aria-valuetext="loading" />
              )}
              {isAdding ? 'Add article' : 'Edit article'}
            </Button>
          </div>
        </Grid>
      </Grid>
      <AreYouSureDialog
        open={deleteAreYouSureOpen}
        onConfirm={() => {
          setDeleteAreYouSureOpen(false);
          onDelete();
        }}
        onClose={() => setDeleteAreYouSureOpen(false)}
      />
    </div>
  );
}

export default HelpAddEdit;
