import { createReducer } from '@reduxjs/toolkit';

import { LocationManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type LocationState = {
  locations: Loadable<LocationManager.Location.LocationAdminAPI.Location[]>;
  upload: Loadable<null>;
};

const initialState: LocationState = {
  locations: {
    status: 'idle',
  },
  upload: {
    status: 'idle',
  },
};

export const [getLocations, setLocations] = makeThunkFromAPICall(LocationManager.getLocationList, 'location/locations');

export const [addLocation, setUploadAddStatus] = makeThunkFromAPICall(
  LocationManager.addNewLocation,
  'location/upload/set-add-status',
);
export const [updateLocation, setUploadUpdateStatus] = makeThunkFromAPICall(
  LocationManager.updateLocation,
  'location/upload/set-update-status',
);

export const locationReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLocations, (state, action) => {
    return { ...state, locations: { ...state.locations, ...action.payload.loadable } };
  });
  builder.addCase(setUploadAddStatus, (state, action) => {
    return { ...state, upload: { ...state.upload, ...action.payload.loadable } };
  });
  builder.addCase(setUploadUpdateStatus, (state, action) => {
    return { ...state, upload: { ...state.upload, ...action.payload.loadable } };
  });
});

export const LocationActions = {
  getLocations,
  addLocation,
  updateLocation,
};

export default locationReducer;
