import { createAction, createReducer } from '@reduxjs/toolkit';

import { LocationManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type ValidatorState = {
  locations: Loadable<LocationManager.Location[]>;
};

const initialState: ValidatorState = {
  locations: {
    status: 'idle',
  },
};

export const modifyObservation = createAction<{
  id: number;
  current: Partial<LocationManager.Location.Observation>;
}>('validator/locations/set-current');

export const [submitEdits, setObservationUploadStatus] = makeThunkFromAPICall(
  LocationManager.editObservation,
  'validator/observations/upload/set-status',
);

export const [validateObservation, setValidateObservationUploadStatus] = makeThunkFromAPICall(
  LocationManager.validateObservation,
  'validator/observations/validate/set-status',
);

export const [getLocations, setLocationStatus] = makeThunkFromAPICall(
  LocationManager.getLocationListAsValidator,
  'validator/locations/set-status',
);

export const validatorReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLocationStatus, (state, action) => {
    const { loadable } = action.payload;
    return {
      ...state,
      locations: {
        ...state.locations,
        status: loadable.status,
        error: loadable.error,
        object:
          loadable.object?.map((loc) => ({
            ...loc,
            newObservation: state.locations.object?.find((l) => l.id === loc.id)?.newObservation ?? loc.newObservation,
          })) ?? state.locations.object,
      },
    };
  });
  builder.addCase(setObservationUploadStatus, (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        object: state.locations.object
          ? state.locations.object.map((loc) => ({
              ...loc,
              newObservation:
                action.payload.input.location.id === loc.id
                  ? { ...loc.newObservation, ...action.payload.loadable }
                  : loc.newObservation,
            }))
          : state.locations.object,
      },
    };
  });
  builder.addCase(setValidateObservationUploadStatus, (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        object: state.locations.object
          ? state.locations.object.map((loc) => ({
              ...loc,
              newObservation:
                action.payload.input.location.id === loc.id
                  ? { ...loc.newObservation, ...action.payload.loadable }
                  : loc.newObservation,
            }))
          : state.locations.object,
      },
    };
  });
  builder.addCase(modifyObservation, (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        object: state.locations.object
          ? state.locations.object.map((loc) => ({
              ...loc,
              newObservation: {
                ...loc.newObservation,
                object:
                  action.payload.id === loc.id
                    ? { ...loc.newObservation.object, ...action.payload.current }
                    : loc.newObservation.object,
              },
            }))
          : state.locations.object,
      },
    };
  });
});

export const ValidatorActions = {
  modifyObservation,
  submitEdits,
  validateObservation,
  getLocations,
};

export default validatorReducer;
