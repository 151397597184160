import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1)}px 0px`,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'pointer',
      },
    },
    disabled: {
      backgroundColor: theme.palette.common.neutral,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'default',
      },
    },
    linkTitle: {
      fontWeight: 'bold',
    },
    linkSubtitle: {
      fontWeight: 'bold',
    },
    linkArrow: {
      color: theme.palette.common.neutral,
    },
  }),
);

interface EditorMenuButtonProps {
  title: string;
  summary: string;
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const EditorMenuButton: React.FunctionComponent<EditorMenuButtonProps> = ({
  title,
  summary,
  to,
  onClick,
  disabled,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleClick = () => {
    if (to && !disabled) navigate(to);
    if (onClick && !disabled) onClick();
  };

  return (
    <Paper className={`${classes.link} ${disabled ? classes.disabled : ''}`} elevation={0} onClick={handleClick}>
      <div>
        <Typography variant="h5" className={classes.linkTitle}>
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" className={classes.linkSubtitle}>
          {summary}
        </Typography>
      </div>
      <div>
        <ArrowForward className={classes.linkArrow} />
      </div>
    </Paper>
  );
};

EditorMenuButton.displayName = 'EditorMenuButton';
export default EditorMenuButton;
