import { createReducer, createAction } from '@reduxjs/toolkit';

import { LocationManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type ObserverState = {
  locations: Loadable<LocationManager.Location[]>;
  upload: Loadable<null>;
};

const initialState: ObserverState = {
  locations: {
    status: 'idle',
  },
  upload: {
    status: 'idle',
  },
};

export const setNewObservation = createAction<{
  id: number;
  current: Partial<LocationManager.Location.Observation>;
}>('observer/locations/set-current');

export const setHighlightLocation = createAction<number>('observer/locations/highlight');

export const [getLocations, setLocationStatus] = makeThunkFromAPICall(
  LocationManager.getLocationListAsObserver,
  'observer/locations/set-status',
);

export const [updateDistances, setUpdateLocationStatus] = makeThunkFromAPICall(
  LocationManager.applyDistances,
  'observer/locations/set-location-status',
  false,
);

export const [addObservation, setUploadStatus] = makeThunkFromAPICall(
  LocationManager.addNewObservation,
  'observer/upload/set-status',
);

export const [editObservation, setObservationUploadStatus] = makeThunkFromAPICall(
  LocationManager.editObservation,
  'observer/observations/upload/set-status',
);

export const oberseverReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLocationStatus, (state, action) => {
    return { ...state, locations: { ...state.locations, ...action.payload.loadable } };
  });
  builder.addCase(setUpdateLocationStatus, (state, action) => {
    return { ...state, locations: { ...state.locations, ...action.payload.loadable } };
  });
  builder.addCase(setUploadStatus, (state, action) => {
    return { ...state, upload: { ...state.upload, ...action.payload.loadable } };
  });
  builder.addCase(setHighlightLocation, (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        object: state.locations.object
          ? state.locations.object.map((loc) => ({
              ...loc,
              highlight: action.payload === loc.id,
            }))
          : state.locations.object,
      },
    };
  });
  builder.addCase(setObservationUploadStatus, (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        object: state.locations.object
          ? state.locations.object.map((loc) => ({
              ...loc,
              newObservation:
                action.payload.input.location.id === loc.id
                  ? { ...loc.newObservation, ...action.payload.loadable }
                  : loc.newObservation,
            }))
          : state.locations.object,
      },
    };
  });
  builder.addCase(setNewObservation, (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        object: state.locations.object
          ? state.locations.object.map((loc) => ({
              ...loc,
              newObservation: {
                ...loc.newObservation,
                object:
                  action.payload.id === loc.id
                    ? { ...loc.newObservation.object, ...action.payload.current }
                    : loc.newObservation.object,
              },
            }))
          : state.locations.object,
      },
    };
  });
});

export const ObserverActions = {
  setNewObservation,
  setHighlightLocation,
  getLocations,
  updateDistances,
  addObservation,
  editObservation,
};

export default oberseverReducer;
