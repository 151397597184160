import { Common } from 'models';
import { makeAPICall } from './api';
import * as LayerManager from 'models/layer';
import { getGeoserverEndpoint } from 'utils';

export declare namespace Incident {
  export type WarningCategory = 'NotApplicable' | 'Advice' | 'WatchAndAct' | 'EmergencyWarning';

  export namespace API {
    export interface Geometry {
      type: string;
      coordinates: number[];
    }

    export interface Geometry2 {
      type: string;
      coordinates: number[][][][];
    }

    export interface Properties {
      jurisdiction_id: number;
      jurisdiction_name: Common.Jurisdictions;
      incident_slug?: any;
      update_date?: string;
      notified?: string;
      closed?: any;
      title: string;
      warning_category: WarningCategory;
      location_description: string;
      region: string;
      district?: any;
      lga: string;
      suburb: string;
      status: string;
      incident_type: string;
      fire_type: string;
      is_fire?: boolean;
      is_warning?: any;
      size?: any;
      size_description: string;
      incident_level: string;
      resources?: any;
      aircraft?: any;
      agency: string;
      geometry2: Geometry2;
      created: string;
      modified: string;
      native_id: string;
    }

    export interface Feature {
      type: string;
      id: string;
      geometry: Geometry;
      geometry_name: string;
      properties: Properties;
      bbox: number[];
    }

    export interface Properties2 {
      name: string;
    }

    export interface Crs {
      type: string;
      properties: Properties2;
    }

    export interface Root {
      type: string;
      features: Feature[];
      totalFeatures: number;
      numberMatched: number;
      numberReturned: number;
      timeStamp: string;
      crs: Crs;
      bbox: number[];
    }
  }
}

export interface Incident {
  type: string;
  id: string;
  nativeId: string;
  jurisdiction: Common.Jurisdictions | null;
  incidentSlug?: any;
  updateDate?: Date;
  notified?: Date;
  closed?: any;
  title: string;
  warningCategory: Incident.WarningCategory;
  locationDescription: string;
  region: string;
  district?: any;
  lga: string;
  suburb: string;
  status: string;
  incidentType: string;
  fireType: string;
  isFire?: boolean;
  isWarning?: any;
  size?: any;
  sizeDescription: string;
  incidentLevel: string;
  resources?: any;
  aircraft?: any;
  agency: string;
  created: Date;
  modified: Date;
  bbox: Common.Bounds;
}

export const mapAPIToIncidents = (root: Incident.API.Root): Incident[] =>
  root.features.map((feat) => ({
    id: feat.id,
    nativeId: feat.properties.native_id,
    type: feat.type,
    jurisdiction: feat.properties.jurisdiction_name,
    incidentSlug: feat.properties.incident_slug,
    updateDate: feat.properties.update_date ? new Date(feat.properties.update_date) : undefined,
    notified: feat.properties.notified ? new Date(feat.properties.notified) : undefined,
    closed: feat.properties.closed,
    title: feat.properties.title,
    warningCategory: feat.properties.warning_category,
    locationDescription: feat.properties.location_description,
    region: feat.properties.region,
    district: feat.properties.district,
    lga: feat.properties.lga,
    suburb: feat.properties.suburb,
    status: feat.properties.status,
    incidentType: feat.properties.incident_type,
    fireType: feat.properties.fire_type,
    isFire: feat.properties.is_fire,
    isWarning: feat.properties.is_warning,
    size: feat.properties.size,
    sizeDescription: feat.properties.size_description,
    incidentLevel: feat.properties.incident_level,
    resources: feat.properties.resources,
    aircraft: feat.properties.aircraft,
    agency: feat.properties.agency,
    created: new Date(feat.properties.created),
    modified: new Date(feat.properties.modified),
    bbox: {
      minLong: feat.bbox[0],
      minLat: feat.bbox[1],
      maxLong: feat.bbox[2],
      maxLat: feat.bbox[3],
    },
  }));

export const getIncidents = makeAPICall<Incident[], void, Incident.API.Root>(
  () => ({
    ext: `?service=WFS&version=1.0.0&request=GetFeature&typeName=${
      LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName
    }&maxFeatures=10000&outputFormat=application%2Fjson`,
    endpoint: getGeoserverEndpoint('ows'),
    skipAuth: false,
  }),
  mapAPIToIncidents,
);

export const getIncidentsByBBox = makeAPICall<Incident[], { bbox: Common.Bounds }, Incident.API.Root>((payload) => {
  const { minLong, minLat, maxLong, maxLat } = payload.bbox;
  return {
    ext: `?service=WFS&version=1.0.0&request=GetFeature&typeName=${
      LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName
    }&maxFeatures=10000&outputFormat=application%2Fjson&BBOX=${minLong},${minLat},${maxLong},${maxLat},EPSG%3A4326`,
    endpoint: getGeoserverEndpoint('ows'),
    skipAuth: false,
  };
}, mapAPIToIncidents);
