import { makeAPICall } from './api';

import * as LocationManager from 'models/location';
import { isToday } from 'utils';

export declare namespace FireArea {
  export namespace API {
    export interface Note {
      note_id: number;
      note: string;
      modified: string;
      user: User;
    }

    export interface User {
      user_id: number;
      user_name: string;
      user_email: string;
      given_name: string | null;
      family_name: string | null;
    }

    export interface Observation {
      observation_id: number;
      observer_id: number;
      observation_time: string;
      validation_time?: any;
      fuel_load?: number;
      fuel_condition?: number;
      curing: number;
      validator_id?: number;
      is_confident: boolean;
      is_continuous: boolean;
      status: string;
      photo_url?: any;
      observation_notes: Note[];
      observer: User;
      validator: User;
    }

    export interface Geometry {
      type: string;
      coordinates: number[];
    }

    export interface Location {
      location_id: number;
      status: string;
      location_name: string;
      observations: Observation[];
      geometry: Geometry;
    }

    export interface Statistic {
      map_layer_stats_id: number;
      percentile_10: number | null;
      percentile_50: number | null;
      percentile_90: number | null;
      satellite_field_diff: number | null;
    }

    export interface Bounds {
      max_lat: number;
      max_long: number;
      min_lat: number;
      min_long: number;
    }

    export interface FireArea {
      bounds: Bounds;
      statistics: Statistic[] | null;
      fire_management_area_id: number;
      fire_management_area_name: string;
      jurisdiction_id: number;
      created: Date;
      modified: Date;
      locations: Location[];
    }

    export interface Root {
      data: FireArea[];
      count: number;
    }
  }

  export interface Statistic {
    id: number;
    percentile10: number | null;
    percentile50: number | null;
    percentile90: number | null;
    satelliteFieldDiff: number | null;
  }

  export interface Bounds {
    maxLat: number;
    maxLong: number;
    minLat: number;
    minLong: number;
  }

  export interface Jurisdiction {
    id: number;
    name: 'NSW' | 'VIC' | 'QLD' | 'TAS' | 'SA' | 'NT' | 'WA' | 'ACT';
  }
}

export interface FireArea {
  id: number;
  name: string;
  jurisdictionId: number;
  locations: LocationManager.Location[];
  modified: number;
  created: number;
  bounds: FireArea.Bounds;
  statistics: FireArea.Statistic[];
}

const mapAPItoFireAreas = (root: FireArea.API.Root): FireArea[] =>
  root.data.map((area) => ({
    id: area.fire_management_area_id,
    name: area.fire_management_area_name,
    jurisdictionId: area.jurisdiction_id,
    modified: new Date(area.modified).getTime(),
    created: new Date(area.created).getTime(),
    bounds: {
      maxLat: area.bounds.max_lat,
      maxLong: area.bounds.max_long,
      minLat: area.bounds.min_lat,
      minLong: area.bounds.min_long,
    },
    statistics: area.statistics
      ? area.statistics.map((stat) => ({
          id: stat.map_layer_stats_id,
          percentile10: stat.percentile_10,
          percentile50: stat.percentile_50,
          percentile90: stat.percentile_90,
          satelliteFieldDiff: stat.satellite_field_diff,
        }))
      : [
          {
            id: -1,
            percentile10: null,
            percentile50: null,
            percentile90: null,
            satelliteFieldDiff: null,
          },
        ],
    locations: area.locations
      ? area.locations.map(
          (loc) =>
            ({
              id: loc.location_id,
              name: loc.location_name,
              lon: loc.geometry.coordinates[0],
              lat: loc.geometry.coordinates[1],
              distance: null,
              highlight: false,
              age:
                loc.observations && loc.observations[0]
                  ? Date.now() - new Date(loc.observations[0].observation_time).getTime()
                  : null,
              observations: loc.observations
                ? loc.observations.map((obs) => {
                    const time = new Date(obs.observation_time).getTime();
                    const isTimeToday = isToday(new Date(time));
                    return {
                      time,
                      id: obs.observation_id,
                      locationId: loc.location_id,
                      curing: obs.curing,
                      fuelCondition: obs.fuel_condition,
                      fuelLoad: obs.fuel_load,
                      confident: obs.is_confident,
                      continuous: obs.is_continuous,
                      notes: obs.observation_notes.map((note) => ({
                        id: note.note_id,
                        note: note.note,
                        modified: note.modified,
                        user: note.user
                          ? {
                              id: note.user.user_id,
                              userName: note.user.user_name,
                              email: note.user.user_email,
                              firstName: note.user.given_name,
                              familyName: note.user.family_name,
                            }
                          : null,
                      })),
                      observer: obs.observer
                        ? {
                            id: obs.observer.user_id,
                            userName: obs.observer.user_name,
                            email: obs.observer.user_email,
                            firstName: obs.observer.given_name,
                            familyName: obs.observer.family_name,
                          }
                        : null,
                      validator: obs.validator
                        ? {
                            id: obs.validator.user_id,
                            userName: obs.validator.user_name,
                            email: obs.validator.user_email,
                            firstName: obs.validator.given_name,
                            familyName: obs.validator.family_name,
                          }
                        : null,
                      submitted: obs.status === 'submitted' || (obs.status === 'validated' && isTimeToday),
                      validated: obs.status === 'validated',
                      validatedToday: obs.status === 'validated' && isTimeToday,
                      imageUrl: obs.photo_url || undefined,
                    };
                  })
                : [],
              newObservation: {
                status: 'idle',
              },
            } as LocationManager.Location),
        )
      : [],
  }));

export const getFireAreas = makeAPICall<FireArea[], { layerId: number }, FireArea.API.Root>(
  (pl) => ({
    ext: `/fire-management-areas/${pl.layerId}/stats`,
  }),
  mapAPItoFireAreas,
);
