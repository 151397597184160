import { createReducer } from '@reduxjs/toolkit';

import { MeteogramManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type MeteogramState = {
  meteograms: Loadable<MeteogramManager.Meteograms>;
  identify: Loadable<MeteogramManager.Meteograms.WeatherStation[]>;
};

const initialState: MeteogramState = {
  meteograms: {
    status: 'idle',
  },
  identify: {
    status: 'idle',
  },
};

export const [getMeteograms, setMeteograms] = makeThunkFromAPICall(MeteogramManager.getMeteograms, 'meteograms/set');

export const [getWeatherStationsByBBox, setIdentify] = makeThunkFromAPICall(
  MeteogramManager.identifyWeatherStations,
  'meteograms/identify',
);

export const meteogramReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMeteograms, (state, action) => {
    // The state will be fully overriden in this case so observations are
    // removed if new data comes in without that field.
    return { ...state, meteograms: { ...action.payload.loadable } };
  });

  builder.addCase(setIdentify, (state, action) => {
    return { ...state, identify: { ...state.identify, ...action.payload.loadable } };
  });
});

export const MeteogramActions = {
  getMeteograms,
  getWeatherStationsByBBox,
};

export default meteogramReducer;
