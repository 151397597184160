import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Link } from '@material-ui/core';
import { Error } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      margin: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.black70,
    },
    titleContainer: {
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
  }),
);

interface Error404Props {
  link?: string;
  title?: string;
  action?: string;
}

const Error404: React.FunctionComponent<Error404Props> = ({ link, title, action }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className={classes.titleContainer}>
        <Error className={classes.errorText} />
        <Typography variant="h5" className={classes.errorText}>
          Error 404: {title ?? 'Page not found'}
        </Typography>
      </div>
      <Typography align="center" variant="subtitle2" className={classes.errorText}>
        The page you&apos;re looking for cannot be found.
      </Typography>
      <Link component={RouterLink} to={link ?? '/'}>
        {action ?? 'Click here to return home'}
      </Link>
    </div>
  );
};

Error404.displayName = 'Error404';
export default Error404;
