/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, List, ListItem, ListItemText, ListItemIcon, Typography, Theme } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { WmsManager, LayerManager } from 'models';
import { LayerActions } from 'state/layers';

import { ErrorSegment } from 'components';

import ClosableCard from './CloseableCard';
import { getFormattedPolygonLegendDataWithMappedValues } from 'models/wms';

const useStyles = makeStyles((theme: Theme) => ({
  legendSwatch: {
    width: 25,
    height: 25,
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
  legendValue: {
    fontWeight: 'bold',
  },
  legendMidValue: {
    fontWeight: 'bold',
    padding: `0px ${theme.spacing(1)}px`,
  },
  legendValueContainer: {
    display: 'grid',
    width: '100%',
    padding: `0px ${theme.spacing(1)}px`,
    gridTemplateColumns: '1fr auto 1fr',
    justifyItems: 'center',
  },
  legendTitle: {
    color: theme.palette.common.neutralDark,
    fontWeight: 'bold',
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    padding: `${theme.spacing(0.5)}px 2px`,
  },
}));

interface IProps {
  layer: null | LayerManager.Layer;
  legendRef?: React.RefObject<any>;
  forceType?: string;
  labelFormatter?: (entry: WmsManager.WMS.GetLegend.Entry) => string;
  maxHeight?: number;
}

const getFormattedLegendData = (legend: WmsManager.WMS.GetLegend.Legend) => {
  const entries = legend.rules[0]?.symbolizers[0]?.Raster?.colormap?.entries;
  if (entries == null) {
    console.group('Failed to get Legend information');
    console.error('Returning an empty legend instead.');
    console.error(legend);
    console.groupEnd();
  }
  return (entries || []).filter((x) => +x.quantity > -9000 && x.label !== 'nodata');
};

const Legend: React.FunctionComponent<IProps> = ({ layer, labelFormatter, forceType, legendRef, maxHeight }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { legends } = useAppSelector((state) => state.layers);

  useEffect(() => {
    if (layer && legends[layer.id] == null) {
      dispatch(LayerActions.setLegend({ input: { layer }, loadable: { status: 'idle' } }));
    }

    if (layer && !layer.noLegend && legends[layer.id] != null && legends[layer.id].status === 'idle') {
      dispatch(LayerActions.getLegend({ layer }));
    }
  }, [layer, legends]);

  if (
    layer &&
    !layer.noLegend &&
    (legends[layer.id] == null || legends[layer.id].status === 'loading' || legends[layer.id].status === 'idle')
  ) {
    return (
      <ClosableCard title="Legend" contentStyle={{ padding: theme.spacing(1), display: 'grid', placeItems: 'center' }}>
        <CircularProgress size={16} aria-valuetext="loading" />
      </ClosableCard>
    );
  }

  if (layer == null || layer.noLegend) {
    return (
      <ClosableCard title="Legend" contentStyle={{ padding: theme.spacing(1), display: 'grid', placeItems: 'center' }}>
        <Typography variant="subtitle1">Legend not available</Typography>
      </ClosableCard>
    );
  }

  const legend = legends[layer.id].object;

  if (legend == null) {
    return (
      <ClosableCard title="Legend" contentStyle={{ padding: theme.spacing(1), display: 'grid', placeItems: 'center' }}>
        <ErrorSegment
          error={{ code: 404, error: 'Unable to get Legend' }}
          onRetry={() => {
            if (layer) dispatch(LayerActions.getLegend({ layer }));
          }}
        />
      </ClosableCard>
    );
  }

  const defaultLabelFormatter = (entry: WmsManager.WMS.GetLegend.Entry) => {
    return `${entry.label || entry.quantity}${
      !entry.label && layer && typeof layer !== 'string' && layer.units ? layer.units : ''
    }`;
  };

  const legendType =
    forceType ||
    legend.rules[0].symbolizers[0]?.Raster?.colormap?.type ||
    (legend.rules[0].symbolizers[0]?.Point?.url && 'graphic') ||
    (legend.rules[0].symbolizers[0]?.Polygon?.fill?.startsWith('[Recode(') ? 'polygonWithMappedValues' : undefined);

  const renderTitle = () => (
    <ListItem className={classes.listItem}>
      <ListItemText>
        <Typography variant="subtitle1" className={classes.legendTitle}>
          {layer.name} {layer.units != null && `(${layer.units.trim()})`}
        </Typography>
      </ListItemText>
    </ListItem>
  );

  const renderStandardItem = (
    entry: WmsManager.WMS.GetLegend.Entry,
    index: number,
    array: WmsManager.WMS.GetLegend.Entry[],
  ) => {
    const displayValue = (labelFormatter || defaultLabelFormatter)(entry);

    let drawEntry = true;

    array.forEach((otherEntry, otherIndex) => {
      if (otherIndex > index && otherEntry.label === entry.label && otherEntry.color === entry.color) {
        drawEntry = false;
      }
    });

    if (!drawEntry) return <ListItem key={index} style={{ padding: 0 }} />;

    return (
      <ListItem key={index} className={classes.listItem}>
        <ListItemIcon>
          <div
            className={classes.legendSwatch}
            style={{ background: entry.color, border: entry.color === '#FFFFFF' ? 'lightgrey 1px solid' : undefined }}
          />
        </ListItemIcon>
        <ListItemText style={{ margin: 0 }}>
          <Typography className={classes.legendValue}>{displayValue}</Typography>
        </ListItemText>
      </ListItem>
    );
  };

  const renderStandardLegend = () => {
    return (
      <div ref={legendRef} style={{}}>
        <List style={{ paddingTop: 0 }}>
          {renderTitle()}
          {getFormattedLegendData(legend).map(renderStandardItem)}
        </List>
      </div>
    );
  };

  const renderPolygonLegendWithMappedValues = () => {
    return (
      <div ref={legendRef} style={{}}>
        <List style={{ paddingTop: 0 }}>
          {renderTitle()}
          {getFormattedPolygonLegendDataWithMappedValues(legend).map(renderStandardItem)}
        </List>
      </div>
    );
  };

  const renderIntervalsItem = (
    entry: WmsManager.WMS.GetLegend.Entry,
    index: number,
    arr: WmsManager.WMS.GetLegend.Entry[],
  ) => {
    const displayValue = (labelFormatter || defaultLabelFormatter)(entry);

    const upperRange = arr[index + 1] ? (labelFormatter || defaultLabelFormatter)(arr[index + 1]) : undefined;

    if (!upperRange)
      return (
        <ListItem key={index} className={classes.listItem}>
          <div className={classes.legendSwatch} style={{ background: entry.color }} />
          <div className={classes.legendValueContainer}>
            <Typography className={classes.legendValue} />
            <Typography className={classes.legendValue}>{displayValue}+</Typography>
          </div>
        </ListItem>
      );

    return (
      <ListItem key={index} className={classes.listItem}>
        <div className={classes.legendSwatch} style={{ background: entry.color }} />
        <div className={classes.legendValueContainer}>
          <Typography className={classes.legendValue}>{displayValue}</Typography>
          <Typography className={classes.legendMidValue}>-</Typography>
          <Typography className={classes.legendValue}>{upperRange}</Typography>
        </div>
      </ListItem>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderIntervalsLegend = () => {
    const items = getFormattedLegendData(legend).map(renderIntervalsItem);
    return (
      <div ref={legendRef} style={{}}>
        <List style={{ paddingTop: 0 }}>
          {renderTitle()}
          {items}
        </List>
      </div>
    );
  };

  const renderGraphicItem = (rule: WmsManager.WMS.GetLegend.Rule, index: number) => {
    const point = rule.symbolizers[0].Point;
    return (
      <ListItem key={index} className={classes.listItem}>
        <ListItemIcon>
          <img
            className={classes.legendSwatch}
            width={point?.size}
            height={point?.size}
            src={point?.url}
            alt={point?.abstract}
          />
        </ListItemIcon>
        <ListItemText style={{ margin: 0 }}>
          <Typography className={classes.legendValue}>{rule.title}</Typography>
        </ListItemText>
      </ListItem>
    );
  };

  const renderGraphicLegend = () => {
    const items = legend.rules.map(renderGraphicItem);
    return (
      <div style={{}}>
        <List style={{ paddingTop: 0 }}>
          {renderTitle()}
          {items}
        </List>
      </div>
    );
  };

  const renderLegendByType = (type?: string): JSX.Element => {
    if (!type) return <Typography variant="subtitle1">Legend not set</Typography>;

    // if (type === 'intervals') return renderIntervalsLegend();

    if (type === 'polygonWithMappedValues') return renderPolygonLegendWithMappedValues();

    if (type === 'graphic') return renderGraphicLegend();

    // TODO maybe: type='values'

    return renderStandardLegend();
  };

  return (
    <ClosableCard
      title="Legend"
      contentStyle={{
        padding: theme.spacing(1),
        maxHeight: maxHeight ?? 450,
        overflowX: 'visible',
        overflowY: 'scroll',
        minWidth: 200,
      }}
    >
      {renderLegendByType(legendType)}
    </ClosableCard>
  );
};

Legend.displayName = 'Legend';
export default Legend;
