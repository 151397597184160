import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const GlobalStyles = {
  '@global': {
    button: {
      fontFamily: 'inherit',
    },
    input: {
      fontFamily: 'inherit',
    },
    optgroup: {
      fontFamily: 'inherit',
    },
    select: {
      fontFamily: 'inherit',
    },
    textarea: {
      fontFamily: 'inherit',
    },
  },
};

interface GlobalStyleSheetProps {
  children?: React.ReactNode;
}

function GlobalStyleSheet(props: GlobalStyleSheetProps) {
  const { children = null } = props;
  return <>{children}</>;
}

export default withStyles(GlobalStyles)(GlobalStyleSheet);
