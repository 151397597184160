import { createReducer } from '@reduxjs/toolkit';

import { DangerRatingsManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type DangerRatingState = {
  summary: Loadable<DangerRatingsManager.FDR.SummaryForecastList>;
  weather: Loadable<DangerRatingsManager.FDR.WeatherForecastList>;
  detailed: Loadable<DangerRatingsManager.FDR.DetailedForecastList>;
};

const initialState: DangerRatingState = {
  summary: {
    status: 'idle',
  },
  weather: {
    status: 'idle',
  },
  detailed: {
    status: 'idle',
  },
};

export const [getDetailedForecast, setDetailedForecast] = makeThunkFromAPICall(
  DangerRatingsManager.getDetailedForecast,
  'dangerratings/detailed/set',
);

export const [getSummaryForecast, setSummaryForecast] = makeThunkFromAPICall(
  DangerRatingsManager.getSummaryForecast,
  'dangerratings/summary/set',
);

export const [getWeatherForecast, setWeatherForecast] = makeThunkFromAPICall(
  DangerRatingsManager.getWeatherForecast,
  'dangerratings/weather/set',
);

export const dangerRatingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setDetailedForecast, (state, action) => {
    return { ...state, detailed: { ...state.detailed, ...action.payload.loadable } };
  });
  builder.addCase(setSummaryForecast, (state, action) => {
    return { ...state, summary: { ...state.summary, ...action.payload.loadable } };
  });
  builder.addCase(setWeatherForecast, (state, action) => {
    return { ...state, weather: { ...state.weather, ...action.payload.loadable } };
  });
});

export const DangerRatingActions = {
  getDetailedForecast,
  getSummaryForecast,
  getWeatherForecast,
};

export default dangerRatingsReducer;
