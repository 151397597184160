import { createReducer } from '@reduxjs/toolkit';

import { AuthUserManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type AuthUserState = {
  user: Loadable<AuthUserManager.AuthUser.AuthUserGetRequest>;
  upload: Loadable<null>;
};

const initialState: AuthUserState = {
  user: {
    status: 'idle',
  },
  upload: {
    status: 'idle',
  },
};

export const [getSelf, setStatus] = makeThunkFromAPICall(AuthUserManager.getSelf, 'user/set-status');

export const [updateSelf, setUploadStatus] = makeThunkFromAPICall(
  AuthUserManager.updateSelfDetails,
  'user/upload-status',
);

export const authUserReducer = createReducer(initialState, (builder) => {
  builder.addCase(setStatus, (state, action) => {
    return { ...state, user: { ...state.user, ...action.payload.loadable } };
  });

  builder.addCase(setUploadStatus, (state, action) => {
    return { ...state, upload: { ...state.upload, ...action.payload.loadable } };
  });
});

export const AuthUserActions = {
  getSelf,
  updateSelf,
};

export default authUserReducer;
