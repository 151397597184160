import { Loadable, ErrorTypes, ErrorResp, mapCodeToMessage, Common } from 'models';

import { makeAPICall, makeMockAPICall } from './api';
import { getUserState } from 'utils';
import { FireArea } from './firearea';

export declare namespace MapLayer {
  export type Type =
    | 'fuel-type'
    | 'fuel-type-5km'
    | 'grass-curing'
    | 'grass-fuel-condition'
    | 'grass-fuel-load'
    | 'precandidate-fuel-type'
    | 'time-since-fire'
    | 'fire-history';

  export type Status = 'auto-candidate' | 'pending-authorisation' | 'authorised' | 'rejected' | 'processing' | 'error';

  export namespace API {
    export interface Jurisdiction {
      jurisdiction_id: number;
      jurisdiction_name: 'NSW' | 'VIC' | 'QLD' | 'TAS' | 'SA' | 'NT' | 'WA' | 'ACT';
    }

    export interface Note {
      note_id: number;
      note: string;
      modified: string;
      user: User;
    }

    export interface User {
      user_id: number;
      user_name: string;
      user_email: string;
      given_name: string | null;
      family_name: string | null;
    }

    export interface MapLayer {
      map_layer_id: number;
      jurisdiction: Jurisdiction;
      approver?: User;
      submitter?: User;
      map_layer_type: Type;
      status: MapLayer.Status;
      map_layer_notes: Note[];
      error: string;
      created: string;
      modified: string;
      new_observation_count: number;
      total_observation_count: number;
      creation_time: string;
      parent_map_layer_id: number | null;
      submission_time: string | null;
      approval_time: string | null;
    }

    export interface Root {
      data: MapLayer[];
      count: number;
    }
  }

  export interface Jurisdiction {
    id: number;
    name: 'NSW' | 'VIC' | 'QLD' | 'TAS' | 'SA' | 'NT' | 'WA' | 'ACT';
  }

  export interface SubmitFields {
    layer: MapLayer;
    note?: string;
  }

  export interface TiffUploadFields {
    layer: MapLayer;
    blob?: Blob;
    file?: File;
  }

  export interface LayerOperation {
    operation_value: number | null;
    operation_type: 'set' | 'translate' | 'setNull';
    input_type: 'Location' | 'FireManagementArea';
    input_id: number;
    input_buffer?: string;
    note?: string;
    fuel_state_type: 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type' | 'Time Since Fire';
  }

  export interface StagedOperation {
    operation: LayerOperation;
    layer: MapLayer;
    fireArea: FireArea;
  }

  export interface RegenerateLayerFields {
    layer: MapLayer;
    operations: LayerOperation[];
  }
}

export interface MapLayer {
  id: number;
  name: string;
  thumbnail?: string;
  jurisdiction: MapLayer.Jurisdiction;
  approver?: Common.User;
  approvalTime: number | null;
  submitter?: Common.User;
  submissionTime: number | null;
  type: MapLayer.Type;
  status: MapLayer.Status;
  notes: Common.Note[];
  parentId: number | null;
  newObservationCount: number;
  totalObservationCount: number;
  error?: string;
  recordCreated: number;
  created: number;
  modified: number;
  updateStatus: Loadable<null>;
}

export const getMapLayerThumbnail = (type: MapLayer.Type): string | null => {
  switch (type) {
    case 'fire-history':
      return null;
    case 'fuel-type':
      return '/layers/fse-fuel-type-small.png';
    case 'grass-curing':
      return '/layers/fse-grass-curing-small.png';
    case 'grass-fuel-condition':
      return '/layers/fse-grass-fuel-condition-small.png';
    case 'grass-fuel-load':
      return '/layers/fse-grass-fuel-load-small.png';
    case 'precandidate-fuel-type':
      return null;
    case 'time-since-fire':
      return '/layers/fse-time-since-fire-small.png';
    default:
      return null;
  }
};

export const getMapLayerName = (type: MapLayer.Type): string => {
  switch (type) {
    case 'fire-history':
      return 'Fire History';
    case 'fuel-type':
      return 'Fuel Type';
    case 'grass-curing':
      return 'Grass Curing';
    case 'grass-fuel-condition':
      return 'Grass Fuel Condition';
    case 'grass-fuel-load':
      return 'Grass Fuel Load';
    case 'time-since-fire':
      return 'Time Since Fire';
    default:
      return 'Unknown';
  }
};

export const mockData: MapLayer[] = [
  {
    id: 0,
    name: getMapLayerName('grass-curing'),
    thumbnail: getMapLayerThumbnail('grass-curing') || undefined,
    jurisdiction: {
      id: 0,
      name: 'NSW',
    },
    type: 'grass-curing',
    status: 'auto-candidate',
    notes: [
      {
        note: 'This is Mock Layer #1',
        modified: new Date(Date.now() - 1000 * 60 * 60 * 2).toISOString(),
      },
    ],
    newObservationCount: 10,
    totalObservationCount: 20,
    created: Date.now() - 1000 * 60 * 60 * 2,
    modified: Date.now() - 1000 * 60 * 60 * 2,
    updateStatus: {
      status: 'idle',
    },
    approvalTime: null,
    parentId: null,
    recordCreated: Date.now() - 1000 * 60 * 60 * 2,
    submissionTime: null,
  },
  {
    id: 1,
    name: getMapLayerName('grass-fuel-load'),
    thumbnail: getMapLayerThumbnail('grass-fuel-load') || undefined,
    jurisdiction: {
      id: 0,
      name: 'NSW',
    },
    type: 'grass-fuel-load',
    status: 'auto-candidate',
    notes: [
      {
        note: 'This is Mock Layer #2',
        modified: new Date(Date.now() - 1000 * 60 * 60 * 3).toISOString(),
      },
    ],
    newObservationCount: 15,
    totalObservationCount: 20,
    created: Date.now() - 1000 * 60 * 60 * 3,
    modified: Date.now() - 1000 * 60 * 60 * 3,
    updateStatus: {
      status: 'idle',
    },
    approvalTime: null,
    parentId: null,
    recordCreated: Date.now() - 1000 * 60 * 60 * 2,
    submissionTime: null,
  },
  {
    id: 2,
    name: getMapLayerName('grass-fuel-condition'),
    thumbnail: getMapLayerThumbnail('grass-fuel-condition') || undefined,
    jurisdiction: {
      id: 0,
      name: 'NSW',
    },
    type: 'grass-fuel-condition',
    status: 'pending-authorisation',
    notes: [
      {
        note: 'This is Mock Layer #3',
        modified: new Date(Date.now() - 1000 * 60 * 60 * 0.25).toISOString(),
      },
    ],
    newObservationCount: 8,
    totalObservationCount: 20,
    created: Date.now() - 1000 * 60 * 60 * 0.5,
    modified: Date.now() - 1000 * 60 * 60 * 0.5,
    updateStatus: {
      status: 'idle',
    },
    approvalTime: null,
    parentId: null,
    recordCreated: Date.now() - 1000 * 60 * 60 * 2,
    submissionTime: null,
  },
  {
    id: 3,
    name: getMapLayerName('time-since-fire'),
    thumbnail: getMapLayerThumbnail('time-since-fire') || undefined,
    jurisdiction: {
      id: 0,
      name: 'NSW',
    },
    type: 'time-since-fire',
    status: 'pending-authorisation',
    notes: [
      {
        note: 'This is Mock Layer #4',
        modified: new Date(Date.now() - 1000 * 60 * 60 * 0.25).toISOString(),
      },
    ],
    newObservationCount: 8,
    totalObservationCount: 20,
    created: Date.now() - 1000 * 60 * 60 * 0.5,
    modified: Date.now() - 1000 * 60 * 60 * 0.5,
    updateStatus: {
      status: 'idle',
    },
    approvalTime: null,
    parentId: null,
    recordCreated: Date.now() - 1000 * 60 * 60 * 2,
    submissionTime: null,
  },
];

export const getRaster = makeAPICall<Blob, { layer: MapLayer }, { link: string }>(
  (payload) => {
    const { layer } = payload;

    return {
      ext: `/maplayers/${layer.id}/raster`,
      requestData: {
        method: 'GET',
      },
    };
  },
  async (root) => {
    const blob = await fetch(root.link).then((res) => res.blob());
    return blob;
  },
);

export const getModisRaster = makeAPICall<Blob, void, { link: string }>(
  () => ({
    ext: `/maplayers/modis-curing/latest`,
    requestData: {
      method: 'GET',
    },
  }),
  async (root) => {
    const blob = await fetch(root.link).then((res) => res.blob());
    return blob;
  },
);

export const setRaster = makeAPICall<null, MapLayer.TiffUploadFields>(
  (payload, state) => {
    const { layer, blob, file } = payload;
    const auth = state?.auth;

    if (!auth?.object)
      throw {
        error: mapCodeToMessage[ErrorTypes.INVALID_CREDENTIALS],
        code: ErrorTypes.INVALID_CREDENTIALS,
      } as ErrorResp;

    const data = new FormData();

    const jurisdiction = getUserState(auth.object);

    if (!layer || !state)
      throw {
        error: mapCodeToMessage[ErrorTypes.NOT_ENOUGH_DETAIL],
        code: ErrorTypes.NOT_ENOUGH_DETAIL,
      };

    data.append('map_type', layer.type);
    data.append('jurisdiction', jurisdiction as string);
    if (blob) data.append('map_layer_file', blob, `layer-${layer.id}.tif`);

    if (file) data.append('map_layer_file', file, `layer-${layer.id}.tif`);

    return {
      ext: `/maplayers/upload-candidate`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const authoriseLayer = makeAPICall<null, MapLayer.SubmitFields>(
  (payload) => {
    const { layer, note } = payload;

    const data = new FormData();
    if (note) data.append('note_text', `${note}`);

    return {
      ext: `/maplayers/${layer.id}/authorise`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const submitLayer = makeAPICall<null, MapLayer.SubmitFields>(
  (payload) => {
    const { layer, note } = payload;

    const data = new FormData();
    data.append('note_text', `${note ?? ''}`);
    data.append('authoriser_id', '');

    return {
      ext: `/maplayers/${layer.id}/submit-for-authorisation`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const requestEditsLayer = makeAPICall<null, MapLayer.SubmitFields>(
  (payload) => {
    const { layer, note } = payload;

    const data = new FormData();
    data.append('note_text', `${note ?? ''}`);

    return {
      ext: `/maplayers/${layer.id}/reject`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const createNewAutoCandidateLayer = makeAPICall<null, MapLayer.SubmitFields>(
  (payload, state) => {
    const { layer } = payload;
    const auth = state?.auth;

    if (!auth?.object)
      throw {
        error: mapCodeToMessage[ErrorTypes.INVALID_CREDENTIALS],
        code: ErrorTypes.INVALID_CREDENTIALS,
      } as ErrorResp;

    const jurisdiction = getUserState(auth.object);

    if (!layer || !jurisdiction)
      throw {
        error: mapCodeToMessage[ErrorTypes.NOT_ENOUGH_DETAIL],
        code: ErrorTypes.NOT_ENOUGH_DETAIL,
      };

    const data = new FormData();

    data.append('map_type', layer.type);
    data.append('jurisdiction', jurisdiction);

    return {
      ext: `/maplayers/auto-candidate`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const regenerateLayer = makeAPICall<null, MapLayer.RegenerateLayerFields>(
  (payload, state) => {
    const { layer, operations } = payload;
    const auth = state?.auth;

    if (!auth?.object)
      throw {
        error: mapCodeToMessage[ErrorTypes.INVALID_CREDENTIALS],
        code: ErrorTypes.INVALID_CREDENTIALS,
      } as ErrorResp;

    const jurisdiction = getUserState(auth?.object);

    if (!layer || !jurisdiction)
      throw {
        error: mapCodeToMessage[ErrorTypes.NOT_ENOUGH_DETAIL],
        code: ErrorTypes.NOT_ENOUGH_DETAIL,
      };

    return {
      ext: `/maplayers/${layer.id}/adjust`,
      contentType: 'application/json',
      requestData: {
        method: 'PUT',
        body: JSON.stringify(operations),
      },
    };
  },
  () => null,
);

export const getLayers = makeAPICall<MapLayer[], void, MapLayer.API.Root>(
  () => ({
    ext: `/maplayers`,
  }),
  (root) =>
    root.data.map((layer) => ({
      id: layer.map_layer_id,
      type: layer.map_layer_type,
      name: getMapLayerName(layer.map_layer_type),
      thumbnail: getMapLayerThumbnail(layer.map_layer_type) || undefined,
      error: layer.error,
      created: new Date(layer.creation_time).getTime(),
      modified: new Date(layer.modified).getTime(),
      parentId: layer.parent_map_layer_id,
      recordCreated: new Date(layer.created).getTime(),
      approvalTime: layer.approval_time ? new Date(layer.approval_time).getTime() : null,
      submissionTime: layer.submission_time ? new Date(layer.submission_time).getTime() : null,
      notes: layer.map_layer_notes.map((note) => ({
        id: note.note_id,
        note: note.note,
        modified: note.modified,
        user: note.user
          ? {
              id: note.user.user_id,
              userName: note.user.user_name,
              email: note.user.user_email,
              firstName: note.user.given_name,
              familyName: note.user.family_name,
            }
          : undefined,
      })),
      jurisdiction: {
        id: layer.jurisdiction.jurisdiction_id,
        name: layer.jurisdiction.jurisdiction_name,
      },
      submitter: layer.submitter
        ? {
            id: layer.submitter.user_id,
            userName: layer.submitter.user_name,
            email: layer.submitter.user_email,
            firstName: layer.submitter.given_name,
            familyName: layer.submitter.family_name,
          }
        : undefined,
      approver: layer.approver
        ? {
            id: layer.approver.user_id,
            userName: layer.approver.user_name,
            email: layer.approver.user_email,
            firstName: layer.approver.given_name,
            familyName: layer.approver.family_name,
          }
        : undefined,
      status: layer.status,
      newObservationCount: layer.new_observation_count,
      totalObservationCount: layer.total_observation_count,
      updateStatus: {
        status: 'idle',
      },
    })),
);

export const getMockLayers = makeMockAPICall<MapLayer[], void, MapLayer[]>(
  () => ({
    ext: `/`,
  }),
  (data) => data,
  mockData,
  1000,
);
