import React, { ReactNode } from 'react';

type ConditionallyRenderProps = {
  children: ReactNode;
  when: boolean;
};

export default function ConditionallyRender({ when, children }: ConditionallyRenderProps) {
  if (when && children !== undefined) {
    // Return children like this as it is an older version of React
    return <>{children}</>;
  }
  return null;
}
