import React from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Chip, TableCell, TableRow, Theme } from '@material-ui/core';

import { User } from 'models/user';
import PrimaryButton from 'components/PrimaryButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    badge: {
      marginRight: theme.spacing(2),
    },
    th: {
      fontWeight: 'bold',
    },
  }),
);

interface IProps {
  user: User.User;
}

const UserListItem: React.FunctionComponent<IProps> = ({ user }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/admin/users/${user.user_id}`);
  };
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.th}>
        {user.family_name}, {user.given_name}
      </TableCell>
      <TableCell>{user.user_email}</TableCell>
      <TableCell>
        {user.roles
          .filter((item) => {
            return item.role_name !== 'SystemAdmin';
          })
          .map((role) => (
            <Chip key={role.role_id} className={classes.badge} label={role.role_name} />
          ))}
      </TableCell>
      <TableCell align="right">
        <PrimaryButton onClick={handleClick}>Edit</PrimaryButton>
      </TableCell>
    </TableRow>
  );
};

export default UserListItem;
