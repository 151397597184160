/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* global $ */

import 'summernote/dist/summernote-lite';
import 'summernote/dist/summernote-lite.css';
import 'codemirror/lib/codemirror.css';

import React, { useEffect, useRef, PropsWithChildren } from 'react';

import { usePrevious } from 'hooks';

interface ReactSummernoteProps extends PropsWithChildren {
  tag?: string; // will determing using div or textarea field for form components like redux-form
  codeview?: boolean;
  className?: string;
  value?: string;
  options?: Summernote.Options;
  disabled?: boolean;
  onInit?: (event: any) => void;
  onEnter?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  onKeyUp?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  onPaste?: (event: any) => void;
  onChange?: (event: any) => void;
  onImageUpload?: (
    images: FileList,
    insertImage: (url: string, filenameOrCallback: string | Summernote.EditImageCallback) => void,
  ) => void;
}

const ReactSummernote: React.FunctionComponent<ReactSummernoteProps> = ({
  codeview,
  value,
  className,
  options,
  disabled,
  children,
  onInit,
  onEnter,
  onFocus,
  onBlur,
  onKeyUp,
  onKeyDown,
  onPaste,
  onChange,
  onImageUpload,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const editor = divRef.current && $(divRef.current);

  const prevProps = usePrevious({ value, codeview, disabled });

  const optionsWithDefaults: Summernote.Options = {
    height: 350,
    dialogsInBody: false,
    disableDragAndDrop: true,
    toolbar: [
      ['style', ['style']],
      ['font', ['fontname', 'bold', 'underline', 'color', 'superscript', 'subscript', 'clear']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['codeview']],
    ],
    ...options,
  };

  // potential states
  let noteEditable: JQuery<HTMLElement> | null = null;
  let notePlaceholder: JQuery<HTMLElement> | null = null;

  let focus = () => {
    editor?.summernote('focus');
  };

  let isEmpty = () => {
    return editor?.summernote('isEmpty');
  };

  let reset = () => {
    editor?.summernote('reset');
  };

  let disable = () => {
    editor?.summernote('disable');
  };

  let enable = () => {
    editor?.summernote('enable');
  };

  let insertImage = (url: string, filenameOrCallback: string | Summernote.EditImageCallback) => {
    editor?.summernote('insertImage', url, filenameOrCallback);
  };

  let insertNode = (node: JQuery.Node) => {
    editor?.summernote('insertNode', node);
  };

  let insertText = (text: string) => {
    editor?.summernote('insertText', text);
  };

  const toggleState = (setToDisabled?: boolean) => {
    if (setToDisabled) {
      disable();
    } else {
      enable();
    }
  };

  const onImageUploadInternal = (images: FileList) => {
    if (onImageUpload) {
      onImageUpload(images, insertImage);
    } else {
      // What to run by default
      const file = images[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        insertImage(reader.result as string, file.name);
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    }
  };

  const replace = (content: string) => {
    if (noteEditable != null && notePlaceholder != null) {
      const prevContent = noteEditable.html();
      const contentLength = content.length;

      if (prevContent !== content) {
        if (isEmpty() && contentLength > 0) {
          notePlaceholder.hide();
        } else if (contentLength === 0) {
          notePlaceholder.show();
        }

        noteEditable.html(content);
      }
    }
  };
  const initSummernote = (ref: HTMLElement | null) => {
    const e = ref != null ? $(ref) : null;
    if (e?.summernote) {
      const onInitInternal = () => {
        if (e) {
          const $container = e.parent();
          noteEditable = $container.find('.note-editable');
          notePlaceholder = $container.find('.note-placeholder');

          toggleState(disabled);

          if (onInit)
            onInit({
              summernote: e.summernote.bind(e),
              focus,
              isEmpty,
              reset,
              replace,
              disable,
              enable,
              insertImage,
              insertNode,
              insertText,
            });
        }
      };

      optionsWithDefaults.callbacks = {
        onInit: onInitInternal,
        onEnter,
        onFocus,
        onBlur,
        onKeyup: onKeyUp,
        onKeydown: onKeyDown,
        onPaste,
        onChange,
        onImageUpload: onImageUploadInternal,
      };

      const $container = e.parent();
      noteEditable = $container.find('.note-editable');
      notePlaceholder = $container.find('.note-placeholder');

      e.summernote(optionsWithDefaults);

      if (codeview) {
        e.summernote('codeview.activate');
      }

      focus = () => {
        e.summernote('focus');
      };

      isEmpty = () => {
        return e.summernote('isEmpty');
      };

      reset = () => {
        e.summernote('reset');
      };

      disable = () => {
        e.summernote('disable');
      };

      enable = () => {
        e.summernote('enable');
      };

      insertImage = (url: string, filenameOrCallback: string | Summernote.EditImageCallback) => {
        e.summernote('insertImage', url, filenameOrCallback);
      };

      insertNode = (node: JQuery.Node) => {
        e.summernote('insertNode', node);
      };

      insertText = (text: string) => {
        e.summernote('insertText', text);
      };
    }

    // @ts-ignore
    divRef.current = ref;
  };

  useEffect(() => {
    return () => {
      if (editor?.summernote) {
        editor.summernote('destroy');
      }
    };
  }, []);

  useEffect(() => {
    const codeviewCommand = codeview ? 'codeview.activate' : 'codeview.deactivate';

    if (value != null && value !== prevProps?.value) {
      replace(value);
    }

    if (disabled !== prevProps?.disabled) {
      toggleState(disabled);
    }

    if (codeview !== prevProps?.codeview) {
      editor?.summernote(codeviewCommand);
    }
  }, [value, disabled, codeview]);

  return (
    <div className={className}>
      <style>
        {`
        
        .note-modal.open {
          display: grid;
          place-content: center;
        }

        `}
      </style>
      <div ref={initSummernote}>{children}</div>
    </div>
  );
};

export default ReactSummernote;
