import { createReducer } from '@reduxjs/toolkit';
import { makeThunkFromAPICall } from 'state';
import { JurisdictionSummaryManager, Loadable } from 'models';

// Define the state structure
export type JurisdictionSummaryState = {
  jurisdictionSummary: Loadable<JurisdictionSummaryManager.JurisdictionSummaryRecord>;
};

const initialState: JurisdictionSummaryState = {
  jurisdictionSummary: {
    status: 'finished',
  },
};

export const [fetchJurisdictionSummary, setJurisdictionSummary] = makeThunkFromAPICall(
  JurisdictionSummaryManager.getJurisdictionSummary,
  'JurisdictionSummary/setJurisdictionSummary',
);

export const jurisdictionSummaryReducer = createReducer(initialState, (builder) => {
  builder.addCase(setJurisdictionSummary, (state, action) => {
    return { ...state, jurisdictionSummary: { ...action.payload.loadable } };
  });
});

export const JurisdictionSummaryActions = {
  fetchJurisdictionSummary,
};

export default jurisdictionSummaryReducer;
