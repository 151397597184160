import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { toDDMonFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previousText: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
  }),
);

interface PreviousValueProps {
  text: string;
  time: number | undefined;
  style?: React.CSSProperties;
  className?: string;
  textStyle?: React.CSSProperties;
}

const PreviousValue: React.FunctionComponent<PreviousValueProps> = ({ text, time, style, className, textStyle }) => {
  const classes = useStyles();

  return (
    <div className={className} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', ...style }}>
      <Typography variant="subtitle1" className={classes.previousText} style={textStyle}>
        {time ? (
          <>
            <AccessTime />
            &nbsp;Previously validated on {toDDMonFormat(new Date(time))}: {text}
          </>
        ) : (
          'Observation has not been validated'
        )}
      </Typography>
    </div>
  );
};

PreviousValue.displayName = 'PreviousValue';
export default PreviousValue;
