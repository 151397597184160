/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Button, CircularProgress } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { EditorActions } from 'state/editor';
import { ErrorSegment, MapLayerListItem } from 'components';
import { MapLayerManager } from 'models';
import { hasObservations } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
    filterButton: {
      width: '250px',
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
    },
    filterButtonSelected: {
      width: '250px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
    },
    topSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'stretch',
    },
    button: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '180px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
  }),
);

type Filters = 'ready' | 'pending' | 'all';

function FSEValidatorDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { auth, editor } = useAppSelector((state) => state);
  const { maplayers } = editor;
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<Filters>('ready');

  const filterFn = (layer: MapLayerManager.MapLayer) => {
    if (filter === 'all') return true;
    if (filter === 'pending') return layer.status === 'pending-authorisation';
    return layer.status === 'auto-candidate' || layer.status === 'rejected' || layer.status === 'processing';
  };

  const mapLayersByType: Record<string, MapLayerManager.MapLayer[]> = {};

  maplayers?.object?.forEach((layer) => {
    if (mapLayersByType[layer.type] == null) mapLayersByType[layer.type] = [];
    mapLayersByType[layer.type].push(layer);
  });

  Object.keys(mapLayersByType).forEach((type) => {
    mapLayersByType[type].sort((a, b) => (a.created < b.created ? 1 : -1));
  });

  const mapLayerItems = Object.keys(mapLayersByType).map((type) => {
    // dont make an item if the layer type doesn't exist
    if (mapLayersByType[type] == null) return undefined;
    // dont make an item if the latest one is filtered out
    if (!filterFn(mapLayersByType[type][0])) return undefined;

    const layer = mapLayersByType[type][0];
    const prevAuthorisedLayer = mapLayersByType[type].find((l) => l.status === 'authorised');
    return (
      <MapLayerListItem
        hasObservations={hasObservations(layer)}
        key={layer.id}
        layer={layer}
        prevAuthorisedLayer={prevAuthorisedLayer}
      />
    );
  });

  useEffect(() => {
    if (maplayers.status === 'idle' && auth.status === 'finished') dispatch(EditorActions.getLayers()).then(() => {});
  }, [dispatch, maplayers.status, auth.status]);

  useEffect(() => {
    // will update locations every 30 seconds
    const interval = setInterval(() => dispatch(EditorActions.getLayers()), 30000);
    setTimeout(() => dispatch(EditorActions.getLayers()), 2000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.title}>
            <span style={{ color: theme.palette.common.neutralDark }}>Editor /</span> Process fuel state maps
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <div className={classes.topSection}>
            <div className={classes.filterContainer}>
              <Button
                variant={filter === 'ready' ? 'contained' : 'text'}
                onClick={() => setFilter('ready')}
                className={filter === 'ready' ? classes.filterButtonSelected : classes.filterButton}
              >
                Ready for processing
              </Button>
              <Button
                variant={filter === 'pending' ? 'contained' : 'text'}
                onClick={() => setFilter('pending')}
                className={filter === 'pending' ? classes.filterButtonSelected : classes.filterButton}
              >
                Pending authorisation
              </Button>
              <Button
                variant={filter === 'all' ? 'contained' : 'text'}
                onClick={() => setFilter('all')}
                className={filter === 'all' ? classes.filterButtonSelected : classes.filterButton}
              >
                All maps and models
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.menu}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {maplayers.status === 'loading' && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ margin: '8px' }} size={24} aria-valuetext="loading" />
            </div>
          )}
          {maplayers.status === 'error' && maplayers.error && (
            <ErrorSegment
              error={maplayers.error}
              text="Failed To load Locations"
              onRetry={() => dispatch(EditorActions.getLayers())}
            />
          )}
          {mapLayerItems}
        </Grid>
      </Grid>
      <Grid container className={classes.menu} style={{ marginBottom: theme.spacing(5) }}>
        <Grid item xs={9} />
        <Grid item xs={2}>
          <div className={classes.topSection}>
            <div className={classes.filterContainer}>
              <Button
                className={classes.button}
                onClick={() => navigate('/editor/fueltypemodels/dashboard')}
                disabled={false}
              >
                Fuel Type Models
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default FSEValidatorDashboard;
