import { createAction, createReducer } from '@reduxjs/toolkit';

import { makeMockAPICall } from 'models/api';
import { ToastManager } from 'models';
import { makeThunkFromAPICall } from 'state';

export type ToastState = {
  toast: ToastManager.Toast[];
  index: number;
};

const initialState: ToastState = {
  toast: [],
  index: 0,
};

export const addToast = createAction<ToastManager.Toast>('toast/add');

export const removeToast = createAction<ToastManager.Toast>('toast/remove');

// This should be in models however it causes a circular dependency issue as it attempts to import ToastActions
const showInternalToast = makeMockAPICall<null, ToastManager.Toast, null>(
  (payload, state, dispatch) => {
    if (dispatch) {
      dispatch(addToast(payload));
      setTimeout(() => dispatch(removeToast(payload)), 5000);
    }
    return {};
  },
  (t) => t,
);

export const [showToast, setShowToast] = makeThunkFromAPICall(showInternalToast, 'toast/set-status');

export const toastReducer = createReducer(initialState, (builder) => {
  builder.addCase(addToast, (state, action) => {
    const newToast = action.payload;
    newToast.index = state.index;
    const toast = [...state.toast, newToast];
    return { toast, index: state.index + 1 };
  });
  builder.addCase(removeToast, (state, action) => {
    const toast = state.toast.filter((x) => x.index !== action.payload.index);
    return { toast, index: state.index };
  });
});

export const ToastActions = {
  showToast,
  addToast,
  removeToast,
};

export default toastReducer;
