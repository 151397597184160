import { createReducer } from '@reduxjs/toolkit';

import { MapLayerManager, FireAreaManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type AuthoriserState = {
  maplayers: Loadable<MapLayerManager.MapLayer[]>;
  fireareas: Record<number, Loadable<FireAreaManager.FireArea[]>>;
};

const initialState: AuthoriserState = {
  maplayers: {
    status: 'idle',
  },
  fireareas: {},
};

export const [getLayers, setMapLayerStatus] = makeThunkFromAPICall(
  MapLayerManager.getLayers,
  'authoriser/maplayer/set-status',
);

export const [authoriseLayer, setMapLayerAuthoriseStatus] = makeThunkFromAPICall(
  MapLayerManager.authoriseLayer,
  'authoriser/firearea/edit/set-authorise-status',
);
export const [requestEditsLayer, setMapLayerEditStatus] = makeThunkFromAPICall(
  MapLayerManager.requestEditsLayer,
  'authoriser/firearea/edit/set-status',
);

export const [getFireAreas, setFireAreaStatus] = makeThunkFromAPICall(
  FireAreaManager.getFireAreas,
  'authoriser/firearea/set-status',
);

export const authoriserReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMapLayerStatus, (state, action) => {
    return { ...state, maplayers: { ...state.maplayers, ...action.payload.loadable } };
  });

  builder.addCase(setFireAreaStatus, (state, action) => {
    return {
      ...state,
      fireareas: {
        ...state.fireareas,
        [action.payload.input.layerId]: {
          ...state.fireareas[action.payload.input.layerId],
          ...action.payload.loadable,
        },
      },
    };
  });

  builder.addCase(setMapLayerEditStatus, (state, action) => {
    return {
      ...state,
      maplayers: {
        ...state.maplayers,
        object: state.maplayers.object
          ? state.maplayers.object.map((obj) => ({
              ...obj,
              updateStatus:
                action.payload.input.layer.id === obj.id
                  ? {
                      ...obj.updateStatus,
                      ...action.payload.loadable,
                    }
                  : obj.updateStatus,
            }))
          : state.maplayers.object,
      },
    };
  });

  builder.addCase(setMapLayerAuthoriseStatus, (state, action) => {
    return {
      ...state,
      maplayers: {
        ...state.maplayers,
        object: state.maplayers.object
          ? state.maplayers.object.map((obj) => ({
              ...obj,
              updateStatus:
                action.payload.input.layer.id === obj.id
                  ? {
                      ...obj.updateStatus,
                      ...action.payload.loadable,
                    }
                  : obj.updateStatus,
            }))
          : state.maplayers.object,
      },
    };
  });
});

export const AuthoriserActions = {
  getLayers,
  authoriseLayer,
  requestEditsLayer,
  getFireAreas,
};

export default authoriserReducer;
