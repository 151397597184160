/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ToastManager } from 'models';
import { ToastActions } from 'state/toast';
import { Grow } from '@material-ui/core';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 64,
    left: 0,
    right: 0,
    zIndex: 100000,
  },
  alert: {
    margin: theme.spacing(2),
    cursor: 'pointer',
  },
}));

const ToastComponent = () => {
  const classes = useStyles();
  const { toast } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const handleClick = (t: ToastManager.Toast) => {
    dispatch(ToastActions.removeToast(t));
  };

  const renderToast = (t: ToastManager.Toast) => {
    return (
      <Grow key={t.index} in>
        <Alert
          className={classes.alert}
          severity={t.type === ToastManager.ToastType.success ? 'success' : 'error'}
          onClick={() => handleClick(t)}
        >
          {t.message}
        </Alert>
      </Grow>
    );
  };

  return <div className={classes.root}>{toast.toast.map(renderToast)}</div>;
};

export default ToastComponent;
