/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Paper, Typography } from '@material-ui/core';
import {
  Bookmark,
  BookmarkBorder,
  RadioButtonChecked,
  RadioButtonUnchecked,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerManager } from 'models';
import { LayerActions } from 'state/layers';
import { AnalyticsActions } from 'state/analytics';

import { toTimeDDMonYearFormat } from 'utils';
import { LoadableImage } from 'components';
import HelpTypography from 'components/HelpTypography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 100,
      marginBottom: theme.spacing(1),
    },
    listItemMain: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto auto 1fr auto',
      justifyItems: 'start',
      alignItems: 'center',
    },
    thumbnail: {
      width: 30,
      height: 30,
      borderRadius: 6,
      minWidth: 30,
    },
    icon: {
      cursor: 'pointer',
      color: theme.palette.common.neutral,
    },
    thumbnailContainer: {
      margin: theme.spacing(1),
    },
    expandedSection: {
      padding: theme.spacing(1),
    },
    expandedText: {
      color: theme.palette.common.neutralDark,
    },
  }),
);

interface LayerListItemProps {
  layer: LayerManager.Layer;
  compareMapIndex?: number;
  style?: React.CSSProperties;
  hideBookmark?: boolean;
}

const LayerListItem: React.FunctionComponent<LayerListItemProps> = ({
  layer,
  style,
  compareMapIndex,
  hideBookmark,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const reduxState = useAppSelector((state) => state);
  const {
    bookmarkedLayers,
    selectedLayers,
    selectedMainLayer,
    selectedCompareLayers,
    selectedBaseMap,
    selectedBoundary,
  } = reduxState.layers;
  const bookmarked = bookmarkedLayers.object?.find((l) => l.id === layer.id);

  const isSelected =
    (selectedMainLayer === layer.id && compareMapIndex == null) ||
    (compareMapIndex != null && selectedCompareLayers[compareMapIndex] === layer.id) ||
    selectedBaseMap === layer.id ||
    selectedBoundary === layer.id ||
    (selectedLayers && selectedLayers.indexOf(layer.id) > -1);

  const handleSelectLayer = () => {
    if (layer.isMainLayer) {
      if (compareMapIndex == null) {
        dispatch(LayerActions.setSelectedMainLayer(layer.id));
        // This will always mean that as a main layer is selected and the user clicks to go to the
        // comparison page the most recent main layer will be in the first position
        dispatch(LayerActions.setSelectedComparePageMainLayer({ id: layer.id, index: 0 }));
      } else {
        dispatch(LayerActions.setSelectedComparePageMainLayer({ id: layer.id, index: compareMapIndex }));
      }
    } else if (layer.isBaseMap) {
      dispatch(LayerActions.setBasemapLayer(layer.id));
    } else if (layer.isBoundary) {
      dispatch(LayerActions.setBoundaryLayer(layer.id));
    } else if (selectedLayers) {
      dispatch(LayerActions.setSelectedLayers([...selectedLayers, layer.id]));
    } else {
      dispatch(LayerActions.setSelectedLayers([layer.id]));
    }
    dispatch(AnalyticsActions.postLayerView(layer));
  };

  const handleUnselectLayer = () => {
    if (layer.isMainLayer) {
      if (compareMapIndex == null) {
        dispatch(LayerActions.setSelectedMainLayer(null));
      } else {
        dispatch(LayerActions.setSelectedComparePageMainLayer({ id: null, index: compareMapIndex }));
      }
    } else if (layer.isBaseMap) {
      dispatch(LayerActions.setBasemapLayer(null));
    } else if (layer.isBoundary) {
      dispatch(LayerActions.setBoundaryLayer(null));
    } else if (selectedLayers) {
      dispatch(LayerActions.setSelectedLayers(selectedLayers.filter((id) => id !== layer.id)));
    }
  };

  const handleAddBookmark = () => {
    if (!bookmarked) dispatch(LayerActions.addBookmark({ layer }));
  };

  const handleRemoveBookmark = () => {
    if (bookmarked) dispatch(LayerActions.removeBookmark({ layer }));
  };

  let btn = isSelected ? (
    <RadioButtonChecked className={classes.icon} onClick={handleUnselectLayer} />
  ) : (
    <RadioButtonUnchecked className={classes.icon} onClick={handleSelectLayer} />
  );
  if (!(layer.isMainLayer || layer.isBaseMap || layer.isBoundary))
    btn = isSelected ? (
      <CheckBox className={classes.icon} onClick={handleUnselectLayer} />
    ) : (
      <CheckBoxOutlineBlank className={classes.icon} onClick={handleSelectLayer} />
    );

  return (
    <Paper
      className={classes.root}
      elevation={isSelected ? 6 : 0}
      style={{
        backgroundColor: isSelected ? theme.palette.common.white : 'rgb(0,0,0,0)',
        ...style,
      }}
    >
      <div className={classes.listItemMain}>
        {btn}
        <div className={classes.thumbnailContainer}>
          <LoadableImage
            alt={`Icon preview for ${layer.name}`}
            width={30}
            height={30}
            src={layer.thumbnail}
            className={classes.thumbnail}
            progressSize={10}
          />
        </div>
        <HelpTypography
          text={layer.summary ? [layer.summary] : undefined}
          variant="subtitle1"
          style={{ color: '#64737D', textDecoration: 'none' }}
          tooltip
        >
          {layer.name}
        </HelpTypography>
        {!hideBookmark && (
          <>
            {bookmarked ? (
              <Bookmark className={classes.icon} onClick={handleRemoveBookmark} />
            ) : (
              <BookmarkBorder className={classes.icon} onClick={handleAddBookmark} />
            )}
          </>
        )}
      </div>
      {isSelected && (
        <div className={classes.expandedSection}>
          {!layer.isNotUpdateable && (
            <Typography className={classes.expandedText} variant="subtitle2">
              Last Updated: {layer.lastUpdated != null ? toTimeDDMonYearFormat(new Date(layer.lastUpdated)) : 'Never'}
            </Typography>
          )}
        </div>
      )}
    </Paper>
  );
};

LayerListItem.displayName = 'LayerListItem';
export default LayerListItem;
