import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card, CardContent, TextField, Button, InputAdornment } from '@material-ui/core';

import { round, calculateFuelLoad, isValidFuelLoad } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    text: {
      paddingLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
  }),
);

const mapInputToValue = (input: string) => round(+input, 1);

interface FuelLoadInputProps {
  fuelCondition: number;
  continuious: boolean;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
  initialValue?: number;
}

const FuelLoadInput: React.FunctionComponent<FuelLoadInputProps> = ({
  fuelCondition,
  continuious,
  onChange,
  style,
  initialValue,
}) => {
  const classes = useStyles();
  const initValue = initialValue != null ? `${initialValue}` : `${calculateFuelLoad(fuelCondition, continuious)}`;

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initValue);

  return (
    <Card style={{ ...style }}>
      <CardContent className={classes.content}>
        {editing ? (
          <>
            <TextField
              size="small"
              error={!isValidFuelLoad(value)}
              helperText="Must be a valid number between 1 and 12"
              value={value}
              onChange={(event) => setValue(event.target.value)}
              variant="standard"
              InputProps={{
                endAdornment: <InputAdornment position="end">t/ha</InputAdornment>,
                className: classes.text,
              }}
            />
            <Button
              variant="text"
              onClick={() => {
                if (isValidFuelLoad(value)) {
                  setEditing(false);
                  const newValue = mapInputToValue(value);
                  onChange(newValue);
                  setValue(`${newValue}`);
                }
              }}
              disabled={!isValidFuelLoad(value)}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h6" className={classes.text}>
              {value} t/ha{' '}
            </Typography>
            <Button variant="text" onClick={() => setEditing(true)}>
              Change
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};

FuelLoadInput.displayName = 'FuelLoadInput';
export default FuelLoadInput;
