import { createAction, createReducer } from '@reduxjs/toolkit';

import { MapLayerManager, FireAreaManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type EditorState = {
  maplayers: Loadable<MapLayerManager.MapLayer[]>;
  fireareas: Record<number, Loadable<FireAreaManager.FireArea[]>>;
  layerOperations: MapLayerManager.MapLayer.StagedOperation[];
  tiffDownload: Loadable<Blob>;
  tiffUpload: Loadable<null>;
  modisDownload: Loadable<Blob>;
};

const initialState: EditorState = {
  maplayers: {
    status: 'idle',
  },
  fireareas: {},
  layerOperations: [],
  tiffDownload: {
    status: 'idle',
  },
  modisDownload: {
    status: 'idle',
  },
  tiffUpload: {
    status: 'idle',
  },
};

export const addOperations = createAction<MapLayerManager.MapLayer.StagedOperation[]>(
  'editor/firearea/edit/add-operations',
);

export const clearOperations = createAction<{ layer?: MapLayerManager.MapLayer; fireArea?: FireAreaManager.FireArea }>(
  'editor/firearea/edit/clear-operations',
);

export const [getLayers, setMapLayerStatus] = makeThunkFromAPICall(
  MapLayerManager.getLayers,
  'editor/maplayer/set-status',
);

export const [uploadTiff, setTiffUploadStatus] = makeThunkFromAPICall(
  MapLayerManager.setRaster,
  'editor/maplayer/set-tiff-upload',
);

export const [downloadTiff, setTiffDownloadStatus] = makeThunkFromAPICall(
  MapLayerManager.getRaster,
  'editor/maplayer/set-tiff-download',
);

export const [downloadModisTiff, setModisDownloadStatus] = makeThunkFromAPICall(
  MapLayerManager.getModisRaster,
  'editor/maplayer/set-modis-download',
);

export const [regenerateLayer, setMapLayerRegenerateStatus] = makeThunkFromAPICall(
  MapLayerManager.regenerateLayer,
  'editor/firearea/edit/regenerate',
);

export const [getFireAreas, setFireAreaStatus] = makeThunkFromAPICall(
  FireAreaManager.getFireAreas,
  'editor/firearea/set-status',
);

export const [submitLayer, setMapLayerSubmitStatus] = makeThunkFromAPICall(
  MapLayerManager.submitLayer,
  'editor/firearea/edit/set-submit-status',
);

export const [createNewAutoCandidateLayer, setMapLayerEditStatus] = makeThunkFromAPICall(
  MapLayerManager.createNewAutoCandidateLayer,
  'editor/firearea/edit/set-status',
);

export const editorReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMapLayerStatus, (state, action) => {
    return { ...state, maplayers: { ...state.maplayers, ...action.payload.loadable } };
  });

  builder.addCase(setTiffDownloadStatus, (state, action) => {
    return { ...state, tiffDownload: { ...state.tiffDownload, ...action.payload.loadable } };
  });

  builder.addCase(setTiffUploadStatus, (state, action) => {
    return { ...state, tiffUpload: { ...state.tiffUpload, ...action.payload.loadable } };
  });

  builder.addCase(setModisDownloadStatus, (state, action) => {
    return { ...state, modisDownload: { ...state.modisDownload, ...action.payload.loadable } };
  });

  builder.addCase(setFireAreaStatus, (state, action) => {
    return {
      ...state,
      fireareas: {
        ...state.fireareas,
        [action.payload.input.layerId]: {
          ...state.fireareas[action.payload.input.layerId],
          ...action.payload.loadable,
        },
      },
    };
  });

  builder.addCase(setMapLayerEditStatus, (state, action) => {
    return {
      ...state,
      maplayers: {
        ...state.maplayers,
        object: state.maplayers.object
          ? state.maplayers.object.map((obj) => ({
              ...obj,
              updateStatus:
                action.payload.input.layer.id === obj.id
                  ? {
                      ...obj.updateStatus,
                      ...action.payload.loadable,
                    }
                  : obj.updateStatus,
            }))
          : state.maplayers.object,
      },
    };
  });

  builder.addCase(setMapLayerSubmitStatus, (state, action) => {
    return {
      ...state,
      maplayers: {
        ...state.maplayers,
        object: state.maplayers.object
          ? state.maplayers.object.map((obj) => ({
              ...obj,
              updateStatus:
                action.payload.input.layer.id === obj.id
                  ? {
                      ...obj.updateStatus,
                      ...action.payload.loadable,
                    }
                  : obj.updateStatus,
            }))
          : state.maplayers.object,
      },
    };
  });

  builder.addCase(addOperations, (state, action) => {
    const operations = action.payload;
    const { layerOperations } = state;

    operations.forEach((x) => layerOperations.push(x));
  });

  builder.addCase(clearOperations, (state, action) => {
    const { layer, fireArea } = action.payload;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { layerOperations, ...rest } = state;

    if (layer && fireArea) {
      const newOperations = state.layerOperations.filter(
        (x) => x.fireArea.id !== fireArea.id || x.layer.id !== layer.id,
      );
      return { layerOperations: newOperations, ...rest };
    }

    if (layer) {
      const newOperations = state.layerOperations.filter((x) => x.layer.id !== layer.id);
      return { layerOperations: newOperations, ...rest };
    }

    if (fireArea) {
      const newOperations = state.layerOperations.filter((x) => x.fireArea.id !== fireArea.id);
      return { layerOperations: newOperations, ...rest };
    }

    return state;
  });
});

export const EditorActions = {
  addOperations,
  clearOperations,
  getLayers,
  uploadTiff,
  downloadTiff,
  downloadModisTiff,
  regenerateLayer,
  getFireAreas,
  submitLayer,
  createNewAutoCandidateLayer,
};

export default editorReducer;
