import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'rgba(246, 246, 246, 0.6)',
      border: '1px solid rgba(218, 224, 228, 0.6)',
      borderRadius: 24,
      height: 48,
      display: 'inline-block',
      padding: 7,
    },
    item: {
      height: 32,
      color: theme.palette.common.neutralDark,
      display: 'inline-block',
      padding: '5px 30px',
      fontWeight: 600,
      cursor: 'pointer',
      '&:hover': {
        color: 'black',
      },
      outline: 'none',
    },
    activeItem: {
      opacity: 1,
      height: 32,
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRadius: 16,
      display: 'inline-block',
      padding: '5px 30px',
      fontWeight: 600,
      cursor: 'pointer',
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
      outline: 'none',
    },
  }),
);

interface IProps {
  values: string[];
  defaultValue?: string;
  onChange: (newValue: string) => void;
}

const Toggle: React.FunctionComponent<IProps> = ({ values, defaultValue, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue || values[0]);

  const handleClick = (itemValue: string) => {
    if (itemValue === value) return;
    setValue(itemValue);
    onChange(itemValue);
  };

  const renderItem = (item: string) => (
    <div
      key={item}
      className={item === value ? classes.activeItem : classes.item}
      role="button"
      tabIndex={0}
      onClick={() => handleClick(item)}
      onKeyPress={() => handleClick(item)}
    >
      {item}
    </div>
  );

  return <div className={classes.root}>{values.map(renderItem)}</div>;
};

export default Toggle;
