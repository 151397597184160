/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';

import { useParams, Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Link, Button, CircularProgress } from '@material-ui/core';
import { ChevronLeft, ArrowForward, Edit } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { HelpActions } from 'state/help';
import { HelpManager } from 'models';
import { Error404, HelpItemCard } from 'components';
import { toDDMMYYYYFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    back: {
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
      textDecoration: 'underline',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      display: 'flex',
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      color: theme.palette.common.black,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
    },
    subtitle: {
      paddingBottom: theme.spacing(1),
      color: theme.palette.common.black70,
    },
    updated: {
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
      color: theme.palette.common.black70,
    },
    link: {
      color: theme.palette.text.link,
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
    },
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      color: theme.palette.text.link,
    },
  }),
);

function HelpDetails() {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { auth, help } = useAppSelector((state) => state);

  const isHelpPublisher = (auth.object?.decoded_token?.['cognito:groups']?.indexOf('HelpPublisher') ?? -1) > -1;

  const { helpItem } = useParams<{ helpItem: string }>();

  const itemId = parseInt(helpItem ?? '', 10);

  const item = help.get[itemId]?.object != null ? help.get[itemId].object : null;

  useEffect(() => {
    if (
      !Number.isNaN(itemId) &&
      auth.status === 'finished' &&
      (help.get[itemId] == null || help.get[itemId]?.status === 'idle')
    ) {
      dispatch(HelpActions.getItem({ id: itemId }));
    }
  }, [auth.status, help.get[itemId]?.status]);

  useEffect(() => {
    if (auth.status === 'finished' && help.list.status === 'idle') {
      dispatch(HelpActions.getList());
    }
  }, [auth.status, help.list.status]);

  if (help.get[itemId] == null || help.get[itemId].status === 'loading') {
    return (
      <div style={{ margin: theme.spacing(2) }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={30} aria-valuetext="loading" />
          <Typography align="center" variant="h6">
            Loading help article
          </Typography>
        </div>
      </div>
    );
  }

  if (item == null) {
    return (
      <div style={{ margin: theme.spacing(2) }}>
        <Error404 link="/help" title="Topic not found" action="Click to return to the help centre" />
      </div>
    );
  }

  const { title, longTitle, updated, content, group, topic, related, externalLink } = item;

  const relatedItems =
    help.list.object != null
      ? (related.map((id) => help.list.object?.find((i) => i.id === id)).filter((x) => x != null) as HelpManager.Item[])
      : [];

  return (
    <div>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Link component={RouterLink} to="/help">
            <Typography align="left" variant="subtitle1" className={classes.back}>
              <ChevronLeft />
              Help centre
            </Typography>
          </Link>
          <Typography variant="h4" className={classes.title}>
            {longTitle ?? title}
          </Typography>
          <div>
            <Typography variant="h6" className={classes.subtitle}>
              {group} &nbsp; <span style={{ color: theme.palette.common.neutralDark }}>/ &nbsp; {topic}</span>
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              {isHelpPublisher ? (
                <Link component={RouterLink} to={`/help/edit/${helpItem}`}>
                  <Typography align="left" variant="subtitle1" className={classes.back}>
                    <Edit />
                    Edit article
                  </Typography>
                </Link>
              ) : (
                <div />
              )}

              <Typography align="right" variant="subtitle1" className={classes.updated}>
                Last updated: {toDDMMYYYYFormat(updated)}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          {externalLink && (
            <Button variant="outlined">
              <Link href={externalLink} target="_blank" style={{ display: 'flex' }}>
                <Typography variant="subtitle1" className={classes.link}>
                  Go to website
                </Typography>
                <ArrowForward className={classes.icon} />
              </Link>
            </Button>
          )}
          {/* This is used to render the content from the WYSIWYG. Server runs cleaning of XSS  */
          /* eslint-disable-next-line react/no-danger  */}
          {content != null && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </Grid>
      </Grid>
      <Divider style={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(2) }} />
      <Grid container className={classes.menu} style={{ marginBottom: theme.spacing(2) }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h6" className={classes.subtitle}>
            Related Links
          </Typography>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: `${theme.spacing(2)}px` }}>
            {relatedItems.map((x) => (
              <HelpItemCard key={x.id} item={x} />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default HelpDetails;
