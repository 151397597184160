/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Grid } from '@material-ui/core';

import { MapLayerManager } from 'models';
import { EditorMenuButton } from 'components';
import { toTimeDDMonYearFormat, getFullName } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    map: {
      width: '100%',
      height: '100%',
    },
    drawer: {
      width: '50%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '50%',
    },
    drawerContent: {
      padding: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    subtitle: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.neutralDark, // neutralDark
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      left: '0%',
    },
    contentShift: {
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: '50%',
    },
  }),
);

interface AuthoriserWorkflowInitComponentProps {
  layer?: MapLayerManager.MapLayer | null;
}

const AuthoriserWorkflowInitComponent: React.FunctionComponent<AuthoriserWorkflowInitComponentProps> = ({ layer }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        {layer ? (
          <>
            <Typography variant="h5" className={classes.title}>
              Process {layer.name} map
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Created: {toTimeDDMonYearFormat(new Date(layer.created))}
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Editor: {getFullName(layer.submitter)}
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Start your review by selecting one of the following:
            </Typography>
            {!(layer.type === 'time-since-fire' || layer.type === 'fuel-type') && (
              <EditorMenuButton
                title={`${layer.name} map`}
                summary="Explore the map layers data"
                to={`/authoriser/layer/${layer.id}/arealist`}
              />
            )}

            {layer.status !== 'authorised' && (
              <EditorMenuButton
                title="Download"
                summary="Download the candidate .tif file"
                to={`/authoriser/layer/${layer.id}/download`}
              />
            )}

            <EditorMenuButton title="Notes" summary="View all notes" to={`/authoriser/layer/${layer.id}/notes`} />
          </>
        ) : (
          <Typography variant="h5" className={classes.title}>
            Layer Not Found
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default AuthoriserWorkflowInitComponent;
