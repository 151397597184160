import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Paper, Typography, Button } from '@material-ui/core';
import { FuelTypeModelsManager } from 'models';
import { toTimeDDMonYearFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 1050,
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    segmentContainer: {
      display: 'grid',
      padding: `0px ${theme.spacing(1)}px`,
      gridAutoFlow: 'column',
      gridTemplateColumns: '450px 375px auto',
      alignItems: 'start',
      justifyItems: 'start',
      width: '100%',
    },
    segment: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
    segmentEnd: {
      display: 'flex',
      padding: theme.spacing(2),
      alignSelf: 'center',
      justifySelf: 'end',
      flexDirection: 'row',
    },
    segmentTitle: {
      color: theme.palette.common.neutralDark,
    },
    segmentError: {
      color: 'red',
      fontWeight: 'bold',
    },
    segmentAge: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    segmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
    segmentValue: {
      minWidth: '32px',
    },
    img: {
      display: 'block',
      overflow: 'hidden',
      width: '100%',
    },
    imgPaper: {
      padding: 0,
      overflow: 'hidden',
    },
    button: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    viewDetailsButton: {
      color: theme.palette.common.grey,
      textDecoration: 'underline',
      marginRight: theme.spacing(1),
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      width: 'fit-content',
    },
  }),
);

type FuelTypeListItemProps = {
  display?: 'block' | 'inline-block';
  style?: React.CSSProperties;
  summaryItem: FuelTypeModelsManager.FuelTypeModelSummary;
  fse_role: string;
  to?: string;
  disableButton?: boolean;
};

const FuelTypeModelListItem: React.FunctionComponent<FuelTypeListItemProps> = ({
  summaryItem,
  fse_role,
  style,
  display,
  to,
  disableButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const disabled = false;

  let mainText = '';
  let subText = '';
  let dateTitleText = '';
  let dateDetail = '';
  if (summaryItem && summaryItem.status === 'authorised') {
    mainText = 'Authorised Fuel Type Model';
    subText = `Authorised by ${summaryItem.approver?.user_name}`;
    dateTitleText = `Authorised date:`;
    dateDetail = summaryItem.authorise_date ? toTimeDDMonYearFormat(summaryItem.authorise_date) : '';
  } else if (summaryItem && summaryItem.status === 'submitted') {
    mainText = 'Candidate Fuel Type Model';
    if (summaryItem.submitter) {
      subText = `Submitted by ${summaryItem.submitter?.user_name}`;
      dateTitleText = `Submitted date:`;
      dateDetail = summaryItem.submission_date ? toTimeDDMonYearFormat(summaryItem.submission_date) : '';
    }
  } else if (summaryItem && summaryItem.status === 'rejected') {
    mainText = 'Rejected Fuel Type Model';
    if (summaryItem.submitter) {
      subText = `Submitted by ${summaryItem.submitter?.user_name}`;
      dateTitleText = `Submitted date:`;
      dateDetail = summaryItem.submission_date ? toTimeDDMonYearFormat(summaryItem.submission_date) : '';
    }
  } else if (summaryItem && summaryItem.status === 'new') {
    mainText = 'New Fuel Type Model';
    subText = `For submitting / validating a new fuel type model file`;
    dateTitleText = '';
    dateDetail = '';
  }

  let errorText: string | null = null;
  if (summaryItem.status === 'rejected') errorText = 'Rejected';
  if (summaryItem.status === 'error') errorText = 'Error';

  let bgColor = theme.palette.common.white;
  if (hover) bgColor = theme.palette.common.neutralXXLight;
  if (disabled) bgColor = theme.palette.common.neutralLight;

  return (
    <Paper
      className={classes.root}
      variant="outlined"
      style={{
        backgroundColor: bgColor,
        display: display || 'flex',
        ...style,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
    >
      <div className={classes.segmentContainer}>
        <div className={classes.segment} style={{ borderRight: `1px solid ${theme.palette.common.neutralXLight}` }}>
          <Typography
            variant="subtitle2"
            className={`${classes.segmentTitle} ${errorText ? classes.segmentError : ''}`}
          >
            {errorText || ''}&nbsp;
          </Typography>
          <div className={classes.segmentMiddle}>
            <Typography variant="h6" className={classes.segmentValue} style={{ fontWeight: 'bold' }}>
              {mainText}
            </Typography>
          </div>
          <Typography variant="subtitle2" className={classes.segmentAge}>
            {subText}
          </Typography>
        </div>

        <div className={classes.segment}>
          <Typography variant="subtitle2" className={classes.segmentTitle} />
          <div className={classes.segmentMiddle}>
            <Typography variant="h5" className={classes.segmentValue}>
              {dateTitleText}
            </Typography>
          </div>
          <Typography variant="subtitle2" className={classes.segmentAge}>
            {dateDetail}
          </Typography>
        </div>

        <div className={classes.segmentEnd}>
          {summaryItem.status === 'new' && (
            <Button
              className={classes.button}
              variant="contained"
              disabled={disableButton}
              component={RouterLink}
              to={to ?? `/${fse_role}/fueltypemodels/${summaryItem.status}`}
            >
              Upload and Review
            </Button>
          )}
          {summaryItem.status === 'authorised' && (
            <Button
              className={classes.button}
              variant="contained"
              disabled={disableButton}
              component={RouterLink}
              to={to ?? `/${fse_role}/fueltypemodels/${summaryItem.status}`}
            >
              Download
            </Button>
          )}
          {summaryItem.status === 'rejected' && (
            <Button
              className={classes.button}
              variant="contained"
              disabled={disableButton}
              component={RouterLink}
              to={to ?? `/${fse_role}/fueltypemodels/${summaryItem.status}`}
            >
              Review
            </Button>
          )}
          {summaryItem.status === 'submitted' && (
            <Button
              className={classes.button}
              variant="contained"
              disabled={disableButton}
              component={RouterLink}
              to={to ?? `/${fse_role}/fueltypemodels/${summaryItem.status}`}
            >
              Review
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
};

FuelTypeModelListItem.displayName = 'FuelTypeModelListItem';
export default FuelTypeModelListItem;
