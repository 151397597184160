import React, { useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerManager } from 'models';
import { LayerActions } from 'state/layers';

import LayerListItem from './LayerListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    title: {
      color: theme.palette.common.neutralDark,
    },
    twocolumns: {},
    groupTitle: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    groupSection: {
      margin: `${theme.spacing(2)}px 0px`,
    },
  }),
);

interface LayerListProps {
  title?: string;
  columns?: number;
  compareMapIndex?: number;
  hideBookmark?: boolean;
  filterFn?: (layer: LayerManager.Layer) => boolean;
}

const LayerList: React.FunctionComponent<LayerListProps> = ({
  title,
  columns,
  filterFn,
  compareMapIndex,
  hideBookmark,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const { layers } = useAppSelector((state) => state.layers);

  const mainLayers = filterFn ? layers.object?.filter(filterFn) : layers.object;

  const groupsIds = mainLayers
    ?.reduce((acc, layer) => {
      if (acc.indexOf(layer.group) === -1) return [...acc, layer.group];
      return acc;
    }, [] as string[])
    .sort(
      (a, b) =>
        LayerManager.groups.indexOf(a as LayerManager.Layer.Groups) -
        LayerManager.groups.indexOf(b as LayerManager.Layer.Groups),
    );

  const groups: Record<string, LayerManager.Layer[]> = {};

  if (mainLayers) groupsIds?.forEach((id) => (groups[id] = mainLayers.filter((l) => l.group === id)));

  useEffect(() => {
    if (layers.status === 'idle' && auth.status === 'finished') dispatch(LayerActions.getLayers());
  }, [layers.status, auth.status, dispatch]);

  useEffect(() => {
    if (auth.status === 'finished') dispatch(LayerActions.getLayers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const mapFn = (layer: LayerManager.Layer) => (
    <LayerListItem
      key={layer.id}
      layer={layer}
      compareMapIndex={compareMapIndex}
      hideBookmark={hideBookmark}
      style={{
        breakInside: 'avoid',
        pageBreakInside: 'avoid',
      }}
    />
  );
  return (
    <div className={classes.root}>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}
      <div style={{ columns: columns ?? 1 }}>
        {groupsIds?.map((group) => (
          <div key={group} className={classes.groupSection}>
            <Typography className={classes.groupTitle} variant="subtitle2">
              {group}
            </Typography>
            {groups[group].map(mapFn)}
          </div>
        ))}
      </div>
    </div>
  );
};

LayerList.displayName = 'LayerList';
export default LayerList;
