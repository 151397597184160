/* eslint-disable no-param-reassign */
/* eslint max-classes-per-file: "off" */
/* eslint class-methods-use-this: "off" */
import { Map as OLMap, Feature, MapBrowserEvent, Collection, Overlay, VectorTile } from 'ol';
import { fromLonLat, toLonLat, transformExtent } from 'ol/proj';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import MVT from 'ol/format/MVT';
import { Point, MultiPoint } from 'ol/geom';
import { Style, Icon, Stroke, Text, Fill } from 'ol/style';
import { Translate } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { FeatureLike } from 'ol/Feature';
import { StyleFunction } from 'ol/style/Style';
import { MousePosition, ScaleLine } from 'ol/control';
import { boundingExtent } from 'ol/extent';
import { Common, AuthManager } from 'models';

import { getGeoserverEndpoint, getUserStateGeoserverEndpoint, getUserState, getTileGrid } from 'utils';
import config from 'config';
import { createStringXY } from 'ol/coordinate';
import { customColors } from 'theme';
import View, { FitOptions } from 'ol/View';
import TileState from 'ol/TileState';
import TileSource from 'ol/source/Tile';

type Bounds = Common.Bounds;

export interface MapContext {
  map: OLMap;
}

export interface MapCommand {
  apply: (context: MapContext) => void;
}

const darkMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    src: '/map/marker-dark.png',
  }),
});

const activeMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    src: '/map/marker-active.png',
  }),
});

export class ZoomToCoords implements MapCommand {
  constructor(coords: number[], zoom?: number) {
    this.coords = coords;
    this.zoom = zoom;
  }

  zoom?: number;

  coords: number[];

  apply(context: MapContext) {
    context.map.getView().animate({
      zoom: this.zoom,
      center: fromLonLat(this.coords),
    });
  }
}

export class ZoomToBounds implements MapCommand {
  constructor(bounds: Bounds, options?: FitOptions) {
    this.bounds = bounds;
    this.options = {
      padding: [50, 50, 50, 50],
      maxZoom: 20,
      duration: 1000,
      ...options,
    };
  }

  bounds: Bounds;

  options: FitOptions;

  apply(context: MapContext) {
    const geometry = new MultiPoint([
      fromLonLat([this.bounds.minLong, this.bounds.minLat]),
      fromLonLat([this.bounds.maxLong, this.bounds.maxLat]),
    ]);

    context.map.getView().fit(geometry, this.options);
  }
}

export class GetMapBounds implements MapCommand {
  constructor(private cb?: (bounds: Common.Bounds) => void) {}

  private bounds: Common.Bounds | null = null;

  getBounds() {
    return this.bounds;
  }

  apply(context: MapContext) {
    context.map.on('moveend', (event) => {
      const extent = transformExtent(
        event.map.getView().calculateExtent(event.map.getSize()),
        'EPSG:3857',
        'EPSG:4326',
      );
      this.bounds = {
        minLong: extent[0],
        minLat: extent[1],
        maxLong: extent[2],
        maxLat: extent[3],
      };
      if (this.cb) this.cb(this.bounds);
    });
  }
}

export class Resize implements MapCommand {
  apply(context: MapContext) {
    context.map.updateSize();
  }
}

export interface CoordsWithId {
  coords: number[];
  id: number;
  meta?: string;
  text?: string;
}

export class AddMarkers implements MapCommand {
  constructor(coords: CoordsWithId[], onClick?: (index: number) => void, onStyle?: StyleFunction) {
    this.coords = coords;
    this.onClick = onClick;
    this.onStyle = onStyle;
  }

  coords: CoordsWithId[];

  onClick?: (id: number) => void;

  onStyle?: StyleFunction;

  remove?: () => void;

  apply(context: MapContext) {
    const updateCursor = (e: MapBrowserEvent<any>) => {
      const hit = context.map.getFeaturesAtPixel(e.pixel).length > 0;
      context.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    };

    const handleClick = (e: MapBrowserEvent<any>) => {
      const [feature] = context.map.getFeaturesAtPixel(e.pixel);
      if (!feature) return;
      if (this.onClick) this.onClick(feature.get('id'));
    };

    const source = new VectorSource({
      features: this.coords.map((x) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat(x.coords)),
        });
        feature.setStyle(this.onStyle || darkMarkerStyle);
        feature.set('id', x.id);
        if (x.meta !== undefined) feature.set('meta', x.meta);
        if (x.text !== undefined) feature.set('text', x.text);
        return feature;
      }),
    });

    const layer = new VectorLayer({
      source,
    });
    (layer as any).addedByThisComponent = true;
    (layer as any).updateCursor = updateCursor;
    (layer as any).handleClick = handleClick;
    context.map.getLayers().forEach((x) => {
      if (x && (x as any).addedByThisComponent === true) {
        if ((x as any).handleClick) context.map.un('click', (x as any).handleClick);
        if ((x as any).updateCursor) context.map.un('pointermove', (x as any).updateCursor);
        context.map.removeLayer(x);
      }
    });
    context.map.addLayer(layer);

    if (this.onClick) {
      context.map.on('click', handleClick);
      context.map.on('pointermove', updateCursor);
    }

    this.remove = () => context.map.removeLayer(layer);
  }
}

export class AddMoveableMarker implements MapCommand {
  constructor(onMove: (coords: number[]) => void) {
    this.onMove = onMove;
  }

  private onMove: (coords: number[]) => void;

  private geometry?: Point;

  updateCoords = (coords: number[]) => this.geometry?.setCoordinates(fromLonLat(coords));

  remove?: () => void;

  apply(context: MapContext) {
    const coords = context.map.getView().getCenter();
    if (coords) {
      this.geometry = new Point(coords);

      const feature = new Feature({ geometry: this.geometry });
      feature.setStyle(activeMarkerStyle);

      const source = new VectorSource({
        features: [feature],
      });

      const layer = new VectorLayer({ source });
      context.map.addLayer(layer);

      const translate = new Translate({
        features: new Collection([feature]),
      });
      context.map.addInteraction(translate);

      this.remove = () => {
        context.map.removeLayer(layer);
        context.map.removeInteraction(translate);
      };

      translate.on('translateend', () => {
        if (!this.geometry) return;
        this.onMove(toLonLat(this.geometry.getCoordinates()));
      });

      this.onMove(toLonLat(coords));
    }
  }
}

export class AddWmsMvtLayer implements MapCommand {
  readonly labelStyle = new Text({
    font: '14px Roboto, Helvetica, Arial',
    overflow: true,
    fill: new Fill({
      color: customColors.white,
    }),
    stroke: new Stroke({
      color: 'black',
    }),
    backgroundFill: new Fill({
      color: 'rgba(0,0,0,0.7)',
    }),
    padding: [5, 4, 4, 5],
  });

  constructor({
    layerName,
    labelField,
    auth,
  }: {
    layerName: string | null;
    labelField?: string | null;
    params?: { [key: string]: any };
    auth?: AuthManager.Auth | null;
  }) {
    this.layerName = layerName;
    this.auth = auth;
    this.labelField = labelField;
    this.url = layerName ? this.getUrl(layerName) : null;

    this.vtStyle = new Style({
      stroke: new Stroke({
        color: 'black',
        width: 2,
      }),
      text: labelField != null ? this.labelStyle : undefined,
    });

    this.source = this.updateSource();
  }

  private layerName: string | null;

  private url: string | null;

  private labelField?: string | null;

  private auth?: AuthManager.Auth | null;

  private vtStyle: Style;

  private highlighted?: string;

  private source: VectorTileSource | null;

  private currentLayer?: VectorTileLayer;

  highlight(name?: string) {
    if (name === this.highlighted) return;
    this.highlighted = name;
    this.source?.changed();
  }

  private getUrl(layerId: string) {
    return `${config.geoserver_url}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.1.1&LAYER=${layerId}&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`;
  }

  private updateSource() {
    if (this.url == null || this.layerName == null) return null;

    const source = new VectorTileSource({
      format: new MVT({ idProperty: 'iso_a3' }),
      url: this.layerName != null ? this.getUrl(this.layerName) : '',
    });

    source.setTileLoadFunction((painOldTile, url) => {
      const tile = painOldTile as VectorTile;

      const headers: Record<string, string> = {};

      if (this.auth?.creds) headers.Authorization = `${this.auth.creds.token_type} ${this.auth.creds.access_token}`;

      tile.setLoader((extent, resolution, projection) => {
        fetch(url, {
          headers,
        })
          .then((response) => {
            response.arrayBuffer().then((data) => {
              try {
                const format = tile.getFormat(); // ol/format/MVT configured as source format
                const features = format.readFeatures(data, {
                  extent,
                  featureProjection: projection,
                });
                // @ts-ignore
                tile.setFeatures(features);
              } catch (e) {
                tile.setState(TileState.ERROR);
              }
            });
          })
          .catch(() => {
            tile.setState(TileState.ERROR);
          });
      });
    });

    if (this.currentLayer) this.currentLayer?.setSource(source);
    return source;
  }

  update({
    layerName,
    labelField,
    auth,
  }: {
    layerName: string | null;
    labelField?: string | null;
    auth?: AuthManager.Auth | null;
  }) {
    this.layerName = layerName;
    this.auth = auth;
    this.labelField = labelField;
    this.url = layerName ? this.getUrl(layerName) : null;

    this.vtStyle = new Style({
      stroke: new Stroke({
        color: 'black',
        width: 2,
      }),
      text: labelField != null ? this.labelStyle : undefined,
    });

    if (!layerName) {
      this.currentLayer?.setVisible(false);
      return;
    }

    if (this.source == null) this.source = this.updateSource();
    if (this.source && this.url) {
      this.source.setUrl(this.url);
      this.source.refresh();
    }

    if (!this.currentLayer?.getVisible()) {
      this.currentLayer?.setVisible(true);
    }

    if (this.currentLayer)
      this.currentLayer.setStyle((feature: FeatureLike) => {
        if (this.labelField) {
          const name = feature.get(this.labelField);
          this.vtStyle.getText().setText(name);
          const strokeWidth = name != null && name === this.highlighted ? 5 : 2;
          this.vtStyle.getStroke().setWidth(strokeWidth);
        }
        return this.vtStyle;
      });
  }

  apply(context: MapContext) {
    if (this.source == null) this.source = this.updateSource();

    this.currentLayer = new VectorTileLayer({
      declutter: true,
      source: this.source ?? undefined,
      style: (feature: FeatureLike) => {
        if (this.labelField) {
          const name = feature.get(this.labelField);
          this.vtStyle.getText().setText(name);
          const strokeWidth = name != null && name === this.highlighted ? 5 : 2;
          this.vtStyle.getStroke().setWidth(strokeWidth);
        }
        return this.vtStyle;
      },
    });

    context.map.addLayer(this.currentLayer);
  }
}

export class AddWmsTileLayer implements MapCommand {
  constructor({
    layerName,
    date,
    auth,
    params,
    opacity,
    useUserState,
  }: {
    layerName: string | null;
    date?: Date | null;
    auth?: AuthManager.Auth | null;
    params?: { [key: string]: any };
    opacity?: number;
    useUserState?: boolean;
  }) {
    this.layerName = layerName;
    this.date = date;
    this.opacity = opacity != null ? opacity / 100 : 1;
    this.useUserState = useUserState;
    this.auth = auth;
    this.url = useUserState && auth ? getUserStateGeoserverEndpoint(auth) : getGeoserverEndpoint();
    this.state = auth ? getUserState(auth) : null;
    this.params = {
      LAYERS: this.layerName,
      TIME: date?.toISOString(),
      VERSION: '1.1.1',
      TILED: true,
      SRS: 'EPSG:3857',
      ...params,
    };
    this.source = this.updateSource();
  }

  private layerName: string | null;

  private date?: Date | null;

  private auth?: AuthManager.Auth | null;

  private issueTime?: Date | null;

  private url: string | null;

  private state: string | null;

  private currentLayer?: TileLayer<TileSource>;

  private params: { [key: string]: any };

  private source: TileWMS | null;

  private useUserState?: boolean;

  private opacity: number;

  private updateSource() {
    if (this.url == null || this.layerName == null) return null;
    let stateExtent = null;
    if (this.state && this.useUserState) {
      // const coords = config.jurisdictionBounds[this.state];
      const coords = config.jurisdictionBounds[this.state as keyof typeof config.jurisdictionBounds];
      stateExtent = boundingExtent([
        fromLonLat([coords.minLong, coords.minLat], this.params.SRS),
        fromLonLat([coords.maxLong, coords.minLat], this.params.SRS),
        fromLonLat([coords.maxLong, coords.maxLat], this.params.SRS),
        fromLonLat([coords.minLong, coords.minLat], this.params.SRS),
      ]);
    }

    const source = new TileWMS({
      crossOrigin: 'anonymous',
      url: this.url,
      params: this.updateParams(),
      tileGrid: getTileGrid(this.params.SRS, stateExtent),
    });
    source.setTileLoadFunction((tile, src) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      // Need it like this for the 'this' variable to work
      // eslint-disable-next-line func-names
      xhr.addEventListener('loadend', function () {
        const data = this.response;
        if (data !== undefined) {
          try {
            // @ts-ignore
            tile.getImage().src = URL.createObjectURL(data);
          } catch (e) {
            console.error(e);
            tile.setState(TileState.ERROR);
          }
        } else {
          tile.setState(TileState.ERROR);
        }
      });
      xhr.addEventListener('error', () => {
        tile.setState(TileState.ERROR);
      });
      xhr.open('GET', src);

      if (this.auth?.creds)
        xhr.setRequestHeader('Authorization', `${this.auth.creds.token_type} ${this.auth.creds.access_token}`);

      xhr.send();
    });

    if (this.currentLayer) this.currentLayer?.setSource(source);
    return source;
  }

  private updateParams() {
    this.params = { ...this.params, LAYERS: this.layerName, TIME: this.date?.toISOString() };
    return this.params;
  }

  update({ layerName, date, auth }: { layerName: string | null; date?: Date | null; auth?: AuthManager.Auth | null }) {
    this.layerName = layerName;
    this.auth = auth;
    if (date !== undefined) this.date = date;
    if (this.useUserState && auth) this.url = getUserStateGeoserverEndpoint(auth);

    if (this.source == null) this.source = this.updateSource();

    if (this.source) {
      this.source.updateParams(this.updateParams());
      this.source.refresh();
    }
  }

  updateDate(date?: Date | null, issueTime?: Date | null) {
    if (date !== null) this.date = date;
    if (issueTime !== null) this.issueTime = issueTime;

    if (this.source) {
      this.source.updateParams({
        ...this.params,
        TIME: this.date?.toISOString(),
        DIM_ISSUE_TIME: this.issueTime?.toISOString(),
      });
    }
  }

  refresh() {
    if (this.source) {
      this.source.updateParams({ ...this.params, LAYERS: '' });
      this.source.refresh();

      setTimeout(() => {
        this.source?.updateParams(this.updateParams());
        this.source?.refresh();
      }, 10);
    }
  }

  setOpacity(value: number) {
    this.opacity = value / 100;
    this.currentLayer?.setOpacity(value / 100);
  }

  apply(context: MapContext) {
    if (this.source == null) this.source = this.updateSource();

    this.currentLayer = new TileLayer({ source: this.source || undefined, opacity: this.opacity });
    context.map.addLayer(this.currentLayer);
  }
}

export class AddScaleLine implements MapCommand {
  apply({ map }: MapContext) {
    const scaleLine = new ScaleLine({
      target: '',
    });

    map.addControl(scaleLine);
  }
}

export class MouseCoords implements MapCommand {
  apply(context: MapContext) {
    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326',
      undefinedHTML: '&nbsp;',
    });
    context.map.addControl(mousePositionControl);
  }
}

type ClickEvent = (coords: number[], event: MapBrowserEvent<any>) => void;

export class MouseClick implements MapCommand {
  constructor(onClick: ClickEvent) {
    this.onClick = onClick;
  }

  private onClick: ClickEvent;

  private event?: MapBrowserEvent<UIEvent>;

  apply(context: MapContext) {
    context.map.on('click', (event) => {
      this.event = event;
      this.onClick(toLonLat(event.coordinate), event);
    });
  }

  update(onClick: ClickEvent) {
    this.onClick = onClick;
  }

  rerunLastClick() {
    if (this.event) this.onClick(toLonLat(this.event.coordinate), this.event);
  }
}

export class MapMove implements MapCommand {
  view?: View;

  maps: OLMap[] = [];

  resize() {
    this.maps.forEach((x) => x.updateSize());
  }

  apply(context: MapContext) {
    this.maps.push(context.map);
    if (!this.view) {
      this.view = context.map.getView();
      return;
    }
    context.map.setView(this.view);
  }
}

export class ShowPopup implements MapCommand {
  constructor(coords?: number[], message?: string) {
    this.coords = coords;
    this.message = message;
  }

  private coords?: number[];

  private message?: string;

  apply(context: MapContext) {
    const overlay = context.map.getOverlayById('popup') as Overlay;
    if (this.coords && this.message) {
      if (overlay) {
        overlay.setPosition(fromLonLat(this.coords));
        const element = overlay.getElement()?.getElementsByClassName('popup-content')[0];
        if (element) element.innerHTML = this.message || '';
        return;
      }
    }
    overlay?.setPosition(undefined);
  }
}

export class ShowOverlay implements MapCommand {
  constructor(id: string, coords?: number[]) {
    this.coords = coords;
    this.id = id;
  }

  private coords?: number[];

  private id: string;

  apply(context: MapContext) {
    const overlay = context.map.getOverlayById(this.id);
    if (this.coords && overlay) {
      overlay.setPosition(fromLonLat(this.coords));
      return;
    }
    overlay?.setPosition(undefined);
  }
}

export class HideOverlay implements MapCommand {
  constructor(id: string) {
    this.id = id;
  }

  private id: string;

  apply(context: MapContext) {
    const overlay = context.map.getOverlayById(this.id);
    overlay?.setPosition(undefined);
  }
}
