import { ErrorResp, ErrorTypes } from 'models';

// eslint-disable-next-line
export function createMessageFromErrorType(errorType: ErrorTypes): (err: ErrorResp) => string {
  return (err) => {
    return errorType === err.code ? err.data.detail : '';
  };
}

export const messageWhenBadRequest = createMessageFromErrorType(ErrorTypes.BAD_REQUEST);
