/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, TextField, Link } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { HelpActions } from 'state/help';
import { HelpItemCard } from 'components';
import { HelpManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      color: theme.palette.common.black,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    groupTitle: {
      color: theme.palette.common.neutralDark,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(3),
    },
    groupSection: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: `${theme.spacing(2)}px`,
    },
    statusText: {
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
      paddingTop: theme.spacing(1),
    },
    back: {
      fontWeight: 'bold',
      paddingTop: theme.spacing(1),
      color: theme.palette.common.neutralDark,
      textDecoration: 'underline',
      display: 'flex',
    },
  }),
);

function HelpMenu() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { auth, help } = useAppSelector((state) => state);

  const [filter, setFilter] = useState('');

  const isHelpPublisher = (auth.object?.decoded_token?.['cognito:groups']?.indexOf('HelpPublisher') ?? -1) > -1;

  const helpGroups: Record<string, Record<string, HelpManager.Item[]>> = {};

  const groupOrder: string[] = [];

  const filteredHelp =
    help.list.object?.filter((item) => {
      const lcFilter = filter.toLowerCase();

      return (
        !lcFilter ||
        item.title.toLowerCase().indexOf(lcFilter) > -1 ||
        (item.longTitle && item.longTitle.toLowerCase().indexOf(lcFilter) > -1) ||
        item.group.toLowerCase().indexOf(lcFilter) > -1 ||
        item.topic.toLowerCase().indexOf(lcFilter) > -1 ||
        item.tags.reduce<boolean>((acc, tag) => acc || tag.toLowerCase().indexOf(lcFilter) > -1, false)
      );
    }) ?? [];

  filteredHelp.forEach((item) => {
    const groupName = item.group[0].toUpperCase() + item.group.substring(1).toLowerCase();
    if (helpGroups[groupName] == null) {
      helpGroups[groupName] = {};
      groupOrder.push(groupName);
    }

    const topicName = item.topic[0].toUpperCase() + item.topic.substring(1).toLowerCase();

    if (helpGroups[groupName][topicName] == null) helpGroups[groupName][topicName] = [];

    helpGroups[groupName][topicName].push(item);
  });

  useEffect(() => {
    if (auth.status === 'finished' && help.list.status === 'idle') {
      dispatch(HelpActions.getList());
    }
  }, [auth.status, help.list.status]);

  return (
    <div>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h4" className={classes.title}>
            Help Centre
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu} style={{ marginBottom: theme.spacing(6) }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
              value={filter}
              size="small"
              onChange={(event: any) => setFilter(event?.target?.value)}
              variant="outlined"
              placeholder="Filter Help Topics"
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" className={classes.statusText}>
              {filteredHelp.length === 0 && 'No articles found'}
              {filteredHelp.length === 1 && '1 article found'}
              {filteredHelp.length > 1 && `${filteredHelp.length} articles found`}
            </Typography>
            {isHelpPublisher ? (
              <Link component={RouterLink} to="/help/edit">
                <Typography align="left" variant="subtitle1" className={classes.back}>
                  <AddCircleOutline />
                  Add new article
                </Typography>
              </Link>
            ) : (
              <div />
            )}
          </div>
          {groupOrder.map((group) => (
            <div key={group}>
              <Typography variant="h6" className={classes.groupTitle}>
                {group}
              </Typography>
              <div className={classes.groupSection}>
                {Object.keys(helpGroups[group]).map((topic) =>
                  helpGroups[group][topic].map((item) => <HelpItemCard key={item.id} item={item} />),
                )}
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default HelpMenu;
