import React from 'react';
import { FuelTypeModelsManager } from 'models';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    th: {
      fontWeight: 'bold',
      fontSize: 10,
    },
    tc: {
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      textAlign: 'center',
      fontSize: '14px',
    },
    smPad: {
      padding: 12,
    },
    square: {
      borderRadius: 4,
      width: 48,
      height: 48,
      padding: 1,
      fontSize: '14px',
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: theme.spacing(0.75),
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
  }),
);

type ModelTableProps = {
  fuelTypeModels: FuelTypeModelsManager.FuelTypeModels.FuelTypeModel[];
  modelStatus: string;
  abbreviated: boolean;
  tableStyle?: React.CSSProperties;
};

const ModelTable: React.FunctionComponent<ModelTableProps> = (tableProps) => {
  const classes = useStyles();
  const { fuelTypeModels, modelStatus, abbreviated, tableStyle } = tableProps;
  const targetStatus = modelStatus === 'new' ? 'adhoc' : modelStatus;
  const targetFTM = fuelTypeModels.filter((row) => row.status === targetStatus);

  if (abbreviated) {
    return (
      <TableContainer>
        <Table size="small" style={tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.th} ${classes.smPad}`} style={{ textAlign: 'right' }}>
                Fuel_Name
              </TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>FBM</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>Fuel_FDR</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>FTno_State</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>H_o</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>FI_b</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>FI_o</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>FI_s</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>FL_b</TableCell>
              <TableCell className={`${classes.th} ${classes.smPad}`}>...</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {targetFTM.length === 1
              ? targetFTM[0].param_list.map((row) => (
                  <TableRow key={row.FTno_State}>
                    <TableCell className={`${classes.th} ${classes.smPad}`} component="th" scope="row">
                      {row.Fuel_Name}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} component="th" scope="row">
                      {row.FBM}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.Fuel_FDR}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.FTno_State}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.H_o}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.FI_b}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.FI_o}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.FI_s}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                      {row.FL_b}
                    </TableCell>
                    <TableCell className={`${classes.th} ${classes.smPad}`} align="right" />
                  </TableRow>
                ))
              : undefined}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.th} ${classes.smPad}`} style={{ textAlign: 'right' }}>
              Fuel_Name
            </TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FBM</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fuel_FDR</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FTno_State</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>H_o</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_b</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_o</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_s</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FL_b</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FL_o</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FL_s</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fk_b</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fk_o</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fk_s</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>H_el</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>H_ns</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Cov_o</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FHS_b</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FHS_s</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_el</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_ns</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FL_el</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FL_ns</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fk_el</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fk_ns</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Hk_ns</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FHS_el</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FHS_ns</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>WF_Sav</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Prod_BG</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>WRF_For</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_total</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FI_total</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FL_total</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>FTno_FDR</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Fk_total</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Spotting</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>WF_Heath</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {targetFTM.length === 1
            ? targetFTM[0].param_list.map((row) => (
                <TableRow key={row.FTno_State}>
                  <TableCell className={`${classes.th} ${classes.smPad}`} component="th" scope="row">
                    {row.Fuel_Name}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FBM}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fuel_FDR}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FTno_State}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.H_o}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FI_b}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FI_o}
                  </TableCell>

                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FI_s}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FL_b}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FL_o}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FL_s}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fk_b}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fk_o}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fk_s}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.H_el}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.H_ns}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Cov_o}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FHS_b}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FHS_s}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FI_el}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FI_ns}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FL_el}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FL_ns}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fk_el}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fk_ns}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Hk_ns}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FHS_el}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FHS_ns}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.WF_Sav}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Prod_BG}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.WRF_For}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FI_total}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FL_total}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.FTno_FDR}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Fk_total}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.Spotting}
                  </TableCell>
                  <TableCell className={`${classes.th} ${classes.smPad}`} align="right">
                    {row.WF_Heath}
                  </TableCell>
                </TableRow>
              ))
            : undefined}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ModelTable;
