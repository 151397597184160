import React, { useEffect, useState, FunctionComponent } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, TextField, Button } from '@material-ui/core';

import { useAppDispatch } from 'hooks';

import { LocationManager } from 'models';

import { ToastActions } from 'state/toast';
import { LocationActions } from 'state/location';

import { MapCommand, AddMoveableMarker } from 'components/map/MapCommands';
import PrimaryButton from 'components/PrimaryButton';
import { ToastType } from 'models/toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.common.black,
    },
    title: {
      marginTop: 25,
      fontSize: 40,
      paddingBottom: 8,
      borderBottom: '2px solid black',
      marginBottom: 25,
      position: 'relative',
    },
    subTitle: {
      marginTop: 25,
      fontSize: 20,
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: '1px solid black',
      color: theme.palette.grey[700],
      position: 'relative',
    },
    input: {
      marginBottom: 12,
    },
    spaced: {
      margin: theme.spacing(1),
    },
  }),
);

const NewLocationComponent: FunctionComponent<{
  onCancel: () => void;
  mapDispatch?: (command: MapCommand) => void;
}> = ({ onCancel, mapDispatch }) => {
  const [nameValue, setName] = useState('');
  const [lngValue, setLng] = useState('');
  const [latValue, setLat] = useState('');
  const [commandValue, setCommand] = useState<AddMoveableMarker>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const mapCommand = new AddMoveableMarker((coords) => {
      const [lng, lat] = coords.map((x) => x.toString());
      setLng(lng);
      setLat(lat);
    });

    setCommand(mapCommand);

    if (mapDispatch) mapDispatch(mapCommand);
    return () => {
      if (mapCommand.remove) mapCommand.remove();
    };
  }, [mapDispatch]);

  const setCoords = (coords: string[]) => {
    commandValue?.updateCoords(coords.map((x) => parseFloat(x)));
    const [lng, lat] = coords;
    if (lngValue !== lng) setLng(lng);
    if (latValue !== lat) setLat(lat);
  };

  const handleCreate = async () => {
    const location: LocationManager.Location.LocationAdminAPI.Location = {
      location_id: 0,
      status: '',
      location_name: nameValue,
      geometry: {
        type: '',
        coordinates: [lngValue, latValue].map((x) => parseFloat(x)),
      },
    };

    try {
      await dispatch(LocationActions.addLocation({ location }))
        .then(() => {
          dispatch(
            ToastActions.showToast({
              message: `${nameValue} created`,
              type: ToastType.success,
            }),
          );
        })
        .then(() => dispatch(LocationActions.getLocations()))
        .then(onCancel);
    } catch (e: any) {
      await dispatch(
        ToastActions.showToast({
          message: e?.data?.detail || `Error encountered adding new location`,
          type: ToastType.error,
        }),
      );
      onCancel();
    }
  };

  const classes = useStyles();

  return (
    <form noValidate autoComplete="off">
      <div onClick={onCancel} className={classes.backLink} onKeyPress={onCancel} tabIndex={0} role="button">
        <Typography>&lt; Back</Typography>
      </div>

      <Typography className={classes.title}>New Location</Typography>

      <TextField
        className={classes.input}
        variant="outlined"
        fullWidth
        label="Name"
        value={nameValue}
        onChange={(e) => setName(e.target.value)}
      />

      <Typography className={classes.subTitle}>Coordinates</Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        fullWidth
        label="Latitude"
        value={latValue}
        onChange={(e) => setCoords([lngValue, e.target.value])}
      />
      <TextField
        className={classes.input}
        variant="outlined"
        fullWidth
        label="Longitude"
        value={lngValue}
        onChange={(e) => setCoords([e.target.value, latValue])}
      />

      <PrimaryButton
        className={classes.spaced}
        variant="contained"
        color="primary"
        onClick={handleCreate}
        disabled={!nameValue}
      >
        Create
      </PrimaryButton>
      <Button className={classes.spaced} variant="contained" onClick={() => onCancel()}>
        Cancel
      </Button>
    </form>
  );
};

export default NewLocationComponent;
