/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { MobileView, BrowserView } from 'react-device-detect';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Button, CircularProgress } from '@material-ui/core';
import { Sort } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ValidatorActions } from 'state/validator';
import { LocationListItem, ErrorSegment, EditFieldDialog, ViewUtils } from 'components';
import { LocationManager } from 'models';
import { getProperty } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
    filterButton: {
      width: '200px',
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
    },
    filterButtonSelected: {
      width: '200px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
    },
    topSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'stretch',
    },
  }),
);

const sortValues: {
  field: (string | number)[];
  desc: boolean;
  label?: string;
  groupFields?: (string | number)[][];
  groupBy?: boolean;
  groupText?: string;
}[] = [
  {
    field: ['age'],
    desc: true,
  },
  {
    field: ['observations', 0, 'observer', 'userName'],
    label: 'Observer',
    desc: false,
    groupFields: [
      ['observations', 0, 'observer', 'firstName'],
      ['observations', 0, 'observer', 'familyName'],
    ],
    groupBy: true,
    groupText: 'Observed by ',
  },
  {
    field: ['name'],
    desc: false,
  },
];

const rotateSort = (value: number) => (value + 1) % sortValues.length;

function FSEValidatorDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const { validator, auth } = useAppSelector((state) => state);
  const { locations } = validator;
  const dispatch = useAppDispatch();

  const [showAll, setShowAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSettings, setModalSettings] = useState<ViewUtils.EditFieldDialogParams | null>(null);

  const [sortByIdx, setSortByIdx] = React.useState(0);
  const sortBy = sortValues[rotateSort(sortByIdx)];

  const sortFn = (a: LocationManager.Location, b: LocationManager.Location) => {
    const fieldA = getProperty(sortBy.field, a);
    const fieldB = getProperty(sortBy.field, b);
    if (fieldA == null && fieldB == null) return 0;
    if (sortBy.desc) {
      if (fieldA == null) return -1;
      if (fieldB == null) return 1;
      return fieldA < fieldB ? 1 : -1;
    }
    if (fieldA == null) return 1;
    if (fieldB == null) return -1;
    return fieldA > fieldB ? 1 : -1;
  };

  const filterFn = (loc: LocationManager.Location) => {
    if (showAll)
      return !(
        (loc.observations[0] && loc.observations[0].validated && !loc.observations[0].validatedToday) ||
        !loc.observations[0]
      );
    return loc.observations[0] && !loc.observations[0].validated && loc.observations[0].submitted;
  };

  const openModal = (option: ViewUtils.ClickableFields, location: LocationManager.Location) => {
    ViewUtils.openEditFieldDialog({ option, location, setModalSettings, setModalOpen });
  };

  let prevField = '';
  const locationList =
    locations.object &&
    locations.object
      .slice()
      .filter(filterFn)
      .sort(sortFn)
      .flatMap((location) => {
        const { id } = location;
        const field = getProperty(sortBy.field, location);
        const jsx = [];
        let groupLabel = `${id}`;
        if (sortBy.groupBy && sortBy.groupFields && field !== prevField) {
          groupLabel = sortBy.groupFields.map((x) => getProperty(x, location)).join(' ');
          jsx.push(
            <Typography key={`title-${field}`} variant="h6">
              {sortBy.groupText || ''} {groupLabel || field || 'no one'}
            </Typography>,
          );
          prevField = field;
        }

        jsx.push(
          <LocationListItem
            key={`obs-${id}`}
            location={location}
            display="inline-block"
            style={{}}
            onAccept={() => {
              if (location.observations[0])
                dispatch(ValidatorActions.validateObservation({ location, id: location.observations[0].id })).then(() =>
                  dispatch(ValidatorActions.getLocations()),
                );
            }}
            onCuringClick={() => openModal('curing', { ...location })}
            onFuelLoadClick={() => openModal('fuelLoad', { ...location })}
            onFuelConditionClick={() => openModal('fuelCondition', { ...location })}
          />,
        );
        return (
          <div key={groupLabel} id="allValidatorLocations">
            {jsx}
          </div>
        );
      });

  useEffect(() => {
    if (auth.status === 'finished') dispatch(ValidatorActions.getLocations());
  }, [dispatch, auth.status]);

  useEffect(() => {
    // will update locations every 120 seconds
    const interval = setInterval(() => dispatch(ValidatorActions.getLocations()), 30000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <BrowserView>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.title}>
              <span style={{ color: theme.palette.common.neutralDark }}>Validator /</span> Observations
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container className={classes.menu}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div className={classes.topSection}>
              <div className={classes.filterContainer}>
                <Button
                  id="awaitingValidation"
                  variant={showAll ? 'text' : 'contained'}
                  onClick={() => setShowAll(false)}
                  className={showAll ? classes.filterButton : classes.filterButtonSelected}
                >
                  Awaiting Validation
                </Button>
                <Button
                  id="validatorAllLocations"
                  variant={showAll ? 'contained' : 'text'}
                  onClick={() => setShowAll(true)}
                  className={showAll ? classes.filterButtonSelected : classes.filterButton}
                >
                  All Locations
                </Button>
              </div>
              <div>
                <Button variant="outlined" onClick={() => setSortByIdx(rotateSort(sortByIdx))}>
                  <Sort />
                  Group by {sortBy.label || sortBy.field}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.menu}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            {locations.status === 'loading' && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress aria-valuetext="loading" />
              </div>
            )}
            {locations.status === 'error' && locations.error && (
              <ErrorSegment
                error={locations.error}
                text="Failed To load Locations"
                onRetry={() => dispatch(ValidatorActions.getLocations())}
              />
            )}
            {locationList}
          </Grid>
        </Grid>
      </BrowserView>

      <MobileView>
        <div style={{ padding: theme.spacing(2) }}>
          <Typography variant="h4" className={classes.title}>
            <span style={{ color: theme.palette.common.neutralDark }}>Validator /</span> Observations
          </Typography>
          <div style={{ display: 'grid', gridGap: theme.spacing(1), marginBottom: theme.spacing(2) }}>
            <div className={classes.filterContainer}>
              <Button
                id="awaitingValidation"
                variant={showAll ? 'text' : 'contained'}
                onClick={() => setShowAll(false)}
                className={showAll ? classes.filterButton : classes.filterButtonSelected}
                style={{ width: 'auto' }}
              >
                Awaiting Validation
              </Button>
              <Button
                id="validatorAllLocations"
                variant={showAll ? 'contained' : 'text'}
                onClick={() => setShowAll(true)}
                className={showAll ? classes.filterButtonSelected : classes.filterButton}
                style={{ width: 'auto' }}
              >
                All Locations
              </Button>
            </div>
            <div>
              <Button variant="outlined" onClick={() => setSortByIdx(rotateSort(sortByIdx))}>
                <Sort />
                Group by {sortBy.label || sortBy.field}
              </Button>
            </div>
          </div>
          {locations.status === 'loading' && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress aria-valuetext="loading" />
            </div>
          )}
          {locations.status === 'error' && locations.error && (
            <ErrorSegment
              error={locations.error}
              text="Failed To load Locations"
              onRetry={() => dispatch(ValidatorActions.getLocations())}
            />
          )}
          {locationList}
        </div>
      </MobileView>
      {modalSettings && <EditFieldDialog open={modalOpen} onClose={() => setModalOpen(false)} {...modalSettings} />}
    </>
  );
}

export default FSEValidatorDashboard;
