import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

interface CustomDateTimePickerProps {
  onChange?: (date: Date) => void;
  style?: React.CSSProperties;
  className?: string;
  initialDate?: Date;
  text: string;
  minDate?: Date;
  minDateMessage?: string;
}

const CustomDateTimePicker: React.FunctionComponent<CustomDateTimePickerProps> = ({
  onChange,
  style,
  className,
  initialDate,
  text,
  minDate,
  minDateMessage,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const initDate = initialDate || new Date();
  const [selectedDate, setSelectedDate] = useState(initDate.toISOString());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);

    if (onChange) onChange(date.toDate());
  };

  return (
    <div>
      <Typography variant="subtitle1" className={className} style={style} onClick={() => setIsOpen(true)}>
        {text}
      </Typography>

      <DateTimePicker
        style={{ display: 'none' }}
        variant="dialog"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label=""
        format="d MMM yyyy"
        ampm={false}
        value={selectedDate}
        minDate={minDate}
        minDateMessage={minDateMessage}
        onChange={handleDateChange}
        disableFuture
        showTodayButton
      />
    </div>
  );
};

CustomDateTimePicker.displayName = 'CustomDateTimePicker';
export default CustomDateTimePicker;
