/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { TextareaAutosize, Paper, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      width: '100%',
      border: '0px',
      '&:focus': {
        outlineWidth: '0px',
      },
      resize: 'none',
    },
    paper: {
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
    },
  }),
);

interface PrettierTextAreaProps {
  paperStyle?: React.CSSProperties;
  paperClassName?: string;
}

const PrettierTextArea: React.FunctionComponent<PrettierTextAreaProps & Parameters<typeof TextareaAutosize>[0]> = (
  props,
) => {
  const classes = useStyles();
  const { paperStyle, paperClassName, className, ...restProps } = props;
  return (
    <Paper className={`${paperClassName} ${classes.paper}`} style={paperStyle}>
      <TextareaAutosize className={`${className ?? ''} ${classes.textarea}`} {...restProps} />
    </Paper>
  );
};

PrettierTextArea.displayName = 'PrettierTextArea';
export default PrettierTextArea;
