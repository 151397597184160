/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
// This is disabled so we can use the recommended approach to using react-dropzone
import React, { useEffect, useState } from 'react';

import { useParams, Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Card, Theme, Typography, Button, Link, CircularProgress } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import config from 'config';
import { LoadableImage } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EditorActions } from 'state/editor';
import { LayerActions } from 'state/layers';
import { getUserState } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      marginTop: 200,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: 225,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    downloadCard: {
      height: 500,
      textAlign: 'center',
      verticalAlign: 'middle',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundColor: theme.palette.common.neutralXXLight,
    },
    uploadCard: {
      height: 500,
      background: theme.palette.common.neutralXXLight,
      textAlign: 'center',
      display: 'grid',
      placeItems: 'center',
    },
  }),
);

const UploadDownloadAuthoriser = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { auth, editor } = useAppSelector((state) => state);

  const [downloadImgSrc, setDownloadImgSrc] = useState<string>('');

  const { maplayers, tiffDownload } = editor;
  const { layerId } = useParams<{ layerId: string }>();
  const layer = maplayers.object && maplayers.object.find((l) => l.id === +(layerId ?? ''));

  const userJurisdiction = auth.object ? getUserState(auth.object) : null;

  const geoserverLayerId = layer ? `fse-${userJurisdiction}:${layer.type}-candidate-${userJurisdiction}` : null;

  useEffect(() => {
    if (!downloadImgSrc && auth.status === 'finished' && geoserverLayerId) {
      const jdBounds = config.jurisdictionBounds[userJurisdiction ?? 'aus'];
      const height = 400;
      const width = Math.floor(height * ((jdBounds.maxLong - jdBounds.minLong) / (jdBounds.maxLat - jdBounds.minLat)));

      dispatch(
        LayerActions.queryWMSImage({
          options: {
            layers: geoserverLayerId,
            bbox: `${jdBounds.minLong},${jdBounds.minLat},${jdBounds.maxLong},${jdBounds.maxLat}`,
            height,
            width,
            // @ts-ignore
            rnd: `${Math.floor(Math.random() * 1000)}`,
          },
        }),
      )
        .then((dataURL) => setDownloadImgSrc(dataURL))
        .catch(() => setDownloadImgSrc('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='));
    }
  }, [auth.status, maplayers.status]);

  const handleDownload = async () => {
    if (layer) {
      const blob = await dispatch(EditorActions.downloadTiff({ layer }));
      const url = window.URL.createObjectURL(blob);
      const created = new Date(layer.created);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${layer.type}-${created.getFullYear()}${`${created.getMonth() + 1}`.padStart(
        2,
        '0',
      )}${`${created.getDate()}`.padStart(2, '0')}.tif`;

      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  useEffect(() => {
    if (maplayers.status === 'idle' && auth.status === 'finished') dispatch(EditorActions.getLayers());
  }, [dispatch, maplayers.status, auth.status]);

  return (
    <>
      <div style={{ paddingLeft: 25, marginTop: 25 }}>
        <Link
          component={RouterLink}
          to={`/authoriser/layer/${layerId}`}
          style={{
            display: window.location.pathname === `/authoriser/layer/${layerId}` ? 'none' : 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ display: 'flex', alignItems: 'center', color: theme.palette.common.neutralDark }}
          >
            <ChevronLeft /> Back
          </Typography>
        </Link>
        {maplayers.status === 'finished' && (
          <Typography variant="h4">{layer ? `Download ${layer.name} map` : 'Invalid map'}</Typography>
        )}
      </div>

      <div style={{ padding: 25, display: 'grid', placeItems: 'center' }}>
        <Card elevation={3} className={classes.downloadCard}>
          <LoadableImage style={{ height: 500, width: 500 }} src={downloadImgSrc} />
        </Card>
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <Button
            className={classes.primaryButton}
            disabled={tiffDownload.status === 'loading'}
            onClick={handleDownload}
            style={{ margin: theme.spacing(2), width: '80%', color: 'white' }}
          >
            Download .tif file
            {tiffDownload.status === 'loading' && (
              <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UploadDownloadAuthoriser;
