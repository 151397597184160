import { makeAPICall } from './api';
import { formatQueryString } from 'utils';

export declare namespace User {
  export interface Role {
    role_id: number;
    role_name: string;
  }

  export interface IdentityProvider {
    provider_id: number;
    provider_name: string;
    proivder_type: string;
    domain_names: string[];
  }

  export interface User {
    user_id: number;
    user_name: string;
    user_email: string;
    cognito_status: string;
    roles: Role[];
    family_name: string;
    given_name: string;
    receive_observer_emails: boolean;
    receive_validator_emails: boolean;
    receive_editor_emails: boolean;
    receive_authoriser_emails: boolean;
    identity_provider: IdentityProvider;
    identity_provider_id: number;
  }

  export interface UserList {
    users: User[];
    page: number;
    size: number;
    total: number;
  }

  interface APIModelRoot {
    data: User[];
    page: number;
    page_size: number;
    count: number;
    size: number;
    total: number;
  }

  export interface UserNewRequest {
    user_id?: number;
    user_name: string;
    user_email: string;
    given_name: string;
    family_name: string;
    groups: string[];
  }

  export interface UserUpdateRequest {
    user_id?: number;
    user_name: string;
    user_email: string;
    given_name: string;
    family_name: string;
    cognito_action: string;
    groups: string[];
    auth_provider_is_okta: boolean;
  }
}

export interface UserListQuery {
  jurisdiction: string;
  page?: number;
  size?: number;
  roles?: string[];
  search?: string;
}

export const getUserListForJurisdiction = makeAPICall<User.UserList, UserListQuery, User.APIModelRoot>(
  (payload) => {
    const query = formatQueryString({
      page: payload.page || 0,
      page_size: payload.size || 500,
      roles: payload.roles,
      search: payload.search,
    });
    return {
      ext: `/users/jurisdictions/${payload.jurisdiction}?${query}`,
    };
  },
  (x) => {
    return { users: x.data, total: x.total, size: x.page_size, page: x.page, count: x.count };
  },
);

export const addNewUser = makeAPICall<null, { user: User.UserNewRequest }>(
  (payload) => {
    const { user } = payload;
    const data = new FormData();

    data.append('user_name', user.user_name);
    data.append('user_email', user.user_email);
    data.append('given_name', user.given_name);
    data.append('family_name', user.family_name);
    user.groups.forEach((group) => {
      data.append('groups', group);
    });

    return {
      ext: `/users`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const deleteUser = makeAPICall<null, { username: string }>(
  (payload) => {
    const { username } = payload;
    const data = new FormData();

    data.append('user_name', username);

    return {
      ext: `/users`,
      contentType: null,
      requestData: {
        method: 'DELETE',
        body: data,
      },
    };
  },
  () => null,
);

export const updateUser = makeAPICall<null, { user: User.UserUpdateRequest }>(
  (payload) => {
    const { user } = payload;
    const data = new FormData();
    if (!user.auth_provider_is_okta) {
      data.append('given_name', user.given_name);
      data.append('family_name', user.family_name);
    }

    data.append('cognito_action', user.cognito_action);
    user.groups.forEach((group) => {
      data.append('groups', group);
    });

    return {
      ext: `/users/${user.user_id}`,
      contentType: null,
      requestData: {
        method: 'PUT',
        body: data,
      },
    };
  },
  () => null,
);

export const getEditUser = makeAPICall<User.User, { user_id: number }, User.APIModelRoot>(
  (payload) => ({
    ext: `/users/${payload.user_id}`,
  }),
  (x) => x.data[0],
);
