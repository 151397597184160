import { customColors } from 'theme';

import config from 'config';
import { ErrorResp } from './api';

import * as AuthManager from './auth';

import * as AuthUserManager from './authuser';
import * as LocationManager from './location';
import * as MapLayerManager from './maplayer';
import * as FireAreaManager from './firearea';
import * as WmsManager from './wms';
import * as UserManager from './user';
import * as AssignedLocationManager from './assignedlocation';
import * as ToastManager from './toast';
import * as LayerManager from './layer';
import * as IncidentManager from './incidents';
import * as MeteogramManager from './meteograms';
import * as FuelTypeModelsManager from './fueltypemodels';
import * as DangerRatingsManager from './dangerratings';
import * as DangerDefinitionsManager from './dangerdefinitions';
import * as HelpManager from './help';
import * as AnalyticsManager from './analytics';
import * as DownloadManager from './download';
import * as ArchiveManager from './archive';
import * as AuthDomainsManager from './authdomains';
import * as JurisdictionSummaryManager from './jurisdictionsummary';

export {
  AuthManager,
  AuthUserManager,
  LocationManager,
  MapLayerManager,
  WmsManager,
  UserManager,
  AssignedLocationManager,
  FireAreaManager,
  ToastManager,
  LayerManager,
  IncidentManager,
  MeteogramManager,
  FuelTypeModelsManager,
  DangerRatingsManager,
  DangerDefinitionsManager,
  HelpManager,
  AnalyticsManager,
  DownloadManager,
  ArchiveManager,
  AuthDomainsManager,
  JurisdictionSummaryManager,
};

export enum JurisdictionsEnum {
  'act',
  'nsw',
  'nt',
  'qld',
  'sa',
  'tas',
  'vic',
  'wa',
}

export const jurisdictions = Object.keys(JurisdictionsEnum).filter((x) => Number.isNaN(+x)) as Common.Jurisdictions[];

export declare namespace Common {
  export interface User {
    id: number;
    userName: string;
    email: string;
    firstName: string | null;
    familyName: string | null;
  }

  export interface Note {
    id?: number;
    note: string;
    modified?: string;
    user?: User;
  }

  export interface Bounds {
    maxLat: number;
    maxLong: number;
    minLat: number;
    minLong: number;
  }

  export type Jurisdictions = keyof typeof JurisdictionsEnum;
}

/**
 * Is a workaround to allow strings with autocomplete info (dev use only)
 * See https://github.com/microsoft/TypeScript/issues/29729
 *
 * Usage:
 * let x: LiteralUnion<"hello" | "world">;
 * x = "hey"; // no error but autocompletes with the above
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type LiteralUnion<T extends U, U = string> = T | (U & {});

export interface Loadable<T> {
  status: 'idle' | 'loading' | 'finished' | 'error';
  error?: ErrorResp;
  object?: T | null;
}

export * from './api';

export interface CarouselSlides {
  value: number;
  label: string;
  title: string;
  subtitle?: string;
  style: React.CSSProperties;
  imgPath: string;
  helpId?: string;
}

export interface ProgressSteps {
  title: string;
  subtitle: string;
  heading: string;
  label: string;
  image?: string;
  optional?: boolean;
  content: JSX.Element;
  completed?: boolean;
  helpId: string;
}

/**
 * Similar to callAPI however more specifically suited for interacting with a Web Map Service (WMS)
 * More details here: https://docs.geoserver.org/stable/en/user/services/wms/reference.html
 * example url (missing '&' between query params):
 *
 *  URL Below:
 *  http://afdrs-alb-385932098.ap-southeast-2.elb.amazonaws.com/geoserver/fse/wms?
 *      SERVICE=WMS
 *      VERSION=1.1.1
 *      REQUEST=GetMap
 *      FORMAT=image/png
 *      TRANSPARENT=true
 *      LAYERS=fse:jurisdiction
 *      exceptions=application/vnd.ogc.se_inimage
 *      SRS=EPSG:4326
 *      STYLES=
 *      WIDTH=768
 *      HEIGHT=654
 *      BBOX=102.3046875,-47.900390625,169.8046875,9.580078125
 */

const convertParameters = (request: any) =>
  Object.keys(request)
    .map((key) => `${key}=${encodeURIComponent((request as any)[key])}`)
    .join('&');

export async function callWMS<T>(request: WmsManager.WMS.Query) {
  const parameters = convertParameters(request);
  const wmsPath = `${config.geoserver_url}/wms`;

  const response = await fetch(`${wmsPath}?${parameters}`);
  return (await response.json()) as T;
}

export const fuelConditionCarouselSlides: CarouselSlides[] = [
  {
    value: 1,
    title: 'Eaten out',
    subtitle: '(<10cm)',
    label: 'Generally it is less than 10cm in height',
    style: {
      backgroundColor: customColors.white,
      borderColor: customColors.green,
    },
    imgPath: '/fuelload/eaten-out.png',
  },
  {
    value: 2,
    title: 'Grazed',
    subtitle: '(10-50cm)',
    label: 'Generally it is more than 10cm and less than 50cm in height',
    style: {
      backgroundColor: customColors.white,
      borderColor: customColors.green,
    },
    imgPath: '/fuelload/grazed.png',
  },
  {
    value: 3,
    title: 'Natural',
    subtitle: '(>50cm)',
    label: 'Generally it is more than 50cm in height',
    style: {
      backgroundColor: customColors.white,
      borderColor: customColors.green,
    },
    imgPath: '/fuelload/natural.png',
  },
];

export const curingCarouselSlides: CarouselSlides[] = [
  {
    value: 0,
    title: '0%',
    label: 'From early growth to start of seed head development',
    style: {
      backgroundColor: '#EBF9E8',
      borderColor: customColors.green,
    },
    imgPath: '/curing/0.png',
  },
  {
    value: 10,
    title: '10%',
    label: 'Seed heads formed and flowering',
    style: {
      backgroundColor: '#EBF9E8',
      borderColor: customColors.green,
    },
    imgPath: '/curing/10.png',
  },
  {
    value: 20,
    title: '20%',
    label: 'Seed heads maturing and changing colour',
    style: {
      backgroundColor: '#EBF9E8',
      borderColor: customColors.green,
    },
    imgPath: '/curing/20.png',
  },
  {
    value: 30,
    title: '30%',
    label: 'Yellow becoming apparent in leaves',
    style: {
      backgroundColor: '#EBF9E8',
      borderColor: customColors.green,
    },
    imgPath: '/curing/30.png',
  },
  {
    value: 40,
    title: '40%',
    label: 'Slightly more than half green',
    style: {
      backgroundColor: '#FDF3D3',
      borderColor: '#FADF8A',
    },
    imgPath: '/curing/40.png',
  },
  {
    value: 50,
    title: '50%',
    label: 'Half green and half yellow, half of stems have dropped their seeds',
    style: {
      backgroundColor: '#FDF3D3',
      borderColor: '#FADF8A',
    },
    imgPath: '/curing/50.png',
  },
  {
    value: 60,
    title: '60%',
    label: 'Slightly more than half yellow',
    style: {
      backgroundColor: '#FDF3D3',
      borderColor: '#FADF8A',
    },
    imgPath: '/curing/60.png',
  },
  {
    value: 70,
    title: '70%',
    label: 'Yellow dominating landscape, some green visible',
    style: {
      backgroundColor: '#F8E5BF',
      borderColor: '#F0C97A',
    },
    imgPath: '/curing/70.png',
  },
  {
    value: 80,
    title: '80%',
    label: 'Lower third of stalk may be green',
    style: {
      backgroundColor: '#F8E5BF',
      borderColor: '#F0C97A',
    },
    imgPath: '/curing/80.png',
  },
  {
    value: 90,
    title: '90%',
    label: 'Very little green in landscape, all seeds dropped',
    style: {
      backgroundColor: '#F8E5BF',
      borderColor: '#F0C97A',
    },
    imgPath: '/curing/90.png',
  },
  {
    value: 100,
    title: '100%',
    label: 'No green in landscape, stalks fully cured and break easily',
    style: {
      backgroundColor: '#F8E5BF',
      borderColor: '#F0C97A',
    },
    imgPath: '/curing/100.png',
  },
];
