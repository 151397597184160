import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Paper, Typography, Button, CircularProgress } from '@material-ui/core';

import { MapLayerManager } from 'models';
import { LoadableImage } from 'components';
import { toReadableAge, toDDMMYYYYFormat } from 'utils';
import ConditionallyRender from 'utils/ConditionallyRender';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 1050,
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    segmentContainer: {
      display: 'grid',
      padding: `0px ${theme.spacing(1)}px`,
      gridAutoFlow: 'column',
      gridTemplateColumns: '200px 250px 175px 200px auto',
      alignItems: 'start',
      justifyItems: 'start',
      width: '100%',
    },
    segment: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
    segmentEnd: {
      display: 'flex',
      padding: theme.spacing(2),
      alignSelf: 'center',
      justifySelf: 'end',
      flexDirection: 'row',
    },
    segmentTitle: {
      color: theme.palette.common.neutralDark,
    },
    segmentError: {
      color: 'red',
      fontWeight: 'bold',
    },
    segmentAge: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    segmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
    segmentValue: {
      minWidth: '32px',
    },
    img: {
      display: 'block',
      overflow: 'hidden',
      width: '100%',
    },
    imgPaper: {
      padding: 0,
      overflow: 'hidden',
    },
    button: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    viewDetailsButton: {
      color: theme.palette.common.grey,
      textDecoration: 'underline',
      marginRight: theme.spacing(1),
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      width: 'fit-content',
    },
  }),
);

const statusToStr: Map<MapLayerManager.MapLayer.Status, string> = new Map([
  ['authorised', 'Authorised:'],
  ['auto-candidate', 'Generated:'],
  ['error', 'Generated:'],
  ['pending-authorisation', 'Submitted:'],
  ['processing', 'Processing since:'],
  ['rejected', 'Submitted:'],
]);

export type LayerListItemProps = {
  display?: 'block' | 'inline-block';
  style?: React.CSSProperties;
  layer: MapLayerManager.MapLayer;
  prevAuthorisedLayer?: MapLayerManager.MapLayer;
  to?: string;
  disableButton?: boolean;
  hasObservations?: boolean;
};

const MapLayerListItem: React.FunctionComponent<LayerListItemProps> = ({
  layer,
  style,
  display,
  prevAuthorisedLayer,
  to,
  disableButton,
  hasObservations = true,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let age;

  const {
    id,
    name,
    status,
    approvalTime,
    created,
    modified,
    submissionTime,
    thumbnail,
    newObservationCount,
    totalObservationCount,
  } = layer;

  if (status === 'authorised') {
    age = Date.now() - (approvalTime ?? 0);
  } else if (status === 'pending-authorisation' || (status === 'rejected' && submissionTime !== null)) {
    age = Date.now() - (submissionTime ?? 0);
  } else if (status === 'processing' && submissionTime !== null) {
    // Assume it is processing for authorisation
    age = Date.now() - modified;
  } else {
    age = Date.now() - created;
  }

  const [hover, setHover] = useState(false);

  const disabled = status === 'processing' || status === 'error' || status === 'authorised';

  let errorText: string | null = null;
  if (status === 'rejected') errorText = 'Rejected';
  if (status === 'error') errorText = 'Error';

  let bgColor = theme.palette.common.white;
  let textStyle = {};
  if (hover) bgColor = theme.palette.common.neutralXXLight;
  if (disabled) {
    bgColor = theme.palette.common.neutralXLight;
    textStyle = { color: theme.palette.common.black };
  }

  return (
    <Paper
      className={classes.root}
      variant="outlined"
      style={{
        backgroundColor: bgColor,
        display: display || 'flex',
        ...style,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
    >
      <div className={classes.segmentContainer}>
        <div className={classes.segment} style={{ width: '100%' }}>
          <Paper className={classes.imgPaper}>
            <LoadableImage alt={`icon for ${name}`} aspectRatio={0.47} src={thumbnail} className={classes.img} />
          </Paper>
        </div>
        <div className={classes.segment} style={{ borderRight: `1px solid ${theme.palette.common.neutralXLight}` }}>
          <Typography
            variant="subtitle2"
            className={`${classes.segmentTitle} ${errorText ? classes.segmentError : ''}`}
            style={{ ...textStyle }}
          >
            {errorText || ''}&nbsp;
          </Typography>
          <div className={classes.segmentMiddle}>
            <Typography variant="h6" className={classes.segmentValue} style={{ fontWeight: 'bold' }}>
              {name}
            </Typography>
          </div>
          <Typography variant="subtitle2" className={classes.segmentAge} style={{ ...textStyle }}>
            {statusToStr.get(status)} {toReadableAge(age)}
          </Typography>
        </div>

        <div className={classes.segment}>
          <Typography variant="subtitle2" className={classes.segmentTitle} style={{ ...textStyle }}>
            Field Observation
          </Typography>
          <div className={classes.segmentMiddle}>
            <Typography data-testid="newObservationCount" variant="h5" className={classes.segmentValue}>
              {/* TODO Refactor This is not the best way of doing this */}
              {(hasObservations && `${newObservationCount} new`) || 'N/A'}
            </Typography>
          </div>
          <ConditionallyRender when={hasObservations}>
            <Typography variant="subtitle2" className={classes.segmentAge} style={{ ...textStyle }}>
              {((newObservationCount / totalObservationCount) * 100).toFixed(2)}% of {totalObservationCount} total
            </Typography>
          </ConditionallyRender>
        </div>

        <div className={classes.segment}>
          <Typography variant="subtitle2" className={classes.segmentTitle} style={{ ...textStyle }}>
            Last authorised
          </Typography>
          <div className={classes.segmentMiddle}>
            <Typography data-testid="lastAuthorisedDate" variant="h5" className={classes.segmentValue}>
              {prevAuthorisedLayer && prevAuthorisedLayer.modified
                ? toDDMMYYYYFormat(new Date(prevAuthorisedLayer.modified))
                : 'N/A'}
            </Typography>
          </div>
          <Typography
            data-testid="lastAuthorisedTime"
            variant="subtitle2"
            className={classes.segmentAge}
            style={{ ...textStyle }}
          >
            {prevAuthorisedLayer && prevAuthorisedLayer.modified
              ? toReadableAge(Date.now() - prevAuthorisedLayer.modified)
              : ''}
          </Typography>
        </div>

        <div className={classes.segmentEnd}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={status === 'processing' || disableButton}
            component={RouterLink}
            to={to ?? `/editor/layer/${id}`}
          >
            {status === 'processing' ? 'Processing' : 'Review and Process'}
            {status === 'processing' && (
              <CircularProgress style={{ marginLeft: theme.spacing(2) }} size={20} aria-valuetext="loading" />
            )}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

MapLayerListItem.displayName = 'MapLayerListItem';
export default MapLayerListItem;
