import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card, CardContent, CardActions, Divider, Link } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

import { HelpManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 220,
      minHeight: 200,
      display: 'grid',
      gridTemplateRows: '1fr auto auto',
    },
    topic: {
      fontSize: 14,
      color: theme.palette.common.neutralDark,
    },
    title: {
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    summary: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    link: {
      color: theme.palette.text.link,
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
      fontSize: 'larger',
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.link,
    },
  }),
);

interface HelpItemCardProps {
  item: HelpManager.Item;
}

const HelpItemCard: React.FunctionComponent<HelpItemCardProps> = ({ item }) => {
  const classes = useStyles();
  const { id, title, topic, summary, externalLink, columnSpan } = item;

  const topicName = topic[0].toUpperCase() + topic.substring(1).toLowerCase();

  const to = `/help/${id}`;

  return (
    <Card className={classes.root} style={columnSpan ? { gridColumnEnd: `span ${columnSpan}` } : {}}>
      <CardContent>
        <Typography className={classes.topic} gutterBottom>
          {topicName}
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {columnSpan && columnSpan > 1 && (
          <Typography variant="body1" className={classes.summary}>
            {summary}
          </Typography>
        )}
      </CardContent>
      <Divider />
      {externalLink ? (
        <Link href={externalLink} target="_blank">
          <CardActions>
            <Typography className={classes.link}>Go to website</Typography>
            <ArrowForward className={classes.icon} style={{ marginLeft: 'auto' }} />
          </CardActions>
        </Link>
      ) : (
        <Link component={RouterLink} to={to}>
          <CardActions>
            <Typography className={classes.link}>Read article</Typography>
            <ArrowForward className={classes.icon} style={{ marginLeft: 'auto' }} />
          </CardActions>
        </Link>
      )}
    </Card>
  );
};

HelpItemCard.displayName = 'HelpItemCard';
export default HelpItemCard;
