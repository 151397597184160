import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Button } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { ErrorResp } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  }),
);

interface ErrorSegmentProps {
  error: ErrorResp;
  style?: React.CSSProperties;
  onRetry?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text?: string;
  hideRetry?: boolean;
}

const ErrorSegment: React.FunctionComponent<ErrorSegmentProps> = ({ error, style, onRetry, text, hideRetry }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...style,
      }}
    >
      <Typography color="error" align="center" variant="h5" className={classes.errorText}>
        <Error />
        &nbsp;{text || 'An Error Occurred'}
      </Typography>
      <Typography color="error" align="center" variant="subtitle2" className={classes.errorText}>
        {error.code}: {error.error}
      </Typography>
      {!hideRetry && (
        <Button variant="contained" style={{ width: '90%' }} onClick={onRetry}>
          Retry
        </Button>
      )}
    </div>
  );
};

ErrorSegment.displayName = 'ErrorSegment';
export default ErrorSegment;
