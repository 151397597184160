/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card } from '@material-ui/core';
import { PrettierTextArea } from 'components';
import { MapLayerManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    primaryButton: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    textArea: {
      width: '100%',
      height: 300,
      border: 'none',
      padding: theme.spacing(2),
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      color: '#757575',
      fontSize: '16px',
      resize: 'none',
      outline: 0,
    },
    label: {
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(2),
    },
  }),
);

interface IProps {
  layer?: MapLayerManager.MapLayer | null;
  onNoteChange?: (note: string) => void;
}

const WorkflowRequestEdits: React.FunctionComponent<IProps> = ({ layer, onNoteChange }) => {
  const classes = useStyles();

  const [noteValue, setNote] = useState('');

  const handleNoteUpdate = (note: string) => {
    setNote(note);
    if (onNoteChange) onNoteChange(note);
  };

  if (!layer) return <>No Layer</>;

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>Add Note</Typography>
      <Card>
        <PrettierTextArea
          className={classes.textArea}
          placeholder="Type note..."
          onChange={(e) => handleNoteUpdate(e.target.value)}
          minRows={10}
          value={noteValue}
        />
      </Card>
    </div>
  );
};

export default WorkflowRequestEdits;
