/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';

import { handleLogin } from 'state';
import { AnalyticsActions } from 'state/analytics';
import { refreshToken } from 'state/auth';

import {
  HelpMenu,
  HelpDetails,
  FSEMenuDesktop,
  FSEMenuMobile,
  FSELocationsMobile,
  FSEDataEntryMobile,
  FSEDataEntryDesktop,
  FSEValidatorDashboard,
  FSEValidatorDetails,
  FSELocationAdminDesktop,
  FSEEditorDashboard,
  FSEAuthoriserFuelTypeModelDashboard,
  FSEEditorFuelTypeModelDashboard,
  FSEEditorWorkFlow,
  FSEEditorFuelTypeModelWorkFlow,
  FSEAuthoriserDashboard,
  FSEAuthoriserWorkFlow,
  FSEAuthoriserFuelTypeModelWorkFlow,
  FSELocationsDesktop,
  AdminEditUser,
  AdminListUsers,
  AdminNewUser,
  SelfEditMyDetails,
  HelpAddEdit,
  DownloadFile,
  FSEExport,
} from 'pages';
import { NavBar, Error404 } from 'components';
import UploadDownload from 'components/editor/UploadDownload';
import UploadDownloadAuthoriser from 'components/authoriser/UploadDownloadAuthoriser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      width: '100%',
    },
    root: {
      height: '100vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    fixScroll: {
      overflow: 'hidden',
    },
    error404: {
      margin: theme.spacing(2),
    },
  }),
);

function FuelStateEditor() {
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);
  const [loginAttempted, setLoginAttempted] = useState(auth?.object?.creds != null);

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const naviagte = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth.status === 'finished') dispatch(AnalyticsActions.postPageView(location.pathname));
  }, [location, auth.status]);

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `AFDRS Fuel State Editor`;

    handleLogin(dispatch, auth, user, naviagte);
    setLoginAttempted(true);

    const interval = setInterval(() => {
      if (auth.object) dispatch(refreshToken({ auth: auth.object }));
    }, 18 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, auth, user, naviagte]);

  if (auth.status !== 'finished')
    return (
      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <CircularProgress aria-valuetext="loading" />
      </div>
    );

  return loginAttempted ? (
    <div className={classes.root}>
      <NavBar />
      <Routes>
        {/* Common/Help Pages */}
        <Route path="/download" element={<DownloadFile />} />
        <Route path="/help/edit/:helpItem?" element={<HelpAddEdit />} />
        <Route path="/help/:helpItem" element={<HelpDetails />} />
        <Route path="/help" element={<HelpMenu />} />
        <Route path="/export" element={<FSEExport />} />

        {/* Observer Pages */}
        <Route
          path="/observer/location/:locationId/*"
          element={
            <>
              <BrowserView>
                <FSEDataEntryDesktop />
              </BrowserView>
              <MobileView viewClassName={classes.fixScroll}>
                <FSEDataEntryMobile />
              </MobileView>
            </>
          }
        />
        <Route
          path="/observer/locations/*"
          element={
            <>
              <BrowserView>
                <FSELocationsDesktop />
              </BrowserView>
              <MobileView viewClassName={classes.fixScroll}>
                <FSELocationsMobile />
              </MobileView>
            </>
          }
        />
        {/* Validator Pages */}
        <Route
          path="/validator/location/:locationId/*"
          element={
            <div className={classes.fixScroll}>
              <FSEValidatorDetails />
            </div>
          }
        />
        <Route
          path="/validator/dashboard"
          element={
            <div>
              <FSEValidatorDashboard />
            </div>
          }
        />
        {/* Editor Pages */}
        <Route
          path="/editor/dashboard"
          element={
            <BrowserView>
              <FSEEditorDashboard />
            </BrowserView>
          }
        />

        <Route
          path="/editor/fueltypemodels/dashboard"
          element={
            <BrowserView>
              <FSEEditorFuelTypeModelDashboard />
            </BrowserView>
          }
        />

        <Route
          path="/editor/layer/:layerId/download"
          element={
            <BrowserView>
              <UploadDownload />
            </BrowserView>
          }
        />

        <Route
          path="/editor/layer/:layerId/*"
          element={
            <BrowserView>
              <FSEEditorWorkFlow />
            </BrowserView>
          }
        />

        <Route
          path="/editor/fueltypemodels/:modelStatus/*"
          element={
            <BrowserView>
              <FSEEditorFuelTypeModelWorkFlow />
            </BrowserView>
          }
        />
        <Route
          path="/editor/fueltypemodels"
          element={
            <BrowserView>
              <FSEEditorFuelTypeModelWorkFlow />
            </BrowserView>
          }
        />
        {/* Authoriser Pages */}
        <Route
          path="/authoriser/dashboard"
          element={
            <BrowserView>
              <FSEAuthoriserDashboard />
            </BrowserView>
          }
        />

        <Route
          path="/authoriser/fueltypemodels/dashboard"
          element={
            <BrowserView>
              <FSEAuthoriserFuelTypeModelDashboard />
            </BrowserView>
          }
        />

        <Route
          path="/authoriser/fueltypemodels/:modelStatus/*"
          element={
            <BrowserView>
              <FSEAuthoriserFuelTypeModelWorkFlow />
            </BrowserView>
          }
        />

        {/* <Route
          path="/authoriser/fueltypemodels"
          element={
            <BrowserView>
              <FSEAuthoriserFuelTypeModelWorkFlow />
            </BrowserView>
          }
        /> */}

        <Route
          path="/authoriser/layer/:layerId/download"
          element={
            <BrowserView>
              <UploadDownloadAuthoriser />
            </BrowserView>
          }
        />

        <Route
          path="/authoriser/layer/:layerId/*"
          element={
            <BrowserView>
              <FSEAuthoriserWorkFlow />
            </BrowserView>
          }
        />

        {/* Common FSE Pages */}
        <Route
          path="/"
          element={
            <>
              <BrowserView>
                <FSEMenuDesktop />
              </BrowserView>
              <MobileView viewClassName={classes.fixScroll}>
                <FSEMenuMobile />
              </MobileView>
            </>
          }
        />

        {/* Admin/User Manager Pages */}
        <Route
          path="/admin/locations"
          element={
            <div>
              <FSELocationAdminDesktop />
            </div>
          }
        />

        <Route
          path="/admin/users/new"
          element={
            <div>
              <AdminNewUser />
            </div>
          }
        />

        <Route
          path="/admin/users/:userId"
          element={
            <div>
              <AdminEditUser />
            </div>
          }
        />

        <Route path="/self/mydetails" element={<SelfEditMyDetails />} />

        <Route
          path="/admin/users"
          element={
            <div>
              <AdminListUsers />
            </div>
          }
        />
        {/* Catch-All Error 404 */}
        <Route
          element={
            <div className={classes.error404}>
              <Error404 />
            </div>
          }
        />
      </Routes>
    </div>
  ) : (
    <></>
  );
}

export default FuelStateEditor;
