/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Button, CircularProgress } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { AuthoriserActions } from 'state/authoriser';
import { ErrorSegment, MapLayerListItem } from 'components';

import { hasObservations, groupBy } from 'utils';
import { MapLayer } from 'models/maplayer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
    filterButton: {
      width: '250px',
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
    },
    filterButtonSelected: {
      width: '250px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
    },
    topSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'stretch',
    },
    button: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '180px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
  }),
);

type Filters = 'ready' | 'pending' | 'all';

function getFilters(filter: Filters) {
  const pendingAuth = ['authorised', 'pending-authorisation'];
  const preAuth = ['auto-candidate', 'rejected', 'processing', 'error'];

  switch (filter) {
    case 'all':
      return [pendingAuth, preAuth];
    case 'ready':
      return [pendingAuth];
    case 'pending':
      return [preAuth];
  }
}

function FilteredMapList({
  mapLayersByStatus,
  filter,
}: {
  mapLayersByStatus: Record<string, Record<string, MapLayer[]>>;
  filter: Filters;
}) {
  const filterCategories = getFilters(filter);

  const filteredLayers = Object.values(mapLayersByStatus).flatMap((byStatus) => {
    const filteredItems = filterCategories.map((filters) => {
      return filters.flatMap((subFilter) => {
        return byStatus[subFilter];
      });
    });

    return filteredItems.filter((item) => item);
  });

  const firstOfEachItem = filteredLayers.map((layer) => layer.sort((a, b) => (a.created < b.created ? 1 : -1))[0]);

  return (
    <>
      {firstOfEachItem?.map((layer: MapLayer) => {
        if (layer.status === 'authorised') {
          return null;
        }
        return (
          <MapLayerListItem
            key={layer.id}
            layer={layer}
            hasObservations={hasObservations(layer)}
            prevAuthorisedLayer={mapLayersByStatus[layer.type].authorised[0] ?? false}
            to={`/authoriser/layer/${layer.id}`}
            disableButton={
              layer.status === 'auto-candidate' ||
              layer.status === 'rejected' ||
              layer.status === 'processing' ||
              layer.status === 'error'
            }
          />
        );
      })}
    </>
  );
}

function FSEAuthoriserDashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { auth, authoriser } = useAppSelector((state) => state);
  const { maplayers } = authoriser;
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<Filters>('ready');

  const mapLayersByStatus = useMemo(() => {
    const sortedMaplayers = maplayers.object?.slice().sort((a, b) => (a.created < b.created ? 1 : -1)) ?? [];
    const mapLayersByType = groupBy(sortedMaplayers, (maplayer) => maplayer.type);

    return Object.entries(mapLayersByType).reduce(
      (prev: Record<string, Record<string, MapLayer[]>>, [type, layers]) => {
        const byStatus = groupBy(layers, (layer) => layer.status);
        return { ...prev, [type]: byStatus };
      },
      {},
    );
  }, [maplayers.object]);

  useEffect(() => {
    if (maplayers.status === 'idle' && auth.status === 'finished') {
      dispatch(AuthoriserActions.getLayers());
    }
  }, [dispatch, maplayers.status, auth.status]);

  useEffect(() => {
    // will update locations every 30 seconds
    const interval = setInterval(() => dispatch(AuthoriserActions.getLayers()), 30000);
    setTimeout(() => dispatch(AuthoriserActions.getLayers()), 2000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.title}>
            <span style={{ color: theme.palette.common.neutralDark }}>Authoriser /</span> Authorise fuel state maps
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <div className={classes.topSection}>
            <div className={classes.filterContainer}>
              <Button
                variant={filter === 'ready' ? 'contained' : 'text'}
                onClick={() => setFilter('ready')}
                className={filter === 'ready' ? classes.filterButtonSelected : classes.filterButton}
              >
                Ready for authorisation
              </Button>
              <Button
                variant={filter === 'pending' ? 'contained' : 'text'}
                onClick={() => setFilter('pending')}
                className={filter === 'pending' ? classes.filterButtonSelected : classes.filterButton}
              >
                Pending edits
              </Button>
              <Button
                variant={filter === 'all' ? 'contained' : 'text'}
                onClick={() => setFilter('all')}
                className={filter === 'all' ? classes.filterButtonSelected : classes.filterButton}
              >
                All maps and models
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.menu}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {maplayers.status === 'loading' && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ margin: '8px' }} size={24} aria-valuetext="loading" />
            </div>
          )}
          {maplayers.status === 'error' && maplayers.error && (
            <ErrorSegment
              error={maplayers.error}
              text="Failed To load Locations"
              onRetry={() => dispatch(AuthoriserActions.getLayers())}
            />
          )}
          <FilteredMapList mapLayersByStatus={mapLayersByStatus} filter={filter} />
        </Grid>
      </Grid>
      <Grid container className={classes.menu} style={{ marginBottom: theme.spacing(5) }}>
        <Grid item xs={9} />
        <Grid item xs={2}>
          <div className={classes.topSection}>
            <div className={classes.filterContainer}>
              <Button
                className={classes.button}
                onClick={() => navigate('/authoriser/fueltypemodels/dashboard')}
                disabled={false}
              >
                Fuel Type Models
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default FSEAuthoriserDashboard;
