/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { Common } from 'models';
import { toTimeDDMonYearFormat, getFullName } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      width: '100%',
      margin: theme.spacing(1),
    },
    meta: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark
      fontWeight: 'bold',
    },
  }),
);

const Note: React.FunctionComponent<{ note: Common.Note }> = ({ note }) => {
  const classes = useStyles();
  return (
    <div className={classes.note}>
      <Typography variant="subtitle1">{note.note}</Typography>
      {note.modified && (
        <Typography variant="subtitle2" className={classes.meta}>
          {note.user && `${getFullName(note.user)}:`} {toTimeDDMonYearFormat(new Date(note.modified))}
        </Typography>
      )}
    </div>
  );
};

Note.displayName = 'Note';
export default Note;
