/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import { Note } from 'components';
import { MapLayerManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    primaryButton: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    textArea: {
      width: '100%',
      height: 300,
      border: 'none',
      padding: theme.spacing(2),
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      color: '#757575',
      fontSize: '16px',
      resize: 'none',
      outline: 0,
    },
    label: {
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(2),
    },
  }),
);

interface IProps {
  layer?: MapLayerManager.MapLayer | null;
}

const WorkflowNotes: React.FunctionComponent<IProps> = ({ layer }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!layer) return <>No Layer</>;

  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        style={{ fontWeight: 'bold', color: theme.palette.text.primary, marginBottom: theme.spacing(3) }}
      >
        {layer?.name ? `${layer.name} notes` : 'Invalid layer'}
      </Typography>
      <Typography className={classes.label}>Notes</Typography>
      {layer.notes && layer.notes.length > 0 ? (
        layer.notes.map((note) => <Note key={note.id} note={note} />)
      ) : (
        <Typography variant="subtitle1">No notes available</Typography>
      )}
    </div>
  );
};

export default WorkflowNotes;
