import { makeAPICall } from './api';

export declare namespace API {
  export interface NotFound {
    details: string;
  }

  export interface GoodResponse {
    link: string;
  }
}

export interface Payload {
  path: string;
}

export interface BlobLink {
  blob: Blob;
  link: string;
}

export const downloadFile = makeAPICall<BlobLink | null, Payload, API.NotFound & API.GoodResponse>(
  (payload) => {
    const { path } = payload;

    return {
      ext: `/help/document/download?path=${path}`,
      requestData: {
        method: 'GET',
      },
    };
  },
  async (root) => {
    if (root.link == null) return null;

    const blob = await fetch(root.link).then((res) => res.blob());
    return {
      blob,
      link: root.link,
    };
  },
);
