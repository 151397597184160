import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  CardContent,
  Card,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
} from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons';
import { customColors } from 'theme';

import { FireAreaManager, MapLayerManager } from 'models';
import { MapLayer } from 'models/maplayer';
import OptionButton from '../OptionButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
    close: {
      marginLeft: 'auto',
      color: theme.palette.common.neutralDark, // neutralDark
      textDecoration: 'underline',
      position: 'absolute',
      right: 0,
      top: 0,
      margin: 8,
    },
    button: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
      width: '-webkit-fill-available',
    },
    grid: {
      padding: theme.spacing(),
      alignSelf: 'center',
    },
    checked: {
      '&$checked': {
        color: theme.palette.common.green,
      },
    },
    section: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
  }),
);

interface IProps {
  fireArea: FireAreaManager.FireArea;
  layer: MapLayerManager.MapLayer;
  onStageOperation: (operation: MapLayerManager.MapLayer.StagedOperation) => void;
}

const MapLayerEditor: React.FunctionComponent<IProps> = ({ fireArea, layer, onStageOperation }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [editingValue, setEditing] = useState(false);
  const [nullSelected, setNullSelected] = useState(false);
  const [oldValue, setOldValue] = useState<string | null>(null);
  const [isValid, setValidation] = useState<boolean>(true);
  const [errorValidationMessage, setValidationMessage] = useState<string | null>('');
  const [settingGrassCuringValue, setGrassCuringValue] = useState<string | null>('50');
  const [settingFuelLoadValue, setFuelLoadValue] = useState<string | null>('5.0');
  const [settingFuelConditionValue, setFuelConditionValue] = useState<string | null>('3');
  const [editingOption, setEditingOption] = useState(0);
  const [selectedLocationValue, setSelectedLocation] = useState(fireArea.locations[0]?.id || 0);
  const [radiusValue, setRadius] = useState('1000');

  const optionsDict = [
    { label: 'Set fire management district', value: ['Grass Curing', 'Grass Fuel Load', 'Grass Fuel Condition'] },
    { label: 'Translate fire management district', value: ['Grass Curing', 'Grass Fuel Load'] },
    { label: 'Set location', value: ['Grass Curing', 'Grass Fuel Load', 'Grass Fuel Condition'] },
    { label: 'Translate location', value: ['Grass Curing', 'Grass Fuel Load'] },
  ];

  const optionsBounds = {
    'Grass Curing': {
      minValue: 0,
      maxValue: 100,
    },
    'Grass Fuel Load': {
      minValue: 0,
      maxValue: 12.0,
    },
    'Grass Fuel Condition': {
      minValue: 1,
      maxValue: 3,
    },
  };

  const optionsLabelIndexMap = optionsDict
    .map((thing, index) => (thing.value.includes(layer.name) ? index : -1))
    .filter((value) => value !== -1);

  const optionsLabelMap = optionsLabelIndexMap.map((index) => optionsDict[index].label);

  const parseValueToInt = (): number | null => {
    let value = null;

    if (layer.name === 'Grass Curing') {
      value = settingGrassCuringValue === null ? null : parseInt(settingGrassCuringValue, 10);
    }
    if (layer.name === 'Grass Fuel Load') {
      value = settingFuelLoadValue === null ? null : parseFloat(settingFuelLoadValue);
    }
    if (layer.name === 'Grass Fuel Condition') {
      value = settingFuelConditionValue === null ? null : parseInt(settingFuelConditionValue, 10);
    }

    return value;
  };

  const createOperation = (): MapLayer.LayerOperation => {
    const value = parseValueToInt();

    switch (editingOption) {
      case 0:
        if (value == null && nullSelected) {
          return {
            input_id: fireArea.id,
            input_type: 'FireManagementArea',
            operation_type: 'setNull',
            operation_value: value,
            note: `Setting the ${fireArea.name} fire management district to ${value}`,
            fuel_state_type: layer.name as 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type',
          };
        } else {
          return {
            input_id: fireArea.id,
            input_type: 'FireManagementArea',
            operation_type: 'set',
            operation_value: value,
            note: `Setting the ${fireArea.name} fire management district to ${value}`,
            fuel_state_type: layer.name as 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type',
          };
        }
      case 1:
        return {
          input_id: fireArea.id,
          input_type: 'FireManagementArea',
          operation_type: 'translate',
          operation_value: value,
          note: `Translating the ${fireArea.name} fire management district by ${value}`,
          fuel_state_type: layer.name as 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type',
        };
      case 2:
        if (value == null && nullSelected) {
          return {
            input_id: selectedLocationValue,
            input_type: 'Location',
            operation_type: 'setNull',
            operation_value: value,
            input_buffer: radiusValue,
            note: `Setting the ${
              fireArea.locations.find((x) => x.id === selectedLocationValue)?.name
            } location to ${value}`,
            fuel_state_type: layer.name as 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type',
          };
        } else {
          return {
            input_id: selectedLocationValue,
            input_type: 'Location',
            operation_type: 'set',
            operation_value: value,
            input_buffer: radiusValue,
            note: `Setting the ${
              fireArea.locations.find((x) => x.id === selectedLocationValue)?.name
            } location to ${value}`,
            fuel_state_type: layer.name as 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type',
          };
        }
      case 3:
        return {
          input_id: selectedLocationValue,
          input_type: 'Location',
          operation_type: 'translate',
          operation_value: value,
          input_buffer: radiusValue,
          note: `Translating the ${
            fireArea.locations.find((x) => x.id === selectedLocationValue)?.name
          } location by ${value}`,
          fuel_state_type: layer.name as 'Grass Curing' | 'Grass Fuel Condition' | 'Grass Fuel Load' | 'Fuel Type',
        };
      default:
        throw new Error('layer not known');
    }
  };

  const isOutsideBounds = (value: number, bounds: any) => value < bounds.minValue || value > bounds.maxValue;

  const setValidationState = (message: string, isValidInput: boolean) => {
    setValidationMessage(message);
    setValidation(isValidInput);
  };

  const isValidInput = () => {
    const value = parseValueToInt();
    const layerBounds = (optionsBounds as any)[layer.name];

    if (value === null && nullSelected) {
      return setValidationState('', true);
    }

    if (value === null || (isNaN(value) && !nullSelected)) {
      return setValidationState('Empty value is not accepted', false);
    }

    if (
      (editingOption === 1 || editingOption === 3) &&
      isOutsideBounds(value, { minValue: -layerBounds.maxValue, maxValue: layerBounds.maxValue })
    ) {
      return setValidationState(
        `Input value ${value} is outside of bounds -${layerBounds.maxValue} to ${layerBounds.maxValue}`,
        false,
      );
    }
    if ((editingOption === 0 || editingOption === 2) && isOutsideBounds(value, layerBounds)) {
      return setValidationState(
        `Input value ${value} is outside of bounds ${layerBounds.minValue} to ${layerBounds.maxValue}`,
        false,
      );
    }
    setValidationState('', true);
  };

  useEffect(() => {
    isValidInput();
  }, [settingGrassCuringValue, settingFuelLoadValue, settingFuelConditionValue, nullSelected, editingOption]);

  const handleSubmit = () => {
    const operation = createOperation();
    onStageOperation({ layer, fireArea, operation });
    setEditing(false);
  };

  // Set the editing option value from 0 -> 3 based on options dictionary mapping
  const handleEditingOptions = (value: number) => {
    setOldValue(null);
    setNullSelected(false);
    setEditingOption(optionsLabelIndexMap[value]);
  };

  if (!editingValue) {
    return (
      <Button
        variant="outlined"
        onClick={() => setEditing(true)}
        style={{
          color: theme.palette.common.neutralDark,
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '40px',
        }}
      >
        <Edit /> Edit {layer.name}
      </Button>
    );
  }

  const renderLocationPicker = () => {
    return (
      <Grid container justify="space-between">
        <Grid item sm={6} className={classes.grid}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedLocationValue}
              onChange={(e) => setSelectedLocation(e.target.value as any)}
              label="Location"
            >
              {fireArea.locations.map((x) => (
                <MenuItem value={x.id}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} className={classes.grid}>
          <TextField
            label="Radius"
            size="small"
            variant="outlined"
            value={radiusValue}
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            fullWidth
            onChange={(e) => setRadius(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderInputAndButton = () => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item sm={8} className={classes.grid} style={{ minHeight: '100px' }}>
          {layer.name === 'Grass Curing' && (
            <TextField
              label="Value"
              size="small"
              variant="outlined"
              value={settingGrassCuringValue === null ? '' : settingGrassCuringValue}
              disabled={nullSelected}
              fullWidth
              error={!isValid}
              helperText={errorValidationMessage}
              onChange={(e) => {
                setGrassCuringValue(e.target.value);
              }}
            />
          )}
          {layer.name === 'Grass Fuel Load' && (
            <TextField
              label="Value"
              size="small"
              variant="outlined"
              value={settingFuelLoadValue === null ? '' : settingFuelLoadValue}
              disabled={nullSelected}
              fullWidth
              error={!isValid}
              helperText={errorValidationMessage}
              onChange={(e) => {
                setFuelLoadValue(e.target.value);
              }}
            />
          )}
          {layer.name === 'Grass Fuel Condition' && (
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={settingFuelConditionValue === null ? '' : settingFuelConditionValue}
              disabled={nullSelected}
              onChange={(e) => setFuelConditionValue(e.target.value as string)}
              label="Location"
            >
              <MenuItem value={'1'}>Eaten Out</MenuItem>
              <MenuItem value={'2'}>Grazed</MenuItem>
              <MenuItem value={'3'}>Natural</MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item sm={4} className={classes.grid} style={{ minHeight: '100px' }}>
          <Button variant="contained" className={classes.button} onClick={handleSubmit} disabled={!isValid}>
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderLabelAndNullCheckbox = (text: string) => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item sm={8} className={classes.grid}>
          <Typography style={{ color: theme.palette.common.neutralDark, fontSize: 14 }}>{text}</Typography>
        </Grid>
        <Grid item sm={4} className={classes.grid} style={{ display: 'flex' }}>
          <Checkbox
            checked={nullSelected}
            className={classes.checked}
            onChange={(event) => {
              setNullSelected(event.target.checked);
              if (event.target.checked) {
                setOldValue(
                  layer.name === 'Grass Curing'
                    ? settingGrassCuringValue
                    : layer.name === 'Grass Fuel Load'
                    ? settingFuelLoadValue
                    : layer.name === 'Grass Fuel Condition'
                    ? settingFuelConditionValue
                    : null,
                );
                if (layer.name === 'Grass Curing') {
                  setGrassCuringValue(null);
                }
                if (layer.name === 'Grass Fuel Load') {
                  setFuelLoadValue(null);
                }
                if (layer.name === 'Grass Fuel Condition') {
                  setFuelConditionValue(null);
                }
              } else {
                if (layer.name === 'Grass Curing') {
                  setGrassCuringValue(oldValue);
                }
                if (layer.name === 'Grass Fuel Load') {
                  setFuelLoadValue(oldValue);
                }
                if (layer.name === 'Grass Fuel Condition') {
                  setFuelConditionValue(oldValue);
                }
              }
            }}
          />
          <Typography style={{ color: theme.palette.common.neutralDark, fontSize: 14, alignSelf: 'center' }}>
            Send a null value
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderLabel = (text: string) => {
    return (
      <Typography style={{ color: theme.palette.common.neutralDark, fontSize: 14, marginBottom: 16 }}>
        {text}
      </Typography>
    );
  };

  const renderForm = () => {
    switch (editingOption) {
      case 0:
        switch (layer.name) {
          case 'Grass Curing':
            return (
              <>
                {renderLabelAndNullCheckbox(
                  'Set the whole fire management district to a uniform value between 0 to 100. Each current observation will NOT be updated with a new value except if set to NULL, if set to NULL each observation will have a new value of NULL until a new observation is validated. If set to NULL, a regeneration will be performed.',
                )}
                {renderInputAndButton()}
              </>
            );
          case 'Grass Fuel Load':
            return (
              <>
                {renderLabelAndNullCheckbox(
                  'Set the whole fire management district to a uniform decimal value between 1.0 to 12.0. Each current observation will NOT be updated with a new value except if set to NULL, if set to NULL each observation will have a new value of NULL until a new observation is validated. If set to NULL, a regeneration will be performed.',
                )}
                {renderInputAndButton()}
              </>
            );
          case 'Grass Fuel Condition':
            return (
              <>
                {renderLabelAndNullCheckbox(
                  'Set the whole fire management district to a uniform value (Eaten Out/Grazed/Natural). Each current observation will NOT be updated with a new value except if set to NULL, if set to NULL each observation will have a new value of NULL until a new observation is validated. If set to NULL, a regeneration will be performed.',
                )}
                {renderInputAndButton()}
              </>
            );
          default:
            return <>unknown option {editingOption}</>;
        }
      case 1:
        switch (layer.name) {
          case 'Grass Curing':
            return (
              <>
                {renderLabel(
                  'Increase or decrease the whole fire management district by a percentage (-100 to 100). Current observations will NOT be updated with the new value.',
                )}
                {renderInputAndButton()}
              </>
            );
          case 'Grass Fuel Load':
            return (
              <>
                {renderLabel(
                  'Increase or decrease the whole fire management district by fixed decimal value (-12.0 to 12.0). Current observations will NOT be updated with the new value.',
                )}
                {renderInputAndButton()}
              </>
            );
          default:
            return <>unknown option {editingOption}</>;
        }
      case 2:
        switch (layer.name) {
          case 'Grass Curing':
            return (
              <>
                {renderLabelAndNullCheckbox(
                  'Set the location to a value between 0 to 100 with a defined radius. The current observation will be updated and used on-going until a new observation is validated, the radius will not be kept for future regenerations (normal interpolation will occur when a regeneration occurs). If null values are included when edits are submitted, the radius you have set will be removed and normal interpolation will occur through regeneration.',
                )}
                {renderLocationPicker()}
                {renderInputAndButton()}
              </>
            );
          case 'Grass Fuel Load':
            return (
              <>
                {renderLabelAndNullCheckbox(
                  'Set the location to a value between 1.0 to 12.0 with a defined radius. The current observation will be updated and used on-going until a new observation is validated, the radius will not be kept for future regenerations (normal interpolation will occur when a regeneration occurs). If null values are included when edits are submitted, the radius you have set will be removed and normal interpolation will occur through regeneration.',
                )}
                {renderLocationPicker()}
                {renderInputAndButton()}
              </>
            );
          case 'Grass Fuel Condition':
            return (
              <>
                {renderLabelAndNullCheckbox(
                  'Set the location to a Eaten Out/Grazed/Natural value with a defined radius. The current observation will be updated and used on-going until a new observation is validated, the radius will not be kept for future regenerations (normal interpolation will occur when a regeneration occurs). If null values are included when edits are submitted, the radius you have set will be removed and normal interpolation will occur through regeneration.',
                )}
                {renderLocationPicker()}
                {renderInputAndButton()}
              </>
            );
          default:
            return <>unknown option {editingOption}</>;
        }
      case 3:
        switch (layer.name) {
          case 'Grass Curing':
            return (
              <>
                {renderLabel(
                  'Increase or decrease the location by a percentage (-100 to 100). The current observation will be updated and used on-going until a new observation is validated, the radius will not be kept for future regenerations (normal interpolation will occur when a regeneration occurs).',
                )}
                {renderLocationPicker()}
                {renderInputAndButton()}
              </>
            );
          case 'Grass Fuel Load':
            return (
              <>
                {renderLabel(
                  'Increase or decrease the location by a fixed value (-12.0 to 12.0). The current observation will be updated and used on-going until a new observation is validated, the radius will not be kept for future regenerations (normal interpolation will occur when a regeneration occurs).',
                )}
                {renderLocationPicker()}
                {renderInputAndButton()}
              </>
            );
          default:
            return <>unknown option {editingOption}</>;
        }
      default:
        return <>unknown option {editingOption}</>;
    }
  };

  return (
    <Card>
      <div style={{ position: 'relative', color: theme.palette.common.neutralDark, fontSize: 14 }}>
        <div style={{ display: 'inline-block', borderRight: `1px solid ${customColors.neutralDark}`, padding: 16 }}>
          Edit {layer.name}
        </div>
        <div style={{ display: 'inline-block', padding: '10px 16px', color: 'black' }}>
          {optionsDict[editingOption].label}{' '}
          <OptionButton options={optionsLabelMap} onChange={(e) => handleEditingOptions(e)} />
        </div>
        <Button className={classes.close} onClick={() => setEditing(false)} variant="text">
          <Close /> Close
        </Button>
      </div>
      <CardContent style={{ borderTop: `1px solid ${customColors.neutralDark}`, minHeight: 170 }}>
        {renderForm()}
      </CardContent>
    </Card>
  );
};

export default MapLayerEditor;
