import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Link, Typography, TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { LocationManager } from 'models';
import LocationCard from './LocationCard';
import LinkButton from './LinkButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.common.black,
    },
    title: {
      marginTop: 25,
      fontSize: 40,
      paddingBottom: 8,
      borderBottom: '2px solid black',
      marginBottom: 25,
      position: 'relative',
    },
    addNewLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      bottom: 16,
      fontSize: 14,
    },
  }),
);

const sorter = (
  a: LocationManager.Location.LocationAdminAPI.Location,
  b: LocationManager.Location.LocationAdminAPI.Location,
) => {
  const nameA = `${a.status}|${a.location_name.toUpperCase()}`;
  const nameB = `${b.status}|${b.location_name.toUpperCase()}`;
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const LocationsComponent: React.FunctionComponent<{
  onShowNew: () => void;
  locations: LocationManager.Location.LocationAdminAPI.Location[];
  onClick: (location: LocationManager.Location.LocationAdminAPI.Location) => void;
}> = ({ onShowNew, onClick, locations }) => {
  const classes = useStyles();

  const [filter, setFilter] = useState('');

  const lowerCaseFilter = filter.toLowerCase();

  const sortedLocations = locations
    .filter((loc) => !filter || loc.location_name.toLowerCase().includes(lowerCaseFilter))
    .sort(sorter);

  return (
    <>
      <Link component={RouterLink} to="/" className={classes.backLink}>
        <Typography>&lt; Back</Typography>
      </Link>
      <Typography className={classes.title}>
        Locations
        <LinkButton text="+ Add new" onClick={onShowNew} className={classes.addNewLink} />
      </Typography>
      <TextField
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="outlined"
        fullWidth
        size="small"
        label="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {sortedLocations.map((loc) => (
        <LocationCard key={loc.location_id} location={loc} onClick={onClick} />
      ))}
    </>
  );
};

export default LocationsComponent;
