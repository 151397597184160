import { AnalyticsManager, LayerManager, MeteogramManager } from 'models';
import { makeThunkFromAPICall } from 'state';

export const [postCustomEvent, setEventStatus] = makeThunkFromAPICall(AnalyticsManager.postEvent, 'analytics/post');

export const postLogout = () => postCustomEvent({ message: 'logout' });

export const postLayerView = (layer: LayerManager.Layer) =>
  postCustomEvent({
    message: 'layer-change',
    params: {
      id: layer.serviceName ?? layer.id,
      name: layer.name,
      issue_time: layer.lastUpdated ? new Date(layer.lastUpdated).toISOString() : undefined,
    },
  });

export const postWeatherStationView = (ws: MeteogramManager.Meteograms.WeatherStation) =>
  postCustomEvent({ message: 'view-weather-station', params: { id: ws?.id, name: ws?.name } });

export const postPageView = (route: string) =>
  postCustomEvent({ message: 'page-view', params: { route, url: window.location.origin } });

export const postGenerateReport = (name: string) => postCustomEvent({ message: 'report-generated', params: { name } });

export const postGenerateReportError = (name: string, error: string) =>
  postCustomEvent({ message: 'report-error', params: { name, error }, level: 'error' });

export const AnalyticsActions = {
  postCustomEvent,
  postLogout,
  postLayerView,
  postWeatherStationView,
  postPageView,
  postGenerateReport,
  postGenerateReportError,
};
