import { createReducer, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import * as AuthManager from 'models/auth';

import { Loadable, ErrorTypes, isErrorResp, ErrorResp } from 'models';

import config from 'config';

export type AuthState = Loadable<AuthManager.Auth>;

const initialState: AuthState = {
  status: 'idle',
};

export const setAuth = createAction('auth/set', (auth: AuthManager.Auth) => {
  // Update the auth details in localstorage
  if (auth) {
    // calculate the refresh time as a Date object if it doesn't exist and the creds do
    if (auth.refresh_time == null && auth.creds) {
      const refreshTime = new Date();
      refreshTime.setSeconds(refreshTime.getSeconds() + auth.creds.expires_in);
      // eslint-disable-next-line no-param-reassign
      auth.refresh_time = refreshTime;
    }

    if (auth.creds?.id_token) {
      // eslint-disable-next-line no-param-reassign
      auth.decoded_token = jwtDecode<AuthManager.IdentityToken>(auth.creds.id_token);
    }

    if (auth.creds && !auth.creds?.refresh_token) {
      // the new credentials do not have a refresh_token, perhaps saved credentials do?
      // can we load the old refresh_token, and reuse it?
      const existingLocalStorageJson = localStorage.getItem(config.localstorage_auth);
      if (existingLocalStorageJson) {
        const existingLocalStorageValue = JSON.parse(existingLocalStorageJson) as AuthManager.Auth;
        if (existingLocalStorageValue.creds?.refresh_token) {
          // eslint-disable-next-line no-param-reassign
          auth.creds.refresh_token = existingLocalStorageValue.creds?.refresh_token;
        }
      }
    }

    localStorage.setItem(config.localstorage_auth, JSON.stringify(auth));
  } else {
    localStorage.removeItem(config.localstorage_auth);
  }

  return {
    payload: auth,
  };
});

export const setStatus = createAction<Loadable<AuthManager.Auth>>('auth/set-status');

export const getToken = createAsyncThunk('auth/get-token', async (payload: { code: string }, thunk) => {
  const dispatch = thunk.dispatch;

  dispatch(setStatus({ status: 'loading' }));
  if (payload.code) {
    try {
      const resp = await AuthManager.getToken(payload.code);
      if (!isErrorResp(resp)) {
        dispatch(
          setAuth({
            creds: resp,
          }),
        );
        dispatch(
          setStatus({
            status: 'finished',
          }),
        );
        return resp;
      }
      dispatch(
        setStatus({
          error: resp,
          status: 'error',
        }),
      );
      throw resp;
    } catch (err) {
      dispatch(
        setStatus({
          error: err as ErrorResp,
          status: 'error',
        }),
      );
      throw err;
    }
  } else {
    const err = {
      error: 'Must have authorisation code',
      code: ErrorTypes.NOT_ENOUGH_DETAIL,
    };
    dispatch(
      setStatus({
        error: err,
        status: 'error',
      }),
    );
    throw err;
  }
});

export const refreshToken = createAsyncThunk(
  'auth/refresh-token',
  async (payload: { auth: AuthManager.Auth }, thunk) => {
    // don't set the status to loading, or you get a spinner on the page
    // this is a silent refresh behind the scenes...
    // thunk.dispatch(setStatus({ status: 'loading' }));
    console.log('Refreshing Token');
    if (payload.auth.creds) {
      try {
        const resp = await AuthManager.applyRefresh(payload.auth.creds);
        if (!isErrorResp(resp)) {
          thunk.dispatch(
            setAuth({
              creds: resp,
            }),
          );
          thunk.dispatch(
            setStatus({
              status: 'finished',
            }),
          );
        } else {
          thunk.dispatch(
            setStatus({
              error: resp,
              status: 'error',
            }),
          );
        }
      } catch (err) {
        thunk.dispatch(
          setStatus({
            error: err as ErrorResp,
            status: 'error',
          }),
        );
      }
    } else {
      thunk.dispatch(
        setStatus({
          error: {
            error: 'No credentials provided',
            code: ErrorTypes.INVALID_CREDENTIALS,
          },
          status: 'error',
        }),
      );
    }
  },
);

export const revokeToken = createAsyncThunk('auth/revoke-token', async (payload: { auth: AuthManager.Auth }, thunk) => {
  thunk.dispatch(setStatus({ status: 'loading' }));
  if (payload.auth.creds && payload.auth.creds.access_token) {
    try {
      const resp = await AuthManager.revokeToken(payload.auth.creds.access_token);
      if (!isErrorResp(resp)) {
        thunk.dispatch(
          setAuth({
            creds: null,
            refresh_time: null,
            decoded_token: null,
          }),
        );
        thunk.dispatch(
          setStatus({
            status: 'finished',
          }),
        );
      } else {
        thunk.dispatch(
          setStatus({
            error: resp,
            status: 'error',
          }),
        );
      }
    } catch (err) {
      thunk.dispatch(
        setStatus({
          error: err as ErrorResp,
          status: 'error',
        }),
      );
    }
  } else {
    thunk.dispatch(
      setStatus({
        error: {
          error: 'No credentials provided',
          code: ErrorTypes.INVALID_CREDENTIALS,
        },
        status: 'error',
      }),
    );
  }
});

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(setStatus, (state, action) => {
    return { ...state, ...action.payload };
  });
  builder.addCase(setAuth, (state, action) => {
    return { ...state, object: action.payload };
  });
});

export const AuthActions = {
  setAuth,
  setStatus,
  getToken,
  refreshToken,
  revokeToken,
};

export default authReducer;
