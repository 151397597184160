import React, { useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import { ProgressSteps } from 'models';
import { ProgressNavList } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      marginTop: theme.spacing(1),
      borderRadius: '4px',
    },
    accordionSummary: {
      backgroundColor: theme.palette.common.white,
    },
    accordionSummaryText: {
      color: theme.palette.text.secondary,
    },
    accordionDetails: {
      backgroundColor: theme.palette.common.white,
    },
  }),
);

interface ExpandableProgressNavProps {
  onChange?: (value: number) => void;
  steps: ProgressSteps[];
  currentStepId: number;
}

const ExpandableProgressNav: React.FunctionComponent<ExpandableProgressNavProps> = ({
  steps,
  currentStepId,
  onChange,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      className={classes.accordion}
      style={{
        backgroundColor: expanded ? 'white' : theme.palette.common.neutralXXLight,
      }}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{ root: classes.accordionSummary }}
        style={{
          borderBottom: expanded ? `1px solid ${theme.palette.common.neutralLight}` : '0px solid',
        }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          className={classes.accordionSummaryText}
          style={{
            color: expanded ? theme.palette.common.black : theme.palette.text.secondary,
            width: '100%',
            fontWeight: 'bold',
          }}
        >
          Step {currentStepId + 1} of {steps.length}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <ProgressNavList
          steps={steps}
          onChange={(value) => {
            if (onChange) onChange(value);
            setExpanded(false);
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

ExpandableProgressNav.displayName = 'ExpandableProgressNav';
export default ExpandableProgressNav;
