import { createReducer } from '@reduxjs/toolkit';

import { AuthDomainsManager, Loadable, HelpManager } from 'models';
import { makeThunkFromAPICall } from 'state';

export type ValidEmailOktaState = {
  get: Record<number, Loadable<HelpManager.Item | null>>;
};

const initalState: ValidEmailOktaState = {
  get: {},
};

export const [getAuthDomains, setAuthDomains] = makeThunkFromAPICall(AuthDomainsManager.getAuthDomains, 'auth_domains');

export const authDomainsReducer = createReducer(initalState, (builder) => {
  builder.addCase(setAuthDomains, (state, action) => {
    return {
      ...state,
      get: { ...state.get, ...action.payload.loadable },
    };
  });
});

export const AuthDomainsActions = {
  getAuthDomains,
};

export default authDomainsReducer;
