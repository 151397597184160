import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { LocationManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
    segmentTitle: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark;
    },
    segmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
  }),
);

interface ConfidenceStatusProps {
  style?: React.CSSProperties;
  className?: string;
  observation?: LocationManager.Location.Observation;
  prevObservation?: LocationManager.Location.Observation;
}

const ConfidenceStatus: React.FunctionComponent<ConfidenceStatusProps> = ({ style, className, observation }) => {
  const classes = useStyles();

  return (
    <div className={className} style={style}>
      <Typography variant="subtitle2" className={classes.segmentTitle}>
        Confidence
      </Typography>

      <div className={classes.segmentMiddle}>
        {observation ? (
          <Typography variant="h5">{observation.confident ? 'Confident' : 'Not confident'}</Typography>
        ) : (
          <Typography variant="h5">N/A</Typography>
        )}
      </div>
    </div>
  );
};

ConfidenceStatus.displayName = 'ConfidenceStatus';
export default ConfidenceStatus;
