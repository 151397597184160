/**
 * For Theme configuration details, see https://material-ui.com/customization/theming/
 * When modifying colours, consider using this tool provided by Material UI
 * https://material.io/tools/color/
 *
 */

import { createTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core/styles';
import { CommonColors } from '@material-ui/core/styles/createPalette';

/**
 * Colours Provided by Today Design
 * - See Foundation Elements -> Colour page
 * - The matrix on this page maps these colours into the theme
 */
export const customColors: CommonColors = {
  black: '#000',
  black70: 'rgba(0,0,0,0.7)',
  grey: '#696969',
  neutralDark: '#64737D',
  neutral: '#808F99',
  neutralLight: '#CAD0D4',
  neutralXLight: '#DAE0E4',
  neutralXXLight: '#F6F6F6',
  white: '#FFF',
  green: '#008040',
  red: '#D91E18',
  blue: '#1368D8',
  fdrNoRating: '#FFFFFF',
  fdrModerate: '#78BE20',
  fdrHigh: '#F2CD00',
  fdrExtreme: '#FF8200',
  fdrCatastrophic: '#DA291C',
};

/**
 * Ensures all defaults are set so that they can be omitted in options
 * @param options Theme options to set
 */
function createMyTheme(options: ThemeOptions) {
  return createTheme({
    ...options,
    palette: {
      ...options.palette,
      common: customColors,
      background: {
        default: '#FFF',
        paper: '#FFF',
        mobileHelp: '#FFF',
        panel: '#FFF',
        footer: '#FFF',
        timebar: '#FFF',
        details: '#FFF',
        nav: '#FFF',
      },
      iconDark: {
        fill: '#FFF',
        highlight: '#EEE',
      },
      iconLight: {
        fill: '#FFF',
        highlight: '#EEE',
      },
      textLight: options.palette?.text,
      textDark: options.palette?.text,
    },
  });
}

const generateTheme = (useResponsiveFontSizes = true) => {
  const theme = createMyTheme({
    overrides: {
      MuiPickerDTToolbar: {
        toolbar: {
          backgroundColor: customColors.black70,
        },
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: customColors.black70,
        },
      },
      MuiPickersModal: {
        withAdditionalAction: {
          '&> button': {
            color: customColors.black70,
            '&:hover': {
              backgroundColor: customColors.neutralXLight,
            },
          },
        },
      },
    },
    palette: {
      background: {
        default: customColors.neutralXXLight,
        paper: customColors.neutralXXLight,
        mobileHelp: customColors.black,
        panel: customColors.black70,
        footer: customColors.neutralLight,
        timebar: customColors.white,
        details: customColors.white,
        nav: customColors.white,
      },
      secondary: {
        main: customColors.neutralLight,
      },
      success: {
        main: customColors.green,
      },
      error: {
        main: customColors.red,
      },
      info: {
        main: customColors.blue,
      },
      iconDark: {
        fill: customColors.neutral,
        highlight: customColors.black,
      },
      iconLight: {
        fill: customColors.neutralXLight,
        highlight: customColors.white,
      },
      text: {
        primary: customColors.black,
        secondary: customColors.grey,
        label: customColors.neutralDark,
        link: customColors.neutralDark,
      },
      textLight: {
        primary: customColors.white,
        secondary: customColors.neutralXXLight,
        label: customColors.neutralXLight,
        link: customColors.neutralXLight,
      },
      divider: customColors.neutralLight,
    },
  });
  return useResponsiveFontSizes ? responsiveFontSizes(theme) : theme;
};

export default generateTheme;
