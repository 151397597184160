/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button, ButtonTypeMap, CircularProgress } from '@material-ui/core';
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.black,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      '&:disabled': {
        backgroundColor: theme.palette.common.neutralDark,
        color: theme.palette.common.neutralLight,
      },
    },
  }),
);

const PrimaryButton: React.FunctionComponent<DefaultComponentProps<ButtonTypeMap> & { loading?: boolean }> = ({
  className,
  children,
  loading,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button className={[classes.root, className].join(' ')} {...props}>
      {loading ? <CircularProgress size={16} aria-valuetext="loading" /> : children}
    </Button>
  );
};

PrimaryButton.displayName = 'PrimaryButton';
export default PrimaryButton;
