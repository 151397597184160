import React, { useEffect, useState, FunctionComponent } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card, CardContent, Chip } from '@material-ui/core';

import { getUserState, prettifyLatLng } from 'utils';
import { useAppSelector, useAppDispatch } from 'hooks';
import { LocationManager, UserManager, AssignedLocationManager } from 'models';

import { ToastActions } from 'state/toast';
import { ObserverActions } from 'state/observer';
import { LocationActions } from 'state/location';
import { UserActions } from 'state/user';
import { AssignedLocationActions } from 'state/assignedlocation';

import { ToastType } from 'models/toast';
import CardListComponent from './CardListComponent';
import RedButton from './RedButton';
import LinkButton from './LinkButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 25,
      fontSize: 40,
      paddingBottom: 8,
      borderBottom: '2px solid black',
      marginBottom: 25,
      position: 'relative',
    },
    subTitle: {
      marginTop: 25,
      fontSize: 20,
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: '1px solid black',
      color: theme.palette.grey[700],
      position: 'relative',
    },
    editLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 14,
      marginLeft: 12,
    },
    backLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.common.black,
    },
    coords: {
      color: '#4D4C51',
    },
    fmd: {
      color: theme.palette.common.neutral,
    },
    chip: {
      marginLeft: theme.spacing(1),
    },
  }),
);

const usersForRole = (
  roleName: string,
  assignments: AssignedLocationManager.LocationAssignment.LocationAssignment[],
) => {
  return assignments.filter((x) => x.role.role_name === roleName).map((x) => x.user);
};

const filterUsersForRole = (
  roleName: string,
  users: UserManager.User.UserList | null,
  assignedUsers: AssignedLocationManager.LocationAssignment.User[],
) => {
  const assignedUserIds = new Set<number>(assignedUsers.map((x) => x.user_id));
  return (users?.users || [])
    .filter((x) => x.roles.find((role) => role.role_name === roleName))
    .filter((x) => !assignedUserIds.has(x.user_id));
};

const EditLocationComponent: FunctionComponent<{
  onCancel: () => void;
  location: LocationManager.Location.LocationAdminAPI.Location;
}> = ({ onCancel, location }) => {
  const [editNameValue, setEditNameValue] = useState(false);
  const [assignmentsValue, setAssignmentsValue] = useState<
    AssignedLocationManager.LocationAssignment.LocationAssignment[] | null
  >(null);

  const { users, auth } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.status === 'finished' && (users.observers.status === 'idle' || users.validators.status === 'idle')) {
      if (auth?.object) {
        const userState = getUserState(auth?.object);
        if (users.observers.status === 'idle') {
          dispatch(UserActions.getObserversForJurisdiction({ jurisdiction: userState || '', roles: ['Observer'] }));
        }
        if (users.validators.status === 'idle') {
          dispatch(UserActions.getValidatorsForJurisdiction({ jurisdiction: userState || '', roles: ['Validator'] }));
        }
      }
    }
  }, [auth?.object, auth.status, dispatch, users.observers.status, users.validators.status]);

  useEffect(() => {
    dispatch(AssignedLocationActions.getAssignmentsForLocation({ locationId: location.location_id })).then(
      setAssignmentsValue,
    );
  }, [dispatch, location.location_id]);

  const handleReload = () => {
    setAssignmentsValue(null);
    dispatch(AssignedLocationActions.getAssignmentsForLocation({ locationId: location.location_id }))
      .then(setAssignmentsValue)
      .then(() => {
        dispatch(ObserverActions.getLocations());
        dispatch(LocationActions.getLocations());
      });
  };

  const loadingUsers = assignmentsValue === null;

  const handleToggleActive = (newStatus: string) => {
    const updatedLocation: LocationManager.Location.LocationAdminAPI.Location = {
      location_name: location.location_name,
      geometry: location.geometry,
      location_id: location.location_id,
      status: newStatus,
    };
    dispatch(LocationActions.updateLocation({ location: updatedLocation }))
      .then(() => {
        dispatch(
          ToastActions.showToast({
            message: `${location.location_name} updated to ${newStatus}`,
            type: ToastType.success,
          }),
        );
      })
      .then(() => dispatch(LocationActions.getLocations()))
      .then(onCancel);
  };

  const classes = useStyles();

  const renderActivationPanel = () => {
    if (location.status === 'active') {
      const disable = !!assignmentsValue && assignmentsValue.length > 0;
      return (
        <Typography variant="body2" color="textSecondary" component="div">
          <p>Deactivate this location to stop it from being used.</p>
          {disable ? <p>Remove all observers and validators to deactivate</p> : <></>}
          <RedButton
            variant="contained"
            color="secondary"
            onClick={() => handleToggleActive('inactive')}
            fullWidth
            disabled={disable}
          >
            Deactivate
          </RedButton>
        </Typography>
      );
    }
    return (
      <Typography variant="body2" color="textSecondary" component="div">
        Activate this location to start using it again.
        <RedButton variant="contained" color="secondary" onClick={() => handleToggleActive('active')} fullWidth>
          Activate
        </RedButton>
      </Typography>
    );
  };

  const renderNameField = () => (
    <Card elevation={0} style={{ marginTop: 25, marginBottom: 25 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Are you sure?
        </Typography>
        {renderActivationPanel()}
      </CardContent>
    </Card>
  );

  return (
    <>
      <div onClick={onCancel} className={classes.backLink} onKeyPress={onCancel} tabIndex={0} role="button">
        <Typography>&lt; Back</Typography>
      </div>

      <Typography className={classes.title} component="div">
        {editNameValue ? renderNameField() : location?.location_name}

        <Chip label={location.status === 'active' ? 'Active' : 'Inactive'} className={classes.chip} />

        {!editNameValue && (
          <LinkButton
            text={location.status === 'active' ? 'Deactivate' : 'Activate'}
            onClick={() => setEditNameValue(true)}
            className={classes.editLink}
          />
        )}

        <Typography variant="h4" className={classes.fmd}>
          {location?.fire_management_area_name}
        </Typography>

        {!editNameValue && (
          <Typography className={classes.coords}>{prettifyLatLng(location?.geometry.coordinates || [0, 0])}</Typography>
        )}
      </Typography>

      <Typography className={classes.subTitle}>Observers</Typography>

      <CardListComponent
        location={location}
        selectedUsers={usersForRole('Observer', assignmentsValue || [])}
        loading={loadingUsers}
        availableUsers={filterUsersForRole(
          'Observer',
          users.observers.object || null,
          usersForRole('Observer', assignmentsValue || []),
        )}
        roleName="Observer"
        onUpdate={handleReload}
      />

      <Typography className={classes.subTitle}>Validators</Typography>

      <CardListComponent
        location={location}
        selectedUsers={usersForRole('Validator', assignmentsValue || [])}
        loading={loadingUsers}
        availableUsers={filterUsersForRole(
          'Validator',
          users.validators.object || null,
          usersForRole('Validator', assignmentsValue || []),
        )}
        roleName="Validator"
        onUpdate={handleReload}
      />
    </>
  );
};

export default EditLocationComponent;
