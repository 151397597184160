import { createReducer } from '@reduxjs/toolkit';

import { ArchiveManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type ArchiveState = {
  maps: Loadable<ArchiveManager.ArchiveMapRecord[]>;
  observations: Loadable<ArchiveManager.FlatObservation[]>;
  fuelTypes: Loadable<ArchiveManager.FuelTypeRecord[]>;
};

const initialState: ArchiveState = {
  maps: {
    status: 'idle',
  },
  observations: {
    status: 'idle',
  },
  fuelTypes: {
    status: 'idle',
  },
};

export const [getMaps, setMaps] = makeThunkFromAPICall(ArchiveManager.getArchiveLayers, 'archive/set-maps');

export const [getFuelTypes, setFuelTypes] = makeThunkFromAPICall(
  ArchiveManager.getFuelTypeRecords,
  'archive/set-fuel-types',
);

export const [getObservations, setObservations] = makeThunkFromAPICall(
  ArchiveManager.getObservationArchive,
  'archive/observations',
);

export const [downloadTiff, setTiffDownloadStatus] = makeThunkFromAPICall(
  ArchiveManager.downloadMapTif,
  'archive/tiff-download',
);

export const archiveReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMaps, (state, action) => {
    return { ...state, maps: { ...state.maps, ...action.payload.loadable } };
  });
  builder.addCase(setObservations, (state, action) => {
    return { ...state, observations: { ...state.observations, ...action.payload.loadable } };
  });
  builder.addCase(setFuelTypes, (state, action) => {
    return { ...state, fuelTypes: { ...state.fuelTypes, ...action.payload.loadable } };
  });
});

export const ArchiveActions = {
  getMaps,
  getFuelTypes,
  getObservations,
  downloadTiff,
};

export default archiveReducer;
