import React, { useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button, ButtonGroup } from '@material-ui/core';

import MeasureTool, { ShapeType, MeasureUnits } from 'components/map/MeasureTool';
import CloseableCard from './CloseableCard';
import PrimaryButton from 'components/PrimaryButton';
import { superScriptKmSquared, superScriptMetersSquared } from 'utils';

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: 'fit-content',
    },
    settings: {
      paddingTop: spacing(1),
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gap: spacing(1),
    },
    groupedButton: {
      padding: `${spacing(0.5)} ${spacing(2)}`,
    },
    groupedSelectedButton: {
      padding: `${spacing(0.5)} ${spacing(2)}`,
      backgroundColor: palette.common.neutralLight,
    },
  }),
);

interface Props {
  measureTool: MeasureTool;
  reverseIcon?: boolean;
}

const MeasureToolControls: React.FunctionComponent<Props> = ({ measureTool }) => {
  const classes = useStyles();
  const [updateVal, setForceUpdate] = useState(0);
  const forceUpdate = () => setForceUpdate(updateVal + 1);

  const currentUnit = measureTool.getUnits();
  const currentMeasureType = measureTool.getMeasureType();
  const isMeasuring = measureTool.isMeasuring();

  return (
    <CloseableCard
      title="Measure Tool"
      reverseExpandDirection={true}
      onClose={() => {
        if (isMeasuring) {
          measureTool.stop();
        }

        measureTool.clear();
        forceUpdate();
      }}
    >
      <div className={classes.root}>
        <PrimaryButton
          variant="contained"
          onClick={() => {
            if (isMeasuring) {
              measureTool.stop();
            } else {
              measureTool.start();
            }

            forceUpdate();
          }}
        >
          {isMeasuring ? 'Stop Measuring' : 'Measure'}
        </PrimaryButton>
        <div className={classes.settings}>
          <ButtonGroup fullWidth>
            <Button
              className={
                currentMeasureType === ShapeType.LineString ? classes.groupedSelectedButton : classes.groupedButton
              }
              value="LineString"
              onClick={() => {
                if (currentMeasureType === ShapeType.LineString) return;
                measureTool.setMeasureType(ShapeType.LineString);
                forceUpdate();
              }}
            >
              Length
            </Button>
            <Button
              className={
                currentMeasureType === ShapeType.Polygon ? classes.groupedSelectedButton : classes.groupedButton
              }
              value="Polygon"
              onClick={() => {
                if (currentMeasureType === ShapeType.Polygon) return;
                measureTool.setMeasureType(ShapeType.Polygon);
                forceUpdate();
              }}
            >
              Area
            </Button>
          </ButtonGroup>
          <ButtonGroup fullWidth size="small">
            <Button
              className={currentUnit === MeasureUnits.m ? classes.groupedSelectedButton : classes.groupedButton}
              value="m"
              onClick={() => {
                if (currentUnit === MeasureUnits.m) return;
                measureTool.setUnits(MeasureUnits.m);
                forceUpdate();
              }}
            >
              {currentMeasureType === ShapeType.LineString ? MeasureUnits.m : superScriptMetersSquared}
            </Button>
            <Button
              className={currentUnit === MeasureUnits.km ? classes.groupedSelectedButton : classes.groupedButton}
              value="km"
              onClick={() => {
                if (currentUnit === MeasureUnits.km) return;
                measureTool.setUnits(MeasureUnits.km);
                forceUpdate();
              }}
            >
              {currentMeasureType === ShapeType.LineString ? MeasureUnits.km : superScriptKmSquared}
            </Button>
            <Button
              className={currentUnit === MeasureUnits.ha ? classes.groupedSelectedButton : classes.groupedButton}
              disabled={currentMeasureType !== 'Polygon'}
              value="ha"
              onClick={() => {
                if (currentUnit === MeasureUnits.ha) return;
                measureTool.setUnits(MeasureUnits.ha);
                forceUpdate();
              }}
            >
              ha
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </CloseableCard>
  );
};

MeasureToolControls.displayName = 'MeasureToolControls';
export default MeasureToolControls;
