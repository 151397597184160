import { Typography } from '@material-ui/core';
import { ObserverActions } from 'state/observer';
import { ButtonLikeRadioInput, CarouselValueSelector, FuelLoadInput, ImageUpload, PreviousValue } from 'components';
import { curingCarouselSlides, fuelConditionCarouselSlides, LocationManager, ProgressSteps } from 'models';
import React, { Dispatch, SetStateAction } from 'react';

import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { calculateFuelLoad, toDDMonFormat, getFuelLoadRange } from 'utils';
import { RootState } from 'store';

const createDataEntrySteps = (
  newObservation: Partial<LocationManager.Location.Observation>,
  location: LocationManager.Location | null | undefined,
  dispatch: ThunkDispatch<RootState, void, Action>,
  openHelp: (id: string) => void,
  fuelLoadState: [boolean, Dispatch<SetStateAction<boolean>>],
): ProgressSteps[] => {
  if (!location) return [];

  const [fuelLoadEdited, setFuelLoadEdited] = fuelLoadState;
  const [lowerBound, upperBound] = getFuelLoadRange(
    newObservation.fuelCondition ?? location.observations[0]?.fuelCondition ?? 0,
    newObservation.continuous ?? location.observations[0]?.continuous ?? false,
  );
  const lastObsDate = location.observations[0] ? toDDMonFormat(new Date(location.observations[0].time)) : 'never';

  return [
    {
      title: newObservation.curing != null ? `${newObservation.curing}% curing` : 'Curing',
      subtitle: location.observations[0] ? `${location.observations[0].curing}% on ${lastObsDate}` : 'No previous data',
      helpId: 'grassCuring',
      heading: 'Grass Curing',
      label: 'Swipe to select a value',
      completed: newObservation.curing != null,
      content: (
        <>
          <CarouselValueSelector
            key="curing"
            initialValue={newObservation.curing ?? location.observations[0]?.curing ?? 0}
            slides={curingCarouselSlides}
            onChange={(value) =>
              dispatch(
                ObserverActions.setNewObservation({
                  id: location.id,
                  current: {
                    curing: value,
                  },
                }),
              )
            }
            openHelp={openHelp}
            style={{
              width: '100%',
            }}
          />
          {location.observations[0]?.curing != null && (
            <PreviousValue
              text={`${location.observations[0].curingPrevious}%`}
              time={location.observations[0].validationTime}
            />
          )}
        </>
      ),
    },
    {
      title:
        newObservation.fuelCondition != null
          ? LocationManager.fuelConditionText(newObservation.fuelCondition)
          : 'Fuel Condition',
      subtitle: location.observations[0]
        ? `${LocationManager.fuelConditionText(location.observations[0].fuelCondition)} on ${lastObsDate}`
        : 'No previous data',
      heading: 'Fuel Condition',
      helpId: 'fuelCondition',
      label: 'Swipe to select a value',
      completed: newObservation.fuelCondition != null,
      content: (
        <>
          <CarouselValueSelector
            key="fuelcondition"
            slides={fuelConditionCarouselSlides}
            initialValue={newObservation.fuelCondition ?? location.observations[0]?.fuelCondition ?? 0}
            onChange={(value) =>
              dispatch(
                ObserverActions.setNewObservation({
                  id: location.id,
                  current: {
                    fuelCondition: value,
                    fuelLoad: fuelLoadEdited
                      ? newObservation.fuelLoad
                      : calculateFuelLoad(
                          value,
                          newObservation.continuous ?? location.observations[0]?.continuous ?? false,
                        ),
                  },
                }),
              )
            }
            openHelp={openHelp}
            style={{
              width: '100%',
            }}
          />
          {location.observations[0]?.fuelCondition != null && (
            <PreviousValue
              text={LocationManager.fuelConditionText(location.observations[0].fuelConditionPrevious)}
              time={location.observations[0].validationTime}
            />
          )}
        </>
      ),
    },
    {
      title:
        newObservation.continuous != null
          ? `${newObservation.continuous ? 'Continuous' : 'Discontinuous'}`
          : 'Continuity',
      subtitle:
        location.observations[0]?.continuous != null
          ? `${location.observations[0].continuous ? 'Continuous' : 'Discontinuous'} on ${lastObsDate}`
          : `No previous data`,
      heading: 'Continuity',
      helpId: 'fuelContinuity',
      label: 'Please specify the continuity',
      completed: newObservation.continuous != null,
      content: (
        <>
          <ButtonLikeRadioInput
            options={[
              { text: 'Continuous', value: 1 },
              { text: 'Discontinuous', value: 0 },
            ]}
            initialValue={newObservation.continuous ?? location.observations[0]?.continuous ? 1 : 0}
            onChange={(value) =>
              dispatch(
                ObserverActions.setNewObservation({
                  id: location.id,
                  current: {
                    continuous: value === 1,
                    fuelLoad: fuelLoadEdited
                      ? newObservation.fuelLoad
                      : calculateFuelLoad(
                          newObservation.fuelCondition ?? location.observations[0]?.fuelCondition ?? 0,
                          value === 1,
                        ),
                  },
                }),
              )
            }
            style={{ width: '100%' }}
          />
          {location.observations[0]?.continuous != null && (
            <PreviousValue
              text={location.observations[0].continuous ? 'Continuous' : 'Discontinuous'}
              time={location.observations[0].validationTime}
            />
          )}
        </>
      ),
    },
    {
      title: newObservation.fuelLoad != null ? `${newObservation.fuelLoad} t/ha` : 'Fuel Load',
      subtitle: location.observations[0]
        ? `${location.observations[0].fuelLoad} t/ha on ${lastObsDate}`
        : 'No previous data',
      heading: 'Fuel Load',
      helpId: 'fuelLoad',
      label: 'Review calculated fuel load',
      completed: newObservation.fuelLoad != null,
      content: (
        <>
          <div>
            <Typography variant="subtitle1" color="textSecondary" style={{ padding: 8 }}>
              The value below is the default between the guidance range of {lowerBound} to {upperBound} based on your
              input of fuel condition (
              {newObservation.fuelCondition != null && location.observations[0]
                ? LocationManager.fuelConditionText(
                    newObservation.fuelCondition ?? location.observations[0].fuelCondition,
                  )
                : 'none'}
              ) and continuity (
              {
                // eslint-disable-next-line no-nested-ternary
                newObservation.continuous != null && location.observations[0]
                  ? newObservation.continuous ?? location.observations[0].continuous
                    ? 'Continuous'
                    : 'Discontinuous'
                  : 'none'
              }
              ). Values may be adjusted to reflect observed conditions.
            </Typography>
            <FuelLoadInput
              fuelCondition={newObservation.fuelCondition ?? location.observations[0]?.fuelCondition ?? 0}
              continuious={newObservation.continuous ?? location.observations[0]?.continuous ?? 0}
              initialValue={newObservation.fuelLoad ?? 0}
              onChange={(value) => {
                setFuelLoadEdited(true);
                dispatch(
                  ObserverActions.setNewObservation({
                    id: location.id,
                    current: {
                      fuelLoad: value,
                    },
                  }),
                );
              }}
              style={{ width: '100%' }}
            />
          </div>
          {location.observations[0] && (
            <PreviousValue
              text={`${location.observations[0].fuelLoadPrevious} t/ha`}
              time={location.observations[0].validationTime}
            />
          )}
        </>
      ),
    },
    {
      title: 'Photo',
      subtitle:
        newObservation.image != null ||
        (location.observations[0] && location.observations[0].submitted && location.observations[0].imageUrl != null)
          ? 'Uploaded'
          : 'Incomplete',
      image: newObservation.image,
      heading: 'Observation Photo',
      helpId: 'observationPhoto',
      label: 'Upload an observation photo',
      completed:
        newObservation.image != null ||
        (location.observations[0] && location.observations[0].submitted && location.observations[0].imageUrl != null),
      optional: true,
      content: (
        <>
          <div />
          <ImageUpload
            initialValue={newObservation.image}
            initialURL={
              location.observations[0] && location.observations[0].submitted
                ? location.observations[0].imageUrl
                : undefined
            }
            onChange={(value) =>
              dispatch(
                ObserverActions.setNewObservation({
                  id: location.id,
                  current: {
                    image: value || undefined,
                  },
                }),
              )
            }
          />
        </>
      ),
    },
  ];
};

export default createDataEntrySteps;
