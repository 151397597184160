import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Box, CircularProgress, Dialog, DialogContent, Theme, Typography } from '@material-ui/core';

import { View } from 'ol';
import { Style, Icon } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { FeatureLike } from 'ol/Feature';

import config from 'config';
import { LayerManager, LocationManager } from 'models';
import { useAppSelector, useAppDispatch } from 'hooks';
import { LocationActions } from 'state/location';
import {
  MapCommand,
  ZoomToCoords,
  ZoomToBounds,
  AddMarkers,
  HideOverlay,
  AddScaleLine,
  MouseCoords,
  ShowOverlay,
  AddWmsTileLayer,
  AddWmsMvtLayer,
} from 'components/map/MapCommands';
import { getUserState } from 'utils';

import NewLocationComponent from 'components/locationadmin/NewLocationComponent';
import EditLocationComponent from 'components/locationadmin/EditLocationComponent';
import LocationsComponent from 'components/locationadmin/LocationComponent';
import LocationPopup from 'components/map/LocationPopup';
import MeasureToolControls from 'components/map/MeasureToolControls';
import MeasureTool, { ShapeType } from 'components/map/MeasureTool';

import { FloatingMapButton, BasemapsBoundariesDialog, Legend, Map, MapOverlay, LayerList } from '../components';
import OpacitySlider from 'components/map/OpacitySlider';
import { Close, Edit, Layers } from '@material-ui/icons';
import { LayerActions } from 'state/layers';

const panelWidth = 450;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      background: theme.palette.common.white,
    },
    leftPanel: {
      width: panelWidth,
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
    },
    leftContent: {
      height: '100%',
      overflow: 'auto',
      padding: 25,
    },
    rightPanel: {
      position: 'absolute',
      left: panelWidth,
      right: 0,
      bottom: 0,
      top: 0,
      height: '100%',
      overflowX: 'hidden',
    },
    layersFAB: {
      flexGrow: 1,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
    popupTitle: {
      color: theme.palette.common.neutralDark,
    },
  }),
);

const styleMarker = (f: FeatureLike) =>
  new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: f.get('meta') === 'active' ? '/map/marker-dark.png' : '/map/marker-light.png',
    }),
  });

const FSELocationAdminDesktop = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [showNewValue, setShowNew] = useState(false);
  const [formLocationId, setFormLocationId] = useState(0); // location in the left section
  const [mapLocationPopupId, setMapLocationPopupId] = useState(0); // location popup on the map
  const [mapDispatch, setMapDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();
  const [featureLayerCommand, setFeatureLayerCommand] = useState<AddWmsTileLayer>();
  const [boundaryLayerCommand, setBoundaryLayerCommand] = useState<AddWmsMvtLayer>();
  const [basemapsDialogOpen, setBasemapsDialogOpen] = useState(false);
  const [layerDialogOpen, setLayerDialogOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const { current } = containerRef;
  const view = new View({ center: fromLonLat([130, -28]), zoom: 5 });
  const { location, auth } = useAppSelector((state) => state);
  const { locations } = location;
  const { layers, selectedBoundary, selectedMainLayer, selectedBaseMap } = useAppSelector((state) => state.layers);
  const dispatch = useAppDispatch();

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => scrollRef?.current?.scrollTo(0, 0);
  const selectedFeatureLayer = layers.object?.find((l) => l.id === selectedMainLayer);

  // loads the layers once page/auth is loaded
  // map display is handled elsewhere
  useEffect(() => {
    if (layers.status === 'idle' && auth.status === 'finished') {
      dispatch(LayerActions.getLayers());
      dispatch(LayerActions.setSelectedMainLayer(null));
    }
  }, [dispatch, layers.status, auth.status]);

  // loads the locations once page/auth is loaded
  // map display is handled elsewhere
  useEffect(() => {
    if (locations.status === 'idle' && auth.status === 'finished') dispatch(LocationActions.getLocations());
  }, [dispatch, locations.status, auth.status]);

  // updates the boundary on the map after it changes
  useEffect(() => {
    const selectedBoundaryLayer = layers.object?.find((l) => l.id === selectedBoundary);
    boundaryLayerCommand?.update({
      layerName: selectedBoundaryLayer != null ? selectedBoundaryLayer.serviceName : null,
      labelField: selectedBoundaryLayer != null ? selectedBoundaryLayer.mvtLabelField : null,
      auth: auth.object,
    });
  }, [selectedBoundary, boundaryLayerCommand, layers.object, auth.object]);

  // updates the main layer on the map when it changes
  useEffect(() => {
    featureLayerCommand?.update({
      layerName: selectedFeatureLayer?.serviceName ?? null,
      auth: auth.object,
      date: LayerManager.getLatestLayerStartDate([selectedFeatureLayer]),
    });
  }, [selectedMainLayer, featureLayerCommand, layers.object, auth.object]);

  const getBounds = useCallback(() => {
    // 5 is a magic number; a guestimate of when we should change to the jurisdiction bounds
    if (locations.object?.length == null || locations.object?.length <= 5) {
      return config.jurisdictionBounds[getUserState(auth?.object) || 'aus'];
    }
    return {
      minLong: Math.min(...locations.object?.map((x) => x.geometry.coordinates[0])),
      minLat: Math.min(...locations.object?.map((x) => x.geometry.coordinates[1])),
      maxLong: Math.max(...locations.object?.map((x) => x.geometry.coordinates[0])),
      maxLat: Math.max(...locations.object?.map((x) => x.geometry.coordinates[1])),
    };
  }, [locations.object, auth]);

  const handleLocationListClick = (l: LocationManager.Location.LocationAdminAPI.Location) => {
    setFormOpen(true);
    setFormLocationId(l.location_id);
    scrollToTop();
    mapDispatch?.dispatch(new ZoomToCoords(l.geometry.coordinates, 15));
  };

  const handleLocationMapClick = (id: number) => {
    const l = locations?.object?.find((x) => x.location_id === id);
    if (!l) return;

    setMapLocationPopupId(l.location_id);
    // if side bar already on details
    if (formOpen) {
      scrollToTop();
      mapDispatch?.dispatch(new ZoomToCoords(l.geometry.coordinates, 15));
      setFormLocationId(l.location_id);
    }
    mapDispatch?.dispatch(new ShowOverlay('location', l.geometry.coordinates));
  };

  // this useEffect displays the locations on the map once they're loaded after another useEffect
  useEffect(() => {
    if (locations.status === 'finished') {
      const markers = (locations.object || []).map((x) => ({
        coords: x.geometry.coordinates,
        id: x.location_id,
        meta: x.status,
      }));

      mapDispatch?.dispatch(new AddMarkers(markers, handleLocationMapClick, styleMarker));
      if (!locations.object) return;
      const bounds = getBounds();
      if (bounds) mapDispatch?.dispatch(new ZoomToBounds(bounds));
    }
  }, [getBounds, current, locations.object, locations.status, mapDispatch, formOpen]);

  const measureTool = useMemo(() => {
    return new MeasureTool(true, ShapeType.LineString, true);
  }, []);

  const handleMapRegistration = (mapDispatcher: (command: MapCommand) => void) => {
    setMapDispatch({ dispatch: mapDispatcher });
    mapDispatcher(new AddScaleLine());
    mapDispatcher(new MouseCoords());

    const featureLayer = new AddWmsTileLayer({ layerName: null, auth: auth.object });
    setFeatureLayerCommand(featureLayer);
    mapDispatcher(featureLayer);

    const boundaryLayer = new AddWmsMvtLayer({ layerName: null, labelField: null, auth: auth.object });
    setBoundaryLayerCommand(boundaryLayer);
    mapDispatcher(boundaryLayer);

    mapDispatcher(measureTool);
  };

  const handleOpacity = (value: number) => {
    if (featureLayerCommand) {
      featureLayerCommand.setOpacity(value);
    }
  };

  const handleCancel = () => {
    setShowNew(false);
    setFormOpen(false);
    setFormLocationId(0);
    mapDispatch?.dispatch(new HideOverlay('location'));
    scrollToTop();
    const bounds = getBounds();
    if (bounds) mapDispatch?.dispatch(new ZoomToBounds(bounds));
  };

  const handleAddNew = () => {
    setShowNew(true);
    scrollToTop();
  };

  const renderLeftSection = () => {
    if (locations.status !== 'finished') {
      return <CircularProgress style={{ marginTop: 50, marginLeft: 140 }} aria-valuetext="loading" />;
    }
    if (showNewValue) return <NewLocationComponent onCancel={handleCancel} mapDispatch={mapDispatch?.dispatch} />;
    if (formLocationId) {
      const selectedLocation = locations.object?.find((x) => x.location_id === formLocationId);
      if (selectedLocation) {
        return <EditLocationComponent onCancel={handleCancel} location={selectedLocation} />;
      }
    }
    return (
      <LocationsComponent
        locations={locations.object || []}
        onShowNew={handleAddNew}
        onClick={handleLocationListClick}
      />
    );
  };

  const hideLocationOverlay = () => {
    mapDispatch?.dispatch(new HideOverlay('location'));
  };

  const btnText = selectedFeatureLayer?.name ?? 'Set layer';
  let btnFontSize = 14;
  if (btnText.length > 14) btnFontSize = 12;
  if (btnText.length > 18) btnFontSize = 11;

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.leftPanel}>
        <div className={classes.leftContent} ref={scrollRef}>
          {renderLeftSection()}
        </div>
      </div>
      <div className={classes.rightPanel}>
        <Map
          registerMapCommand={handleMapRegistration}
          view={view}
          showUser
          basemap={selectedBaseMap}
          shouldDisplay
          customOverlays={[
            {
              id: 'location',
              jsx: (
                <LocationPopup
                  close={hideLocationOverlay}
                  location={locations?.object?.find((x) => x.location_id === mapLocationPopupId)}
                  onViewDetails={handleLocationListClick}
                />
              ),
            },
          ]}
        >
          {/* top left box */}
          <Box display="flex" flexDirection="row" style={{ position: 'absolute', left: '70px', top: '20px' }}>
            <FloatingMapButton
              width="max-content"
              height="30px"
              flex={true}
              className={classes.layersFAB}
              buttonStyle={{
                color: theme.palette.common.neutralDark,
                fontSize: btnFontSize,
              }}
              style={{
                borderRadius: '45px',
              }}
              muiButton
              onClick={() => {
                setLayerDialogOpen(true);
              }}
            >
              {btnText} <Edit />
            </FloatingMapButton>

            <FloatingMapButton
              onClick={() => setBasemapsDialogOpen(true)}
              buttonStyle={{
                color: theme.palette.common.neutralDark,
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
              width="150px"
              height="30px"
              flex={true}
              className={classes.layersFAB}
              style={{ borderRadius: 4 }}
            >
              <Typography variant="subtitle1" className={classes.popupTitle} style={{ display: 'flex' }}>
                <Layers /> Layer Settings
              </Typography>
            </FloatingMapButton>
          </Box>

          {/* top right box */}
          <Box
            display="flex"
            flexDirection="column"
            style={{
              position: 'absolute',
              left: 'default',
              right: theme.spacing(2),
              top: theme.spacing(2),
            }}
          >
            <MapOverlay
              opacity={1.0}
              flex={true}
              style={{
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
                filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
              }}
            >
              <OpacitySlider onChange={handleOpacity} />
            </MapOverlay>

            {selectedFeatureLayer && (
              <MapOverlay
                flex={true}
                opacity={1.0}
                style={{
                  marginTop: theme.spacing(1),
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
                }}
              >
                <Legend layer={selectedFeatureLayer ?? null} />
              </MapOverlay>
            )}
          </Box>

          {/* bottom right box */}
          <Box
            display="flex"
            flexDirection="column"
            style={{
              position: 'absolute',
              left: 'default',
              bottom: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <MapOverlay
              flex={true}
              opacity={1.0}
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
              }}
            >
              <MeasureToolControls measureTool={measureTool} />
            </MapOverlay>
          </Box>

          {/* Dialogs / modals */}
          <BasemapsBoundariesDialog open={basemapsDialogOpen} setOpen={setBasemapsDialogOpen} />
          <Dialog
            open={layerDialogOpen}
            onClose={() => setLayerDialogOpen(true)}
            fullWidth
            maxWidth="md"
            aria-labelledby="Feature Layer Manager"
            aria-describedby="Manages the feature layers for the selected map"
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
              <Typography variant="h6" className={classes.popupTitle}>
                Base Layers
              </Typography>
              <Close style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setLayerDialogOpen(false)} />
            </div>
            <DialogContent>
              <LayerList
                columns={2}
                hideBookmark
                filterFn={(l) => !l.isBaseMap && !l.isBoundary && !l.isHidden && !l.isPrivileged && !l.isUtility}
              />
            </DialogContent>
          </Dialog>
        </Map>
      </div>
    </div>
  );
};

export default FSELocationAdminDesktop;
