import { makeAPICall, makeMockAPICall } from './api';

// import colourInterpHelper from './helper_functions/colourInterpHelper';

import { colourInterpHelper } from '../utils/index';

import * as AuthManager from 'models/auth';
import * as LayerManager from 'models/layer';
import config from 'config';

/**
 * Meant to define the relevant details of a Web Map Service (WMS)
 * To avoid rewriting the wheel too much, best to implement from OpenLayers (import 'ol') types or
 * have types that extend them for our own specific use cases.
 *
 * For a general WMS Spec see this url:
 * https://docs.geoserver.org/stable/en/user/services/wms/reference.html
 *
 * example query (missing '&' between query params):
 *
 *  http://afdrs-alb-385932098.ap-southeast-2.elb.amazonaws.com/geoserver/fse/wms?
 *      SERVICE=WMS
 *      VERSION=1.1.1
 *      REQUEST=GetMap
 *      FORMAT=image/png
 *      TRANSPARENT=true
 *      LAYERS=fse:jurisdiction
 *      exceptions=application/vnd.ogc.se_inimage
 *      SRS=EPSG:4326
 *      STYLES=
 *      WIDTH=768
 *      HEIGHT=654
 *      BBOX=102.3046875,-47.900390625,169.8046875,9.580078125
 */
export declare namespace WMS {
  export type GetCapabilitiesQuery = Pick<
    WMS.Query,
    'request' | 'service' | 'version' | 'request' | 'namespace' | 'format' | 'rootLayer'
  >;

  export type GetLegendGraphic = Pick<
    WMS.Query,
    'service' | 'version' | 'request' | 'layer' | 'format' | 'legend_options' | 'width' | 'height'
  >;

  export type GetMapQuery = Pick<
    WMS.Query,
    'request' | 'service' | 'version' | 'transparent' | 'bgcolor' | 'exceptions' | 'time' | 'sld' | 'sld_body'
  > &
    Required<Pick<WMS.Query, 'layers' | 'styles' | 'srs' | 'bbox' | 'width' | 'height' | 'format'>>;

  export type GetFeatureInfoQuery = Pick<
    WMS.Query,
    | 'request'
    | 'service'
    | 'version'
    | 'info_format'
    | 'feature_count'
    | 'exceptions'
    | 'buffer'
    | 'cql_filter'
    | 'filter'
    | 'propertyName'
    | 'exclude_nodata_result'
    | 'i'
    | 'j'
  > &
    Required<Pick<WMS.Query, 'layers' | 'styles' | 'srs' | 'bbox' | 'width' | 'height' | 'query_layers' | 'x' | 'y'>>;

  /**
   * Openlayers doesn't exactly have a query object/interface that can easily be interchanged.
   * Generally the url is built from wmslayer.getFeatureInfoURL however this takes info from both the the
   * layer object and parameters to that method. Instead we want a query interface that is detached from any layer object.
   * We want this so it's more standard when passing it around, and more standard functions can then be created.
   */
  export interface Query {
    /* Shared between most request Types */
    service: 'WMS';
    version: '1.0.0' | '1.1.0' | '1.1.1' | '1.3.0';
    request: 'GetCapabilities' | 'GetMap' | 'GetFeatureInfo' | 'DescribeLayer' | 'GetLegendGraphic';
    // As per https://docs.geoserver.org/stable/en/user/services/wms/reference.html#exceptions default xml
    exceptions?:
      | 'application/vnd.ogc.se_xml'
      | 'application/vnd.ogc.se_inimage'
      | 'application/vnd.ogc.se_blank'
      | 'application/vnd.gs.wms_partial'
      | 'application/json'
      | 'text/javascript';

    // As per https://docs.geoserver.org/stable/en/user/services/wms/outputformats.html#wms-output-formats
    format?:
      | 'image/png'
      | 'image/png8'
      | 'image/jpeg'
      | 'image/vnd.jpeg-png'
      | 'image/vnd.jpeg-png8'
      | 'image/gif'
      | 'image/tiff8'
      | 'image/geotiff'
      | 'image/geotiff8'
      | 'image/svg'
      | 'application/pdf'
      | 'rss'
      | 'kml'
      | 'kmz'
      | 'application/openlayers'
      | 'application/json;type=utfgrid'
      | 'application/json';

    /* Specific to both GetFeatureInfo and GetMap */
    // defined to be a comma separated list
    layers?: string;
    // defined to be a comma separated list
    styles?: string;
    srs?: string;
    // at least one of src or crs must exist as per defintion, here if crs exists, it will override src which may then be empty
    crs?: string;
    // by definition normally formatted as minx,miny,maxx,maxy
    bbox?: string;
    // in pixels
    width?: number;
    // in pixels
    height?: number;

    /* GetFeatureInfo */
    // normally a comma separated list
    query_layers?: string[];
    info_format?:
      | 'text/plain'
      | 'application/vnd.ogc.gml'
      | 'application/vnd.ogc.gml/3.1.1'
      | 'text/html'
      | 'application/json'
      | 'text/javascript';
    // max features to return: defaults to 1
    feature_count?: number;
    // X and Y coords of point on map
    x?: number;
    y?: number;
    // I and J are parameter keys for the X/Y points on the map (at least x/y or i/j must exist)
    i?: string;
    j?: string;

    // Some vendor specific parameters see: https://docs.geoserver.org/stable/en/user/services/wms/vendor.html#wms-vendor-parameters
    buffer?: number;
    // ECQL format, see: https://docs.geoserver.org/latest/en/user/filter/ecql_reference.html
    cql_filter?: string;
    // OGC format, see https://docs.geoserver.org/latest/en/user/filter/filter_reference.html
    filter?: string;
    // feature properties to be returned
    propertyName?: string[];
    exclude_nodata_result?: boolean;

    /* Specific to GetMap */

    // default false
    transparent?: boolean;
    // format RRGGBB (default white 'FFFFFF')
    bgcolor?: string;
    // general format yyyy-MM-ddThh:mm:ss.SSSZ see: https://docs.geoserver.org/stable/en/user/services/wms/time.html#wms-time
    time?: string;
    // references a styled layer descriptor XML file, see: https://docs.geoserver.org/stable/en/user/styling/index.html#styling
    sld?: string;
    // url encoded body of file that can be referenced above
    sld_body?: string;

    /* Special to get Capabilities */
    namespace?: string;
    rootLayer?: boolean;

    // the name of the layer
    layer?: string;

    legend_options?: string;
  }

  export namespace GetLegend {
    export interface Graphic {
      mark: string;
      fill: string;
      'fill-opacity': string;
      stroke: string;
      'stroke-width': string;
      'stroke-opacity': string;
      'stroke-linecap': string;
      'stroke-linejoin': string;
    }

    export interface Point {
      title: string;
      abstract: string;
      url: string;
      size: string;
      opacity: string;
      rotation: string;
      graphics: Graphic[];
    }

    export interface Entry {
      label?: string;
      quantity: string;
      color: string;
      opacity: string;
    }

    export interface Colormap {
      entries: Entry[];
      type: string;
    }

    export interface Raster {
      colormap?: Colormap;
      opacity: string;
    }

    export interface Polygon {
      fill?: string;
    }

    export interface Symbolizer {
      Raster?: Raster;
      Point?: Point;
      Polygon?: Polygon;
    }

    export interface Rule {
      symbolizers: Symbolizer[];
      title?: string;
      abstract?: string;
    }

    export interface Legend {
      layerName: string;
      title: string;
      rules: Rule[];
    }

    export interface Root {
      Legend: Legend[];
    }
  }

  export namespace WMSGetFeatureInfo {
    export type Properties = Record<string, any>;

    export interface Feature {
      type: string;
      id: string;
      geometry?: any;
      properties: Properties;
    }

    export interface Root {
      type: string;
      features: Feature[];
      totalFeatures: string;
      numberReturned: number;
      timeStamp: string;
      crs?: any;
    }
  }
}

export function makeGetLegendQuery(
  params: Omit<WMS.GetLegendGraphic, 'request' | 'service' | 'version' | 'format' | 'layer'> &
    Partial<WMS.GetLegendGraphic>,
): WMS.GetLegendGraphic {
  const defaults: Partial<WMS.GetLegendGraphic> = {
    request: 'GetLegendGraphic',
    service: 'WMS',
    version: '1.1.1',
    format: 'application/json',
  };
  return {
    ...defaults,
    ...params,
  } as WMS.GetLegendGraphic;
}

const convertParameters = (request: any, skipEncoding?: boolean) =>
  Object.keys(request)
    .map((key) => `${key}=${skipEncoding ? `${(request as any)[key]}` : encodeURIComponent((request as any)[key])}`)
    .join('&');

export async function queryWMS(
  layers: LayerManager.Layer[],
  coords: number[],
  auth?: AuthManager.Auth | null,
  date?: Date,
  issueTime?: Date,
): Promise<WMS.WMSGetFeatureInfo.Root | null> {
  const layerIdString = layers.map((l) => l.serviceName).join(',');
  if (!layerIdString) return null;

  const preprocessedParams: Record<string, any> = {
    service: 'WMS',
    version: '1.1.0',
    request: 'GetFeatureInfo',
    layers: layerIdString,
    query_layers: layerIdString,
    bbox: [...coords.map((x) => x - 0.01), ...coords.map((x) => x + 0.01)].join(','),
    srs: 'EPSG:4326',
    width: 10,
    height: 10,
    x: 5,
    y: 5,
    info_format: 'application/json',
    feature_count: 50,
  };

  if (date) preprocessedParams.TIME = date.toISOString();
  if (issueTime) preprocessedParams.DIM_ISSUE_TIME = issueTime.toISOString();

  const parameters = convertParameters(preprocessedParams);

  const wmsPath = `${config.geoserver_url}/wms`;

  const requestHeaders: HeadersInit = new Headers();
  if (auth?.creds?.access_token && auth.creds.access_token.length > 0) {
    requestHeaders.set('Authorization', `${auth.creds.token_type} ${auth.creds.access_token}`);
  }

  try {
    const response = await fetch(`${wmsPath}?${parameters}`, { headers: requestHeaders });
    const json = (await response.json()) as WMS.WMSGetFeatureInfo.Root;
    return json;
  } catch (e) {
    return null;
  }
}

export async function queryWMSValues(
  layers: LayerManager.Layer[],
  coords: number[],
  auth?: AuthManager.Auth | null,
  date?: Date,
  issueTime?: Date,
): Promise<Record<string, number>> {
  const layerIdList = layers.map((l) => l.id);
  const layerIdString = layers.map((l) => l.serviceName).join(',');
  if (!layerIdString) return {};

  try {
    const response = await queryWMS(layers, coords, auth, date, issueTime);

    if (response == null) return {};

    const resultList = response.features.map((feat) => feat.properties.GRAY_INDEX);
    const result: Record<string, number> = {};
    layerIdList.forEach((id, idx) => (result[id] = resultList[idx] > -9999 ? resultList[idx] : NaN));

    return result;
  } catch (e) {
    return {};
  }
}

export async function queryVectorLayerWMSValues(
  layers: LayerManager.Layer[],
  featureName: string,
  coords: number[],
  auth?: AuthManager.Auth | null,
  date?: Date,
  issueTime?: Date,
): Promise<Record<string, number | string>> {
  const layerIdList = layers.map((l) => l.id);
  const layerIdString = layers.map((l) => l.serviceName).join(',');
  if (!layerIdString) return {};

  try {
    const response = await queryWMS(layers, coords, auth, date, issueTime);

    if (response == null) return {};

    const resultList = response.features.map((feat) => feat.properties[featureName]);
    const result: Record<string, number | string> = {};
    layerIdList.forEach((id, idx) => (result[id] = resultList[idx] ? resultList[idx] : 'N/A'));

    return result;
  } catch (e) {
    return {};
  }
}

export async function queryWMSValuesFSE(
  layers: LayerManager.Layer[],
  coords: number[],
  auth?: AuthManager.Auth | null,
  date?: Date,
  issueTime?: Date,
): Promise<Record<string, number>> {
  const layerIdList = layers.map((l) => l.id);
  const layerIdString = layers.map((l) => l.serviceName).join(',');
  if (!layerIdString) return {};

  try {
    const response = await queryWMS(layers, coords, auth, date, issueTime);

    if (response == null) return {};

    const resultList = response.features.map((feat) => feat.properties.GRAY_INDEX);
    const result: Record<string, number> = {};
    layerIdList.forEach((id, idx) => (result[id] = resultList[idx] > -9999 ? resultList[idx] : NaN));

    return result;
  } catch (e) {
    return {};
  }
}

const timeBetweenCache = 60 * 60 * 1000; // Every hour
const legendCache: Partial<Record<LayerManager.Layer.LayerIds, WMS.GetLegend.Legend | null | undefined>> = {};
const legendCachePromise: Partial<
  Record<LayerManager.Layer.LayerIds, Promise<WMS.GetLegend.Legend> | null | undefined>
> = {};
const legendLastUpdateTime: Partial<Record<LayerManager.Layer.LayerIds, number | undefined>> = {};

export const forceGetLegend = makeAPICall<WMS.GetLegend.Legend, { layer: LayerManager.Layer }, WMS.GetLegend.Root>(
  ({ layer }) => {
    const query = makeGetLegendQuery({ layer: layer.serviceName ?? '' });
    return {
      ext: `/wms?${convertParameters(query)}`,
      endpoint: config.geoserver_url,
      skipAuth: false,
    };
  },
  (root) => root.Legend[0],
);

export const getLegend = makeMockAPICall<WMS.GetLegend.Legend | null, { layer: LayerManager.Layer }, null>(
  () => {},
  async (_, payload, state, dispatch) => {
    if (legendLastUpdateTime[payload.layer.id] == null) legendLastUpdateTime[payload.layer.id] = 0;

    if (
      Date.now() - (legendLastUpdateTime[payload.layer.id] ?? 0) > timeBetweenCache &&
      state?.auth.status === 'finished'
    ) {
      if (legendCachePromise[payload.layer.id]) {
        legendCache[payload.layer.id] = await legendCachePromise[payload.layer.id];
      } else {
        legendCachePromise[payload.layer.id] = forceGetLegend(payload, state, dispatch);
        legendCache[payload.layer.id] = await legendCachePromise[payload.layer.id];
        legendCachePromise[payload.layer.id] = null;
        legendLastUpdateTime[payload.layer.id] = Date.now();
      }
    }
    return legendCache[payload.layer.id] ?? null;
  },
  null,
  0,
);

export const getLegendImage = makeAPICall<string, { layer: LayerManager.Layer }, Blob>(
  ({ layer }) => {
    const query = makeGetLegendQuery({
      layer: layer.serviceName ?? '',
      format: 'image/png',
      legend_options:
        'bgColor:0xFFFFFF%3BfontSize:12%3BfontName:Arial%3BfontStyle:bold%3Bdpi:96%3Bmx:0.01%3Bmy:0.01%3Bdx:8%3BforceLabels:on%3B',
      width: 20,
      height: 20,
    });
    return {
      ext: `/wms?${convertParameters(query)}`,
      endpoint: config.geoserver_url,
      skipAuth: false,
      dataType: 'blob',
    };
  },
  (imgBlob) => URL.createObjectURL(imgBlob),
);

export const queryWMSImage = makeAPICall<
  string,
  { layer?: LayerManager.Layer; options: Partial<WMS.GetMapQuery> },
  Blob
>(
  ({ layer, options }) => {
    const query = {
      layers: layer?.serviceName ?? '',
      format: 'image/png',
      transparent: true,
      version: '1.1.0',
      service: 'WMS',
      request: 'GetMap',
      srs: 'EPSG:4326',
      ...options,
    };
    return {
      ext: `/wms?${convertParameters(query, true)}`,
      endpoint: config.geoserver_url,
      skipAuth: false,
      dataType: 'blob',
    };
  },
  (imgBlob) => URL.createObjectURL(imgBlob),
);

export const getFormattedPolygonLegendDataWithMappedValues = (legend: WMS.GetLegend.Legend) => {
  /**
   * When using <org:Recode> function in Geoserver SLD configs, this fill becomes a string like the following:
   * [Recode(fdr, 'label1', '#FFFFFF', 'label2', '#64BF30')]
   */
  const fill = legend.rules[0]?.symbolizers[0]?.Polygon?.fill
    ?.replace(/^\[Recode\(|\)\]$/g, '')
    .split(',')
    .slice(1);

  if (!fill) {
    console.group('Failed to get Legend information');
    console.error('Returning an empty legend instead.');
    console.error(legend);
    console.groupEnd();
    return [];
  }

  const entries = [];

  /**
   * Parse this string and convert it into WmsManager.WMS.GetLegend.Entry type
   * The quantity and opacity fields are mandatory in this type, but they are meaningless in this particular case
   */
  for (let i = 1; i < fill.length; i += 2) {
    entries.push({
      label: fill[i - 1].replace(/^['"]|['"]$/g, ''),
      color: fill[i].replace(/^['"]|['"]$/g, ''),
      quantity: '0',
      opacity: '1',
    });
  }

  return entries;
};

export const getLegendEntry = (
  value: number | string,
  legend?: WMS.GetLegend.Legend | null,
  forceType?: string,
): WMS.GetLegend.Entry | null => {
  if (legend == null) return null;

  const legendType =
    forceType ||
    legend.rules[0].symbolizers[0]?.Raster?.colormap?.type ||
    (legend.rules[0].symbolizers[0]?.Point?.url && 'graphic') ||
    (legend.rules[0].symbolizers[0]?.Polygon?.fill?.startsWith('[Recode(') ? 'polygonWithMappedValues' : undefined);

  let entries = null;
  if (legendType === 'polygonWithMappedValues') {
    entries = getFormattedPolygonLegendDataWithMappedValues(legend);
  } else {
    entries = legend.rules[0].symbolizers[0]?.Raster?.colormap?.entries;
  }

  let entry = null;
  if (entries) {
    if (legendType === 'intervals') {
      // Works for Fire Behaviour index, KBDI
      for (let i = 0; i < entries.length; i += 1) {
        if (+entries[i].quantity >= +value) {
          // Fix for mapping 0 to the 1 - 5 colour band
          if (i === 0) {
            entry = entries[i + 1];
          } else {
            entry = entries[i];
          }
          break;
        }
      }
    } else if (legendType === 'ramp') {
      // Works for Grass Curing case
      for (let i = 0; i < entries.length; i += 1) {
        if (+entries[i].quantity >= +value) {
          if (i === 0) {
            entry = entries[i];
          } else {
            entry = JSON.parse(JSON.stringify(entries[i]));
            entry.color = colourInterpHelper({
              colour1Hex: entries[i - 1].color,
              colour2Hex: entries[i].color,
              colour1Quantity: +entries[i - 1].quantity,
              colour2Quantity: +entries[i].quantity,
              interpColourQuantity: +value,
            });
          }

          break;
        }
      }
    } else if (legendType === 'values') {
      entry = entries.find((item) => item.quantity === `${value}`);
      if (!entry) {
        entry = entries.find((item) => +item.quantity === +value);
      }
    } else if (legendType === 'polygonWithMappedValues') {
      entry = entries.find((item) => item.label === `${value}`);
    }
  }
  return entry ?? null;
};

export const getColour = (
  value: number | string,
  legend?: WMS.GetLegend.Legend | null,
  forceType?: string,
): string | null => {
  return getLegendEntry(value, legend, forceType)?.color ?? null;
};

export const getLabel = (value: number | string, legend: WMS.GetLegend.Legend, forceType?: string): string | null => {
  return getLegendEntry(value, legend, forceType)?.label ?? null;
};
