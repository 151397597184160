import React, { useState } from 'react';
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Theme, Typography, TypographyProps, Tooltip } from '@material-ui/core';
import { helpLookup } from 'utils';

const useStyles = makeStyles(() =>
  createStyles({
    noOutline: {
      '&:focus': {
        outline: 'none',
      },
    },
  }),
);

const CustomTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(18),
    padding: theme.spacing(2),
  },
}))(Tooltip);

interface HelpTypographyProps extends TypographyProps {
  helpId?: string;
  tooltip?: boolean;
  text?: Array<string>;
  onTriggerHelp?: (id: string) => void;
}

const HelpTypography: React.FunctionComponent<HelpTypographyProps> = (props) => {
  const { helpId, onTriggerHelp, onClick, style, tooltip, children, text, ...restProps } = props;
  const theme = useTheme();
  const classes = useStyles();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(true);

  let helpText = helpId && helpLookup[helpId] ? helpLookup[helpId].text : [`The help text for "${helpId}" is missing.`];
  if (text) helpText = text;

  // To render JSX inside the title of the material ui a jsx fragment must be used
  const multilineText = (
    <>
      {helpText.map((txt) => (
        <span key={txt} style={{ display: 'block' }}>
          {txt}
        </span>
      ))}
    </>
  );

  return (
    <Typography
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      style={{
        textDecoration: helpId || text ? `underline dotted ${theme.palette.common.neutral}` : 'none',
        cursor: helpId || text ? 'pointer' : 'default',
        ...style,
      }}
      onClick={(e) => {
        if (onTriggerHelp && helpId != null) onTriggerHelp(helpId);
        if (onClick) onClick(e);
      }}
    >
      {tooltip ? (
        <CustomTooltip arrow disableHoverListener title={multilineText} onClose={handleTooltipClose} open={tooltipOpen}>
          <span
            onClick={handleTooltipOpen}
            onKeyPress={handleTooltipOpen}
            role="button"
            tabIndex={0}
            className={classes.noOutline}
          >
            {children}
          </span>
        </CustomTooltip>
      ) : (
        <span>{children}</span>
      )}
    </Typography>
  );
};

HelpTypography.displayName = 'HelpTypography';
export default HelpTypography;
