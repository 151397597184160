import { useEffect, useRef } from 'react';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<ThunkDispatch<RootState, void, Action>>();

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
