/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card, Button } from '@material-ui/core';

import { useAppDispatch } from 'hooks';
import { PrettierTextArea, Note } from 'components';
import { FuelTypeModelsManager } from 'models';
import { FuelTypeModelActions } from 'state/fueltypemodels';
import { ToastActions } from 'state/toast';
import { ToastType } from 'models/toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    primaryButton: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    button: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
      marginLeft: 0,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
      minWidth: 175,
      width: '-webkit-fill-available',
      // @ts-ignore This is for firefox
      width: '-moz-available',
    },
    textArea: {
      width: '100%',
      height: 300,
      border: 'none',
      padding: theme.spacing(2),
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      color: '#757575',
      fontSize: '16px',
      resize: 'none',
      outline: 0,
    },
    label: {
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(2),
    },
  }),
);

type AddNoteProps = {
  modelId: number;
  noteMode: string;
  modelStatus: string;
  userState: string;
  userRole: string;
  fuelTypeModel: FuelTypeModelsManager.FuelTypeModels.FuelTypeModel | undefined;
};

const AddNote: React.FunctionComponent<AddNoteProps> = (addNoteProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { modelId, noteMode, modelStatus, fuelTypeModel, userState, userRole } = addNoteProps;
  const [noteValue, setNoteValue] = useState('');

  const handleNoteUpdate = (note: string) => {
    console.log(handleNoteUpdate);
    setNoteValue(note);
  };

  const handleSubmit = () => {
    // ToDo dispath and then get again
    dispatch(FuelTypeModelActions.submitFuelTypeModelsNote({ modelId, noteText: noteValue })).then(() => {
      // this would not be applicable to modelStatus: authorised
      dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' })).then(() => {
        dispatch(
          ToastActions.showToast({
            message: `Fuel type model note added successfully`,
            type: ToastType.success,
          }),
        );
      });
    });
    navigate(`/${userRole}/fueltypemodels/${modelStatus}`);
  };

  if (modelId <= 0) return <>No Fuel Type Model</>;

  return (
    <div className={classes.root}>
      {noteMode === 'addnote' && (
        <div>
          <Typography className={classes.label}>Add Note</Typography>
          <Card>
            <PrettierTextArea
              className={classes.textArea}
              placeholder="Type note..."
              onChange={(e) => handleNoteUpdate(e.target.value)}
              minRows={10}
              value={noteValue}
            />
          </Card>
          <Button
            className={classes.button}
            // style={{ marginLeft: theme.spacing(3) }}
            disabled={noteValue === ''}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      )}

      {noteMode === 'viewnote' &&
        fuelTypeModel &&
        fuelTypeModel?.notes?.length !== 0 &&
        fuelTypeModel.notes?.map((note) => <Note key={note.id} note={note} />)}

      {noteMode === 'viewnote' && fuelTypeModel && fuelTypeModel?.notes?.length === 0 && (
        <Typography variant="subtitle1">No notes available</Typography>
      )}
    </div>
  );
};

export default AddNote;
