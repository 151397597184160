import React from 'react';
import { useAppDispatch } from 'hooks';
import { AnalyticsActions } from 'state/analytics';
import { Payload } from 'models/analytics';
import config from 'config';
/*
 * Sources used to create this error handler
 *  - Global logging: https://dev.to/omrilotan/front-end-observability-a-practical-guide-to-browser-error-monitoring-2gcm
 *  - Observability APIs: https://ericbidelman.tumblr.com/post/149032341876/the-web-has-a-lot-of-apis-for-observing-things
 *  - Event handling: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 */

export interface ListenerFunction {
  (evt: any): void;
}

interface ErrorFunction {
  (payload: Payload): void;
}

interface AddErrorHistory {
  (message: string): void;
}

interface ErrorProps {
  children: React.ReactNode;
  errorLoggingEnabled: boolean;
  onError: ErrorFunction;
  errorHistory: Array<string>;
  addErrorHistory: AddErrorHistory;
}

interface ErrorState {
  hasError: boolean;
}

class ErrorReporter extends React.Component<ErrorProps, ErrorState> {
  listenerFunction: EventListenerOrEventListenerObject;

  errorHistory: Array<string>;

  addErrorHistory: AddErrorHistory;

  onError: ErrorFunction | null;

  errorLoggingEnabled: boolean;

  constructor(props: ErrorProps) {
    super(props);

    const { onError, errorHistory, addErrorHistory, errorLoggingEnabled } = this.props;
    this.listenerFunction = (evt: Event) => {
      return this.sendError(evt);
    };
    this.onError = onError;
    this.errorHistory = errorHistory;
    this.addErrorHistory = addErrorHistory;
    this.errorLoggingEnabled = errorLoggingEnabled;
    this.state = { hasError: false };
  }

  /**
   * Subscribes global logger to errors on mount
   */
  componentDidMount(): void {
    let passiveSupported = false;
    try {
      const options = {
        // This function will be called when the browser attempts to access to passive property.
        get passive() {
          passiveSupported = true;
          return false;
        },
      };
      const nullEvent = () => {};
      window.addEventListener('afterprint', nullEvent, options);
      window.removeEventListener('afterprint', nullEvent);
    } catch (err) {
      passiveSupported = false;
    }
    const options: AddEventListenerOptions = {};
    if (passiveSupported) {
      options.passive = true;
    }
    window.addEventListener('error', this.listenerFunction, options);
  }

  /**
   * Limits rendering of component
   */
  shouldComponentUpdate(nextProps: ErrorProps, nextState: ErrorState): boolean {
    const { hasError } = this.state;
    return hasError !== nextState.hasError;
  }

  /**
   * Sends error to global logger
   */
  componentDidCatch(error: Error): void {
    this.sendError({ error });
  }

  /**
   * Removes listener for errors
   */
  componentWillUnmount(): void {
    if (this.listenerFunction != null) {
      window.removeEventListener('error', this.listenerFunction);
    }
  }

  /**
   * Updates state so next render shows fallback UI
   */
  static getDerivedStateFromError(): ErrorState {
    return { hasError: true };
  }

  /**
   * Controls logic of send error
   */
  sendError(evt: any): void {
    const errorEvent: ErrorEvent = evt as ErrorEvent;
    // Close the log behind a rollout mechanism to protect your infrastructure
    if (!this.errorLoggingEnabled) return;

    // Limit the amount of errors from one page
    // if ( this.errorHistory.length > 10) return;

    // Ignore extension sources
    if (errorEvent?.filename) {
      if (errorEvent.filename.startsWith('moz-extension://') || errorEvent.filename.startsWith('chrome-extension://')) {
        return;
      }
    }
    // Send the same error twice from the same page can create false multiplications
    if (this.errorHistory.includes(errorEvent.message)) return;
    // A page may be considered stale if it's been open for over, lets say, an hour
    if (window.performance.now() > 36e5) return;

    this.addErrorHistory(errorEvent.message);
    const params: Record<string, string | number | undefined> = {
      stack: errorEvent.error.stack,
      name: errorEvent.error.name,
      url: document.location.href,
      cookie: navigator.cookieEnabled ? document.cookie : 'disabled',
      language: navigator.language,
      readyState: document.readyState,
      secondsIn: Math.round(performance.now() / 1000), // page age in seconds
    };
    if (this.onError !== null) {
      this.onError({ level: 'error', message: errorEvent.message || '', params });
    }
  }

  /**
   * Returns an element to display if react crashes or throws an error
   */
  render(): React.ReactNode {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <>
          <h1>Error:</h1>
          <p>An error has occurred please refresh the page to continue working</p>
        </>
      );
    }
    const { children } = this.props;
    return children;
  }
}

const ThunkErrorReporter: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const initialHistory: Array<string> = [];
  const [errorHistory, setErrorHistory] = React.useState(initialHistory);
  const sendError = (payload: Payload) => {
    // Post error event ignoring problems that occur
    dispatch(AnalyticsActions.postCustomEvent(payload)).catch(() => {});
  };
  const addErrorHistory = (message: string) => setErrorHistory(errorHistory.concat([message]));

  return (
    <ErrorReporter
      onError={sendError}
      errorLoggingEnabled={config.enable_reporting}
      errorHistory={errorHistory}
      addErrorHistory={addErrorHistory}
    >
      {children}
    </ErrorReporter>
  );
};

export default ThunkErrorReporter;
