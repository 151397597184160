/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Link, CircularProgress, Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { DownloadActions } from 'state/download';
import { Error404 } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      color: theme.palette.common.black,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    link: {
      color: theme.palette.text.link,
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
    },
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      color: theme.palette.text.link,
    },
  }),
);

function DownloadFile() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector((state) => state);

  const filepath = new URLSearchParams(window.location.search).get('path');

  const filename = filepath?.split('/').pop() ?? null;

  const [fileLink, setFileLink] = useState<string | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'finished' | 'error'>('idle');

  const handleDownload = async () => {
    if (filepath && filename) {
      setStatus('loading');
      try {
        const blobLink = await dispatch(DownloadActions.downloadFile({ path: filepath }));

        if (blobLink) {
          const { blob, link } = blobLink;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;

          document.body.appendChild(a);
          a.click();
          a.remove();

          setFileLink(link);
          setStatus('finished');
        } else {
          setStatus('error');
        }
      } catch (e) {
        setStatus('error');
      }
    }
  };

  useEffect(() => {
    if (auth.status === 'finished') handleDownload();
  }, [auth.status]);

  return (
    <div>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h4" className={classes.title}>
            File download
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu} style={{ marginBottom: theme.spacing(6) }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          {(status === 'error' || !filepath) && (
            <div style={{ margin: theme.spacing(2) }}>
              <Error404
                link={process.env.REACT_APP_ID === 'FireDangerViewer' ? '/menu' : '/'}
                title="File not found"
                action="Click to return home"
              />
            </div>
          )}
          {(status === 'loading' || status === 'idle') && (
            <div style={{ display: 'grid', placeContent: 'center' }}>
              <CircularProgress size={30} aria-valuetext="loading" />
            </div>
          )}
          {fileLink && filename && (
            <div style={{ display: 'grid', placeContent: 'center' }}>
              <Typography style={{ marginBottom: theme.spacing(2) }} variant="subtitle1">
                If the download hasn&apos;t started, click the button below
              </Typography>
              <Button variant="outlined">
                <Link href={fileLink} download={filename} target="_blank" style={{ display: 'flex' }}>
                  <Typography variant="subtitle1" className={classes.link}>
                    {filename}
                  </Typography>
                  <GetApp className={classes.icon} />
                </Link>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DownloadFile;
