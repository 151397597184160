import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { RootState } from 'store';
import { AuthActions } from 'state/auth';

import config from 'config';

import * as AuthManager from './auth';

/**
 * A list of error codes to be used by the application, primarily for the purpose of logging and error statistics.
 */
export enum ErrorTypes {
  // 0-99 kept for misc application errors (although doesn't have to be strict)
  NOT_ENOUGH_DETAIL = 10,
  INVALID_CREDENTIALS = 20,
  FAILED_TO_SEND_REQUEST = 30,
  // 200 'everything is okay' errors
  OK = 200,
  // 400 errors are web related client errors
  BAD_REQUEST = 400,
  UNAUTHORISED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  IM_A_TEAPOT = 418, // Strictly for completeness reasons: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/418
  // 500 errors are web related server side errors
  SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  ESRI_ERROR = 990,
  GEOPROCESSING_FAILED = 991,
  MOCK_ERROR_CODE = 999,
}

export interface ErrorResp {
  error: string;
  code: ErrorTypes;
  data?: any;
}

// This function is repeated here to avoid a circular dependency caused when importing it from utils
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isErrorResp(obj: any): obj is ErrorResp {
  return typeof obj === 'object' && obj?.error != null && obj?.code != null;
}

export function castToIfNotErrorResp<T>(obj: any): T | ErrorResp {
  if (isErrorResp(obj)) {
    return obj;
  }
  return obj as T;
}

export function raiseIfError<T>(obj: any): T {
  if (isErrorResp(obj)) {
    throw obj;
  } else {
    return obj as T;
  }
}

export interface APIInterfacePayload {
  ext: string;
  requestData?: RequestInit;
  contentType?: string | null;
  dataType?: string;
  endpoint?: string;
  abortSignal?: AbortSignal | null;
  skipAuth?: boolean;
}

export type APIInterface<T extends APIInterfacePayload = APIInterfacePayload> = (
  payload: T,
  state?: RootState,
  dispatch?: ThunkDispatch<RootState, unknown, Action<string>>,
) => Promise<any | ErrorResp>;

export type APICallbackType<Payload = void, CBPayload extends APIInterfacePayload = APIInterfacePayload> = (
  payload: Payload,
  state?: RootState,
  dispatch?: ThunkDispatch<RootState, unknown, Action<string>>,
) => CBPayload;

export type APIMapFunctionType<Obj, Payload = void, RawAPIResponse = any> = (
  response: RawAPIResponse,
  payload: Payload,
  state?: RootState,
  dispatch?: ThunkDispatch<RootState, unknown, Action<string>>,
) => Obj | Promise<Obj>;

export const abstractAPICallFactory = <
  Obj,
  Payload = void,
  RawAPIResponse = any,
  CBPayload extends APIInterfacePayload = APIInterfacePayload,
>(
  apiCall: APIInterface<CBPayload>,
  cb: APICallbackType<Payload, CBPayload>,
  map: APIMapFunctionType<Obj, Payload, RawAPIResponse> = (resp) => resp as any as Obj,
) => {
  return async (
    payload: Payload,
    state?: RootState,
    dispatch?: ThunkDispatch<RootState, unknown, Action<string>>,
  ): Promise<Obj> => {
    const data: CBPayload = {
      requestData: {},
      contentType: 'application/json',
      ...cb(payload, state, dispatch),
    };
    // eslint-disable-next-line no-return-await
    return map(raiseIfError<RawAPIResponse>(await apiCall(data, state, dispatch)), payload, state, dispatch);
  };
};

export const mapCodeToMessage: Record<number, string> = {
  10: 'You cannot send this request as not enough detail has been provided',
  20: 'You have no credentials or your credentials are invalid',
  30: "An error occured while sending the request. This may be due to a failed intenet connection or the server didn't respond.",
  400: 'Bad request sent to server',
  401: 'You are not authorised to view this resource',
  403: 'You are not authorised to view this resource',
  404: 'URL on server cannot be found',
  418: 'Teapot Error',
  990: 'ESRI Error',
  991: 'Geoprocessing Task Failed',
  999: 'This is just a test error for Mock requests',
};

export const callAPI: APIInterface = async (payload, state, dispatch) => {
  const pl = {
    requestData: {} as RequestInit,
    contentType: 'application/json',
    endpoint: config.api_url,
    dataType: 'json',
    ...payload,
  };
  const requestHeaders: HeadersInit = new Headers();
  if (pl.contentType) requestHeaders.set('Content-Type', pl.contentType);
  const creds = state?.auth?.object?.creds;
  if (creds?.access_token && creds.access_token.length > 0) {
    requestHeaders.set('Authorization', `${creds.token_type} ${creds.access_token}`);
  }
  pl.requestData.headers = requestHeaders;
  try {
    let resp = await fetch(pl.endpoint + pl.ext.replace(pl.endpoint, ''), pl.requestData);
    if (resp.status === 401) {
      // There is either an issue with the token or no token when there should be
      if (dispatch && creds?.refresh_token) {
        // retry loading the token
        // reusing the existing refreshToken action appears not
        // to be typed properly or not allowed

        dispatch(
          AuthActions.setStatus({
            status: 'loading',
          }),
        );
        const tokenResp = await AuthManager.applyRefresh(creds);
        if (isErrorResp(tokenResp)) {
          // if loading the token failed, then we return an error
          dispatch(
            AuthActions.setStatus({
              status: 'error',
              error: tokenResp,
            }),
          );
          return {
            error: resp.statusText || mapCodeToMessage[resp.status] || '',
            code: resp.status,
            data: { refreshError: tokenResp },
          };
        }

        await dispatch(
          AuthActions.setStatus({
            status: 'finished',
          }),
        );
        await dispatch(
          AuthActions.setAuth({
            creds: {
              refresh_token: creds.refresh_token,
              ...tokenResp,
            },
          }),
        );

        const newHeaders: Record<string, string> = {};

        if (tokenResp?.access_token && tokenResp.access_token.length > 0) {
          newHeaders.Authorization = `${tokenResp.token_type} ${tokenResp.access_token}`;
        }

        // now lets retry the request with the new data
        resp = await fetch(pl.endpoint + pl.ext.replace(pl.endpoint, ''), {
          ...pl.requestData,
          headers: newHeaders,
        });
      }
    }
    if (!resp.ok) {
      if (resp.status === 401) {
        console.error(
          "Redirecting to login page as the API returned a 401 Error and an attempt to Refresh the token didn't work.",
        );
        // at this point the refresh token will have already been tried
        // or not available, so we should just redirect to the login page
        AuthManager.gotoAuth();
      }
      return {
        error: resp.statusText || mapCodeToMessage[resp.status] || '',
        code: resp.status,
        data: await resp.json(),
      };
    }
    if (pl.dataType === 'blob') {
      return await resp.blob();
    }
    return await resp.json();
  } catch (err) {
    return {
      error: mapCodeToMessage[ErrorTypes.FAILED_TO_SEND_REQUEST],
      code: ErrorTypes.FAILED_TO_SEND_REQUEST,
      data: err,
    } as ErrorResp;
  }
};

/**
 * An API Call Factory which accepts up to 3 Generics and 2 functions to define the API Call function to be built.
 * The 3 Generics in order are as follows:
 * - Obj: The type to be returned
 * - Payload: (void) The type of the payload to be provided to the function call
 * - RawAPIResponse: (any) The type of the expected Raw API response
 *
 * The two parameters are the pre and post API call functions.
 * The first is intended to map the Payload + Redux State to help init the request.
 * The second is intended to map the RawAPIResponse generic to the Obj generic.
 *
 * Using this function for building API Calls is preferable as it handles Authentication and error handling
 * in a manner that integrates well with building a Thunk Action (see: /src/actions/index.ts:makeThunkFromAPICall)
 *
 * @param cb A function that accepts the defined Payload + Redux state and returns request information.
 * @param map A function that maps the requests response to the specified output object Obj
 */
export const makeAPICall = <Obj, Payload = void, RawAPIResponse = any>(
  cb: APICallbackType<Payload>,
  map: APIMapFunctionType<Obj, Payload, RawAPIResponse>,
) => abstractAPICallFactory<Obj, Payload, RawAPIResponse>(callAPI, cb, map);

export function makeMockAPICall<Obj, Payload = void, RawAPIResponse = any>(
  cb: APICallbackType<Payload, any>,
  map: APIMapFunctionType<Obj, Payload, RawAPIResponse>,
  mockData?: RawAPIResponse,
  maxWaitTime = 0,
  failureRate = 0,
) {
  return async (
    payload: Payload,
    state?: RootState,
    dispatch?: ThunkDispatch<RootState, unknown, Action<string>>,
  ): Promise<Obj> => {
    // simulate waiting for server to respond
    cb(payload, state, dispatch);
    if (maxWaitTime > 0) await sleep(Math.random() * maxWaitTime);
    if (failureRate && Math.random() < failureRate)
      throw {
        error: `Mock API Call Test Error (error rate ${failureRate * 100}%)`,
        code: ErrorTypes.MOCK_ERROR_CODE,
      };
    return map(mockData as RawAPIResponse, payload, state, dispatch);
  };
}
