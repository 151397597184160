import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { LocationManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
    segmentTitle: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark;
    },
    segmentAge: {
      color: theme.palette.common.grey, // theme.palette.common.grey;
      fontWeight: 'bold',
    },
    segmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
    segmentValue: {
      cursor: 'pointer',
      textDecoration: 'underline',
      minWidth: '32px',
    },
  }),
);

interface FuelConditionStatusProps {
  style?: React.CSSProperties;
  className?: string;
  observation?: LocationManager.Location.Observation;
  prevObservation?: LocationManager.Location.Observation;
  onValueClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const FuelConditionStatus: React.FunctionComponent<FuelConditionStatusProps> = ({
  style,
  className,
  observation,
  prevObservation,
  onValueClick,
}) => {
  const classes = useStyles();

  return (
    <div className={className} style={style}>
      <Typography variant="subtitle2" className={classes.segmentTitle}>
        Fuel condition
      </Typography>
      {observation ? (
        <div className={classes.segmentMiddle}>
          <Typography variant="h5" className={classes.segmentValue} onClick={onValueClick}>
            {LocationManager.fuelConditionText(observation.fuelCondition ?? null)}
          </Typography>
        </div>
      ) : (
        <Typography variant="h5" className={classes.segmentValue} onClick={onValueClick}>
          null
        </Typography>
      )}
      {observation?.fuelCondition != null && prevObservation?.fuelCondition != null && observation != null ? (
        <Typography variant="subtitle2" className={classes.segmentAge}>
          {observation.fuelCondition === prevObservation.fuelCondition
            ? 'No change'
            : LocationManager.fuelConditionText(prevObservation.fuelCondition)}
        </Typography>
      ) : undefined}
    </div>
  );
};

FuelConditionStatus.displayName = 'FuelConditionStatus';
export default FuelConditionStatus;
