import { LayerManager, Common } from 'models';
import { makeAPICall } from './api';

export declare namespace Meteograms {
  export namespace API {
    export interface SeriesItem {
      forecast_issue_time: string;
      forecast: [string, number][];
      hindcast_issue_time?: string;
      hindcast?: [string, number][];
    }

    export type Series = Record<Partial<LayerManager.Layer.LayerIds>, SeriesItem>;

    export interface Observation {
      air_temperature: number;
      rainfall?: number;
      rel_humidity: number;
      time_utc: string;
      weather_observation_id: number;
      wind_dir: string;
      wind_dir_deg: number;
      wind_spd_kmh: number;
    }

    export interface WeatherStationInfo {
      weather_station_id: number;
      jurisdiction_id: number;
      jurisdiction_name: Common.Jurisdictions;
      stn_height: number;
      stn_name: string;
      bom_id: string;
      observations: Observation[];
    }

    export interface Root {
      series: Series;
      key_factors: Record<Partial<LayerManager.Layer.LayerIds>, number>;
      weather_station_info: WeatherStationInfo | null;
    }

    export interface WeatherStationsRoot {
      data: WeatherStationInfo[];
      count: number;
    }
  }

  export namespace WeatherStation {
    export interface Observation {
      id: number;
      created: Date;
      airTemperature: number;
      rainfall: number;
      relHumidity: number;
      windDir: string;
      windDirDeg: number;
      windSpeedKmh: number;
    }
  }

  export interface WeatherStation {
    id: number;
    jurisdiction: Common.Jurisdictions | null;
    height: number;
    name: string;
    bomId: string;
    observations: WeatherStation.Observation[];
  }

  export interface ForecastItem {
    forecast: [Date, number][];
    forecastIssueTime: Date;
    hindcast?: [Date, number][];
    hindcastIssueTime?: Date;
  }

  export type Forecast = Record<Partial<LayerManager.Layer.LayerIds>, ForecastItem>;

  export interface Dataset {
    name: string;
    values: [string, number][];
  }

  export interface GetParams {
    coords: number[];
    weatherStationId?: number;
  }
}

export interface Meteograms {
  series: Meteograms.Forecast;
  keyFactors: Record<Partial<LayerManager.Layer.LayerIds>, number>;
  weatherStation?: Meteograms.WeatherStation;
}

const mapWeatherStation = (ws: Meteograms.API.WeatherStationInfo): Meteograms.WeatherStation => ({
  id: ws.weather_station_id,
  bomId: ws.bom_id,
  height: ws.stn_height,
  name: ws.stn_name,
  jurisdiction: ws.jurisdiction_name,
  observations: ws.observations.map((obs) => ({
    id: obs.weather_observation_id,
    created: new Date(obs.time_utc),
    airTemperature: obs.air_temperature,
    rainfall: obs.rainfall ?? NaN,
    relHumidity: obs.rel_humidity,
    windDir: obs.wind_dir,
    windDirDeg: obs.wind_dir_deg,
    windSpeedKmh: obs.wind_spd_kmh,
  })),
});

export const getMeteograms = makeAPICall<Meteograms, Meteograms.GetParams, Meteograms.API.Root>(
  ({ coords, weatherStationId }) => ({
    ext: `/fdv/timeseries?longitude=${coords[0]}&latitude=${coords[1]}${
      weatherStationId != null ? `&weather_station_id=${weatherStationId}` : ''
    }`,
  }),
  (root) => {
    const ws = root.weather_station_info;
    return {
      keyFactors: root.key_factors,
      series: Object.keys(root.series).reduce<Record<Partial<LayerManager.Layer.LayerIds>, Meteograms.ForecastItem>>(
        (obj, id) => {
          if (!LayerManager.isValidLayer(id)) return obj;
          const item = root.series[id];
          return {
            ...obj,
            [id]: {
              forecastIssueTime: new Date(item.forecast_issue_time),
              hindcastIssueTime: item.hindcast_issue_time ? new Date(item.hindcast_issue_time) : undefined,
              forecast: item.forecast.map((i) => [new Date(i[0]), i[1]]),
              hindcast: item.hindcast ? item.hindcast.map((i) => [new Date(i[0]), i[1]]) : undefined,
            },
          };
        },
        {} as Record<Partial<LayerManager.Layer.LayerIds>, Meteograms.ForecastItem>,
      ),
      weatherStation: ws != null ? mapWeatherStation(ws) : undefined,
    };
  },
);

export const identifyWeatherStations = makeAPICall<
  Meteograms.WeatherStation[],
  { bbox: Common.Bounds },
  Meteograms.API.WeatherStationsRoot
>(
  ({ bbox }) => ({
    ext: `/fdv/weather-station/latest-observations?min_longitude=${bbox.minLong}&min_latitude=${bbox.minLat}&max_longitude=${bbox.maxLong}&max_latitude=${bbox.maxLat}`,
  }),
  (root) => root.data.map(mapWeatherStation),
);
