import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { LocationManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      margin: theme.spacing(0.25),
      padding: theme.spacing(1),
      border: `0px`,
      backgroundColor: 'rgba(0,0,0,0.75)',
      display: 'grid',
      borderRadius: 4,
    },
    white: {
      color: theme.palette.common.white,
    },
    btn: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.85)',
      },
    },
    leftRight: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto',
    },
    title: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
      width: '100%',
    },
    subtitle: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
    },
    value: {
      color: theme.palette.common.neutralXLight,
      fontWeight: 'bold',
      paddingLeft: theme.spacing(1),
    },
  }),
);

interface LocationPopupProps {
  close: () => void;
  location?: LocationManager.Location.LocationAdminAPI.Location;
  onViewDetails?: (location: LocationManager.Location.LocationAdminAPI.Location) => void;
}

const LocationPopup: React.FunctionComponent<LocationPopupProps> = ({ close, location, onViewDetails }) => {
  const classes = useStyles();

  if (location == null)
    return (
      <Paper className={classes.root}>
        <Typography className={classes.title} variant="subtitle1">
          Location Not Found
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className={classes.btn} onClick={close}>
            <Close /> Close
          </Button>
        </div>
      </Paper>
    );

  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} variant="subtitle1">
        {location.location_name ?? 'No title'}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {location.status === 'active' ? 'Active' : 'Inactive'}
      </Typography>

      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Fire Management District:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {location.fire_management_area_name ?? 'N/A'}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Fire Weather District:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {location.fire_area_name ?? 'N/A'}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Sub-area:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {location.sub_area_name ?? 'N/A'}
        </Typography>
      </div>

      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Observer Count:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {location.observer_count ?? '0'}
        </Typography>
      </div>

      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Validator Count:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {location.validator_count ?? '0'}
        </Typography>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button className={classes.btn} onClick={close}>
          <Close /> Close
        </Button>
        <Button className={classes.btn} onClick={() => onViewDetails && onViewDetails(location)}>
          View Details
        </Button>
      </div>
    </Paper>
  );
};

LocationPopup.displayName = 'LocationPopup';
export default LocationPopup;
